import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import aggregators from "./children/aggregators.json";
import AvalibaleToBeREcycled from "./components/AvalibaleToBeREcycled";
import {
  getInventory,
  getRecycledBatches,
  markAsRecycled,
} from "../../redux/action/pickups";
import LotHistory from "./children/LotHistory";
import { ClipLoader } from "react-spinners";
import { Checkbox, Modal, Tab, Tabs } from "react-materialize";
import InvCard from "./components/InvCard";
import { Link, useNavigate } from "react-router-dom";
import { getReports } from "../../redux/action/analytics";
import MarkAsRecycledModal from "./components/MarkAsRecycledModal";
import MaterialInward from "./components/MaterialInward";
import VirtualStorageUnit from "./components/VirtualStorageUnit";
const Inventory = () => {
  const {
    inventory,
    loadingInventory,
    loadingRecycledBatches,
    creatingRecycledBatch,
  } = useSelector((state) => state.pickups);
  const dispatch = useDispatch();
  const [selected, setSelected] = React.useState([]);
  const navigate = useNavigate();
  const { reports } = useSelector((state) => state.analytics);
  const [contaminationLevel, setContaminationLevel] = React.useState(0);
  const [quantity, setQuantity] = React.useState(0);

  useEffect(() => {
    dispatch(getInventory());
    dispatch(getRecycledBatches());
    dispatch(getReports());
  }, [dispatch]);

  return (
    <div className="p-1">
      <div className="flex space-between">
        <p className="bold">Inventory & Recycled Lot</p>
      </div>

      <div className="mt-2">
        <Tabs>
          <Tab title="material Intake" idx="material-inward">
            <MaterialInward />
          </Tab>

          <Tab title="Virtual Storage UnitS" idx="vsu">
            <VirtualStorageUnit />
          </Tab>

          <Tab title="Recycled Outputs" idx="recycled">
            {/* <RecycledBatches /> */}
            <LotHistory />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Inventory;
