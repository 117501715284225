import React,{useState} from 'react'
import EditGoverance from './EditGoverance/EditGoverance'

export default function WhistleQA() {
  const [showEditGoverance, setShowEditGoverance] = useState(false)

  const handleSubmit = () => {
    setShowEditGoverance(true)
  }
  return (
    <div>
       {!showEditGoverance && (
    <>
      <div
        className="full-width flex justify-center align-center gap-1"
        style={{ background: '#FFFDBE', padding: '5px' }}
      >
        <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
          info
        </span>
        <p className="purple-cercle-text small-text">
          Data entries require supporting documentation for verification.
        </p>
      </div>
      <div className="p-2 questionery-1">
        <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
          <p>
            How many instances of Whistle blowers have been reported or
            identified during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                style={{ width: '30%' }}
                placeholder="Enter the Number of Whistle blowers"
              />
            </span>
          </div>
        </div>
        <div className="qa-1" style={{ fontSize: '18px' }}>
          <p>Current status of resolving the Ethics Violations</p>
          <div style={{ position: 'relative' }}>
            <select
              className="browser-default scrap-select-dropdown"
              style={{
                background: '#FAFAFA',
                border: 'none',
                width: '30%',
              }}
            >
              <option> select the status</option>
              <option>Resolved</option>
              <option>Under investigation</option>
              <option>Pending action</option>
            </select>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 320,
                top: 10,
                color: '#6F2DA8',
              }}
            >
              arrow_drop_down
            </span>
          </div>
        </div>
        <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
          <p>
             How many instances of Whistle blowers have occurred previous year
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                style={{ width: '40%' }}
                placeholder="Enter the Number of Whistle blowers occurred previous year"
              />
            </span>
          </div>
        </div>
        <div className="flex justify-end p-1">
          <button className="btn" onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </>
        )}
        {showEditGoverance && <EditGoverance />}
    </div>
  )
}
