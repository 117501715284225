import React, { useEffect, useState } from "react";
import { Switch } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";
import { notify, notifyError } from "../../../helpers/helper";
import {
  getInvitations,
  inviteAccount,
} from "../../../redux/action/organization";
import { permissions } from "../../../helpers/permissions";
import { useMediaQuery } from "react-responsive";

const InviteAccount = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [allPermissions, setAllPermissions] = useState([]);
  const [disAllowedPermissions, setDisAllowedPermissions] = useState([]);
  const [openPermissions, setOpenPermissions] = useState(false);
  const dispatch = useDispatch();
  const { loadingAccounts } = useSelector((state) => state.organization);

  function sendInvitation(e) {
    if (loadingAccounts || !email || !role)
      return notifyError("Please fill all fields");

    e.preventDefault();

    const data = {
      email,
      role,
      permissions: allPermissions,
      disAllowedPermissions,
    };

    dispatch(
      inviteAccount(data, () => {
        dispatch(getInvitations());
        let elem = document.getElementById("invitation-modal-v2");
        let instance = M.Modal.getInstance(elem);
        instance.close();
        notify("Invitation sent to user", "cercle-purple");
        setEmail("");
        setRole("");
        setAllPermissions([]);
      })
    );
  }

  useEffect(() => {
    if (role === "admin")
      setAllPermissions([...permissions["industry"], ...permissions["admin"]]);
    else if (role === "manager")
      setAllPermissions([...permissions["industry"]]);
    else setAllPermissions([]);
  }, [role]);

  return (
    <section>
      <h4 className="" style={{ margin: 0 }}>
        Invite An Account
      </h4>

      <p className="grey-text small-text">
        Invite a colleague to join your organization on this platform.
      </p>
      <div className="flex mt-1" style={{ gap: "20px" }}>
        <div className="full-width">
          <div className="">
            <h6 className="">Email</h6>
            <div class="row">
              <input
                className="browser-default input-style "
                type="text"
                placeholder="Eg. johndoe@example.com"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>

          <div>
            <h6>Select Role</h6>
            <div className="row  ">
              <select
                className="input-style full-width browser-default "
                onChange={(e) => setRole(e.target.value)}
                value={role}
              >
                <option value={""}>Select the role of the member</option>
                <option value={"admin"}>Admin</option>
                <option value={"manager"}>Site Manager</option>
              </select>
            </div>
          </div>

          <div
            onClick={() => {
              setOpenPermissions(!openPermissions);
            }}
            className="flex align-center lh-0 cercle-purple-text mb-1"
          >
            {openPermissions === true ? (
              <span class="material-symbols-outlined">close</span>
            ) : (
              <span class="material-symbols-outlined">add</span>
            )}
            <b>Add Permissions</b>
          </div>

          {openPermissions ? (
            <div
              className={
                isTablet
                  ? "flex full-width align-center column"
                  : "flex full-width align-center permissions-container"
              }
            >
              <div
                style={{
                  borderRight: !isTablet ? "1px solid #FFFFFF" : null,
                  marginTop: isTablet ? "" : "-10.5rem",
                  borderBottom: isTablet ? "1px solid #FFFFFF" : null,
                }}
                className={
                  isTablet
                    ? "flex column"
                    : "permissions-container-children flex column"
                }
              >
                <b>Standard Permissions</b>
                <ul>
                  {permissions["industry"].map((permission) => (
                    <li>
                      <div
                        style={{ padding: "3px" }}
                        className={
                          isTablet
                            ? "flex gap-2 space-between"
                            : "flex space-between"
                        }
                      >
                        <p className="small-text grey-text">{permission}</p>
                        <Switch
                          offLabel="Off"
                          onChange={function noRefCheck(c) {
                            if (allPermissions.includes(permission)) {
                              let index = allPermissions.findIndex(
                                (item) => item === permission
                              );
                              allPermissions.splice(index, 1);
                              setAllPermissions([...allPermissions]);

                              disAllowedPermissions.push(permission);
                              setDisAllowedPermissions([
                                ...disAllowedPermissions,
                              ]);
                            } else {
                              allPermissions.push(permission);
                              setAllPermissions([...allPermissions]);

                              disAllowedPermissions.splice(
                                disAllowedPermissions.findIndex(
                                  (item) => item === permission
                                ),
                                1
                              );
                              setDisAllowedPermissions([
                                ...disAllowedPermissions,
                              ]);
                            }
                          }}
                          onLabel="On"
                          checked={
                            allPermissions.includes(permission) ? true : false
                          }
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div
                style={{
                  borderLeft: !isTablet ? "1px solid #FFFFFF" : null,
                  borderTop: isTablet ? "1px solid #FFFFFF" : null,
                }}
                className={
                  isTablet ? "mt-1 " : "mt-1 permissions-container-children"
                }
              >
                <b className="mb">Admin Permissions</b>

                <ul>
                  {[
                    "remove-location",
                    "assign-location",
                    "add-location",
                    "remove-network",
                    "add-member",
                    "add-scraps",
                    "accept-invitation",
                    "remove-member",
                    "remove-scrap",
                    "add-network",
                    "update-network",
                  ].map((permission) => (
                    <li>
                      <div
                        style={{ padding: "3px" }}
                        className={
                          isTablet
                            ? "flex gap-2 space-between"
                            : "flex space-between"
                        }
                      >
                        <p className="small-text grey-text">{permission}</p>
                        <Switch
                          offLabel="Off"
                          onChange={function noRefCheck(c) {
                            if (allPermissions.includes(permission)) {
                              let index = allPermissions.findIndex(
                                (item) => item === permission
                              );
                              allPermissions.splice(index, 1);
                              setAllPermissions([...allPermissions]);

                              disAllowedPermissions.push(permission);
                              setDisAllowedPermissions([
                                ...disAllowedPermissions,
                              ]);
                            } else {
                              allPermissions.push(permission);
                              setAllPermissions([...allPermissions]);

                              disAllowedPermissions.splice(
                                disAllowedPermissions.findIndex(
                                  (item) => item === permission
                                ),
                                1
                              );
                              setDisAllowedPermissions([
                                ...disAllowedPermissions,
                              ]);
                            }
                          }}
                          onLabel="On"
                          checked={allPermissions.includes(permission)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="flex justify-center mt-1">
        <button className="btn mt-1" onClick={sendInvitation}>
          {loadingAccounts ? "Sending Invitation..." : "Send Invitation"}
        </button>
      </div>
    </section>
  );
};

export default InviteAccount;
