import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import { getInventory } from "../../../redux/action/pickups";
import { useDispatch, useSelector } from "react-redux";
import { SquareLoader } from "react-spinners";
import VSU from "./children/VSU";
import { useNavigate } from "react-router-dom";
import PercentageChart from "./children/PercentageChart";

function VirtualStorageUnit() {
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedPickup, setSelectedPickup] = useState({});
  const [showMaterialIntakeModal, setShowMaterialIntakeModal] = useState(false);
  const { inventory, loading } = useSelector((state) => state.pickups);
  const [quantityAnalysis, setQuantityAnalysis] = useState([]);
  const [scraps, setScraps] = useState([]);
  const [scrap, setScrap] = useState({
    value: "",
    name: "all available scraps",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInventory());
  }, [dispatch]);

  useEffect(() => {
    setData(inventory);
  }, [inventory]);

  useEffect(() => {
    let scraps = [{ name: "all available scraps", value: "" }];

    inventory.forEach(({ receivedFrom, items }) => {
      items.forEach((item) => {
        if (scraps.some((scrap) => scrap.value === item.scrap._id)) return;
        scraps.push({
          name: item.scrap.name + "-" + item.scrap.variant,
          value: item.scrap._id,
        });
      });
      return scraps;
    });

    setScraps(scraps);
  }, [inventory]);

  useEffect(() => {
    //handle search by scrap
    if (scrap.value === "") return setData(inventory);
    const newData = inventory.filter(({ generator, items }) => {
      return items.some((item) => item.scrap._id === scrap.value);
    });
    setData(newData);
  }, [inventory, scrap]);

  useEffect(() => {
    //handle search by keyword
    if (keyword === "") return setData(inventory);
    const newData = inventory.filter(({ receivedFrom, items }) => {
      return receivedFrom.name.toLowerCase().includes(keyword.toLowerCase());
    });

    setData(newData);
  }, [inventory, keyword]);

  useEffect(() => {
    const pickups = data.map(({ receivedFrom, items }) => {
      return items.map((item) => {
        return item.pickups.map((pickup) => {
          return {
            ...pickup,
            organization: receivedFrom,
            colors: item.colors,
          };
        });
      });
    });

    const flatPickups = pickups.flat(2);

    const quantityAnalysis = flatPickups.reduce((acc, pk) => {
      const index = acc.findIndex(
        (i) => i.organization === pk.organization._id
      );

      if (index === -1) {
        acc.push({
          organization: pk.organization._id,
          name: pk.organization.name,
          value: pk.y,
          ids: [pk._id],
          color: pk.colors[0],
        });
        return acc;
      }

      acc[index].value += pk.y;
      acc[index].ids.push(pk._id);

      return acc;
    }, []);

    setQuantityAnalysis(quantityAnalysis);
  }, [data]);

  const navigate = useNavigate();

  return (
    <div>
      <span className="row full-width flex space-between"></span>

      <header className="flex space-between align-center mb-1 white lighten-2 p-1">
        <p className="bold">Virtual Storage Unit </p>

        <div>
          <input
            id="search"
            type="text"
            className="browser-default disable-focus"
            style={{
              borderRadius: "5px",
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
            }}
            placeholder="Search Aggregator or Trader"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            value={keyword}
          />
        </div>

        <div>
          <select
            className="icons browser-default "
            style={{
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
            onChange={(e) => {
              setScrap({
                value: e.target.value,
                name: e.target.selectedOptions[0].text,
              });
            }}
          >
            {scraps.map((scrap, i) => (
              <option value={scrap.value} key={i}>
                {scrap.name}
              </option>
            ))}
          </select>
        </div>

        <button className="btn" onClick={() => navigate("/create-lot")}>
          Create Recycled Lot
        </button>
      </header>

      <div className="mt-2 mb-2 p-2">
        <b className="mb-1">
          Visual representation of the quantity of materials received from
          different organizations
        </b>
        <PercentageChart data={quantityAnalysis} />
      </div>

      <div className="flex-wrap align-center align-center gap-1 flex ">
        {loading ? (
          <div
            style={{ width: "100%", height: "100%" }}
            className="flex align-center justify-center space-between"
          >
            <SquareLoader
              color={"#6f2da817"}
              loading={true}
              css={{ textAlign: "center" }}
              size={300}
            />
            <SquareLoader
              color={"#6f2da817"}
              loading={true}
              css={{ textAlign: "center" }}
              size={300}
            />

            <SquareLoader
              color={"#6f2da817"}
              loading={true}
              css={{ textAlign: "center" }}
              size={300}
            />
          </div>
        ) : data.length === 0 ? (
          <div className=" full-width flex column align-center justify-center p-2 ">
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "100px", color: "#ccc" }}
            >
              shelves
            </span>
            <p className="grey-text">No New Materials Received So Far</p>
          </div>
        ) : (
          data.map(({ receivedFrom, items }, index) => {
            if (items?.length === 0) return null;

            return items.map((item) => {
              return (
                <VSU
                  item={item}
                  organization={receivedFrom}
                  data={item.pickups}
                  colors={item.colors}
                  key={index}
                  setSelectedPickup={setSelectedPickup}
                  setShowMaterialIntakeModal={setShowMaterialIntakeModal}
                />
              );
            });
          })
        )}
      </div>

      <Modal
        onClose={() => setShowMaterialIntakeModal(false)}
        open={showMaterialIntakeModal}
      ></Modal>
    </div>
  );
}

export default VirtualStorageUnit;
