import { Tooltip } from "react-tooltip";
import { isTabletOrMobile } from "../../../helpers/helper";

export const PickupStatus = ({ item }) => {
    return (
      <div
        style={{
          border: "1px solid #EAECF0",
          borderRadius: "20px",
          height: "1.2rem",
          position: "relative",
          textAlign: isTabletOrMobile ? "center" : "none",
        //   width: "fit-content",
        }}
        data-tooltip-id="status"
        data-tooltip-content={
          item.status === "requested"
            ? "Pickup Raised"
            : item.status === "accepted"
            ? "Pickup Accepted"
            : item.status === "assigned"
            ? "Pending"
            : null
        }
        // data-tooltip-place="right"
      >
        <div
          className="white-text full-height"
          style={{
            background:
              item.status === "requested"
                ? "#FFD400"
                : item.status === "accepted"
                ? "#FF881B"
                : item.status === "assigned"
                ? "#3871DF"
                : item.status === "completed"
                ? "#27AE60"
                : null,
            width:
              item.status === "requested"
                ? 25 + "%"
                : item.status === "accepted"
                ? 50 + "%"
                : item.status === "assigned"
                ? 75 + "%"
                : item.status === "completed"
                ? 100 + "%"
                : 0 + "%",
            borderRadius: "20px",
          }}
        />
        <span
          className="white-text"
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          {item.status === "completed" ? "Completed" : ""}
        </span>
        <Tooltip id="status" />
      </div>
    );
  };