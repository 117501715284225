import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculateTotal } from "../../../helpers/helper";
import { getPickupsHistory } from "../../../redux/action/pickups";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "react-tooltip";

function ReportsPickupHistory() {
  const navigate = useNavigate();
  const { history } = useSelector((state) => state.pickups);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPickupsHistory());
  }, [dispatch]);

  return (
    <div className="box  full-width white ">
      <div className="flex ph-1  align-center space-between ">
        <b className="bold">Pickups History</b>
        <Link to="/pickups-history" className="cercle-purple-text">
          View All
        </Link>
      </div>

      <table className={isTablet ? "striped  full-width responsive-table" : "striped  full-width responsive-table accounttable"}>
        <thead>
          <tr>
            <th>Pickup Date </th>
            <th>Processor</th>
            <th>Price</th>
            <th>GST</th>
            <th>Total</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {history.slice(-4).map((item, index) => {
            return (
              <tr
                key={index}
                onClick={() => navigate("/pickup/" + item._id)}
                className="tableHover"
              >
                <td>{new Date(item.date).toDateString()}</td>
                <td>{item.processor?.name}</td>
                <td>Rs. {item.price}/kg</td>
                <td>N/A</td>
                <td>Rs.{calculateTotal(item.price, item.quantity)}</td>

                <td>
                  <div
                    style={{
                      border: "1px solid #EAECF0",
                      borderRadius: "20px",
                      height: "1.5rem",
                      position: 'relative',
                      textAlign: isTablet ? "center" : 'none'
                    }}
                    data-tooltip-id="status"
                    data-tooltip-content={
                      item.status === 'requested'
                        ? "Pickup Raised"
                        : item.status === 'accepted'
                        ? "Pickup Accepted"
                        : item.status === 'assigned'
                        ? "Pending"
                        : null
                      }
                    // data-tooltip-place="right"
                  >
                    <div
                      className="white-text full-height"
                      style={{
                        background:
                          item.status === 'requested'
                            ? "#FFD400"
                            : item.status === 'assigned'
                            ? "#FF881B"
                            : item.status === 'accepted'
                            ? "#3871DF"
                            : item.status === 'completed'
                            ? "#27AE60"
                            : null,
                        width:
                        item.status === 'requested'
                          ? 25 + '%'
                          : item.status === 'assigned'
                          ? 50 + '%'
                          : item.status === 'accepted'
                          ? 75 + '%'
                          : item.status === 'completed'
                          ? 100 + '%'
                          : 0 + '%',
                        borderRadius: "20px",
                      }}
                    />
                    <span className="white-text" style={{ position: 'absolute', top: 2, bottom: 0, left: 0, right: 0 }}>
                      {item.status === 'completed' ? 'Completed' : ''}
                    </span>
                    <Tooltip id="status"/>
                  </div>
                </td>
                <td>
                  <span class="material-symbols-outlined grey-text text-darken-1">
                    visibility
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {(history?.length === 0 || !history) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            fire_truck
          </span>
          <p className="grey-text mt-1 ">
            Currently you don’t have any pickups.
          </p>
        </div>
      )}
    </div>
  );
}

export default ReportsPickupHistory;
