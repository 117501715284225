import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IWMURL } from "../../../redux/config/urlConfig";
import { CompanyLogo } from "../../../modules";
import { Modal } from "react-materialize";
import UploadImage from "./UploadImage";
import { useMediaQuery } from "react-responsive";

function AccountInfoHeader() {
  const { account } = useSelector((state) => state.account);
  const [src, setSrc] = useState(null);
  const [srcV2, setSrcV2] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);
  const [openCropV2, setOpenCropV2] = useState(false);
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setSrc(reader.result);
        setOpenCrop(true);
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onSelectFileV2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setSrcV2(reader.result);
        setOpenCropV2(true);
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className={isMobile ? "flex white p-2 mb-1 justify-center align-center " : "flex white p-2 mb-1 space-between align-center "}>
      <div className={isMobile ? "flex column align-center gap-2" : "flex align-center gap-2 "}>
        <div className="account-img">
          <img
            style={{
              width: 100,
              height: 100,
              borderRadius: 50,
            }}
            src={src ? src : `${IWMURL}/account/read/photo/${account._id}`}
            alt="profile"
            crossOrigin="anonymous"
          />

          <div className="edit-btn ">
            <input
              type="file"
              id="imageInput"
              accept="image/*"
              onChange={onSelectFile}
              style={{ display: "none" }}
            />
            <label htmlFor="imageInput" className="upload-button">
              <span
                class="material-symbols-outlined white-text upload-button"
                htmlFor="imageInput"
                style={{ fontSize: 18 }}
              >
                edit
              </span>
            </label>
            <Modal style={{ width: '20%', borderRadius: '10px' }} actions={<></>} id="crop-img" open={openCrop}>
              <UploadImage src={src} setOpenCrop={setOpenCrop} />
            </Modal>
          </div>
        </div>
        <div>
          <b>{account.name}</b>
          <p className="grey-text">{account.phoneNumber}</p>
          <p className="grey-text">{account.email}</p>
        </div>
      </div>
      {!isMobile ? 
        <div style={{ position: 'relative' }}>
          <CompanyLogo
          URL={srcV2}
            style={{ width: "70px", height: "70px", objectFit: "cover" }}
          />

          <div className="edit-btn-organization ">
            <input
              type="file"
              id="imageInputV2"
              accept="image/*"
              onChange={onSelectFileV2}
              style={{ display: "none" }}
            />
            <label htmlFor="imageInputV2" className="upload-button">
              <span
                class="material-symbols-outlined white-text upload-button"
                htmlFor="imageInputV2"
                style={{ fontSize: 18 }}
              >
                edit
              </span>
            </label>
            <Modal style={{ width: '20%', borderRadius: '10px' }} actions={<></>} id="crop-imgV2" open={openCropV2}>
              <UploadImage src={srcV2} setOpenCrop={setOpenCropV2} />
            </Modal>
          </div>
        </div> 
      : null}
    </div>
  );
}

export default AccountInfoHeader;