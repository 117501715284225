import React, { useState } from "react";
import { useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { Tooltip } from "react-tooltip";
import { IWMURL } from "../../../../redux/config/urlConfig";

function VSU({
  item,
  data,
  setSelectedPickup,
  setShowMaterialIntakeModal,
  organization,
}) {
  const handleLotData = (index) => {
    if (index >= 0 && index < series[0].data.length) {
      setSelectedPickup({ ...data[index], organization });
      setShowMaterialIntakeModal(true);
    }
  };
  const series = [
    {
      data,
    },
  ];

  return (
    <div className="white  box" style={{ width: "32%" }}>
      <div className="flex align-center space-between ">
        <span>
          <p style={{ fontWeight: "900" }}>#{organization.name}</p>
          <span className="flex align-center  ">
            {/* <img
              src="../images/Plastic-PP.png"
              style={{ width: "20px", height: "20px" }}
              alt="plastic"
            /> */}
            <img
              src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
              className=""
              alt="dashboard-scrap"
              style={{ width: "25px", height: "25px" }}
            />
            <p className="small-text ">
              {item.scrap.name}-{item.scrap.variant}
            </p>
          </span>
        </span>
        <span
          style={{
            fontSize: "11px",
            fontWeight: "700",
            backgroundColor: item.colors[0],
            padding: "0 5px",
            borderRadius: "2px",
            color: "#fff",
          }}
          className="flex align-center justify-center gap-1 "
        >
          <span className=" small-text material-symbols-outlined">code</span>
          <p> VSU</p>
        </span>
      </div>

      <ApexCharts
        key={data.length}
        options={{
          chart: {
            toolbar: { show: false },
            type: "treemap",
            width: 200,
            height: 200,
            events: {
              click: (
                event,
                chartContext,
                { seriesIndex, dataPointIndex, config }
              ) => {
                handleLotData(dataPointIndex);
              },
            },
          },
          title: {
            text: "",
            align: "left",
            enabled: false,
          },

          colors: item.colors,
        }}
        series={series}
        type="treemap"
      />
    </div>
  );
}

export default VSU;
