import React from "react";
import { Dropdown } from "react-materialize";

const OrderSearchBar = ({ setOrdersToDisplay, ordersToDisplay, orders }) => {
  const [keyword, setKeyword] = React.useState("");

  const searchOrders = (keyword) => {
    if (keyword === "") {
      setOrdersToDisplay(orders);
    } else {
      const filteredOrders = ordersToDisplay.filter(
        (order) =>
          order.scrap.name.toLowerCase().includes(keyword.toLowerCase()) ||
          order.scrap.variant.toLowerCase().includes(keyword.toLowerCase()) ||
          order.scrap._id.toLowerCase().includes(keyword.toLowerCase()) ||
          order.quantity.toString().includes(keyword.toLowerCase()) ||
          order.price.toString().includes(keyword.toLowerCase()) ||
          order.status.toLowerCase().includes(keyword.toLowerCase())
      );
      setOrdersToDisplay(filteredOrders);
    }
  };

  return (
    <div>
      <div className="flex align-center mb-1 gap-1">
        <input
          id="search"
          type="text"
          className="browser-default"
          style={{
            borderRadius: "5px",
            padding: "8px",
            width: "90%",
            border: "1px solid #ccc",
          }}
          placeholder="Search announcement..."
          onChange={(e) => {
            setKeyword(e.target.value);
            searchOrders(e.target.value);
          }}
          value={keyword}
        />

        <Dropdown
          trigger={
            <span className="material-symbols-outlined ">filter_list</span>
          }
        >
          <span
            className="capitalize"
            onClick={() => {
              setOrdersToDisplay(orders);
            }}
          >
            show all
          </span>
          <span
            className="capitalize"
            onClick={() => {
              setOrdersToDisplay(
                orders.filter((order) => order.status === "open")
              );
            }}
          >
            show open
          </span>
          <span
            className="capitalize"
            onClick={() => {
              setOrdersToDisplay(
                orders.filter((order) => order.status === "closed")
              );
            }}
          >
            show closed
          </span>
          <span
            className="capitalize"
            onClick={() => {
              const s = orders.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
              );
              setOrdersToDisplay((prev) => [...s]);
            }}
          >
            Sort by date in asc
          </span>
          <span
            className="capitalize"
            onClick={() => {
              const s = orders.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
              setOrdersToDisplay((prev) => [...s]);
            }}
          >
            sort by date in desc
          </span>
          <span
            className="capitalize"
            onClick={() => {
              const s = orders.sort((a, b) => a.quantity - b.quantity);
              setOrdersToDisplay((prev) => [...s]);
            }}
          >
            sort by quantity in asc
          </span>
          <span
            className="capitalize"
            onClick={() => {
              const s = orders.sort((a, b) => b.quantity - a.quantity);
              setOrdersToDisplay((prev) => [...s]);
            }}
          >
            sort by quantity in desc
          </span>
        </Dropdown>
      </div>
    </div>
  );
};

export default OrderSearchBar;
