import React, { PureComponent } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useMediaQuery } from 'react-responsive'

const data = [
  {
    name: 'Jan',
    People: 1500,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Feb',
    People: 2000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Mar',
    People: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Apr',
    People: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'May',
    People: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Jun',
    People: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'July',
    People: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function PeopelReachedChart() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' });

  return (
    <div className="white box mr-1" style={{ width:isMobile ? '100%':'50%',height: '390px', paddingBottom: '60px' }}>
      <div>
        <h5 style={{ paddingBottom: '10px' }}>People reached</h5>
      
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" />
          {/* <YAxis/> */}
          <Tooltip
            cursor={{ strokeWidth: 0, radius: 1 }}
            wrapperStyle={{
              width: 100,
              backgroundColor: '#f5f5f5',
              fontSize: 14,
              borderRadius: 5,
            }}
          />
          <Area
            type="monotone"
            dataKey="People"
            stroke="#6F2DA8"
            fill="#E0BDFF"
            strokeWidth={5}
            pointRadius={8} 
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
