import React, { useState } from "react";
import { useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { Tooltip } from "react-tooltip";
import { generateColor } from "../../../../helpers/helper";
import { IWMURL } from "../../../../redux/config/urlConfig";

function VSU({
  item,
  pickups,
  setData,
  setOutputData,
  organization,
  setShow,
  setQuantityAnalysis,
  setSelectedPickups,
}) {
  const handleLotData = (index) => {
    if (index === undefined || index === -1) return;

    const pk = { ...pickups[index], organization };

    setShow((prev) => false);

    setOutputData((prev) => {
      const newOutputData = [...prev];
      let arr = newOutputData[0].data;
      arr.push({ x: pk.x, y: pk.y });
      return [{ data: arr }];
    });

    setTimeout(() => {
      setShow((prev) => true);
      setData((prev) => {
        const newData = [...prev];
        const dataIndex = newData.findIndex(({ receivedFrom, items }) => {
          return (
            receivedFrom._id === pk.organization._id &&
            items.some((i) => i.scrap._id === item.scrap._id)
          );
        });

        let vsuIndex = newData[dataIndex].items.findIndex(
          (i) => i.scrap._id === item.scrap._id
        );

        newData[dataIndex].items[vsuIndex].pickups.splice(index, 1);
        return newData;
      });
      setSelectedPickups((prev) => {
        const newSelectedPickups = [...prev];
        newSelectedPickups.push(pk._id);
        return newSelectedPickups;
      });
    }, 100);

    setQuantityAnalysis((prev) => {
      const newQuantityAnalysis = [...prev];
      const index = newQuantityAnalysis.findIndex(
        (i) => i.organization === organization._id
      );

      if (index === -1) {
        newQuantityAnalysis.push({
          organization: organization._id,
          name: organization.name,
          value: pk.y,
          ids: [pk._id],
          color: item.colors[0],
        });
        return newQuantityAnalysis;
      }

      newQuantityAnalysis[index].value += pk.y;
      newQuantityAnalysis[index].ids.push(pk._id);

      return newQuantityAnalysis;
    });
  };

  const series = [
    {
      data: pickups,
    },
  ];

  return (
    <div className="white  box">
      <div className="flex align-center space-between ">
        <span>
          <p style={{ fontWeight: "900" }}>#{organization.name}</p>
          <span className="flex align-center  ">
          <img
              src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
              className=""
              alt="dashboard-scrap"
              style={{ width: "25px", height: "25px" }}
            />
            <p className="small-text ">
              {item.scrap.name}-{item.scrap.variant}
            </p>
          </span>
        </span>
        <span
          style={{
            fontSize: "11px",
            fontWeight: "700",
            backgroundColor: item.colors[0],
            padding: "0 5px",
            borderRadius: "2px",
            color: "#fff",
          }}
          className="flex align-center justify-center gap-1 "
        >
          <span className=" small-text material-symbols-outlined">code</span>
          <p> VSU</p>
        </span>
      </div>

      <ApexCharts
        key={pickups.length}
        options={{
          chart: {
            toolbar: { show: false },
            type: "treemap",
            width: 200,
            height: 200,
            events: {
              click: (
                event,
                chartContext,
                { seriesIndex, dataPointIndex, config }
              ) => {
                handleLotData(dataPointIndex);
              },
            },
          },
          title: {
            text: "",
            align: "left",
            enabled: false,
          },

          colors: item.colors,
        }}
        series={series}
        type="treemap"
      />
    </div>
  );
}

export default VSU;
