import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DashboardCard } from '../../../../modules'
import AgeDistributionChart from './components/AgeDistributionChart'
import ChannelizeChart from './components/EmployeeAdditionChart'
import EmployeeDistributionChart from './components/EmployeeDistributionChart'
import EmployeeStatistics from './components/EmployeeStatistics'
import { useMediaQuery } from 'react-responsive'
import Modal from 'react-responsive-modal'
import EnvironmentalCard from '../Environmental/components/EnvironmentalCard'
import EmpAdditionQA from './components/EmpAdditionQA'
import EmpDistributionQA from './components/EmpDistributionQA'
import EmpSatisfactionQA from './components/EmpSatisfactionQA'
import AgeDistributionQA from './components/AgeDistributionQA'

export default function Social() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })
  const navigate = useNavigate()
  const [openED, setOpenED] = useState(false)
  const [card, setCard] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)
  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const [qu4Visible, setQu4Visible] = useState(false)
  const [qu5Visible, setQu5Visible] = useState(false)
  const [modalTitle, setModalTitle] = useState('Add Social Data')
  const toggleQuestionery = () => {
    setQu1Visible(false)
    setQu2Visible(false)
    setQu3Visible(false)
    setQu4Visible(false)
    setQu5Visible(false)

    setCard(false)
  }
  const onOpenEDModal = () => {
    setOpenED(true)
  }
  const onCloseEDModal = () => setOpenED(false)

  const handleCardClick = (name) => {
    setQu1Visible(name === 'emp-addi')
    setQu2Visible(name === 'emp-dis')
    setQu3Visible(name === 'emp-satis')
    setQu4Visible(name === 'injury-no')
    setQu5Visible(name === 'age-dis')

    onOpenEDModal()

    switch (name) {
      case 'emp-addi':
        setModalTitle('Employee Addition')
        break
      case 'emp-dis':
        setModalTitle('Employee Distribution')
        break
      case 'emp-satis':
        setModalTitle('Employee Satisfaction')
        break
      case 'injury-no':
        setModalTitle('Injuries Number')
        break
      case 'age-dis':
        setModalTitle('Age Distribution')
        break
      default:
        setModalTitle('Add Social Data')
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <div>
        <div>
          <div
            className={
              isMobile ? 'flex column' : 'flex align-center space-between p-1'
            }
          >
            <p className="bold mb-1">ESG</p>
            <div className={isMobile ? 'flex column p-1 gap-1' : 'flex gap-1'}>
              <button
                className="btn button-2"
                onClick={() => {
                  navigate('/environmental')
                }}
              >
                Environmental
              </button>
              <button
                className="btn"
                onClick={() => {
                  navigate('/social')
                }}
              >
                Social
              </button>
              <button
                className="btn button-2"
                onClick={() => {
                  navigate('/esg')
                }}
              >
                Governance
              </button>
              <button
                className="btn button-2"
                onClick={() => {
                  navigate('/impact')
                }}
              >
                Impact
              </button>
            </div>
          </div>
          <div
            className={
              isMobile ? 'flex column gap-1' : 'flex gap-2 align-center mr-1'
            }
          >
            <div style={{ gap: '1.5rem' }} className="flex column flex-1 ml-1">
              <DashboardCard
                title="Employee Satisfaction"
                amount={`${10}MT`}
                desc="+100% vs Pervious year"
                img="../images/empmoney.png"
              />

              <DashboardCard
                title="Injuries Number"
                amount={0}
                desc="overall"
                img="../images/injuries.png"
              />
            </div>

            <AgeDistributionChart />
            <ChannelizeChart />
          </div>
          <div
            className={
              isMobile
                ? 'flex column gap-1 mt-1'
                : 'p-1 flex align-center gap-2'
            }
          >
            <EmployeeDistributionChart />
            <EmployeeStatistics />
            <img src="../images/envimg.png" />
          </div>
        </div>
        <div
          className="ESG-btn mt-1 p-1 fab-style-esg flex align-center justify-center gap-1"
          onClick={onOpenEDModal}
        >
          <span class="material-symbols-outlined" style={{ fontSize: '16px' }}>
            add
          </span>
          <p>Social Data</p>
        </div>
      </div>
      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openED}
        onClose={onCloseEDModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          {!card && (
            <div className="flex align-center gap-1">
              <span
                class="material-symbols-outlined"
                onClick={toggleQuestionery}
              >
                arrow_back
              </span>

              <h4>
                {' '}
                {qu1Visible
                  ? 'Employee Addition'
                  : qu2Visible
                  ? 'Employee Distribution'
                  : qu3Visible
                  ? 'Employee Satisfaction'
                  : qu4Visible
                  ? 'Injuries Number'
                  : qu5Visible
                  ? 'Age Distribution'
                  : 'Add Social Data'}
              </h4>
            </div>
          )}
          {!qu1Visible &&
            !qu2Visible &&
            !qu3Visible &&
            !qu4Visible &&
            !qu5Visible && (
              <div
                onClick={onCloseEDModal}
                className="flex align-center justify-center"
                style={{
                  width: 40,
                  height: 40,
                  background: 'rgba(255, 255, 255, 0.50)',
                  borderRadius: '6px',
                  cursor: 'pointer',
                }}
              >
                <span class="material-symbols-outlined">close</span>
              </div>
            )}
        </div>
        {!qu1Visible &&
          !qu2Visible &&
          !qu3Visible &&
          !qu4Visible &&
          !qu5Visible && (
            <div className="flex column gap-2 p-2 card-section">
              <div className="flex gap-2">
                <div
                  className="full-width"
                  onClick={() => handleCardClick('emp-addi')}
                >
                  <EnvironmentalCard
                    name="emp-addi"
                    image="../images/empaddi.png"
                    icon="../images/emp.png"
                    title="Employee Addition"
                    desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                  />
                </div>
                <div
                  className="full-width"
                  onClick={() => handleCardClick('emp-dis')}
                >
                  <EnvironmentalCard
                    name="emp-dis"
                    image="../images/empdis.png"
                    icon="../images/ic-empdis.png"
                    title="Employee Distribution"
                    desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                  />
                </div>
                <div
                  className="full-width"
                  onClick={() => handleCardClick('emp-satis')}
                >
                  <EnvironmentalCard
                    name="emp-satis "
                    image="../images/empsatis.png"
                    icon="../images/ic-empstatis.png"
                    title="Employee Satisfaction"
                    desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                  />
                </div>
              </div>
              <div className="flex gap-2">
                <div
                  style={{ width: '33%' }}
                  onClick={() => handleCardClick('injury-no')}
                >
                  <EnvironmentalCard
                    name="injury-no"
                    image="../images/injuriesno.png"
                    icon="../images/injuries.png"
                    title="Injuries Number"
                    desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                  />
                </div>
                <div
                  style={{ width: '33%' }}
                  onClick={() => handleCardClick('age-dis')}
                >
                  <EnvironmentalCard
                    name="age-dis"
                    image="../images/awareness.png"
                    icon="../images/emp.png"
                    title="Age Distribution"
                    desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                  />
                </div>
              </div>
            </div>
          )}
        {qu1Visible && (
          <div className="qu-1">
            <div
              className="full-width flex justify-center align-center gap-1"
              style={{ background: '#FFFDBE', padding: '5px' }}
            >
              <span
                class="material-symbols-outlined"
                style={{ fontSize: '14px' }}
              >
                info
              </span>
              <p className="purple-cercle-text small-text">
                Data entries require supporting documentation for verification.
              </p>
            </div>
            <EmpAdditionQA />
          </div>
        )}
        {qu2Visible && (
          <div className="qu-1">
            <EmpDistributionQA />
          </div>
        )}
        {qu3Visible && (
          <div className="qu-1">
            <EmpSatisfactionQA />
          </div>
        )}
        {qu4Visible && (
          <div className="qu-1">
            <EmpSatisfactionQA />
          </div>
        )}
        {qu5Visible && (
          <div className="qu-1">
            <AgeDistributionQA />
          </div>
        )}
      </Modal>
    </div>
  )
}
