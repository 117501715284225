import React from 'react'

export default function ProgressBar({title,number,percent,color}) {
  return (
    <div className="box white mr-1" style={{ width: '100%', height: '17vh' }}>
      <p className="grey-text" style={{ fontSize: '20px' }}>
        {title}
      </p>
      <div className="flex align-center space-between">
        <h4>{number}</h4>
        <p className="grey-text" style={{ fontSize: '20px' }}>
          {percent}
        </p>
      </div>
      <div className="main-progress-bar mt-1">
        <div className="progress-bar " style={{background:`${color}`,width:'300px',height:'20px'}}></div>
      </div>
      <div className="progress-bar "></div>
    </div>
  )
}
