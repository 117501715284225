import React from 'react'
import { Divider } from 'react-materialize'
import { useMediaQuery } from 'react-responsive'

export default function ImpactAnalytic({
  img,
  amt,
  title,
  desc,
  desc1,
  bgColor,
  border,
}) 

{
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' });

  return (
    <div style={{ width:isMobile ? '100%': '25%' }} className="box white">
      <div className="flex align-center gap-2">
        <div
          className="flex align-center"
          style={{
            borderRadius: '50%',
            padding: '10px',
            background: `${bgColor}`,
            border: `1px solid ${border}`,
          }}
        >
          <img src={img} style={{ width: '26px', height: '26px' }} />
        </div>
        <div>
          <h3>{amt}</h3>
          <p style={{ color: '#808080' }}>{title}</p>
        </div>
      </div>
      <Divider />
      <span className="flex gap-1 mt-1 ml-1">
        <p className="grey-text">
          {desc}
          <span style={{ color: '#263238' }}> {desc1}</span>
        </p>
      </span>
    </div>
  )
}
