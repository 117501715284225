import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DashboardCard } from '../../../../modules'
import EmissionCharts from './components/EmissionCharts'
import EneryConsumptionChart from './components/EneryConsumptionChart'
import EneryConsumption from './components/EneryConsumption'
import Modal from 'react-responsive-modal'
import { useMediaQuery } from 'react-responsive'
import EnvironmentalCard from './components/EnvironmentalCard'
import EmissionQA from './components/EmissionQA'
import EnergyQA from './components/EnergyQA'
import WaterEmissionQA from './components/WaterEmissionQA'

export default function Environmental() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })
  const isLaptop = useMediaQuery({ query: '(max-width:1600px)' })

  const navigate = useNavigate()
  const [openED, setOpenED] = useState(false)
  const [card, setCard] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)
  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)

  const [modalTitle, setModalTitle] = useState('Add Social Data')
  const toggleQuestionery = () => {
    setQu1Visible(false)
    setQu2Visible(false)
    setQu3Visible(false)

    setCard(false)
  }
  const onOpenEDModal = () => {
    setOpenED(true)
  }
  const onCloseEDModal = () => setOpenED(false)

  const handleCardClick = (name) => {
    setQu1Visible(name === 'Emission')
    setQu2Visible(name === 'energy')
    setQu3Visible(name === 'water-emission')
    onOpenEDModal()

    switch (name) {
      case 'Emission':
        setModalTitle('CO2 Emissions')
        break
      case 'energy':
        setModalTitle('Energy Consumption')
        break
      case 'sapling':
        setModalTitle('Water Emission')
        break

      default:
        setModalTitle('Add Environmental Data')
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <div
        className={
          isMobile ? 'flex column' : 'flex align-center space-between p-1'
        }
        style={{ position: 'relative' }}
      >
        <p className="bold mb-1">ESG</p>
        <div className={isMobile ? 'flex column p-1 gap-1' : 'flex gap-1'}>
          <button
            className="btn"
            onClick={() => {
              navigate('/environmental')
            }}
          >
            Environmental
          </button>
          <button
            className="btn button-2"
            onClick={() => {
              navigate('/social')
            }}
          >
            Social
          </button>
          <button
            className="btn button-2"
            onClick={() => {
              navigate('/esg')
            }}
          >
            Governance
          </button>
          <button
            className="btn button-2"
            onClick={() => {
              navigate('/impact')
            }}
          >
            Impact
          </button>
        </div>
      </div>
      <div
        style={{ gap: '1.5rem' }}
        className={isLaptop ? 'flex column full-width' : 'flex p-1'}
      >
        <div style={{ gap: '1.5rem' }} className="flex column flex-1">
          <DashboardCard
            title="Co2 Emissions"
            amount={`${0} /MT`}
            desc="+100% vs Pervious year"
            img="../images/newco2.png"
          />

          <DashboardCard
            title="Energy Consumption"
            amount={`${9.93} /MWh`}
            desc="overall"
            img="../images/newwater.png"
          />
        </div>
        <div style={{ gap: '1.5rem' }} className="flex column flex-1">
          <DashboardCard
            title="Water Emissions"
            amount={`${9.93} /ML`}
            desc="This Month"
            img="../images/newenergy.png"
          />

          <DashboardCard
            title="Waste generated"
            amount={`${100} /MT`}
            desc="overall"
            img="../images/watergen.png"
          />
        </div>
        <EmissionCharts />
      </div>
      <div
        className={
          isMobile
            ? 'full-width mt-1 flex column gap-1'
            : 'flex align-center gap-2 ml-1 '
        }
      >
        <EneryConsumptionChart />
        <EneryConsumption />
        <span className="mr-1">
          <img
            src="../images/envimg.png"
            style={{ width: isMobile ? '400px' : '500px', height: '345px' }}
          />
        </span>
      </div>
      <div
        onClick={onOpenEDModal}
        className="ESG-btn mt-1 p-1 pointer fab-style-esg flex align-center justify-center gap-1"
      >
        <span class="material-symbols-outlined" style={{ fontSize: '16px' }}>
          add
        </span>
        <p>Environmental Data </p>
      </div>

      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openED}
        onClose={onCloseEDModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          {!card && (
            <div className="flex align-center gap-1">
              <span
                class="material-symbols-outlined"
                onClick={toggleQuestionery}
              >
                arrow_back
              </span>

              <h4>
                {' '}
                {qu1Visible
                  ? 'CO2 Emissions'
                  : qu2Visible
                  ? 'Energy Consumption'
                  : qu3Visible
                  ? 'Water Emission'
                  : 'Add Environment Data'}
              </h4>
            </div>
          )}
          {!qu1Visible && !qu2Visible && !qu3Visible && (
            <div
              onClick={onCloseEDModal}
              className="flex align-center justify-center"
              style={{
                width: 40,
                height: 40,
                background: 'rgba(255, 255, 255, 0.50)',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              <span class="material-symbols-outlined">close</span>
            </div>
          )}
        </div>
        {!qu1Visible && !qu2Visible && !qu3Visible && (
          <div className="flex column gap-2 p-2 card-section">
            <div className="flex gap-2">
              <div
                className="full-width"
                onClick={() => handleCardClick('Emission')}
              >
                <EnvironmentalCard
                  name="Emission"
                  image="../images/co2emission.png"
                  icon="../images/newco2.png"
                  title="Co2 Emissions"
                  desc="Lower CO2 emissions are the environmental heart of ESG and showcasing sustainability."
                />
              </div>
              <div
                className="full-width"
                onClick={() => handleCardClick('energy')}
              >
                <EnvironmentalCard
                  name="energy"
                  image="../images/windmill.png"
                  icon="../images/newenergy.png"
                  title="Energy Consumption"
                  desc="Lowering energy consumption cuts emissions, boosts efficiency, and shines a bright light on your ESG score."
                />
              </div>
              <div
                className="full-width"
                onClick={() => handleCardClick('water-emission')}
              >
                <EnvironmentalCard
                  name="water-emission"
                  image="../images/waterwaves.png"
                  icon="../images/newenergy.png"
                  title="Water Emission"
                  desc="Minimizing water emissions safeguards precious resources and, propelling your ESG score."
                />
              </div>
            </div>
          </div>
        )}
        {qu1Visible && (
          <div className="qu-1">
            <div
              className="full-width flex justify-center align-center gap-1"
              style={{ background: '#FFFDBE', padding: '5px' }}
            >
              <span
                class="material-symbols-outlined"
                style={{ fontSize: '14px' }}
              >
                info
              </span>
              <p className="purple-cercle-text small-text">
                Data entries require supporting documentation for verification.
              </p>
            </div>

            <EmissionQA />
          </div>
        )}
        {qu2Visible && (
          <div className="qu-1">
            <EnergyQA />
          </div>
        )}
        {qu3Visible && (
          <div className="qu-1">
            <WaterEmissionQA onCloseEDModal={onCloseEDModal} />
          </div>
        )}
      </Modal>
    </div>
  )
}
