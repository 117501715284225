import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function EditScrapPage() {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [formValues, setFormValues] = useState({
    scrapName: "",
    activity: "",
    sapCode: "",
    sourceOfGeneration: "",
    scrapType: "",
    disposalMethod: "",
    hazWasteRule: "",
    storageTimeLimit: "",
    scrapLocation: "",
    wasteStorageType: "",
    dateGenerated: "",
    formForHazardousStorage1: "",
    formForHazardousStorage2: ""
  });
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    const isFormFilled = Object.values(formValues).every(
      (value) => value !== ""
    );
    setSaveButtonDisabled(!isFormFilled || !imageUploaded);
  }, [formValues, imageUploaded]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setSelectedImage(e.target.result);
        setImageUploaded(true); // Set imageUploaded to true when image is uploaded
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSave = () => {
    // Logic to save the form data
    console.log("Form data saved:", formValues);
  };
  return (
    <div className="p-1">
      <div className="flex align-center space-between">
        <div className="flex align-center gap-1">
          <span
            class="material-symbols-outlined pointer"
            onClick={() => navigate(-1)}
          >
            keyboard_backspace
          </span>
          <b className="bold">Edit Scrap</b>
        </div>
        <div>
          <button
            className="btn-small"
            // onClick={handleSave}
            onClick={() => {
              navigate("/new-view-scrap");
            }}
            // disabled={saveButtonDisabled}
          >
            Save
          </button>
        </div>
      </div>
      <div className="scrap-container flex gap-2 mt-1">
        <div
          className="scrap-details flex align-center full-width gap-2  white p-2"
          style={{ borderRadius: '10px' }}
        >
          <div className="full-width">
            <div>
              <p>Scrap Name</p>
              <input
                className="new-input"
                placeholder="Enter the scrap name"
                name="scrapName"
                value={formValues.scrapName}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <p>Activity</p>
              <input
                className="new-input"
                placeholder="Enter the activity"
                name="activity"
                value={formValues.activity}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <p>SAP code</p>
              <input
                className="new-input"
                placeholder="Enter the SAP code if any"
                name="sapCode"
                value={formValues.sapCode}
                onChange={handleInputChange}
              />
            </div>

            <div>
              <p>Source of Generation*</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Generation of damaged Plastic pallets</option>
                  <option>Generation of damaged Wooden pallets</option>
                  <option>Generation of RM Carton Box/Carton waste</option>
                  <option>RM Jumbo bag</option>
                  <option>PP small bags 25kg</option>
                  <option>Plastic liner waste</option>
                  <option>Empty Plastic white square barell in Cage</option>
                  <option>Empty Plastic Black/Blue barell</option>
                  <option>PVC Sweeping Dust</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
            <div>
              <p>Scrap type</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>select type</option>
                  <option>Wood Waste</option>
                  <option>Plastic Waste</option>
                  <option>Paper/Carton Waste</option>
                  <option>Pipes & Fittings Processing generated waste. </option>
                  <option>Solvent Processing generated waste. </option>
                  <option>Lab Testing Sample waste. </option>
                  <option>Chemical soaked cotton waste</option>
                  <option>Printing catridge</option>
                  <option>Chemical stored container</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>

            <div>
              <p>Disposal method</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Sold to Authorised Vendor</option>
                  <option>Send to CPCB/SPCB Authorized Recycler</option>
                  <option>Pulverised & Reused in production process </option>
                  <option>Pipes & Fittings Processing generated waste </option>
                  <option>Recycle & Reused in production process </option>
                  <option>Dried & Reused in freezing powder</option>
                  <option>Sold to Authorised Vendor</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
            <div>
              <p>Schedule of Haz Waste rule - HWR</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>5.1</option>
                  <option>2.9</option>
                  <option>8.2</option>
                  <option>5.1</option>
                  <option>2.9</option>
                  <option>8.2</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
            <div>
              <p>Allowable Storage Time limit</p>
              <input
                className="new-input"
                placeholder="Enter the storage time limit"
                name="storageTimeLimit"
                value={formValues.storageTimeLimit}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="full-width">
            <div>
              <p>Allowable Storage Time limit</p>
              <input
                className="new-input"
                placeholder="Enter the storage time limit"
                name="storageTimeLimit"
                value={formValues.storageTimeLimit}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <p>Scrap Location</p>
              <input
                className="new-input"
                placeholder="Enter the location"
                name="scrapLocation"
                value={formValues.scrapLocation}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <p>Waste Storage Type</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Select by State</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
            <div>
              <p>Date Generated</p>
              <input
                className="new-input"
                placeholder="Select the date"
                name="dateGenerated"
                type="date"
                value={formValues.dateGenerated}
                onChange={handleInputChange}
                // style={{ color: '#6F2DA8' }}
              />
            </div>

            <div>
              <p>Waste Classification</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Select by State</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
              <div className="mt-1" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Select by Degradable</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
              <div className="mt-1" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Select by Hazardous</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
            <div>
              <p>Form for storage of Hazardous</p>
              <div className="" style={{ position: "relative" }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{ background: "#FAFAFA", border: "none" }}
                >
                  <option>Select the form</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    color: "#6F2DA8"
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="scrap-upload-img flex column gap-2 p-1"
          style={{ height: "500px" }}
        >
          <div className="scrap-img" style={{ position: "relative" }}>
            {imageUploaded && (
              <>
                <span
                  className="material-symbols-outlined"
                  style={{
                    position: "absolute", // Position the icon on top of the image
                    top: "10px",
                    right: "10px",
                    backgroundColor: "#ECE4F4", // Set the background color
                    padding: "5px",
                    borderRadius: "10%",
                    color: "#6F2DA8"
                  }}
                >
                  delete
                </span>
              </>
            )}
            {imageUploaded ? (
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: "100%" }}
              />
            ) : (
              <div className="upload-box">
                {!imageUploaded && (
                  <div>
                    <div className="dotted-border"></div>
                  </div>
                )}
                <div className="flex align-center justify-center full-width ml-2">
                  <label
                    htmlFor="file-upload"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "10px",
                      color: "#6F2DA8"
                    }}
                  >
                    <span className="material-symbols-outlined">
                      add_photo_alternate
                    </span>
                    <p>Upload Image</p>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            )}
          </div>
          {imageUploaded && (
            <div className="upload-btn">
              <span class="material-symbols-outlined">add_photo_alternate</span>
              <p className="normal-text">Upload Image</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
