import { IWM } from "../config/urlConfig";
import { toast } from "react-toastify";
import { handleError } from "../../helpers/helper";
import { dispatch } from "d3";

export const getVehicles = () => async (dispatch) => {
  dispatch({ type: "GETTING_VEHICLES" });
  IWM.get("/group/read/vehicles")
    .then((res) => {
      dispatch({ type: "GOT_VEHICLES", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_VEHCILES" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getAccounts = () => async (dispatch) => {
  dispatch({ type: "GETTING_ACCOUNTS" });

  IWM.get("/organization/read/accounts")
    .then((res) => {
      dispatch({ type: "GOT_ACCOUNTS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_ACCOUNTS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const deleteAccountAction = (id) => async (dispatch) => {
  dispatch({ type: "DELETING_ACCOUNT" });
  IWM.delete(`/network/delete/${id}`)
    .then((res) => {
      dispatch({ type: "DELETED_ACCOUNT" });
      dispatch(getAccounts());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_DELETE_ACCOUNT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getInvitations = () => async (dispatch) => {
  dispatch({ type: "GETTING_INVITATIONS" });
  IWM.get("/organization/read/invitations")
    .then((res) => {
      dispatch({ type: "GOT_INVITATIONS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_INVITATIONS" });
    });
};

export const getNetworkInvitations = () => async (dispatch) => {
  dispatch({ type: "GETTING_INVITATIONS" });
  IWM.get("/network/read/invitations")
    .then((res) => {
      dispatch({ type: "GOT_INVITATIONS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_INVITATIONS" });
    });
};

export const getGroups = () => async (dispatch) => {
  dispatch({ type: "GETTING_GROUPS" });

  IWM.get("/organization/read/groups")
    .then((res) => {
      dispatch({ type: "GOT_GROUPS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_GROUPS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const addVehicle = (formData, navigate, reset) => async (dispatch) => {
  dispatch({ type: "GETTING_VEHICLES" });
  IWM.post("/vehicle/create", formData)
    .then((res) => {
      reset();
      toast.success("Vehicle Uploaded");
      dispatch(getVehicles());
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_VEHICLES" });
      handleError(error);
    });
};

export const getDrivers = () => async (dispatch) => {
  dispatch({ type: "GETTING_DRIVERS" });

  IWM.get("/group/read/drivers")
    .then((res) => {
      dispatch({ type: "GOT_DRIVERS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_DRIVERS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getLocations = () => async (dispatch) => {
  dispatch({ type: "GETTING_LOCATIONS" });

  IWM.get("/group/read/locations")
    .then((res) => {
      dispatch({ type: "GOT_LOCATIONS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_LOCATIONS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const inviteAccount = (data, cb) => async (dispatch) => {
  dispatch({ type: "INVITING_ACCOUNT" });

  IWM.put("/organization/update/invite-account", data)
    .then((res) => {
      dispatch({ type: "INVITED_ACCOUNT" });
      if (cb) cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_INVITE_ACCOUNT" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const inviteNetwork = (data, cb) => async (dispatch) => {
  dispatch({ type: "INVITING_ACCOUNT" });

  IWM.post("/network/create/invite", data)
    .then((res) => {
      dispatch({ type: "INVITED_ACCOUNT" });
      if (cb) cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_INVITE_ACCOUNT" });
      toast.error(error.response?.data || error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const acceptNetworkInvitation = (inviteId, cb) => async (dispatch) => {
  dispatch({ type: "INVITING_ACCOUNT" });

  IWM.put("/network/update/accept-invitation/" + inviteId)
    .then((res) => {
      dispatch({ type: "INVITED_ACCOUNT" });
      if (cb) cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_INVITE_ACCOUNT" });
      toast.error(error.response?.data || error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const isValidInvite = (inviteId, cb) => async (dispatch) => {
  dispatch({ type: "GETTING_INVITATION" });

  IWM.get("/network/read/invite/" + inviteId)
    .then((res) => {
      dispatch({ type: "GOT_INVITATION" });
      if (cb) cb(res.data?.valid);
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_INVITATION" });
      cb(false);
    });
};

export const rescindInvitation = (id, cb) => async (dispatch) => {
  dispatch({ type: "INVITING_ACCOUNT" });
  IWM.put("/network/update/undo-invite-network/" + id)
    .then((res) => {
      dispatch({ type: "INVITED_ACCOUNT" });
      if (cb) cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_INVITE_ACCOUNT" });
      toast.error(error.response?.data || error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const rescindAccountInvitation = (id, cb) => async (dispatch) => {
  dispatch({ type: "CANCELING_INVITATION" });
  IWM.put("/organization/update/undo-invite-account/" + id)
    .then((res) => {
      dispatch({ type: "CANCELED_INVITATION" });
      if (cb) cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_CANCEL_INVITATION" });
      toast.error(error.response?.data || error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const seenNotification = (id) => async (dispatch) => {
  IWM.put("/group/update/notification/" + id)
    .then((res) => {
      // dispatch({ type: "GOT_NOTIFICATIONS", payload: res.data });
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getOrganization = (callback) => async (dispatch) => {
  dispatch({ type: "GETTING_ORGANIZATION" });
  try {
    const { data } = await IWM.get("/organization/read");
    dispatch({ type: "GOT_ORGANIZATION", payload: data });
    if (callback) callback(data);
  } catch (ex) {
    dispatch({ type: "CANNOT_GET_ORGANIZATION" });
    // dispatch(showError(errorHandler(ex)));
  }
};

export const getNetwork = () => async (dispatch) => {
  dispatch({ type: "GETTING_NETWORK" });
  try {
    const { data } = await IWM.get("/network/read");
    dispatch({ type: "GOT_NETWORK", payload: data });
  } catch (ex) {
    dispatch({ type: "CANNOT_GET_NETWORK" });
  }
};

export const addLocation = (location, callback) => async (dispatch) => {
  dispatch({ type: "ADDING_LOCATION" });
  try {
    const { data } = await IWM.put(
      "/organization/update/add-location",
      location
    );
    dispatch({ type: "ADDED_LOCATION", payload: data });
    dispatch(getOrganization());
    callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_ADD_LOCATION" });
  }
};

export const deleteLocation = (id, callback) => async (dispatch) => {
  dispatch({ type: "ADDING_LOCATION" });
  try {
    const { data } = await IWM.put(
      "/organization/update/remove-location/" + id
    );
    dispatch({ type: "ADDED_LOCATION", payload: data });
    dispatch(getOrganization());
    callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_ADD_LOCATION" });
  }
};

export const updateOrganization = (body, callback) => async (dispatch) => {
  dispatch({ type: "UPDATING_ORGANIZATION" });
  try {
    const { data } = await IWM.put("/organization/update", body);
    dispatch({ type: "UPDATED_ORGANIZATION", payload: data });
    callback();
    dispatch({ type: "GOT_ORGANIZATION", payload: data });
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_UPDATE_ORGANIZATION" });
  }
};

export const addScraps = (body, callback) => async (dispatch) => {
  dispatch({ type: "UPDATING_ORGANIZATION" });
  try {
    const { data } = await IWM.put("/organization/update/add-scraps", body);
    dispatch({ type: "UPDATED_ORGANIZATION", payload: data });
    callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_UPDATE_ORGANIZATION" });
  }
};
export const addScrapToNetwork = (body, callback) => async (dispatch) => {
  dispatch({ type: "UPDATING_ORGANIZATION" });
  try {
    const { data } = await IWM.put("/network/update/assign-scrap", body);
    dispatch({ type: "UPDATED_ORGANIZATION", payload: data });
    dispatch(getOrganization());
    callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_UPDATE_ORGANIZATION" });
  }
};

export const assignLocationToNetwork = (body, callback) => async (dispatch) => {
  dispatch({ type: "UPDATING_NETWORK" });
  try {
    await IWM.put("/network/update/assign-location", body);
    dispatch({ type: "UPDATED_NETWORK" });
    dispatch(getOrganization());
    dispatch(getNetwork());
    callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_UPDATE_NETWORK" });
  }
};

export const removeScraps = (body, callback) => async (dispatch) => {
  dispatch({ type: "UPDATING_ORGANIZATION" });
  try {
    const { data } = await IWM.put("/organization/update/remove-scraps", body);
    dispatch({ type: "UPDATED_ORGANIZATION", payload: data });
    callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_UPDATE_ORGANIZATION" });
  }
};

export const updateOrganizationMember =
  (body, callback) => async (dispatch) => {
    dispatch({ type: "UPDATING_ORGANIZATION" });
    try {
      const { data } = await IWM.put("/organization/update/member", body);
      dispatch(getOrganization());
      callback();
    } catch (ex) {
      toast.error(ex.response?.data || ex.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      dispatch({ type: "CANNOT_UPDATE_ORGANIZATION" });
    }
  };

export const finishSetup = (body, callback) => async (dispatch) => {
  dispatch({ type: "UPDATING_ORGANIZATION" });
  try {
    const { data } = await IWM.put("/organization/update/setup-complete");
    dispatch(getOrganization());
    if (callback) callback();
  } catch (ex) {
    toast.error(ex.response?.data || ex.message, {
      position: toast.POSITION.BOTTOM_LEFT,
      hideProgressBar: true,
      autoClose: 3000,
    });
    dispatch({ type: "CANNOT_UPDATE_ORGANIZATION" });
  }
};

export const getClosestCercleXNetwork =
  (body, callback) => async (dispatch) => {
    dispatch({ type: "GETTING_CLOSEST_NETWORK" });
    try {
      const { data } = await IWM.post(
        "/network/read/nearest-cerclex-network",
        body
      );
      if (callback) callback();
      dispatch({ type: "GOT_CLOSEST_NETWORK", payload: data });
    } catch (ex) {
      toast.error(ex.response?.data || ex.message, {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
      dispatch({ type: "CANNOT_GET_CLOSEST_NETWORK" });
    }
  };
