import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Modal from "react-responsive-modal";
import { Link, useNavigate } from "react-router-dom";
import ReviewDetails from "./ReviewDetails";

const ReviewHistoryTable = ({ history }) => {
  const navigate = useNavigate();
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [openPRD, setOpenPRD] = useState(false);
  const onOpenPRDModal = () => setOpenPRD(true);
  const onClosePRDModal = () => setOpenPRD(false);

  const [ details, setDetails ] = useState('');
  // console.log(details, 'details');

  const viewOnClick = (value) => {
    setDetails(value)
    onOpenPRDModal()
  };

  const images = new Array(3).fill(null).map((_, index) => (
    <img
      key={index} // Important for performance
      src={'https://whatdesigncando.s3.eu-central-1.amazonaws.com/app/uploads/20210120101404/iStock-927987734-1440x959.jpg'}
      style={{ width: "90px", height: "100px", borderRadius: "6px" }}
      className="mr-1"
    />
  ));

  return (
    <div className="box full-width white ">
      <div className="flex ph-1 align-center space-between ">
        <b className="bold">Review History</b>
        {/* {loading ? (
          <ClipLoader color={"#6f2da8"} loading={loading} size={20} />
        ) : ( */}
        <Link to="/review-history" className="cercle-purple-text" state={{ details: history }}>
          View All
        </Link>
        {/* )} */}
      </div>

      <table
        className={
          isTablet
            ? "striped responsive-table "
            : "striped responsive-table dashboardtable"
        }
      >
        <thead>
          <tr className="table-header-row">
            <th>Pickup Date</th>
            <th>Rating</th>
            <th>Contamination Score</th>
            <th>Segregation Score</th>
            <th>Remark</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {history.map((item, index) => {
            return (
              <tr key={index} className="tableHover">
                <td>{item.pickupDate}</td>
                <td>{item.rating}</td>
                <td>{item.contaminationScore}</td>
                <td>{item.segregationScore}</td>
                <td style={{ textTransform: "none" }}>{item.remark}</td>
                <td onClick={()=>{viewOnClick(item)}}>
                  <span class="material-symbols-outlined grey-text text-darken-1">
                    visibility
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {(history?.length === 0 || !history) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            find_in_page
          </span>
          <p className="grey-text mt-1 ">
            No review history available
          </p>
        </div>
      )}

      <Modal
        center
        open={openPRD}
        onClose={onClosePRDModal}
        closeIcon={<></>}
        styles={{
          modal: {
            borderRadius: "10px",
            padding: 0,
            width: isMobile ? "92%" : "",
            position: isMobile ? "absolute" : null,
            left: isMobile ? 0 : null,
          },
        }}
      >
        <ReviewDetails details={details} images={images} onClosePRDModal={onClosePRDModal}/>
      </Modal>
    </div>
  );
};

export default ReviewHistoryTable;
