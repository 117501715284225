import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notify, notifyError } from "../../../helpers/helper";
import { Button } from "react-materialize";
import {
  getNetworkInvitations,
  inviteNetwork,
} from "../../../redux/action/organization";
import { ClipLoader } from "react-spinners";
import { useMediaQuery } from "react-responsive";

const AddPersonalNetwork = ({ closeModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 674px)" });
  const { loading, organization, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const [scrap, setScrap] = useState("");
  const [openLocation, setOpenLocation] = useState(false);
  const [openScraps, setOpenScraps] = useState(false);
  const [values, setValues] = useState({
    email: "",
    organizationName: "",
    role: "",
    phoneNumber: "",
    location: "",
    scraps: [],
  });

  const dispatch = useDispatch();
  const changeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function addNetwork(e) {
    e.preventDefault();
    if (values.phoneNumber.length <= 10)
      return notifyError(
        "Include country code in phone number. Eg. +912345678"
      );
    const data = {
      ...values,
      scraps: values.scraps,
      locations: values.location.map(
        (location) =>
          organization.locations.find((loc) => loc.nickname === location)._id
      ),
    };

    delete data.location;

    dispatch(
      inviteNetwork(data, () => {
        closeModal();
        dispatch(getNetworkInvitations());
        notify("Invitation sent successfully");
        setValues({
          email: "",
          organizationName: "",
          role: "",
          phoneNumber: "",
          location: [],
          scraps: [],
        });

        setScrap("");
      })
    );
  }

  const handleLocationClick = (locationId) => {
    const locationName = organization.locations.find(
      (location) => location._id === locationId
    ).nickname;

    const isLocationSelected = values.location.includes(locationName);

    if (isLocationSelected) {
      setValues((prevValues) => ({
        ...prevValues,
        location: prevValues.location.filter((name) => name !== locationName),
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        location: [...prevValues.location, locationName],
      }));
    }
  };

  const handleScrapSelect = (scrapId) => {
    const isScrapSelected = values.scraps.includes(scrapId);

    if (isScrapSelected) {
      setValues((prevValues) => ({
        ...prevValues,
        scraps: prevValues.scraps.filter((id) => id !== scrapId),
      }));

      setScrap("");
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        scraps: [...prevValues.scraps, scrapId],
      }));

      setScrap(scrapId);
    }
  };

  const wrapperRef = useRef(null);
  const scrapWrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target))
        setOpenLocation(false) || setOpenScraps(false);
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="p-2">
      <form className="full-width" onSubmit={addNetwork}>
        <div
          className={isMobile ? "full-width gap-1" : "full-width flex gap-1"}
        >
          <div className="full-width">
            <div className="mt-1">
              <label className="black-text bold">
                Organization's Name <span className="red-text">*</span>{" "}
              </label>
              <div className="row">
                <input
                  className="browser-default input-style "
                  type="text"
                  placeholder="Enter the organization's name"
                  name="organizationName"
                  onChange={changeHandler}
                  value={values.organizationName}
                  required
                />
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text bold">
                Enter Email <span className="red-text">*</span>
              </label>
              <div className="row">
                <input
                  className="browser-default input-style "
                  type="text"
                  placeholder="Enter the email ID"
                  name="email"
                  onChange={changeHandler}
                  value={values.email}
                />
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text bold">
                Enter phone Number <span className="red-text">*</span>
              </label>
              <div className="row">
                <input
                  className="browser-default input-style "
                  type="text"
                  placeholder="Enter the phone number"
                  name="phoneNumber"
                  onChange={changeHandler}
                  value={values.phoneNumber}
                  required
                />
              </div>
            </div>
          </div>

          <div className="full-width">
            <div className="mt-1">
              <label className="black-text bold">
                Select Role <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <select
                  className="input-style full-width browser-default "
                  name="role"
                  onChange={changeHandler}
                  value={values.role}
                  required
                >
                  <option value={""}>Select the role</option>
                  <option value={"trader/aggregator"}>
                    Trader or Aggregator
                  </option>
                  <option value={"recycler"}>Recycler</option>
                </select>
              </div>
            </div>

            {/* <div className="mt-1">
              <label className="black-text bold">
                Associate a scrap to this network{" "}
                <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <select
                  className="input-style full-width browser-default "
                  name="scrap"
                  onChange={(e) => setScrap(e.target.value)}
                  value={scrap}
                  required
                >
                  <option value={""}>Assign scrap to the network</option>
                  {organization.scraps?.map((scrap, i) => (
                    <option value={scrap._id} key={i}>
                      {scrap.name}-{scrap.variant}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}

            <div className="mt-1">
              <label className="black-text bold">
                Associate scraps to this network{" "}
                <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <div
                  className="input-style full-width browser-default "
                  style={{ position: "relative" }}
                  onClick={() => {
                    setOpenScraps(!openScraps);
                  }}
                >
                  <input
                    className="hide-focus-underline"
                    name="location"
                    onChange={changeHandler}
                    required
                    value={
                      values.scraps?.length > 0
                        ? values.scraps.map((scrap) => {
                            const scrapName = organization.scraps.find(
                              (scrapData) => scrapData._id === scrap
                            ).name;
                            const scrapVariant = organization.scraps.find(
                              (scrapData) => scrapData._id === scrap
                            ).variant;
                            return `${scrapName}-${scrapVariant}`;
                          })
                        : "Select scraps"
                    }
                    style={{ cursor: "default" }}
                    readOnly
                    // onBlur={() => setOpenLocation(false)}
                  />
                </div>
                {openScraps ? (
                  <div
                    ref={wrapperRef}
                    className="white"
                    style={{
                      position: "absolute",
                      border: "1px solid #dadae7",
                      borderRadius: "4px",
                      // height: "rem",
                      overflow: "auto",
                      width: "45.8%",
                      marginTop: "8px",
                      zIndex: 1000,
                    }}
                  >
                    {organization.scraps?.map((scrap, i) => (
                      <div
                        style={{ marginBlock: "5px" }}
                        onClick={() => {
                          handleScrapSelect(scrap._id);
                        }}
                        className="flex align-center gap-1"
                        // value={location._id}
                        key={i}
                      >
                        <div
                          className={
                            values.scraps.includes(scrap._id)
                              ? "ml-1 checkbox-checked cercle-purple"
                              : "ml-1 checkbox-unchecked"
                          }
                        />
                        <p>
                          {scrap.name}-{scrap.variant}
                        </p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="mt-1">
              <label className="black-text bold">
                Associate a location to this network{" "}
                <span className="red-text">*</span>{" "}
              </label>
              <div className="row  ">
                <div
                  className="input-style full-width browser-default "
                  style={{ position: "relative" }}
                  onClick={() => {
                    setOpenLocation(!openLocation);
                  }}
                >
                  <input
                    className="hide-focus-underline"
                    name="location"
                    onChange={changeHandler}
                    required
                    value={
                      values.location.length > 0
                        ? values.location
                        : "Select location(s)"
                    }
                    style={{ cursor: "default" }}
                    readOnly
                    // onBlur={() => setOpenLocation(false)}
                  />
                </div>
                {openLocation ? (
                  <div
                    ref={wrapperRef}
                    className="white"
                    style={{
                      position: "absolute",
                      border: "1px solid #dadae7",
                      borderRadius: "4px",
                      height: "5.5rem",
                      overflow: "auto",
                      width: "45.8%",
                      marginTop: "8px",
                    }}
                  >
                    {organization.locations?.map((location, i) => (
                      <div
                        style={{ marginBlock: "5px" }}
                        onClick={() => {
                          handleLocationClick(location._id);
                        }}
                        className="flex align-center gap-1"
                        // value={location._id}
                        key={i}
                      >
                        <div
                          className={
                            values.location.includes(location.nickname)
                              ? "ml-1 checkbox-checked cercle-purple"
                              : "ml-1 checkbox-unchecked"
                          }
                        />
                        <p>{location.nickname}</p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-2 ">
          <Button
            className="btn-outline"
            modal="close"
            type="button"
            flat
            node="button"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <button
            className="btn"
            type="submit"
            disabled={
              !values.organizationName ||
              !values.phoneNumber ||
              !values.role ||
              !values.location[0] ||
              !values.scraps ||
              loadingAccounts
            }
          >
            {loading ? <ClipLoader size={20} color="#fff" /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPersonalNetwork;
