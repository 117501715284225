import React from "react";
import { useMediaQuery } from "react-responsive";
function DashboardBox({ title, amount, desc, img }) {
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });

  return (
    <div
      className={
        isMobile
          ? "flex white p-1 box gap-1 full-width space-between align-center"
          : " flex white p-1 box full-height gap-1 full-width space-between align-center"
      }
    >
      <div className="flex column ">
        <h3 style={{  }} className="">{amount}</h3>
        <p style={{ color: '#808080' }} className="">{title}</p>
        <p style={{ color: 'rgba(0, 0, 0, 0.35)', lineHeight: '2rem' }} className="small-text">{desc}</p>
      </div>
      <img src={img} className="dashboard-card-img" alt="bashboard-box" />
    </div>
  );
}

export default DashboardBox;
