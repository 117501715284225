import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function AnalyticCard({
  count,
  title,
  trend,
  icon,
  bgColor,
  iconColor,
}) {
  const isTablet = useMediaQuery({ query: '(max-width:1024px)' })
  const isLaptop = useMediaQuery({ query: '(max-width:1600px)' })

  return (
    <div className="full-width flex column space-between gap-1">
      <div
        className={
          isTablet ? 'flex column gap-2' : 'flex full-width gap-2 card-item'
        }
      >
        <div
          style={{
            background: '#fff',
            borderRadius: '10px',
            boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
            height: isLaptop ? '19vh':'15vh',
          }}
          className={isTablet ? 'box' : 'flex column gap-1 full-width'}
        >
          <div className="flex align-center space-between">
            <div className="flex column ml-1">
              <h3>{count}</h3>
              <p
                className="grey-text"
                style={{ fontSize: isTablet ? '1.2rem' : '1.3rem' }}
              >
                {title}
              </p>
            </div>
          </div>
          <div className="flex align-center gap-2 ml-1 "> 
            <span className="material-icons green-text">north_east</span>
            <div className=" flex space-between align-center full-width">
              <span
                className="flex gap-1 align-center"
                style={{ color: '#27AE60' }}
              >
                {trend}
                <p style={{ color: 'rgba(0, 0, 0, 0.35)' }}>vs last 30 days</p>
              </span>
              <div
                style={{
                  width: 45,
                  height: 45,
                  background: `${bgColor}`,
                }}
                className="circle flex align-center justify-center mr-1 mb-1"
              >
                <span
                  className={`material-icons`}
                  style={{ color: `${iconColor}` }}
                >
                  {icon}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
