import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const SearchBar = ({ placeholder, data }) => {
  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [clickOutside, setClickOutside] = useState(false);

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered("");
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    const newFilter = data.filter((value) => {
      const pageTitleMatch = value.pageTitle
        .toLowerCase()
        .includes(searchWord.toLowerCase());
      const keywordsMatch = value.keywords?.some((keyword) =>
        keyword.toLowerCase().includes(searchWord.toLowerCase())
      );

      return pageTitleMatch || keywordsMatch;
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }

    setIsDropdownOpen(newFilter.length > 0);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setClickOutside(true);
    } else {
      setClickOutside(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (clickOutside) {
      setIsDropdownOpen(false);
    }
  }, [clickOutside]);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        ref={dropdownRef}
        className="input-style flex align-center small-text"
        style={{ margin: 0, color: "#BFC3CE", background: "inherit" }}
      >
        <span className="material-symbols-outlined mr-1">search</span>
        <input
          style={{ margin: 0, caretColor: "black" }}
          placeholder={placeholder}
          className="hide-focus-underline"
          value={wordEntered}
          onChange={handleFilter}
        />
        <span className="material-symbols-outlined">tune</span>
      </div>
      {isDropdownOpen && filteredData.length > 0 ? (
        <div className="dataResult">
          {filteredData.slice(0, 15).map((option, index) => (
            <div
              key={index}
              onClick={() => {
                clearInput();
                setIsDropdownOpen(false);
              }}
              style={{
                paddingLeft: "1rem",
                borderTop: index !== 0 ? "1px solid #E6EDFF" : null,
              }}
              className="flex align-center gap-1 sb-hover"
            >
              {option.icon}
              <spa
                className="dataItem"
                onClick={() => {
                  navigate(option.link);
                  setIsDropdownOpen(false);
                }}
              >
                <p>{option.pageTitle} </p>
              </spa>
            </div>
          ))}
        </div>
      ) : !clickOutside ? (
        // <div className="dataResult">
        //   <div style={{ padding: '1rem' }}>
        //     <p>No Result(s) Found</p>
        //   </div>
        // </div>
        <div className="dataResult">
          {data
            .filter(
              (value) =>
                !value.pageTitle
                  .toLowerCase()
                  .includes(wordEntered.toLowerCase()) ||
                !value.keywords?.some((keyword) =>
                  keyword.toLowerCase().includes(wordEntered.toLowerCase())
                )
            )
            .slice(0, 1)
            .map((item, index) => (
              <div style={{ padding: "1rem" }}>
                <p>No Result(s) Found</p>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default SearchBar;
