import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Mon", uv: 4, pv: 2, amt: 1 },
  { name: "Tue", uv: 3, pv: 4, amt: 3 },
  { name: "Wed", uv: 2, pv: 1, amt: 5 },
  { name: "Thu", uv: 2, pv: 3, amt: 2 },
  { name: "Fri", uv: 1, pv: 5, amt: 4 },
  { name: "Sat", uv: 5, pv: 2, amt: 3 },
  { name: "Sun", uv: 3, pv: 3, amt: 1 },
];

const LegendComp = ({ legendName, borderColor, bgColor }) => (
  <div
    style={{
      border: `1px solid ${borderColor}`,
      backgroundColor: `${bgColor}`,
      borderRadius: "20px",
      paddingBlock: "4px",
      paddingInline: "1rem",
    }}
    className="flex align-center gap-1"
  >
    <span style={{ color: `${borderColor}` }} class="material-icons small-text">
      fiber_manual_record
    </span>
    <p style={{ color: `${borderColor}` }}>{legendName}</p>
  </div>
);

const RatingOverviewGraph = () => {
  return (
    <div className="box white" style={{}}>
      <h6 className="">Rating Overview</h6>

      <ResponsiveContainer width="100%" height={250}>
        <ComposedChart data={[]}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            radius={10}
            dataKey="quantity"
            fill="url(#linear-gradient)"
            barSize={32.5}
          />
          <Line type="monotone" dataKey="uv" stroke="#43AA8B" />
          <Line type="monotone" dataKey="pv" stroke="#F3722C" />
          <Line type="monotone" dataKey="amt" stroke="#EA4335" />
        </ComposedChart>
      </ResponsiveContainer>

      <div className="flex align-center justify-center gap-2">
        <LegendComp
          legendName={"Contamination"}
          borderColor={"#EA4335"}
          bgColor={"#FFF9F9"}
        />
        <LegendComp
          legendName={"Segregation"}
          borderColor={"#F3722C"}
          bgColor={"#FFFAF8"}
        />
        <LegendComp
          legendName={"Waste Rating"}
          borderColor={"#43AA8B"}
          bgColor={"#F6FFFC"}
        />
      </div>
    </div>
  );
};

export default RatingOverviewGraph;
