import React, { useState } from 'react'
import EditImpact from './EditImpact/EditImpact'

export default function SaplingsQA({ onCloseEDModal }) {
  const [showEditImpact, setShowEditImpact] = useState(false)

  const handleSubmit = () => {
    setShowEditImpact(true)
  }
  return (
    <div>
      {!showEditImpact && (
        <>
          <div
            className="full-width flex justify-center align-center gap-1"
            style={{ background: '#FFFDBE', padding: '5px' }}
          >
            <span
              class="material-symbols-outlined"
              style={{ fontSize: '14px' }}
            >
              info
            </span>
            <p className="purple-cercle-text small-text">
              Data entries require supporting documentation for verification.
            </p>
          </div>

          <div className="p-1">
            <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
              <p>
                How many saplings or trees were planted as part of the company's
                CSR initiatives during the reporting period
              </p>
              <div style={{ position: 'relative' }}>
                <span>
                  <input
                    className="new-input"
                    style={{ width: '30%' }}
                    placeholder="Enter the number of saplings were planted "
                  />
                </span>
              </div>
            </div>

            <div className="qa-3 mt-1" style={{ fontSize: '18px' }}>
              <p>
                Measures taken for the maintenance and care of the planted
                saplings
              </p>
              <div style={{ position: 'relative' }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{
                    background: '#FAFAFA',
                    border: 'none',
                    width: '30%',
                  }}
                >
                  <option>Select the status</option>
                  <option>Measures taken</option>
                  <option>No - Measures taken</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: 'absolute',
                    left: 320,
                    top: 10,
                    color: '#6F2DA8',
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>

            <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
              <p>What is the estimated survival rate of the planted saplings</p>
              <div style={{ position: 'relative' }}>
                <span>
                  <input
                    className="new-input"
                    placeholder="Enter the percentage of survival rate"
                    style={{ width: '40%' }}
                  />
                </span>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: 'absolute',
                    left: 440,
                    top: 1,
                    color: '#6F2DA8',
                    background: '#ECE4F4',
                    borderRadius: '0 6px 6px 0',
                    padding: '16px',
                  }}
                >
                  <p style={{ fontSize: '14px' }}>%</p>
                </span>
              </div>
            </div>
            <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
              <p>
                How many saplings or trees were planted as part of the company's
                CSR initiatives in the previous year
              </p>
              <div style={{ position: 'relative' }}>
                <span>
                  <input
                    className="new-input"
                    style={{ width: '30%' }}
                    placeholder="Enter the number of saplings were planted "
                  />
                </span>
              </div>
            </div>
            <div className="flex justify-end p-1">
              <button className="btn"  onClick={handleSubmit} onCloseEDModal={onCloseEDModal}>
                Submit
              </button>
            </div>
          </div>
        </>
      )}
      {showEditImpact && <EditImpact />}
    </div>
  )
}
