import React from 'react'

export default function EditEmpAdditionQA({onCloseEditModal}) {
  return (
    <div>
         <div className="p-1">
      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          How many new employees were added to the organization during the
          reporting period
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of Employees"
            />
          </span>
        </div>
      </div>

      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>Provide the gender distribution of the employees</p>
        <div className="flex column" style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of Men"
            />
          </span>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of women"
            />
          </span>
        </div>
      </div>
      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          What were the primary reasons for hiring new employees during the
          pervious year
        </p>
        <div style={{ position: 'relative' }}>
          <select
            className="browser-default scrap-select-dropdown"
            style={{
              background: '#FAFAFA',
              border: 'none',
              width: '30%',
            }}
          >
            <option>Select the reason</option>
            <option>Plastic Waste</option>
          </select>
          <span
            class="material-symbols-outlined"
            style={{
              position: 'absolute',
              left: 320,
              top: 10,
              color: '#6F2DA8',
            }}
          >
            arrow_drop_down
          </span>
        </div>
      </div>
      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          Provide the gender distribution of the employees during the pervious
          year
        </p>
        <div className="flex column" style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of Men"
            />
          </span>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of women"
            />
          </span>
        </div>
      </div>
      <div className="flex justify-end p-1">
        <button className="btn" onClick={onCloseEditModal}>Save</button>
      </div>
    </div>
    </div>
  )
}
