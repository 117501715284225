import React from "react";

import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { ComponentHeader, DashboardCard } from "../../modules";
import { getReports } from "../../redux/action/analytics";
import QuantityOverTime from "./components/QuantityOverTime";
import ReportsPickupHistory from "./components/ReportsPickupHistory";
import VitalSustainabilityMetrics from "./components/VitalSustainabilityMetrics";
import DashboardBox from "../dashboard/components/DashboardBox";
import { useMediaQuery } from "react-responsive";

function Reports() {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { reports } = useSelector((state) => state.analytics);
  const { account } = useSelector((state) => state.account);

  

  useEffect(() => {
    dispatch(getReports());
  }, [dispatch]);

  return (
    <div className="p-1">
      <ComponentHeader title="Overall Reports & Analytics" />

      <div style={{ gap: "1.5rem" }} className={isTablet ? "flex-wrap" : "flex flex-wrap"}>
        <div style={{ gap: "1.5rem" }} className="flex column flex-1">
          <DashboardBox
            title="Cerclex Credits"
            amount={reports?.cercle_credits}
            desc="T&C's Apply"
            img="../images/cerclex.png"
          />

          <DashboardBox
            title="Plastic Credits"
            amount={reports?.plastic_credits}
            desc="T&C's Apply"
            img="../images/plasticCredits.svg"
          />
        </div>
        <div style={{ gap: "1.5rem" }} className={isTablet ? "flex column flex-1 mt-2 mb-2" : "flex column flex-1"}>
          <DashboardBox
            title="Carbon Credits"
            amount={reports?.carbon_credits}
            desc="T&C's Apply"
            img="../images/co2Credits.svg"
          />

          <DashboardBox
            title="Water Credits"
            amount="N/A"
            desc="T&C's Apply"
            img="../images/h2oCredits.svg"
          />
        </div>

        <QuantityOverTime />
      </div>

      <div className="mt-2">
        <ReportsPickupHistory />
    </div>

      {account.permissions.includes("read-analytics") && (
        <div className="full-width mt-1">
          <div className="mv-1 ">
            <p className="bold mb-1">Vital Sustainability Metrics</p>
          </div>

          <div style={{ gap: "1.5rem" }} className="flex flex-wrap">
            <div style={{ gap: "1.5rem" }} className="flex column flex-1">
              {/* <div style={{ gap: "1.5rem" }} className="flex"> */}
              <DashboardBox
                title="Carbon Avoided"
                amount={reports?.co2_avoided}
                desc="T&C's Apply"
                img="../images/carbonavoided.svg"
              />
            </div>
            <div style={{ gap: "1.5rem" }} className="flex column flex-1">
              <DashboardBox
                title="Energy Savings (KWH)"
                amount={reports?.energy_saved}
                desc="T&C's Apply"
                img="../images/energySavings.svg"
              />
            </div>
            <div style={{ gap: "1.5rem" }} className="flex column flex-1">
              <DashboardBox
                title="Trees Saved"
                amount={reports?.trees_saved}
                desc="T&C's Apply"
                img="../images/treesSaved.svg"
              />
            </div>
            <div style={{ gap: "1.5rem" }} className="flex column flex-1">
              <DashboardBox
                title="Water Saved (kL)"
                amount={reports?.water_saved}
                desc="T&C's Apply"
                img="../images/waterSaved.svg"
              />
              {/* </div> */}
            </div>
          </div>
        </div>
      )}

      <div className="mt-2">
        <ReportsPickupHistory />
      </div>

      {/* {account.permissions.includes("read-analytics") && (
        <VitalSustainabilityMetrics reports={reports} />
      )} */}
    </div>
  );
}

export default Reports;
