import React from "react";

function CompanyLogo({ style, URL, userProfile }) {
  if (URL)
    return (
      <div>
        <img
          src={URL}
          className=" ml-1 circle"
          alt="user profile "
          style={style ? style : {}}
        />
      </div>
    );

  if (!userProfile?._id)
    return (
      <div>
        <img
          src={"../images/placeholder.png"}
          className=" ml-1 circle "
          alt="logo"
          style={style ? style : {}}
        
          onError={(e) => {
            e.currentTarget.src = "../images/brand.png";
          }}
        />
      </div>
    );

  return (
    <div>
      <img
        // src={`${AuthURL}/profile/logo/${userProfile.authProfile}`}
        src={"../images/placeholder.png"}
        className=" ml-1 circle"
        alt="logo"
        style={style ? style : {}}
        onError={(e) => {
          e.currentTarget.src = "../images/brand.png";
        }}
      />
    </div>
  );
}

export default CompanyLogo;
