import React from "react";

const ReviewDetails = ({ details, onClosePRDModal, images }) => {
  return (
    <>
      <div className="cercle-purple p-2 white-text flex align-center space-between">
        <h4>Pickup review details</h4>
        <div
          onClick={onClosePRDModal}
          className="flex align-center justify-center"
          style={{
            width: 40,
            height: 40,
            background: "rgba(255, 255, 255, 0.50)",
            borderRadius: "6px",
            cursor: "pointer",
          }}
        >
          <span class="material-symbols-outlined">close</span>
        </div>
      </div>
      <div className="p-2">
        <div style={{ gap: "4rem" }} className="flex">
          <div>
            <p className="grey-text text-darken-1">Pickup date</p>
            <b>{details.pickupDate}</b>
          </div>
          <div>
            <p className="grey-text text-darken-1">Waste rating</p>
            <div className="flex align-center ">
              <b className="">{details.rating}</b>
              <span
                style={{ verticalAlign: "middle" }}
                class="material-icons star-icon orange-text text-lighten-2"
              >
                star
              </span>
            </div>
          </div>
          <div>
            <p className="grey-text text-darken-1">Contamination Score</p>
            <b>{details.contaminationScore}</b>
          </div>
          <div>
            <p className="grey-text text-darken-1">Segregation Score</p>
            <b>{details.segregationScore}</b>
          </div>
        </div>
        <div className="mt-2">
          <p className="grey-text text-darken-1">Photos & Videos</p>
          {images}
        </div>
        <div className="mt-2">
          <p className="grey-text text-darken-1">Review Comments</p>
          <b>{details.remark}</b>
        </div>
        <div className="mt-2">
          <p className="grey-text text-darken-1">Reply to the review</p>
          <textarea
            placeholder="Add note on this review"
            style={{
              backgroundColor: "#FAFAFA",
              border: "none",
              borderRadius: "10px",
              outline: "none",
            }}
            className="p-1"
          />
        </div>

        <div className="flex justify-end">
          <button className="btn-small mt-2">Submit</button>
        </div>
      </div>
    </>
  );
};

export default ReviewDetails;
