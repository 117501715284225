import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { IWMURL } from "../../../redux/config/urlConfig";
import { useEffect } from "react";
import { getAllScraps } from "../../../redux/action/scraps";
import { addScraps } from "../../../redux/action/organization";
import { isTabletOrMobile } from "../../../helpers/helper";

const Step4 = () => {
  const { scraps } = useSelector((state) => state.scraps);
  const { loading, organization } = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");
  const [selected, setSelected] = React.useState([]);

  function submit(e) {
    e.preventDefault();
    dispatch(
      addScraps({ scraps: selected }, () => {
        navigate(-1);
      })
    );
  }

  const filtered = scraps.filter(
    (scrap) =>
      scrap.name.toLowerCase().includes(search.toLowerCase()) ||
      scrap.variant.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    if (organization.scraps)
      setSelected([...organization.scraps.map((s) => s._id)]);
  }, [organization.scraps]);

  useEffect(() => {
    dispatch(getAllScraps());
  }, [dispatch]);

  return (
    <div className="flex  full-height">
      <div className="flex-1 p-2">
        <header className="flex space-between align-center">
          <div
            className="input-style flex align-center"
            style={{
              width: isTabletOrMobile ? "100%" : "40%",
              padding: "0 10px",
              borderRadius: "15px",
              height: "50px",
              border: "1px solid #ccc",
            }}
          >
            <span className="material-symbols-outlined grey-text mr-1">
              search
            </span>
            <input
              className="browser-default "
              type="text"
              placeholder="Search for scraps..."
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>

          <button
            className="btn"
            type="submit"
            onClick={submit}
            disabled={loading || !selected.length}
          >
            {loading ? "Loading..." : "Save"}
          </button>
        </header>

        <div
          className="mt-2 full-width flex flex-wrap gap-1 "
          style={{ overflowY: "auto", height: "90%" }}
        >
          {filtered.length === 0 ? (
            <p className="grey-text text-center full-width">No scraps found</p>
          ) : (
            filtered.map((scrap, i) => {
              return (
                <div
                  key={i}
                  className={
                    selected.includes(scrap._id)
                      ? "scrap-selected"
                      : "scrap-select"
                  }
                  style={{ width: "10vw" }}
                  role="button"
                  onClick={() => {
                    if (selected.includes(scrap._id))
                      setSelected(selected.filter((s) => s !== scrap._id));
                    else setSelected([...selected, scrap._id]);
                  }}
                >
                  <img
                    src={`${IWMURL}/scraps/read/image/${scrap._id}`}
                    alt="scrap"
                  />
                  <div className="flex column align-center">
                    <p className="cercle-purple-text">{scrap.name}</p>
                    <p className="small-text truncate full-width text-center">
                      {scrap.variant}
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <SideBar
        setSelected={setSelected}
        selected={selected}
        loading={loading}
        hideOnSmallDevices={true}
      />
    </div>
  );
};

const SideBar = ({ selected, setSelected, loading, hideOnSmallDevices }) => {
  const { scraps } = useSelector((state) => state.scraps);
  return (
    <div
      className={`first-time-aside ${
        hideOnSmallDevices && "hide-on-med-and-down"
      }`}
    >
      <div className="flex align-center gap-1 ">
        <h3>Selected Scraps </h3>
        <ClipLoader size={20} color="#6f2da8" loading={loading} />
      </div>

      <div className="first-time-aside-list-container full-width">
        {!selected.length ? (
          <div
            className="flex justify-center column align-center  p-2 "
            style={{ height: "70%" }}
          >
            <i
              className="material-symbols-outlined grey-text"
              style={{ fontSize: 40 }}
            >
              info
            </i>
            <p className="grey-text small-text">No Scraps Selected</p>
          </div>
        ) : (
          selected.map((scrap, index) => (
            <div
              className="grey lighten-4 full-width p-1 flex column align-end"
              style={{ borderBottom: "5px solid #fff" }}
              key={index}
            >
              <div className="full-width flex space-between">
                <div className="flex ">
                  <img
                    src={`${IWMURL}/scraps/read/image/${scrap}`}
                    alt="scrap"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />

                  <div>
                    <p>{scraps.find((s) => s._id === scrap)?.name}</p>
                    <p className="small-text">
                      {scraps.find((s) => s._id === scrap)?.variant}
                    </p>
                  </div>
                </div>

                <button
                  className="btn-outline"
                  onClick={() => {
                    if (selected.includes(scrap))
                      setSelected(selected.filter((s) => s !== scrap));
                    else setSelected([...selected, scrap]);
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Step4;
