import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';

export default function ESGHeader() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })
  const navigate = useNavigate();
  return (
    <div className={isMobile ? 'flex column':"flex align-center space-between p-1"}>
      <p className="bold mb-1">ESG</p>
      <div className={isMobile ? 'flex column gap-1':"flex gap-1"}>
        <button className="btn button-2" onClick={()=>{navigate('/social')}}>Environmental</button>
        <button className="btn button-2" onClick={()=>{navigate('/environmental')}}>Social</button>
        <button className="btn" onClick={()=>{navigate('/esg')}}>Governance</button>
        <button className="btn button-2" onClick={()=>{navigate('/impact')}}>Impact</button>
      </div>
    </div>
  )
}
