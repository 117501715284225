import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import EditEnvironmentalCard from '../EditEnvironmentalCard'
import { useMediaQuery } from 'react-responsive'
import EditEmissionQA from './EditEmissionQA'
import EditEnergyQA from './EditEnergyQA'
import EditWaterWmissionQA from './EditWaterWmissionQA'

export default function EditEnvironment() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openEditEngryModal, setOpenEditEngryModal] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const onOpenEditModal = () => {
    setOpenEditModal(true)
  }
  const onCloseEditModal = () => setOpenEditModal(false)

  const onOpenEditEngryModal = () => {
    setOpenEditEngryModal(true)
  }

  const handleCardClick = (name) => {
    setQu1Visible(name === 'edit-emission')
    setQu2Visible(name === 'edit-energy')
    setQu3Visible(name === 'edit-water-emission')
    onOpenEditModal()
    onOpenEditEngryModal()
    setSelectedComponent(name)
  }

  return (
    <>
      {!qu1Visible && !qu2Visible && !qu3Visible && (
        <div className="flex column gap-2 p-1 card-section">
          <div className="flex gap-2">
            <div
              className="full-width"
              onClick={() => handleCardClick('spending')}
            >
              <EditEnvironmentalCard
                name="spending"
                image="../images/co2emission.png"
                icon="../images/newco2.png"
                title="Co2 Emissions"
                desc="Lower CO2 emissions are the environmental heart of ESG and showcasing sustainability."
              />
            </div>
            <div
              className="full-width"
              onClick={() => handleCardClick('goals')}
            >
              <EditEnvironmentalCard
                name="goals"
                image="../images/windmill.png"
                icon="../images/newenergy.png"
                title="Energy Consumption"
                desc="Lowering energy consumption cuts emissions, boosts efficiency, and shines a bright light on your ESG score."
              />
            </div>
            <div
              className="full-width"
              onClick={() => handleCardClick('sapling')}
            >
              <EditEnvironmentalCard
                name="sapling "
                image="../images/waterwaves.png"
                icon="../images/newenergy.png"
                title="Water Emission"
                desc="Minimizing water emissions safeguards precious resources and, propelling your ESG score."
              />
            </div>
          </div>
        </div>
      )}

      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openEditModal}
        onClose={onCloseEditModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          <div className="flex align-center gap-1">
            {!selectedComponent && <h4>Edit Environment Data</h4>}
            {selectedComponent === 'spending' && <h4>Edit CO2 Emissions</h4>}
            {selectedComponent === 'goals' && <h4>Edit Energy Consumption</h4>}
            {selectedComponent === 'sapling' && <h4>Edit Water Emission</h4>}
          </div>

          <div
            onClick={onCloseEditModal}
            className="flex align-center justify-center"
            style={{
              width: 40,
              height: 40,
              background: 'rgba(255, 255, 255, 0.50)',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
          >
            <span class="material-symbols-outlined">close</span>
          </div>
        </div>
        {selectedComponent === 'spending' && (
          <EditEmissionQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'goals' && (
          <EditEnergyQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'sapling' && (
          <EditWaterWmissionQA onCloseEditModal={onCloseEditModal} />
        )}
      </Modal>
    </>
  )
}
