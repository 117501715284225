import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { confirmOTP, loginUser, requestOtpAction } from "../redux/action/auth";
import { ClipLoader } from "react-spinners";
import { Tab, Tabs } from "react-materialize";
import { notifyError } from "../helpers/helper";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState(false);
  const { loading } = useSelector((state) => state.account);
  const [displayText, setDisplayText] = useState("Request OTP");
  const [OTP, setOTP] = useState("");
  const [session, setSession] = useState("");
  const invite = new URLSearchParams(window.location.search).get("invite");

  const loginWithEmail = (e) => {
    e.preventDefault();
    if (password.length < 8)
      return notifyError("Password must be 8 characters long.");

    dispatch(loginUser(email, password, invite));
  };

  const loginWithPhone = (e) => {
    e.preventDefault();
    if (!enteredPhoneNumber) {
      if (phoneNumber.length < 10) return notifyError("Invalid Phone Number");
      dispatch(
        requestOtpAction(phoneNumber, (sessionData) => {
          setSession(sessionData);
          setEnteredPhoneNumber(true);
          setDisplayText("Confirm OTP");
        })
      );
    } else {
      if (OTP.length < 6) return notifyError("OTP must be 6 characters long.");

      if (!session) return notifyError("Request OTP again");
      dispatch(confirmOTP(phoneNumber, OTP, session.session));
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-from">
        <div className=" container ">
          <div className="flex justify-center">
            <img src="/images/wasteknot_logo.png" alt="logo" className="logo" />
          </div>

          <h4 className="cercle-purple-text text-center">
            Sign In To Wasteknot
          </h4>
          <p className="grey-text text-center">
            Enter your details below with an authorized account to access your
            wasteknot dashboard.
          </p>

          <div className="purple lighten-5 cercle-purple-text p-1 flex mt-1">
            <i className="material-symbols-outlined">info</i>

            <p className="ml-1">
              This is a private access only. This dashboard is only for{" "}
              <b>Authorized Accounts</b>. Avoid trying to login if you do not
              have the necessary permissions
            </p>
          </div>

          <div className="mt-1">
            <Tabs className="tabs-fixed-width">
              <Tab active title="Email" idx="email">
                <form className="mt-1 " onSubmit={loginWithEmail}>
                  <div className="input-field-style">
                    <span className="material-symbols-outlined primary">
                      person
                    </span>
                    <input
                      className="browser-default "
                      placeholder="Enter your email ID"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="input-field-style">
                    <span className="material-symbols-outlined primary ">
                      lock
                    </span>
                    <input
                      className="browser-default input-field "
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      style={{ width: "25px" }}
                    >
                      {showPassword ? (
                        <AiFillEye className="eye-icon" />
                      ) : (
                        <AiFillEyeInvisible className="eye-icon" />
                      )}
                    </div>
                  </div>

                  <div className="submit-btn mv-2 column flex align-center ">
                    <button
                      className="btn full-width mb-1 mt-1 "
                      type="submit"
                      disabled={!email || !password || loading}
                    >
                      {loading ? (
                        <ClipLoader color="#fff" size={20} />
                      ) : (
                        "Sign In"
                      )}
                    </button>
                    <b className="grey-text">OR</b>
                  </div>
                </form>
              </Tab>

              <Tab
                options={{
                  duration: 300,
                  onShow: null,
                  responsiveThreshold: Infinity,
                  swipeable: false,
                }}
                idx="phone"
                title="Phone Number"
              >
                <form className="mt-1" onSubmit={loginWithPhone}>
                  {!enteredPhoneNumber ? (
                    <PhoneInput
                      country={"in"}
                      value={phoneNumber}
                      onChange={(phone) => setPhoneNumber(phone)}
                      inputClass="phone-input-style"
                      buttonClass="pphone-input-button-style"
                    />
                  ) : (
                    <div className="input-field-style">
                      <span className="material-symbols-outlined primary ">
                        lock
                      </span>
                      <input
                        className="browser-default input-field "
                        placeholder="Enter OTP Sent to Your Phone Number"
                        type={"number"}
                        min={0}
                        onChange={(e) => setOTP(e.target.value)}
                        value={OTP}
                        required
                      />
                    </div>
                  )}

                  <div className="submit-btn mv-2 column flex align-center ">
                    <button
                      className="btn full-width mb-1 mt-1 "
                      type="submit"
                      disabled={
                        !phoneNumber || loading || enteredPhoneNumber
                          ? !OTP
                          : false
                      }
                    >
                      {loading ? (
                        <ClipLoader color="#fff" size={20} />
                      ) : (
                        displayText
                      )}
                    </button>
                    <b className="grey-text">OR</b>
                  </div>
                </form>
              </Tab>
            </Tabs>
          </div>

          <div className="mt-1">
            <p className="semi-bold">
              Forgot password?<span className="primary"> Reset Password</span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
