import React, { useState } from 'react';
import { StackedCarousel } from 'react-stacked-carousel';
import 'react-stacked-carousel/dist/index.css';

const Stackedcarousel = ({ CAROUSELDATA }) => {
  const [card, setCard] = useState(null);
  const onCardChange = (event) => {
    // console.log("Card", event);
  }
  
  return (
    <div className="main">
      <StackedCarousel
        autoRotate={true}
        onCardChange={onCardChange}
        containerClassName={"container"}
        cardClassName="stacked-card"
        leftButton={<></>}
        rightButton={<></>}
      >
        {CAROUSELDATA.map(( item, index ) => (
        <div key={item.key}>
          <img style={{ height: '555px', borderRadius: 10 }} src={item.image} />
          <div className='stack-texts p-2'>
            <h2>{item.name}</h2>
            <img src='../images/doublequotes.svg' style={{ width: '50px', height: '50px' }}/>
            <p>{item.description}</p>
          </div>
        </div>
        ))}
      </StackedCarousel>
      </div>
  )
}
export default Stackedcarousel;