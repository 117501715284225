import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { isValidInvite } from "../../redux/action/organization";
import { useDispatch, useSelector } from "react-redux";

const AcceptInvitation = () => {
  const navigate = useNavigate();
  const invite = new URLSearchParams(window.location.search).get("invite");
  const dispatch = useDispatch();
  const [msg, setMsg] = React.useState(
    "You have been invited to join a network on wasteknot. Please click the button below to accept the invitation."
  );
  const { loadingInvitations: loading } = useSelector(
    (state) => state.organization
  );

  useEffect(() => {
    dispatch(
      isValidInvite(invite, (valid) => {
        if (!valid) return setMsg("This invitation is invalid or has expired.");

        if (localStorage.getItem("__wasteknot")) {
          //accept invitation
          return;
        }
        //redirect to login
        navigate("/?accept-invitation=true&invite=" + invite);
      })
    );
  }, [dispatch, invite, navigate]);

  return (
    <div className="flex justify-center align-center full-frame">
      <div className="flex column align-center gap-1">
        <h1 className="bold">Accept Invitation</h1>
        <p className="text-center">{msg}</p>
        {loading && <ClipLoader color="#6f2da8" size={20} />}
      </div>
    </div>
  );
};

export default AcceptInvitation;
