import React, { useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Label, Tooltip } from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Men', value: 40 },
  { name: 'Women', value: 100 },
]
const COLORS = ['#5030E5', '#FB896B']

const EmployeeStatistics = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [clickedCell, setClickedCell] = useState(null)

  const handleCellClick = (data) => {
    setClickedCell(data)
  }
  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%' : '33%',
        height: '345px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <p className="bold">Board of directors</p>

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
          <Tooltip formatter={(value, name) => [value, name]} />
            <Pie
              data={data}
              cx={isMobile ? '70%':"80%"}
              cy="50%"
              innerRadius={70}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              labelLine={false}
              onClick={handleCellClick}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}

              {clickedCell && (
                <Label
                  key={`center-label`}
                  value={`${clickedCell.name}: ${clickedCell.value}`}
                  position="center"
                  fontSize={isMobile ? 12 : 18}
                />
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div
          className="flex align-center gap-2 mr-2"
          style={{ position: 'absolute', bottom: 0 }}
        >
          <p style={{color:'#9291A5'}}>Pervious Year </p>
          <h4 style={{marginBottom:'10px'}}>55</h4>
        </div>
      </div>
      <div
        className="flex column gap-2"
        style={{
          position: 'absolute',
          bottom: '60px',
          left: '10px',
        }}
      >
        <div>
          <span
            style={{ color: '#5030E5' }}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#5030E5',
              }}
            ></span>
            <p className="grey-text">Men</p>
          </span>
          <span className="flex align-center gap-1" style={{marginLeft:'10px'}}>
            <h4 style={{ color: '#222222' }}>1</h4>
            <p style={{ color: '#34C759' }} className="flex align-center">
              <span class="material-symbols-outlined">north_east</span> 1
            </p>
          </span>

          <p className="grey-text" style={{marginLeft:'10px'}}>+25% vs Pervious year</p>
        </div>
        <div>
          <span
            style={{ color: '#FB896B' }}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#FB896B',
              }}
            ></span>
            <p className="grey-text">Women</p>
          </span>
          <span className="flex align-center gap-1" style={{marginLeft:'10px'}}>
            <h4 style={{ color: '#222222' }}>5</h4>
            <p style={{ color: '#FF3B30' }} className="flex align-center">
              <span class="material-symbols-outlined">south_west</span> 5
            </p>
          </span>
          <p className="grey-text" style={{marginLeft:'10px'}}>-20% vs Pervious year</p>
        </div>
        {/* <div className='flex align-center gap-2' style={{position:'absolute',bottom:0}}>
            <p>Pervious Year </p>
            <h4>55</h4>
        </div> */}
      </div>
    </div>
  )
}

export default EmployeeStatistics
