import React from 'react'
import { useMediaQuery } from 'react-responsive';

export default function BoardMeetingAnalytic() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' });

  return (
    <div
      className="box white flex space-between column pl-1"
      style={{ width:isMobile ? '100%': '15%' }}
    >
      <p className="bold ml-1">Board Meetings</p>
      <div className=" ml-1">
        <h4>6</h4>
        <p className="bold">No of meetings</p>

        <span style={{ color: '#34C759' }} className="flex gap-1">
          {' '}
          <span class="material-symbols-outlined">north_east</span>
          <p>1</p>
          <p style={{ color: '#7C8DB5' }}> Previous Year</p>
        </span>
      </div>
      <div className=" ml-1">
        <h4>100%</h4>
        <p className="bold">Attendance rate</p>
      </div>
    </div>
  )
}
