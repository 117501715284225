import React from 'react'

export default function EditEmissionQA({ onCloseEditModal }) {
  return (

    <div className="p-2 questionery-1">
    <div className="qa-1" style={{ fontSize: '18px' }}>
      <p>What scopes of CO2 emissions are being measured</p>
      <div style={{ position: 'relative' }}>
        <select
          className="browser-default scrap-select-dropdown"
          style={{
            background: '#FAFAFA',
            border: 'none',
            width: '30%',
          }}
        >
          <option>Select the scope</option>
          <option>Scope 1</option>
          <option>Scope 2</option>
        </select>
        <span
          class="material-symbols-outlined"
          style={{
            position: 'absolute',
            left: 320,
            top: 10,
            color: '#6F2DA8',
          }}
        >
          arrow_drop_down
        </span>
      </div>
    </div>
    <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
      <p>
        What is the total amount of CO2 emissions produced by the company
        during the reporting period
      </p>
      <div style={{ position: 'relative' }}>
        <span>
          <input
            className="new-input"
            style={{ width: '30%' }}
            placeholder="Enter the total amount of CO2 emission"
          />
        </span>
      </div>
    </div>
    <div className="qa-3 mt-1" style={{ fontSize: '18px' }}>
      <p>  What are the major sources contributing to CO2 emissions</p>
      <div style={{ position: 'relative' }}>
        <select
          className="browser-default scrap-select-dropdown"
          style={{
            background: '#FAFAFA',
            border: 'none',
            width: '30%',
          }}
        >
          <option>Select the Source</option>
          <option>Combustion of Fossil Fuels</option>
          <option>Industrial Processes</option>
          <option>Electricity and Heat Production</option>
          <option>Waste Management</option>
          <option>Other </option>
        </select>
        <span
          class="material-symbols-outlined"
          style={{
            position: 'absolute',
            left: 320,
            top: 10,
            color: '#6F2DA8',
          }}
        >
          arrow_drop_down
        </span>
      </div>
    </div>
      <div className="flex justify-end p-1">
        <button className="btn" onClick={onCloseEditModal}>
          Save
        </button>
      </div>
    </div>
  )
}
