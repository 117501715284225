import React from 'react'
import { Dropdown } from 'react-materialize'
import { useNavigate } from 'react-router-dom'

export default function ViewScrapPage() {
  const navigate = useNavigate()
  return (
    <div className="p-1">
      <div className="flex align-center space-between">
        <div className="flex align-center gap-1">
          <span className="material-symbols-outlined pointer" onClick={() => navigate(-1)}>keyboard_backspace</span>
          <b className="bold">Scrap ID: 1234AB</b>
        </div>
        <div>
          <button className="btn-small">Save</button>
        </div>
      </div>
      <div className='white p-2 mt-1' style={{border:'1px solid #EDEDEE',borderRadius:'10px'}}>
      <div className="flex align-center gap-1 p-1">
        <div className="flex space-between full-width ">
          <div className="flex">
            <div>
              <img
                src="../images/hdpe.png"
                alt="scrap"
                style={{ width: '200px', height: '140px' }}
              />
            </div>
            <div className='ml-1'>
              <span className="flex align-center gap-1">
                <p style={{ color: '#6F2DA8' }}>HDPE</p>
                <span
                  style={{
                    border: '1px solid #27AE60',
                    color: '#27AE60',
                    width: '80px',
                    height: '25px',
                    borderRadius: '10px',
                    padding: '2px 8px',
                  }}
                >
                  90 days
                </span>
              </span>

              <p className="mt-1">
                Scrap ID:<span style={{ color: '#6F2DA8' }}> 1234AB</span>{' '}
              </p>
              <p className="mt-1">
                Date Generated:{' '}
                <span style={{ color: '#6F2DA8' }}>12/02/24</span>
              </p>
              <p className="small-text grey-text mt-1">Scrap Info</p>
            </div>
          </div>
          <div>
            
            <Dropdown
              trigger={
                <div>
                 <span className="material-symbols-outlined bold">more_vert</span>
                </div>
              }
            >
              <span  onClick={() => {
              navigate('/new-edit-scrap')
            }}>Edit scrap</span>

              <span className="capitalize">Delete</span>
            </Dropdown>
          </div>
        </div>
      </div>
      <hr style={{ color: '#B0B0B0' }} />
      <div className="scrap-details mt-1">
        <div className="flex align-center">
          <p className="grey-text ml-1">ACTIVITY:</p>
          <p className="ml-1">Scrap from production activities</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">SCHEDULE OF HAZ WASTE RULE-HWR:</p>
          <p className="ml-1">N/A</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">DISPOSAL METHOD:</p>
          <p className="ml-1">Send to CPCB/SPCB Authorized Recycler</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE STORAGE TYPE:</p>
          <p className="ml-1">Pilling</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">SCRAP TYPE:</p>
          <p className="ml-1">Plastic waste</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">ALLOWABLE STORAGE TIME LIMIT:</p>
          <p className="ml-1">90 days</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE CLASSIFICATION BY STATE:</p>
          <p className="ml-1">Solid</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE CLASSIFICATION BY DEGRADABLE:</p>
          <p className="ml-1">Non -Bio Degardable</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE CLASSIFICATION BY HAZARDOUS:</p>
          <p className="ml-1">Non-Hazardous</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE CLASSIFICATION BY DEGRADABLE:</p>
          <p className="ml-1">
            Generation of PVC barrel vacuum powder waste. (Wet)
          </p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE CLASSIFICATION BY HAZARDOUS:</p>
          <p className="ml-1">NA</p>
        </div>
        <div className="flex align-center mt-1">
          <p className="grey-text ml-1">WASTE CLASSIFICATION BY HAZARDOUS:</p>
          <p className="ml-1">Scrap yard</p>
        </div>
      </div>
      </div>
    </div>
  )
}