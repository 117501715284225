import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ChangePasswordAction } from "../../../redux/action/auth";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import { useMediaQuery } from "react-responsive";
import { notify } from "../../../helpers/helper";

function ChangePassword() {
  const { loadingAccount } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldpassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      // setPasswordError('Is Strong Password')
    } else {
      setPasswordError("password is not strong");
    }
  };
  const handelSubmit = (e) => {
    e.preventDefault();
    if (oldPassword === "") {
      setOldPasswordError("please enter old password");
    } else if (password !== confirmPassword) {
      setConfirmPasswordError(
        "The confirm password confirmation does not match."
      );
    } else if (password === "") {
      setPasswordError("please enter the new password");
    } else {
      let data = {
        newPassword: password,
        password: oldPassword,
        confirmPassword: confirmPassword,
      };
      dispatch(
        ChangePasswordAction(data, () => {
          reset();
          notify("Password Changed Successfully");
        })
      );
    }
  };

  const reset = () => {
    setPassword("");
    setOldPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setOldPasswordError("");
    setConfirmPasswordError("");
  };
  return (
    <section className="white p-2 full-width">
      <form onSubmit={handelSubmit} className="container flex column gap-1">
        <div className="mb-1">
          <p className="bold">Change Account Password</p>
          <p className="small-text">
            Change Your Current Account Password
          </p>
        </div>

        
        <div className="flex align-center input-style mb-1">
          {/* <span class="material-symbols-outlined primary ">lock</span> */}
          <input
            className="browser-default  "
            placeholder="Enter your old password"
            type={showOldPassword ? "text" : "password"}
            onChange={(e) => {
              setOldPassword(e.target.value);
              setOldPasswordError("");
            }}
            value={oldPassword}
          />
          <div
            onClick={() => {
              setShowOldpassword(!showOldPassword);
            }}
            style={{ width: "25px" }}
          >
            {showOldPassword ? (
              <AiFillEye className="eye-icon" />
            ) : (
              <AiFillEyeInvisible className="eye-icon" />
            )}
          </div>
        </div>
        {oldPasswordError && (
          <p className="small-text red-text">{oldPasswordError}</p>
        )}

        <div className="flex align-center input-style mb-1">
          {/* <span class="material-symbols-outlined primary ">lock</span> */}
          <input
            className="browser-default "
            placeholder="Enter New Password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError("");
              validate(e.target.value);
            }}
            value={password}
          />
          <div
            onClick={() => {
              setShowPassword(!showPassword);
            }}
            style={{ width: "25px" }}
          >
            {showPassword ? (
              <AiFillEye className="eye-icon" />
            ) : (
              <AiFillEyeInvisible className="eye-icon" />
            )}
          </div>
        </div>
        {passwordError && (
          <p className="small-text red-text">{passwordError}</p>
        )}

        <div className="flex align-center input-style">
          {/* <span class="material-symbols-outlined primary ">lock</span> */}
          <input
            className="browser-default  "
            placeholder="Confirm New Password"
            type={showConfirmPassword ? "text" : "password"}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setConfirmPasswordError("");
            }}
            value={confirmPassword}
          />
          <div
            onClick={() => {
              setShowConfirmPassword(!showConfirmPassword);
            }}
            style={{ width: "25px" }}
          >
            {showConfirmPassword ? (
              <AiFillEye className="eye-icon" />
            ) : (
              <AiFillEyeInvisible className="eye-icon" />
            )}
          </div>
        </div>
        {confirmPasswordError && (
          <p className="small-text red-text">{confirmPasswordError}</p>
        )}

        <div className="justify-start flex mt-2">
          <button
            className="btn"
            type="submit"
            disabled={
              oldPassword === "" ||
              password === "" ||
              confirmPassword === "" ||
              passwordError !== "" ||
              confirmPasswordError !== "" ||
              oldPasswordError !== "" ||
              loadingAccount
            }
          >
            Update Password
          </button>
        </div>
      </form>
    </section>
  );
}

export default ChangePassword;
