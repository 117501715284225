import React from 'react'

export default function ScrapDetailBox({ image,scrap, id, date, location, storage }) {
  return (
    <div
      className="full-width white mt-1"
      style={{ width: '20%', borderRadius: '10px' }}
    >
      <div>
        <img
          src={image}
          style={{ width: '100%', height: '150px' }}
        />
        <div className="p-1">
          <p className="bold" style={{ marginTop: '0px', color: '#6F2DA8' }}>
            {scrap}
          </p>
          <p className="">
            {' '}
            <span className="grey-text">SCRAP ID:</span> {id}{' '}
          </p>
          <p style={{ marginTop: '5px' }}>
            {' '}
            <span className="grey-text">DATE GENERATED:</span> {date}
          </p>
          <p style={{ marginTop: '5px' }}>
            {' '}
            <span className="grey-text">LOCATION:</span> {location}{' '}
          </p>
          <p style={{ marginTop: '5px' }}>
            {' '}
            <span className="grey-text">STORAGE TIME LIMIT:</span> {storage}{' '}
          </p>
        </div>
      </div>
    </div>
  )
}
