import React, { useState } from 'react'
import EditSocialCard from './EditSocial/EditSocialCard'

export default function AgeDistributionQA() {
  const [showEditImpact, setShowEditImpact] = useState(false)

  const handleSubmit = () => {
    setShowEditImpact(true)
  }
  return (
    <>
      {!showEditImpact && (
        <div className="qu-5">
          <div
            className="full-width flex justify-center align-center gap-1"
            style={{ background: '#FFFDBE', padding: '5px' }}
          >
            <span
              class="material-symbols-outlined"
              style={{ fontSize: '14px' }}
            >
              info
            </span>
            <p className="purple-cercle-text small-text">
              Data entries require supporting documentation for verification.
            </p>
          </div>
          <div className="p-1">
            <div className="mt-1" style={{ fontSize: '18px' }}>
              <p>Please select the age range in your organization</p>
              <div style={{ position: 'relative' }}>
                <select
                  className="browser-default scrap-select-dropdown"
                  style={{
                    background: '#FAFAFA',
                    border: 'none',
                    width: '30%',
                  }}
                >
                  <option>Select the age</option>
                  <option>18-24 yrs</option>
                  <option>25-34 yrs</option>
                  <option>35-44 yrs</option>
                  <option>45-54 yrs</option>
                  <option>55 yrs and aboves</option>
                </select>
                <span
                  class="material-symbols-outlined"
                  style={{
                    position: 'absolute',
                    left: 320,
                    top: 10,
                    color: '#6F2DA8',
                  }}
                >
                  arrow_drop_down
                </span>
              </div>
            </div>
            <div className="mt-1" style={{ fontSize: '18px' }}>
              <p>Number of Employees in the 18-24 Age Range</p>
              <div className="flex column" style={{ position: 'relative' }}>
                <span>
                  <input
                    className="new-input"
                    style={{ width: '30%' }}
                    placeholder="Enter the Number of Employees"
                  />
                </span>
              </div>
            </div>
            <div className="mt-1" style={{ fontSize: '18px' }}>
              <p>Number of Employees in the 25-34 Age Range</p>
              <div className="flex column" style={{ position: 'relative' }}>
                <span>
                  <input
                    className="new-input"
                    style={{ width: '30%' }}
                    placeholder="Enter the Number of Employees"
                  />
                </span>
              </div>
            </div>
            <div className="flex justify-end p-1">
              <button className="btn" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {showEditImpact && <EditSocialCard />}
    </>
  )
}
