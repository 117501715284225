import React from "react";
import BreadCrumb from "../layout/BreadCrumb";

function ComponentHeader({ title, link }) {
  return (
    <div className="component-haeder flex s_b ">
      <h4>{title}</h4>
    </div>
  );
}

export default ComponentHeader;
