import React from "react";

const PercentageChart = ({ data }) => {
  let d = data ? [...data] : [];
  const total = d.reduce((acc, item) => acc + item.value, 0);

  d = d.map((item, index) => {
    return {
      ...item,
      percentage: (item.value / total) * 100,
    };
  });

  return (
    <section className="">
      <div className="flex full-width flex-wrap">
        {d.map((item, index) => {
          const isColorTooLight = (color) => {
            const hex = color.replace("#", "");
            const r = parseInt(hex.substring(0, 2), 16);
            const g = parseInt(hex.substring(2, 4), 16);
            const b = parseInt(hex.substring(4, 6), 16);
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155;
          };

          return (
            <div
              key={index}
              style={{
                backgroundColor: item.color,
                width: item.percentage + "%",
                height: "100%",
                color: isColorTooLight(item.color) ? "black" : "white",
                textAlign: "center",
                padding: "0.5rem",
                borderRadius: "5px",
              }}
            >
              {item.percentage.toFixed(2)}%
            </div>
          );
        })}
      </div>

      <div className="flex full-width justify-center align-center mt-1">
        {d.map((item, index) => {
          return (
            <div
              key={index}
              className="flex align-center"
              style={{ marginRight: 20, marginLeft: 10 }}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: item.color,
                  marginRight: 5,
                }}
              />
              <p>{item.name}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default PercentageChart;
