import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  {
    name: '2022',
    Spend: 4000,
    Budget: 2400,
    dispersed: 2400,
  },
  {
    name: '2023',
    Spend: 3000,
    Budget: 1398,
    dispersed: 2210,
  },
  {
    name: '2024',
    Spend: 2000,
    Budget: 9800,
    dispersed: 2290,
  },
]

export default function CSRCharts() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' });

  return (
    <div className="white box ml-1" style={{ width: isMobile ? '100%':'50%', height: '390px',paddingBottom:'40px' }}>
      <div className={isMobile ? 'flex column':"flex align-center space-between p-1 mb-1"}>
        <div className={isMobile ? 'flex':"flex column gap-1" }style={{width:'25%',position:'relative'}}>
          <p className="grey-text small-text">Spend Analysis</p>
          <h5 style={{position:'absolute',marginTop:'20px'}}>Corporate CSR</h5>
        </div>
        <div className="flex align-center gap-2">
          <span
            style={{ color: '#7E72FF' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#7E72FF',
                marginBottom: '3px',
              }}
            ></span>
            <p className="grey-text">Budgeted</p>
          </span>

          <span
            style={{ color: '#A448FF' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#A448FF',
                marginBottom: '3px',
              }}
            ></span>
            <p className="grey-text">Spent</p>
          </span>

          <span
            style={{ color: '#27AE60' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#27AE60',
                marginBottom: '3px',
              }}
            ></span>
            <p className="grey-text">Dispersed Spent</p>
          </span>
        </div>
      </div>
    
      <ResponsiveContainer width="100%" height="90%" >
        <BarChart
          width={500}
          height={200}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          {/* <Tooltip /> */}
          {/* <Legend /> */}
          <Bar dataKey="Budget" fill="#BDB7FF" />
          <Bar dataKey="Spend" fill="#E7E4FF" />
          <Bar dataKey="dispersed" fill="#8FFCC0" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
