import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Label,
} from "recharts";
import { showAmount, showAmountWithCurrency } from "../../../helpers/helper";

const RevenueByLocationChart = ({ COLORS, scrapType, weight, type }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 824px)" });

  const { organization } = useSelector((state) => state.organization);
  const [selected, setSelected] = React.useState(null);
  const { overview } = useSelector((state) => state.analytics);
  const [data, setData] = React.useState([]);

  const { locations } = organization;

  useEffect(() => {
    if (!overview.locationsAnalytics) return;
    if (!locations) return;

    const data = overview.locationsAnalytics.map((locData) => ({
      name: locations.find((loc) => loc._id === locData.location)?.nickname,
      revenue: locData.revenue,
    }));
    setData(data);
    setSelected(0);
  }, [locations, overview.locationsAnalytics]);

  return (
    <div className="box white" style={{ width: isTablet ? "100%" : "32.6%" }}>
      <h5>% Revenue Generated Per Location</h5>
      <hr color="#E5E5EF" style={{ marginTop: "1rem" }} />
      <div className="">
        <div style={{ height: 200 }} className="">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={data || []}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="revenue"
                label={true}
              >
                {data.map((entry, index) => (
                  <Cell
                    style={{ outline: "none" }}
                    key={entry.name}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => setSelected(index)}
                  />
                ))}
                <Label
                  value={"Total Revenue"}
                  className="small-text"
                  position="center"
                  fill="#737373"
                  dy={-10}
                />
                <Label
                  className="bold"
                  value={showAmountWithCurrency(
                    data.reduce((acc, curr) => acc + curr.revenue, 0)
                  )}
                  position="center"
                  fill="#0A0A0A"
                  fontSize={14}
                  dy={10}
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex space-between">
          <div>
            <p className="small-text grey-text text-lighten-1">Location</p>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {data[selected]?.name || "No Location selected"}
            </p>
          </div>
          <div>
            <p className="small-text grey-text text-lighten-1">Total Revenue</p>
            <p style={{ textAlign: "end", fontWeight: "bold" }}>{`${
              data[selected]?.revenue
                ? showAmountWithCurrency(data[selected]?.revenue)
                : 0
            } `}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueByLocationChart;
