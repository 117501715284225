import { Auth, IWM } from "../config/urlConfig";
import { toast } from "react-toastify";
import { showLoading, hideLoading } from "./loading";
import { handleError, notify, notifyError } from "../../helpers/helper";
import { acceptNetworkInvitation } from "./organization";

export const loginUser = (medium, password, invite) => async (dispatch) => {
  dispatch({ type: "LOGGING_IN" });
  try {
    const { data: token } = await Auth.post("/account/login/step-1", {
      medium: medium,
      password: password,
    });

    try {
      const config = { headers: { "x-authentication-token": token } };
      const { data } = await IWM.post("/authenticate", {}, config);
      dispatch(hideLoading());
      dispatch({ type: "LOGGED_IN" });

      IWM.defaults.headers["x-authentication-token"] = data;
      if (invite)
        return dispatch(
          acceptNetworkInvitation(invite, () => {
            window.location.href = "/invite-accepted?token=" + data;
          })
        );

      localStorage.setItem("__wasteknot", data);
      window.location.href = "/";
    } catch (ex) {
      notifyError(ex.response ? ex.response?.data : ex.message);
      dispatch({ type: "CANNOT_LOGIN" });
    }
  } catch (error) {
    dispatch({ type: "CANNOT_LOGIN" });
    dispatch(hideLoading());
    notifyError(error.response ? error.response?.data : error.message);
  }
};

export const requestOtpAction = (phoneNumber, cb) => async (dispatch) => {
  dispatch({ type: "REQUESTING_OTP" });
  try {
    const { data } = await Auth.post(`/account/login/step-1?method=OTP`, {
      medium: phoneNumber,
    });

    dispatch({ type: "REQUESTED_OTP" });
    cb(data);
  } catch (error) {
    dispatch({ type: "CANNOT_REQUEST_OTP" });
    notifyError(error.response ? error.response?.data : error.message);
  }
};

export const confirmOTP = (phoneNumber, OTP, session) => async (dispatch) => {
  let token;
  try {
    dispatch({ type: "CONFIRMING_OTP" });
    const res = await Auth.post("/account/login/step-2?method=OTP", {
      phoneNumber,
      sessionId: session,
      OTP,
    });
    token = res.data;
  } catch (error) {
    dispatch({ type: "CANNOT_CONFIRM_OTP" });
    dispatch({ type: "CANNOT_LOGIN" });
    notifyError(error.response ? error.response?.data : error.message);
  }

  try {
    const { data } = await IWM.post(
      "/authenticate",
      {},
      {
        headers: { "x-authentication-token": token },
      }
    );

    localStorage.setItem("__wasteknot", data);
    dispatch({ type: "CONFIRMED_OTP" });
    window.location.href = "/";
  } catch (error) {
    handleError(error);
    dispatch({ type: "CANNOT_CONFIRM_OTP" });
  }
};

export const updateAccount = (data, cb) => async (dispatch) => {
  try {
    dispatch({ type: "CONFIRMING_OTP" });
    await IWM.put("/account/update", data);
    dispatch({ type: "CONFIRMED_OTP" });
    dispatch(getAccount());
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "CANNOT_CONFIRM_OTP" });
    notifyError(error.response ? error.response?.data : error.message);
  }
};

export const updateOrganization = (name, gst, email) => async (dispatch) => {
  dispatch(showLoading());
  Auth.put("/organization/update", {
    name: name,
    get: gst,
    email: email,
  })
    .then((res) => {
      dispatch(hideLoading());
      toast.success("organization details has been changed successfully");
    })
    .catch((error) => {
      dispatch(hideLoading());
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getAccount = (callback, errorCallback) => async (dispatch) => {
  dispatch({ type: "GETTING_ACCOUNT" });
  try {
    const { data } = await IWM.get("/account/read");
    dispatch({ type: "GOT_ACCOUNT", payload: data });
    if (callback) callback();
  } catch (error) {
    dispatch({ type: "CANNOT_GET_ACCOUNT" });
    // localStorage.removeItem("__wasteknot");
    // window.location.href = "/";
    if (errorCallback) errorCallback();
    handleError(error);
  }
};

export const updatePreference = (preference) => async (dispatch) => {
  dispatch({ type: "UPDATING_ACCOUNT" });
  try {
    await IWM.put("/account/update/preferences", preference);
    dispatch({ type: "UPDATED_ACCOUNT" });
    dispatch(getAccount());
    notify("Preference updated successfully");
  } catch (error) {
    dispatch({ type: "CANNOT_UPDATE_ACCOUNT" });
    handleError(error);
  }
};

export const ChangePasswordAction = (data, cb) => async (dispatch) => {
  dispatch({ type: "UPDATING_ACCOUNT" });
  try {
    await IWM.put("/account/update/password", data);
    dispatch({ type: "UPDATED_ACCOUNT" });
    if (cb) cb();
  } catch (error) {
    dispatch({ type: "CANNOT_UPDATE_ACCOUNT" });
    handleError(error);
  }
};

export const changeMobileNumberStep1 = (mobileNumber) => async (dispatch) => {
  dispatch(showLoading());
  Auth.put("/account/update/update-phone-number", {
    phoneNumber: mobileNumber,
  })
    .then((res) => {
      dispatch(hideLoading());
      res.data && dispatch({ type: "UPDATE_PHONE_NUMBER", payload: res.data });
    })
    .catch((error) => {
      dispatch(hideLoading());
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const changeMobileNumberConfirmOTP =
  (OTP, mobileNumber) => async (dispatch) => {
    dispatch(showLoading());
    Auth.put("/account/update/confirm-update-phone-number", {
      phoneNumber: mobileNumber,
      OTP: OTP,
    })
      .then((res) => {
        dispatch(hideLoading());
        toast.success("phone number has been changed successfully");
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const getOrganization = () => (dispatch) => {
  dispatch({ type: "GET_ORGANIZATION_BGIN" });
  Auth.get("/organization/read")
    .then((res) => {
      dispatch({ type: "GET_ORGAIZATION_SUCCESS", payload: res.data });
    })
    .catch((error) => {
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const updateUserImage = (data) => async (dispatch) => {
  dispatch({ type: "UPDATING_ACCOUNT" });
  try {
    await IWM.put("/account/update/update-photo", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: "UPDATED_ACCOUNT" });
    dispatch(getAccount());
    notify("Image updated successfully");
  } catch (error) {
    dispatch({ type: "CANNOT_UPDATE_ACCOUNT" });
    handleError(error);
  }
};

export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("CCXT");
  localStorage.removeItem("__wasteknot");
  window.location.href = "/";
};

export const getPlans = () => async (dispatch) => {
  dispatch({ type: "GETTING_PLANS" });
  try {
    const { data } = await IWM.get("/plans/read/all");
    dispatch({ type: "GOT_PLANS", payload: data });
  } catch (error) {
    dispatch({ type: "CANNOT_GET_PLANS" });
  }
};



