import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

function NextMilestone() {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { overview } = useSelector((state) => state.analytics);
  const { organization } = useSelector((state) => state.organization);

  let { currentMilestone, totalQuantityChannelized, totalQuantityRecycled } =
    overview;

  if (!currentMilestone) currentMilestone = 0;
  if (!totalQuantityChannelized) totalQuantityChannelized = 0;
  if (!totalQuantityRecycled) totalQuantityRecycled = 0;

  const percentage =
    organization.type === "recycler"
      ? (totalQuantityRecycled / currentMilestone) * 100
      : (totalQuantityChannelized / currentMilestone) * 100;

  return (
    <div
      className={
        isMobile
          ? "mv-1 full-width white p-1 box "
          : "full-width  p-1 box white "
      }
    >
      <b className="bold">Your Current Milestone</b>
      <div className="recycled-waste">
        <p className="grey-text">
          Road To{" "}
          <span className="primary" style={{ fontSize: 22 }}>
            {currentMilestone}
          </span>{" "}
          Tonnes Of Properly Processed Waste
        </p>
        <div
          style={{ position: "relative" }}
          className={isMobile ? "line1-isMobile" : "line1"}
        >
          <div className="line2">
            <div
              className={isMobile ? "progress-rate-isMobile" : "progress-rate"}
              style={{ width: percentage ? percentage + "%" : 0 }}
            >
              {/* <p>{result}</p> */}
              {/* {percentage?.toFixed(2)}% */}
            </div>
            <img
              src="../images/car2.svg"
              className="vector-img"
              alt="car"
              style={{
                width: isMobile ? 38.69 : null,
                height: isMobile ? 24.5 : null,
              }}
            />
          </div>
          <div className="circle white-text flex align-center justify-center percent-tag">
            {percentage?.toFixed(2)}%
          </div>
          <img
            src="../images/goalflag.svg"
            style={{
              width: isMobile ? 30 : 60,
              height: isMobile ? 30 : 60,
              position: "absolute",
              top: isMobile ? -1 : isLaptop ? -15 : -10,
              right: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default NextMilestone;