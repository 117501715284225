import React from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { calculateTotal } from "../../../helpers/helper";


function RecentTransactionTable({ data }) {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const navigate = useNavigate();

  const completed = data?.filter((item) => item.completed).slice(-4);

  return (
    <div
      className={
        isTablet ? "box full-width mv-1 mr-1 white" : "box full-width white "
      }
      style={{ minHeight: "47vh" }}
    >
      <div className="flex align-center space-between ph-1 ">
        <b className="bold">Recent Transactions</b>

        <Link to="/payment-history" state={{ pageName: 'transactions' }} className="cercle-purple-text">
          View All
        </Link>
      </div>

      <table
        className={
          isTablet
            ? " small-text striped payment-history responsive-table"
            : "striped payment-history  dashboardtable"
        }
      >
        <thead>
          <tr>
            <th>{isTablet ? "Date" : "Pickup Completed On"}</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>GST</th>
            <th>Total</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {completed?.map((item, index) => {
            console.log(item, 'GSTt');
            return (
              <tr key={index} className="pointer" onClick={() => navigate("#")}>
                <td>{new Date(item.completedOn).toDateString()}</td>
                <td>{item.quantity} Tonne(s)</td>
                <td>Rs.{item?.price}/kg</td>
                <td>{item.scrap?.GST ? item.scrap?.GST : 'N/A'}</td>
                <td>
                  Rs.
                  {calculateTotal(item.price, item.quantity)}
                </td>
                <td>
                  <div
                    className={
                      item.completed
                        ? "green-text small-text"
                        : " red small-text"
                    }
                  >
                    {item.completed ? "Completed" : "pending"}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {(completed?.length === 0 || !data) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined
          grey-text
          "
            style={{ fontSize: "5rem" }}
          >
            receipt_long
          </span>
          <p className="grey-text mt-1 ">
            Currently you don’t have any Transaction.
          </p>
        </div>
      )}
    </div>
  );
}

export default RecentTransactionTable;
