import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ImpactAnalytic from './components/ImpactAnalytic'
import ImpactCardList from './components/ImpactCardList'
import CSRCharts from './components/CSRCharts'
import PeopelReachedChart from './components/PeopelReachedChart'
import { useMediaQuery } from 'react-responsive'
import Modal from 'react-responsive-modal'
import EnvironmentalCard from '../Environmental/components/EnvironmentalCard'
import SpendingQA from './components/SpendingQA'
import GoalsQA from './components/GoalsQA'
import SaplingsQA from './components/SaplingsQA'
import CampaignsQA from './components/CampaignsQA'
import ProgressBar from './components/ProgressBar'

const cardData = [
  {
    bgColor: '#DAFADF',
    border: '#45E560',
  },
  { bgColor: '#FFEED3', border: '#FFA922' },
  {
    bgColor: '#D5EFFD',
    border: '#2BB1F6',
  },
  {
    bgColor: '#FFDAD9',
    border: '#FF4643',
  },
]

const progressData = [
  {
    title: 'Program',
    number: 5,
    percent: '40%',
    color: '#4EA654',
  },
  {
    title: 'Total Budget',
    number: '85 lakh',
    percent: '30%',
    color: '#FFC727',
  },
]
export default function Impact() {
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })
  const [openED, setOpenED] = useState(false)
  const [card, setCard] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)
  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const [qu4Visible, setQu4Visible] = useState(false)
  const [modalTitle, setModalTitle] = useState('Add Social Data')
  const toggleQuestionery = () => {
    setQu1Visible(false)
    setQu2Visible(false)
    setQu3Visible(false)
    setQu4Visible(false)

    setCard(false)
  }
  const onOpenEDModal = () => {
    setOpenED(true)
  }
  const onCloseEDModal = () => setOpenED(false)

  const handleCardClick = (name) => {
    setQu1Visible(name === 'spending')
    setQu2Visible(name === 'goals')
    setQu3Visible(name === 'sapling')
    setQu4Visible(name === 'campaings')

    onOpenEDModal()

    switch (name) {
      case 'spending':
        setModalTitle('CSR Spending')
        break
      case 'goals':
        setModalTitle('CSR Goals')
        break
      case 'sapling':
        setModalTitle('Planting saplings')
        break
      case 'campaings':
        setModalTitle('Awareness Campaigns')
        break

      default:
        setModalTitle('Add Impact Data')
    }
  }
  return (
    <div style={{ height: '100vh' }}>
      <div
        className={
          isMobile ? 'flex column' : 'flex align-center space-between p-1'
        }
      >
        <p className="bold mb-1">ESG</p>
        <div className={isMobile ? 'flex column gap-1' : 'flex gap-1'}>
          <button
            className="btn  button-2"
            onClick={() => {
              navigate('/environmental')
            }}
          >
            Environmental
          </button>
          <button
            className="btn button-2"
            onClick={() => {
              navigate('/social')
            }}
          >
            Social
          </button>
          <button
            className="btn  button-2"
            onClick={() => {
              navigate('/esg')
            }}
          >
            Governance
          </button>
          <button
            className="btn"
            onClick={() => {
              navigate('/impact')
            }}
          >
            Impact
          </button>
        </div>
      </div>
      <div
        className={
          isMobile ? 'flex column full-width gap-1 mt-1' : 'p-1 flex gap-2'
        }
      >
        <ImpactAnalytic
          img="../images/newrecycler.png"
          amt="56,829"
          title="Single Use plastic Avoided"
          desc="Enough to fill up"
          desc1="20 garbage truck"
          bgColor={cardData[0].bgColor}
          border={cardData[0].border}
        />
        <ImpactAnalytic
          img="../images/electricity.png"
          amt="5,277"
          title="Kwh of electricity saved"
          desc="Enough to fill up"
          desc1="school for a month"
          bgColor={cardData[1].bgColor}
          border={cardData[1].border}
        />
        <ImpactAnalytic
          img="../images/waterdrop.png"
          amt="8,059"
          title="Gallons of water saved "
          desc="Enough to fill up"
          desc1="12 swimming pools"
          bgColor={cardData[2].bgColor}
          border={cardData[2].border}
        />
        <ImpactAnalytic
          img="../images/organization.png"
          amt="82"
          title="Metric tons of CO2 avoided"
          desc="Saved"
          desc1="2 acres of forest"
          bgColor={cardData[3].bgColor}
          border={cardData[3].border}
        />
      </div>
      <div className={isMobile ? 'flex column' : 'ml-1 flex gap-2'}>
        <ImpactCardList />
        <div
          className={
            isMobile
              ? 'mt-1 flex column gap-1'
              : 'full-width flex column gap-2 mr-1'
          }
          style={{ width: isMobile ? '100%' : '50%' }}
        >
          <ProgressBar
            title="Program"
            number={5}
            percent="40%"
            color={progressData[0].color}
          />
          <ProgressBar
            title="Total Budget"
            number="85 lakh"
            percent="30%"
            color={progressData[1].color}
          />
        </div>
      </div>
      <div className="mt-1 flex column gap-2" style={{ position: 'relative' }}>
        <div className={isMobile ? 'flex column gap-1' : 'flex gap-2'}>
          <CSRCharts />
          <PeopelReachedChart />
        </div>
        <div
          className="ESG-btn mt-1 p-1 pointer fab-style-esg flex align-center justify-center gap-1"
          onClick={onOpenEDModal}
        >
          <span class="material-symbols-outlined" style={{ fontSize: '16px' }}>
            add
          </span>
          <p> Impact Data </p>
        </div>
      </div>
      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openED}
        onClose={onCloseEDModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          {!card && (
            <div className="flex align-center gap-1">
              <span
                class="material-symbols-outlined"
                onClick={toggleQuestionery}
              >
                arrow_back
              </span>

              <h4>
                {' '}
                {qu1Visible
                  ? 'CSR Spending'
                  : qu2Visible
                  ? 'CSR Goal'
                  : qu3Visible
                  ? 'Planting saplings'
                  : qu4Visible
                  ? 'Awareness Campaigns'
                  : 'Add Impact Data'}
              </h4>
            </div>
          )}
          {!qu1Visible && !qu2Visible && !qu3Visible && !qu4Visible && (
            <div
              onClick={onCloseEDModal}
              className="flex align-center justify-center"
              style={{
                width: 40,
                height: 40,
                background: 'rgba(255, 255, 255, 0.50)',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              <span class="material-symbols-outlined">close</span>
            </div>
          )}
        </div>
        {!qu1Visible && !qu2Visible && !qu3Visible && !qu4Visible && (
          <div className="flex column gap-2 p-2 card-section">
            <div className="flex gap-2">
              <div
                className="full-width"
                onClick={() => handleCardClick('spending')}
              >
                <EnvironmentalCard
                  name="spending"
                  image="../images/csrspend.png"
                  icon="../images/ic-csrspend.png"
                  title="CSR Spending"
                  desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                />
              </div>
              <div
                className="full-width"
                onClick={() => handleCardClick('goals')}
              >
                <EnvironmentalCard
                  name="goals"
                  image="../images/csrgoal.png"
                  icon="../images/goal.png"
                  title="CSR Goals"
                  desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                />
              </div>
              <div
                className="full-width"
                onClick={() => handleCardClick('sapling')}
              >
                <EnvironmentalCard
                  name="sapling "
                  image="../images/plantsapling.png"
                  icon="../images/plant.png"
                  title="Planting saplings"
                  desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                />
              </div>
            </div>
            <div
              style={{ width: '33%' }}
              onClick={() => handleCardClick('campaings')}
            >
              <EnvironmentalCard
                name="campaings"
                image="../images/awareness.png"
                icon="../images/injuries.png"
                title="Awareness Campaigns"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
          </div>
        )}
        {qu1Visible && (
          <div className="qu-1">
            <div
              className="full-width flex justify-center align-center gap-1"
              style={{ background: '#FFFDBE', padding: '5px' }}
            >
              <span
                class="material-symbols-outlined"
                style={{ fontSize: '14px' }}
              >
                info
              </span>
              <p className="purple-cercle-text small-text">
                Data entries require supporting documentation for verification.
              </p>
            </div>
            <SpendingQA />
          </div>
        )}
        {qu2Visible && (
          <div className="qu-1">
            <GoalsQA />
          </div>
        )}
        {qu3Visible && (
          <div className="qu-1">
            <SaplingsQA onCloseEDModal={onCloseEDModal} />
          </div>
        )}
        {qu4Visible && (
          <div className="qu-1">
            <CampaignsQA />
          </div>
        )}
      </Modal>
    </div>
  )
}
