import React from "react";
import { ComponentHeader, DashboardCard } from "../../modules";
import { Tabs, Tab } from "react-materialize";
import Users from "./components/Accounts.js";
import Locations from "./components/Locations";
import Scraps from "./components/Scraps";
import ActiveUsersChart from "./components/ActiveUsersChart.js";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

function Organization() {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { organization } = useSelector((state) => state.organization);

  return (
    <div className=" p-1">
      <div className="mb-1">
        <ComponentHeader title="Management" />
      </div>

      <div
        className={
          isTablet ? "flex-wrap mb-1 gap-1" : "flex flex-wrap mb-1 gap-2"
        }
      >
        <DashboardCard
          title={"Accounts"}
          icon={"account_circle"}
          color={"pink lighten-5"}
          cardValue={organization?.members?.length}
          style={{ width: "18vw" }}
        />
        <DashboardCard
          title={"Locations"}
          icon={"location_on"}
          color={"blue lighten-5"}
          cardValue={organization?.locations?.length}
          style={{ width: "18vw" }}
        />
        <DashboardCard
          title={"Added Scraps"}
          icon={"manufacturing"}
          color={"purple lighten-5"}
          cardValue={organization?.scraps?.length}
          style={{ width: "18vw" }}
        />
      </div>

      <Tabs>
        <Tab
          options={{ swipeable: false }}
          title="User Accounts Management"
          idx="accounts"
        >
          <Users />
        </Tab>
        <Tab
          options={{ swipeable: false }}
          title="Locations Management"
          idx="locations"
        >
          <Locations />
        </Tab>
        <Tab
          options={{ swipeable: false }}
          title="Scraps Management"
          idx="scraps"
        >
          <Scraps />
        </Tab>

        {/* <Tab
          options={{
            swipeable: false,
          }}
          title="Network"
        >
          <Network />
        </Tab> */}
      </Tabs>
    </div>
  );
}

export default Organization;
