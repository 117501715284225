import React, { useState } from 'react';
import Stackedcarousel from './components/StackedCarousel';
import { AboutOrganisation, AboutWaste, PreAudit } from './components/FillDetails';
import { useMediaQuery } from 'react-responsive';

const STACKDATA = [
    {
        id: 1,
        name: 'Sri Krishna sweets',
        description: 'Sri Krishna Sweets is very environmentally conscious, happy, and proud to be a Net Zero Waste to Landfill brand by collaborating with CercleX.',
        image: 'https://images.jdmagicbox.com/comp/coimbatore/w9/0422px422.x422.160921110607.d4w9/catalogue/sri-krishna-sweets-saravanampatti-coimbatore-inexpensive-restaurants-below-rs-500--b8ok7.jpg?clr=#5b380b',
        key: 'child1'
    },
    {
        id: 2,
        name: 'Broadway Coimbatore',
        description: 'We are thrilled to be a Net Zero Waste to Landfill brand, thanks to our collaboration with CercleX. Being environmentally conscious has never been easier.',
        image: 'https://th-i.thgim.com/public/incoming/djizso/article67015004.ece/alternates/FREE_1200/CB31_Broadway_Cinemas_3.jpg',
        key: 'child2'
    },
    {
        id: 3,
        name: 'Taj Vivanta Coimbatore',
        description: 'Our waste management problem was solved by CercleX WasteKnot with a single swipe waste pickup service',
        image: 'https://cf.bstatic.com/xdata/images/hotel/max1024x768/469577687.jpg?k=72c0fd6d06e99c7380a120478b489047954ee3aa720bedebc6287329337ba079&o=&hp=1',
        key: 'child3'
    },
];

const Onboarding = () => {
    const gradientId = 'gradient'; // Unique ID for the gradient
    const isTablet = useMediaQuery({ query: "(max-width: 1440px)" });
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    const [ page, setPage ] = useState(1);
  
  return (
    <div style={{ justifyContent: 'space-around' }} className='full-width flex'>
        <div style={{  }} className='green'>
            <div style={{ position: 'absolute', left: -450 }} className='full-height flex align-center'>
                <svg width="900" height="900" xmlns="http://www.w3.org/2000/svg">
                    {/* Define the linear gradient */}
                    <defs>
                        <linearGradient id={gradientId} x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style={{ stopColor: '#120320', stopOpacity: 1 }} />
                        <stop offset="100%" style={{ stopColor: '#6F2DA8', stopOpacity: 1 }} />
                        </linearGradient>
                    </defs>

                    {/* Draw the right half of the circle using path */}
                    <path
                    d="M 450 0 A 450 450 0 0 1 450 900" // Path for the right half of the circle
                    fill={`url(#${gradientId})`} // Apply the linear gradient
                    />
                </svg>
            </div>
            <div className=''>
                <Stackedcarousel CAROUSELDATA={STACKDATA}/>
            </div>
        </div>
        <div
            style={{
            width: "40%",
            position: "absolute",
            right: isTablet ? "4rem" : "15rem",
            }}
            className={
            !isTablet
                ? "full-height flex column justify-center"
                : "full-height flex column justify-center"
            }
        >
            {page === 1 ? (
            <AboutOrganisation nextPage={() => setPage(page + 1)} />
            ) : page === 2 ? (
            <AboutWaste
                previousPage={() => setPage(page - 1)}
                nextPage={() => setPage(page + 1)}
            />
            ) : page === 3 ? (
            <PreAudit 
                previousPage={() => setPage(page - 1)}
            />
            ) : null}
        </div>
    </div>
  );
};

export default Onboarding;