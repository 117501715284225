import React from 'react'

export default function EditCamplingQA({onCloseEditModal}) {
  return (
    <div>
    <div
      className="full-width flex justify-center align-center gap-1"
      style={{ background: '#FFFDBE', padding: '5px' }}
    >
      <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
        info
      </span>
      <p className="purple-cercle-text small-text">
        Data entries require supporting documentation for verification.
      </p>
    </div>
    <div className="p-1">
      <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
        <p>
        How many people were reached by the conducted awareness programs
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the number of saplings were planted "
            />
          </span>
        </div>
      </div>

      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          How much of the total budget was actually used for conducting the
          awareness programs
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              placeholder="Enter the used budget for the awareness campaigns"
              style={{ width: '35%' }}
            />
          </span>
          <span
            class="material-symbols-outlined"
            style={{
              position: 'absolute',
              left: 380,
              top: 1,
              color: '#6F2DA8',
              background: '#ECE4F4',
              borderRadius: '0 6px 6px 0',
              padding: '9px',
            }}
          >
            <p>
              <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
            </p>
          </span>
        </div>
      </div>
      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          What was the total budget allocated for the awareness campaigns
          during the reporting period
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              placeholder="Enter the total budget for the awareness campaigns"
              style={{ width: '35%' }}
            />
          </span>
          <span
            class="material-symbols-outlined"
            style={{
              position: 'absolute',
              left: 380,
              top: 1,
              color: '#6F2DA8',
              background: '#ECE4F4',
              borderRadius: '0 6px 6px 0',
              padding: '9px',
            }}
          >
            <p>
              <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
            </p>
          </span>
        </div>
        <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
          <p>
            How many awareness programs were planned by the company during the
            reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                style={{ width: '40%' }}
                placeholder="Enter the number of awareness programs were planted "
              />
            </span>
          </div>
        </div>
        <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
          <p>
            How many awareness programs were actually conducted by the company
            during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                style={{ width: '40%' }}
                placeholder="Enter the number of awareness programs were conducted "
              />
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-end p-1">
        <button className="btn" onClick={onCloseEditModal}>Save</button>
      </div>
    </div>
  </div>
  )
}
