import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ComponentHeader } from "../modules";
import { getPickupsHistory } from "../redux/action/pickups";
import { Loading } from "../modules";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "react-tooltip";
import { calculateTotal, isTabletOrMobile } from "../helpers/helper";
import { PickupStatus } from "./pickups/components/PickupStatus";

const PickupsHistory = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 824px)" });
  const { history, historyLoading } = useSelector((state) => state.pickups);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPickupsHistory());
  }, [dispatch]);

  return (
    <section className="p-1">
      <ComponentHeader title="Pickups History" />
      <div
        className={
          isMobile
            ? "box  full-width white "
            : "box  full-width white dashboardtablev2 table-status"
        }
      >
        <table className="striped  full-width ">
          <thead>
            <tr>
              <th className="">Pickup Date </th>
              <th className="">Processor</th>
              <th className="">Scrap</th>
              <th className="">Price</th>
              <th className="">GST</th>
              <th className="">Total</th>
              <th className="">Quantity</th>
              <th className="">Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody className="responsive-table">
            {history.map((item, index) => {
              console.log(item, "histry");
              return (
                <tr
                  key={index}
                  onClick={() => navigate("/pickup/" + item._id)}
                  className="tableHover"
                >
                  <td>{new Date(item.date).toDateString()}</td>
                  <td>{item.processor?.name}</td>
                  <td>
                    {item.scrap?.name}-{item.scrap?.variant}
                  </td>
                  <td>₹ {item.price}/kg</td>
                  <td>5%</td>
                  <td>Rs.{calculateTotal(item.price, item.quantity)}</td>
                  {item.completed ? (
                    <td> {item.quantity?.toFixed(2)} MT</td>
                  ) : (
                    <td> YET TO BE CONFIRMED</td>
                  )}

                  <td style={{}}>
                    <PickupStatus item={item} />
                  </td>
                  <td>
                    <span class="material-symbols-outlined grey-text text-darken-1">
                      visibility
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {historyLoading && <Loading />}

        {((history?.length == 0 && !historyLoading) ||
          (!history && !historyLoading)) && (
          <div className="flex column align-center full-width  mv-2 p-2">
            <img
              src="../images/pickup.png"
              style={{ width: "130px", height: "100px" }}
              alt="pickup"
            />
            <p className="grey-text mt-1 ">
              Currently you don’t have any pickups.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default PickupsHistory;
