import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashboardCard } from "../../modules";
import { ComponentHeader } from "../../modules";
import { getAnalyticsOverview } from "../../redux/action/analytics.js";
import { getNotifications } from "../../redux/action/notifications.js";
import DashboardPickupHistoryTable from "../dashboard/components/DashboardPickupsHistory.js";
import MainChart from "../dashboard/components/MainChart.js";
import Modal from "react-responsive-modal";
import RaisePickups from "./components/RaisePickups.js";
import Pickups from "./Pickups.js";

function PickupsMain() {
  const { overview } = useSelector((state) => state.analytics);
  const { organization, network } = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const [openPickupModal, setOpenPickupModal] = React.useState(false);

  useEffect(() => {
    dispatch(getAnalyticsOverview());
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <div className="flex mt-1">
      <div className="dashboard-content ">
        <div className="flex align-center space-between">
          <ComponentHeader title="Pickups" />
          <button
            className="btn btn-primary"
            onClick={() => setOpenPickupModal(true)}
          >
            Request A Pickup
          </button>
        </div>

        <Modal
          open={openPickupModal}
          onClose={() => {
            setOpenPickupModal(false);
          }}
          styles={{
            modal: { borderRadius: "2px", width: "80%" },
          }}
        >
          <Pickups
            closeModal={() => {
              setOpenPickupModal(false);
            }}
          />
        </Modal>

        <div style={{ gap: "1.5rem" }} className="flex flex-wrap mt-1">
          <div style={{ gap: "1.5rem" }} className="flex column flex-1">
            <DashboardCard
              title="Total Pickups"
              amount={
                overview.totalPickupsThisMonth
                  ? overview.totalPickupsThisMonth + " Pickups"
                  : 0 + " Pickups"
              }
              desc="This Month"
              img="../images/calendar.png"
              percentageInc={overview.percentagePickupsChangeThisMonth}
              color={"light-blue lighten-5"}
            />

            {organization.type === "recycler" ? (
              <DashboardCard
                title="QUANTITY RECYCLED"
                amount={
                  overview.totalQuantityRecycled
                    ? overview.totalQuantityRecycled + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="Overall"
                img="../images/calendar.png"
              />
            ) : (
              <DashboardCard
                title="My Network"
                amount={network?.length}
                desc="overall"
                img="../images/calendar.png"
                color={"purple lighten-5"}
              />
            )}
          </div>
          <div style={{ gap: "1.5rem" }} className="flex column flex-1">
            <DashboardCard
              title="Pending Pickups"
              amount={
                overview.totalPickupsThisMonth
                  ? overview.totalPickupsThisMonth + " Pickups"
                  : 0 + " Pickups"
              }
              desc="This Month"
              img="../images/calendar.png"
              color={"light-green lighten-5"}
            />
            {organization.type === "recycler" ? (
              <DashboardCard
                title="QUANTITY RECYCLED"
                amount={
                  overview.totalQuantityRecycled
                    ? overview.totalQuantityRecycled + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="Overall"
                img="../images/calendar.png"
              />
            ) : (
              <DashboardCard
                title="Total channelized"
                amount={
                  overview.totalQuantityChannelized
                    ? overview.totalQuantityChannelized + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="overall"
                img="../images/calendar.png"
                percentageInc={overview.percentageQuantityChangeThisMonth}
                color={"pink lighten-5"}
              />
            )}
          </div>

          <MainChart />
        </div>

        <div className="mt-2">
          <DashboardPickupHistoryTable />
        </div>
      </div>
    </div>
  );
}

export default PickupsMain;
