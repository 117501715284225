import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditUserInfo from "./EditUserInfo";
import { IWMURL } from "../../../redux/config/urlConfig";
import EditOrganization from "./EditOrganization";
import AccountInfoHeader from "./AccountInfoHeader";
import { useMediaQuery } from "react-responsive";
import { getAccount, updateAccount } from "../../../redux/action/auth";
import { notify } from "../../../helpers/helper";
import { getOrganization, updateOrganization, updateOrganizationMember } from "../../../redux/action/organization";

function AccountInfo() {
  // const { account } = useSelector((state) => state.account);
  const { organization } = useSelector((state) => state.organization);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  const dispatch = useDispatch();
  const [editAccountModal, setEditAccountModal] = useState(false);

  const { account, loadingAccount } = useSelector((state) => state.account);
  const [userName, setUsername] = useState("");
  const [role, setRole] = React.useState("");
  const [customRole, setCustomRole] = React.useState("");
  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    if (!account) return;
    setUsername(account.name);
    setRole(account.roleDescription ? account.roleDescription : "");
  }, [account]);

  useEffect(() => {
    if (!organization) return;
    setOrganizationName(organization?.name);
  }, [organization]);

  function onSavechange(e) {
    e.preventDefault();

    if (organizationName !== organization?.name) {
      dispatch(
        updateOrganization({ name: organizationName }, () => {
          notify("Updated successfully","Organization name has been updated");
          dispatch(getOrganization());
          const elem = document.getElementById("edit-organization");
          const instance = window.M.Modal.getInstance(elem);
          instance.close();
        })
      );
    }

    if (userName !== account.name) {
      dispatch(
        updateAccount({ name: userName }, () => {
          notify("Updated successfully", "Organization name has been updated");
          dispatch(getAccount());
          const elem = document.getElementById("edit-account-modal");
          const instance = window.M.Modal.getInstance(elem);
          instance.close();
        })
      );
    }

    if (role === "other" && customRole) {
      dispatch(
        updateOrganizationMember({ roleDescription: customRole }, () => {
          notify("Updated Successfully", "your title has been updated");
          const elem = document.getElementById("edit-account-modal");
          const modal = window.M.Modal.getInstance(elem);
          modal.close();
          dispatch(getAccount());
        })
      );
    } else if (
      role !== (account.roleDescription ? account.roleDescription : "")
    ) {
      dispatch(
        updateOrganizationMember({ roleDescription: role }, () => {
          notify("Updated Successfully", "your title has been updated");
          dispatch(getAccount());
          const elem = document.getElementById("edit-account-modal");
          const modal = window.M.Modal.getInstance(elem);
          modal.close();
        })
      );
    }
  }

  return (
    <section className=" p-2  ">
      <div className="container mb-2">
        <AccountInfoHeader />

        {/* <div style={{  }} className="flex space-between align-center">
          <p className="bold">Your Account Information</p>
          <EditUserInfo />
        </div>

        <div className="">
          <div>
            <div className="flex s_b mb-1">
              <p>Your Name:</p>
              <p className="gray semi-bold capitalize ml-1">{account.name}</p>
            </div>

            <div className="flex s_b mb-1 ">
              <p>Mobile Number:</p>
              <p className="gray semi-bold ml-1">+{account.phoneNumber}</p>
            </div>

            <div className="flex s_b mb-1">
              <p>Email: </p>
              <p className="gray semi-bold ml-1">{account.email || "N/A"}</p>
            </div>

            <div className="flex s_b mb-1">
              <p>Role: </p>
              <p className="gray semi-bold ml-1">
                {account.roleDescription || "N/A"}
              </p>
            </div>
          </div>
        </div> */}

        <div className="">
          <div className="container">
            
            <div className={isTablet ? "mt-1" : "flex align-center gap-2 space-between"}>
              <b style={{ color: "#717D96" }}>Name</b>
              <div className="select-item full-width input-style" style={{ background: "#FAFAFA", width: isTablet ? '100%' : '70%' }}>
                <input
                  value={userName}
                  onChange={(e) => setUsername(e.target.value)}
                  className="browser-default hide-focus-underline"
                  style={{ caretColor: 'black' }}
                  type="text"
                />
                </div>
            </div>

            <div className={isTablet ? "mt-1" : "flex align-center gap-2 space-between mt-1"}>
              <b style={{ color: "#717D96" }}>Role</b>
              <select
                className="browser-default select-item full-width input-style"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                value={role}
                style={{ background: "#FAFAFA", width: isTablet ? '100%' : '70%' }}
              >
                <option value="">Select Role</option>
                <option value="sustainability-head">Sustainability Head</option>
                <option value="site-manager">Site Manager</option>
                <option value="other">other</option>
              </select>
            </div>

            {role === "other" && (
            <div className={isTablet ? "mt-1" : "flex align-center gap-2 space-between mt-1"}>
              <b style={{ color: "#717D96" }}>Other Role</b>
              <div className="select-item full-width input-style" style={{ background: "#FAFAFA", width: isTablet ? '100%' : '70%' }}>
                <input
                  value={customRole}
                  onChange={(e) => setCustomRole(e.target.value)}
                  className="browser-default hide-focus-underline"
                  style={{ caretColor: 'black' }}
                  type="text"
                />
                </div>
            </div>
            )}

            <div className={isTablet ? "mt-1" : "flex align-center gap-2 space-between mt-1"}>
              <b style={{ color: "#717D96" }}>Organization</b>
              <div className="select-item full-width input-style" style={{ background: "#FAFAFA", width: isTablet ? '100%' : '70%' }}>
                <input
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                  className="browser-default hide-focus-underline"
                  style={{ caretColor: 'black' }}
                  type="text"
                />
                </div>
                {/* <option value="">Select Organization</option>
                <option value="">{organization.address || "N/A"}</option>
              </select> */}
            </div>
            <button 
              disabled={
                (userName === account.name &&
                  role ===
                    (account.roleDescription ? account.roleDescription : "")) &&
                organizationName === organization?.name ||
                loadingAccount
              } 
              className="btn-small mt-3"
              onClick={onSavechange}
              >Save Changes</button>
          </div>
        </div>

      </div>

      {/* <div className="container mb-2">
        <div className="flex space-between align-center">
          <p className="bold">Your Organization</p>
          <EditOrganization />
        </div>

        <div className="">
          <div className="flex s_b mb-1">
            <p>Organization Name: </p>
            <p className="gray semi-bold ml-1">{organization.name || "N/A"}</p>
          </div>

          <div className="flex s_b mb-1">
            <p>Address: </p>
            <p className="gray semi-bold ml-1">
              {organization.address || "N/A"}
            </p>
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default AccountInfo;
