import React, { useState } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Petrol expenses', value: 25 },
  { name: 'Weight bridge', value: 13 },
  { name: 'Vehicle charge', value: 12 },
  { name: 'Other expenses', value: 12 },
  { name: 'Labor charge', value: 13 },
  { name: 'Total Brand', value: 25 },
]

const COLORS = [
  '#F94144',
  '#2D9CDB',
  '#F8961E',
  '#F9C74F',
  '#90BE6D',
  '#F3722C',
]

export default function AgeDistributionChart() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [clickedCell, setClickedCell] = useState(null)

  const handleCellClick = (data) => {
    setClickedCell(data)
  }
  return (
    <div
      className="white p-1"
      style={{
        width: isMobile ? '100%' : '30%',
        height: '310px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <p className="bold">Age distribution</p>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="40%"
              innerRadius={50}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
              labelLine={false}
              onClick={handleCellClick}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}

              {clickedCell && (
                <Label
                  key={`center-label`}
                  value={`${clickedCell.name}: ${clickedCell.value}`}
                  position="center"
                  fontSize={isMobile ? 12 : 10}
                />
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div
        className="flex space-between"
        style={{
          width: '94%',
          position: 'absolute',
          bottom: '30px',
          //   left: '10px',
        }}
      >
        <div>
          <span
            style={{ color: '#27AE60' }}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#27AE60',
              }}
            ></span>
            <p className="grey-text">Range of 20 - 30 yrs</p>
          </span>
        </div>
        <div>
          <span
            style={{ color: '#FF9500' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#FF9500',
              }}
            ></span>
            <p className="grey-text">30+yrs</p>
          </span>
        </div>
        <div>
          <span
            style={{ color: '#FB896B' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#FB896B',
              }}
            ></span>
            <p className="grey-text">40+yrs</p>
          </span>
        </div>
        <div>
          <span
            style={{ color: '#EA4335' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#EA4335',
              }}
            ></span>
            <p className="grey-text">Retire ~ 5 yrs</p>
          </span>
        </div>
    
      </div>
    </div>
  )
}
