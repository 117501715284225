import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ComponentHeader } from "../../modules";
import PlansCard from "./components/PlansCard";
import { TodaysSales } from "../dashboard/components/TodaysSales";
import BillingHistoryTable from "./components/BillingHistoryTable";
import Modal from "react-responsive-modal";
import { useSelector } from "react-redux";

const BILLINGTABLEDATA = [
  {
    id: 1,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
];

const PlansBillings = () => {
  const { history } = useSelector((state) => state.pickups);

  return (
    <div className="p-1">
      <ComponentHeader title="Plans & Billings" />

      <div className="flex mt-1 full-width gap-1 plan-header">
        <PlansCard type={1} />

        <img
          onClick={() => window.open("https://metabins.co/", "_blank")}
          src="../images/metabinDemoBanner.svg"
          className="pointer"
          alt="metabin"
        />
      </div>

      <div className="mt-2">
        <BillingHistoryTable history={[]} />
      </div>
    </div>
  );
};

export default PlansBillings;
