import React from "react";
import { useMediaQuery } from "react-responsive";

function DownloadApp() {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div className="">
      <div style={{ position: "relative" }} className="mb-1">
        <img
          src="../images/appdownloadbanner.png"
          style={{ width: "100%", borderRadius: "10px" }}
          Alt="Download App"
        />
        {!isMobile ? (
          <div
            style={{
              position: "absolute",
              top: isLaptop ? "14rem" : "19rem",
              left: isLaptop ? "7rem" : "10rem",
            }}
            className="flex gap-2"
          >
            <div>
              <a
                className="white download-app-btn p-1 flex align-center gap-1"
                href="https://play.google.com/store/apps/details?id=com.cerclex.pickupsseller"
              >
                <p>Download Mobile App</p>
                <img src="../images/android.png" alt="android" />
              </a>
            </div>
            {/* <div>
              <a
                className="white download-app-btn p-1 flex align-center gap-1"
                href="https://play.google.com/store/apps/details?id=com.cerclex.pickupsseller"
              >
                <p>Processor App</p>
                <img src="../images/android.png" alt="android" />
              </a>
            </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default DownloadApp;
