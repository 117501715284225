export const analyticsReducer = (
  state = {
    overview: {},
    reports: {},
    networkAnalytics: {},
    overviewLoading: false,
    monthlyQuantityOverTime: [],
    monthlyQuantityOverTimeLoading: false,
    quantityOverTime: [],
    quantityOverTimeLoading: false,
    reportsLoading: false,
    networkAnalyticsLoading: false,
  },
  action
) => {
  switch (action.type) {
    case "GETTING_OVERVIEW":
      return {
        ...state,
        overviewLoading: true,
      };

    case "GOT_OVERVIEW":
      return {
        ...state,
        overview: action.payload,
        overviewLoading: false,
      };

    case "CANNOT_GET_OVERVIEW":
      return {
        ...state,
        overviewLoading: false,
      };

    case "GETTING_MONTHLY_QUANTITY_OVER_TIME":
      return {
        ...state,
        monthlyQuantityOverTimeLoading: true,
      };

    case "GOT_MONTHLY_QUANTITY_OVER_TIME":
      return {
        ...state,
        monthlyQuantityOverTime: action.payload,
        monthlyQuantityOverTimeLoading: false,
      };

    case "CANNOT_GET_MONTHLY_QUANTITY_OVER_TIME":
      return {
        ...state,
        monthlyQuantityOverTimeLoading: false,
      };

    case "GETTING_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTimeLoading: true,
      };

    case "GOT_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTime: action.payload,
        quantityOverTimeLoading: false,
      };

    case "CANNOT_GET_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTimeLoading: false,
      };

    case "GETTING_REPORTS":
      return {
        ...state,
        reportsLoading: true,
      };

    case "GOT_REPORTS":
      return {
        ...state,
        reports: action.payload,
        reportsLoading: false,
      };

    case "CANNOT_GET_REPORTS":
      return {
        ...state,
        reportsLoading: false,
      };

    case "GETTING_NETWORK_ANALYTICS":
      return {
        ...state,
        networkAnalyticsLoading: true,
      };

    case "GOT_NETWORK_ANALYTICS":
      return {
        ...state,
        networkAnalytics: action.payload,
        networkAnalyticsLoading: false,
      };

    case "CANNOT_GET_NETWORK_ANALYTICS":
      return {
        ...state,
        networkAnalyticsLoading: false,
      };

    default:
      return state;
  }
};
