import React from "react";
import { useSelector } from "react-redux";
import { ComponentHeader } from "../../../modules";

const PlansSelect = () => {
  const { plans } = useSelector((state) => state.account);
  const bgs = ["#fff", "#fff", "purple", "green", "yellow"];
  return (
    <article className=" p-2 ">
      <ComponentHeader title="Choose Your Plan" />
      <section className="flex gap-2">
        {plans.map((plan, i) => (
          <div className={`plan ${bgs[i]} lighten-5`} key={i}>
            <span className={`material-symbols-outlined ${bgs[i]}-text`}>
              check_circle
            </span>

            <h6
              className={i !== 2 ? "cercle-purple-text" : ""}
              style={{ color: i === 2 ? "purple" : "" }}
            >
              {plan.name}
            </h6>

            {i === 2 ? <p> Blockchain </p> : <p>No Blockchain</p>}
            <h1
              className={i !== 2 ? "cercle-purple-text" : ""}
              style={i === 2 ? { color: "purple" } : {}}
            >
              {plan.price}
              <h6>
                {plan.currency} {plan.priceSuffix}
              </h6>
            </h1>

            <p>{plan.description}</p>
            <div className="flex align-end gap-1 ">
              <p>Setup Fee:</p>
              <h4
                style={{ margin: 0, color: i === 2 ? "purple" : "" }}
                className={i !== 2 ? "cercle-purple-text" : ""}
              >
                {plan.setUpFee} {plan.currency}
              </h4>
            </div>

            <div style={{ border: "2px solid grey", margin: "10px 0" }} />

            <div>
              {plan.features?.map((feature) => (
                <div className="flex align-center gap-1">
                  <span className="material-symbols-outlined">
                    check_circle
                  </span>
                  <p>{feature}</p>
                </div>
              ))}
            </div>

            <div className="flex justify-center">
              <button
                className="btn mt-1 full-width"
                style={
                  i === 2
                    ? {
                        background: `linear-gradient(to right, #6f2da8, purple)`,
                      }
                    : {}
                }
              >
                Select Plan
              </button>
            </div>
          </div>
        ))}
      </section>
    </article>
  );
};

export default PlansSelect;
