import { generateColor } from "../../helpers/helper";

export const pickupsReducer = (
  state = {
    loading: false,
    availablePickups: [],

    quantityOverTimeLoading: false,
    quantityOverTime: [],

    history: [],
    historyLoading: false,

    pickup: {},
    networkHistory: [],
    networkHistoryLoading: false,

    accepting: false,

    inventory: [],
    loadingInventory: false,

    recycledBatches: [],
    loadingRecycledBatches: false,

    creatingRecycledBatch: false,

    orderLoading: false,
    orders: [],
    order: {},

    completedPickups: [],
  },
  action
) => {
  switch (action.type) {
    case "LOADING_PICKUPS":
      return { ...state, loading: true };

    case "STOP_LOADING_PICKUPS":
      return { ...state, loading: false };

    case "GOT_AVAILABLE_PICKUPS":
      return { ...state, loading: false, availablePickups: action.payload };

    case "GOT_PICKUP_HISTORY":
      return { ...state, loading: false, history: action.payload };

    case "GOT_PICKUP":
      return { ...state, loading: false, pickup: action.payload };

    case "CANNOT_GET_AVAILABLE_PICKUPS":
      return { ...state, loading: false };

    case "GETTING_QUANTITY_OVER_TIME":
      return { ...state, quantityOverTimeLoading: true };

    case "GOT_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTimeLoading: false,
        quantityOverTime: action.payload,
      };

    case "CANNOT_GET_QUANTITY_OVER_TIME":
      return {
        ...state,
        quantityOverTimeLoading: false,
      };

    case "GETTING_PICKUP_HISTORY_WITH_A_NETWORK":
      return { ...state, networkHistoryLoading: true };

    case "GOT_PICKUP_HISTORY_WITH_A_NETWORK":
      return {
        ...state,
        networkHistoryLoading: false,
        networkHistory: action.payload,
      };

    case "CANNOT_GET_PICKUP_HISTORY_WITH_A_NETWORK":
      return {
        ...state,
        networkHistoryLoading: false,
      };

    case "GETTING_PICKUPS_HISTORY":
      return { ...state, historyLoading: true };

    case "GOT_PICKUPS_HISTORY":
      return {
        ...state,
        historyLoading: false,
        history: action.payload,
      };

    case "CANNOT_GET_PICKUPS_HISTORY":
      return {
        ...state,
        historyLoading: false,
      };

    case "ACCEPTING_PICKUP":
      return { ...state, accepting: true };

    case "ACCEPTED_PICKUP":
      return { ...state, accepting: false };

    case "CANNOT_ACCEPT_PICKUP":
      return { ...state, accepting: false };

    case "GETTING_INVENTORY":
      return { ...state, loadingInventory: true };

    case "GOT_INVENTORY":
      return {
        ...state,
        loadingInventory: false,
        inventory: action.payload.map(({ receivedFrom, items }) => {
          return {
            receivedFrom,
            items: items.map((item) => ({
              ...item,
              colors: [generateColor()],
            })),
          };
        }),
      };

    case "CANNOT_GET_INVENTORY":
      return {
        ...state,
        loadingInventory: false,
      };

    case "GETTING_RECYCLED_BATCHES":
      return { ...state, loadingRecycledBatches: true };

    case "GOT_RECYCLED_BATCHES":
      return {
        ...state, 
        loadingRecycledBatches: false,
        recycledBatches: action.payload.map((batch) => ({
          ...batch,
          colors: [generateColor()],
        })),
      };

    case "CANNOT_GET_RECYCLED_BATCHES":
      return {
        ...state,
        loadingRecycledBatches: false,
      };

    case "CREATING_RECYCLED_BATCH":
      return { ...state, creatingRecycledBatch: true };

    case "CREATED_RECYCLED_BATCH":
      return { ...state, creatingRecycledBatch: false };

    case "CANNOT_CREATE_RECYCLED_BATCH":
      return { ...state, creatingRecycledBatch: false };

    case "CREATING_ORDER":
      return { ...state, orderLoading: true };

    case "CREATED_ORDER":
      return { ...state, orderLoading: false };

    case "CANNOT_CREATE_ORDER":
      return { ...state, orderLoading: false };

    case "GETTING_ORDERS":
      return { ...state, orderLoading: true };

    case "GOT_ORDERS":
      return { ...state, orderLoading: false, orders: action.payload };

    case "CANNOT_GET_ORDERS":
      return { ...state, orderLoading: false };

    case "GETTING_SINGLE_ORDER":
      return { ...state, orderLoading: true };

    case "GOT_SINGLE_ORDER":
      return {
        ...state,
        orderLoading: false,
        order: action.payload,

        orders: state.orders.map((order) =>
          order._id === action.payload._id ? action.payload : order
        ),
      };

    case "CANNOT_GET_SINGLE_ORDER":
      return { ...state, orderLoading: false };

    case "GETTING_COMPLETED_PICKUPS":
      return { ...state, loading: true };

    case "GOT_COMPLETED_PICKUPS":
      return { ...state, loading: false, completedPickups: action.payload };

    case "CANNOT_GET_COMPLETED_PICKUPS":
      return { ...state, loading: false };

    case "INVENTORIZING_PICKUP":
      return { ...state, inventorizing: true };

    case "INVENTORIZED_PICKUP":
      return { ...state, inventorizing: false };

    case "CANNOT_INVENTORIZE_PICKUP":
      return { ...state, inventorizing: false };

    default:
      return state;
  }
};
