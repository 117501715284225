import React, { useEffect } from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ClipLoader } from "react-spinners";

const data = [
  ["From", "To", "Waste"],
  ["Food waste", "Clean Coimbatore Alliance", 200],
  ["Clean Coimbatore Alliance", "Recycled", 1],
  ["Clean Coimbatore Alliance", "Compost", 3],
  ["Clean Coimbatore Alliance", "Landfill", 2],
  ["Clean Coimbatore Alliance", "Upcycled", 1],
  ["Food waste", "SK Traders", 7],
  ["SK Traders", "Recycled", 1],
  ["SK Traders", "Compost", 3],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Landfill", 2],
  ["SK Traders", "Upcycled", 0],
];

export const options = {
  sankey: {
    node: {
      colors: ["#F9DC5C", "#E84855", "#3185FC", "#EA4335", "#009B72"],
      label: {
        color: "#000000",
        bold: true,
      },
    },
    link: {
      colorMode: "gradient",
      colors: ["#F9DC5C", "#E84855", "#3185FC", "#EA4335", "#009B72"],
    },
  },
};

const SankeyChart = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { overview, overviewLoading } = useSelector((state) => state.analytics);
  const [data, setData] = React.useState([
    ["From", "To", "Waste"],
    ["Loading", "", 1],
  ]);

  useEffect(() => {
    if (!overview.flowChart) return;
    setData(overview.flowChart);
  }, [overview.flowChart]);

  console.log(data);
  return (
    <div
      style={{ width: isMobile ? "100%" : "70%", height: "50vh" }}
      className="box white"
    >
      <div className="flex space-between">
        <p className="bold"> Waste Flow Chart</p>

        <ClipLoader color={"#6f2da8"} loading={overviewLoading} size={10} />
      </div>

      <Chart
        chartType="Sankey"
        width="100%"
        height={"95%"}
        data={data}
        options={options}
      />
    </div>
  );
};

export default SankeyChart;
