import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Frame from "./layout/Frame";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmOTP, Groups, Login } from "./pages";
import Settings from "./pages/settings/Settings";
import ScrapInventory from "./pages/ScrapInventory";
import store from "./redux/store";
import PickupsHistory from "./pages/PickupsHistory";
import PickupTimeLine from "./pages/PickupTimeLine";
import PaymentsHistory from "./pages/PaymentHistory";
import SinglePickup from "./pages/SinglePickup";
import Dashboard from "./pages/dashboard/Dashboard";
import Reports from "./pages/reports/Reports";
import Organization from "./pages/organization/Organization";
import LoadingPage from "./layout/Loading";
import { getAccount } from "./redux/action/auth";
import { getOrganization } from "./redux/action/organization";
import Step1 from "./pages/firstTime/components/Step1";
import Step2 from "./pages/firstTime/components/Step2";
import Step3 from "./pages/firstTime/components/Step3";
import Step4 from "./pages/firstTime/components/Step4";
import Step5 from "./pages/firstTime/components/Step5";
import Network from "./pages/networks/Network";
import SingleNetwork from "./pages/networks/components/SingleNetwork";
import { notifyError } from "./helpers/helper";
import PickupsReycler from "./pages/pickups/PickupsReycler";
import Inventory from "./pages/inventory/Inventory";
import SingleOrder from "./pages/pickups/components/SingleOrder";
import PickupsMain from "./pages/pickups/PickupsMain";
import PlansBillings from "./pages/plansbilings/PlansBillings";
import ReviewInsights from "./pages/reviewInsights/ReviewInsights";
import Onboarding from "./pages/onboarding/Onboarding";
import OverallBillingHistoryTable from "./pages/plansbilings/components/OverallBillingHistoryTable";
import OverallReviewHistoryTable from "./pages/reviewInsights/components/OverallReviewHistory";
// import NewAddScrap from "./pages/NewAddScrap";
// import EditScrapPage from "./pages/EditScrapPage";
import NewAddScrap from "./pages/ScrapInventory/components/NewAddScrap";
import EditScrapPage from "./pages/ScrapInventory/components/EditScrapPage";
import ViewSingleScrap from "./pages/ViewSingleScrap";
import ViewScrapPage from "./pages/ScrapInventory/components/ViewScrapPage";
import ESG from "./pages/ESG/ESG";
import Environmental from "./pages/ESG/children/Environmental/Environmental";
import Social from "./pages/ESG/children/Social/Social";
import Impact from "./pages/ESG/children/Impact/Impact";
import PlansSelect from "./pages/plansbilings/components/PlansSelect";
import CreateLot from "./pages/inventory/children/CreateLot";
import LotHistory from "./pages/inventory/children/LotHistory";
import AcceptInvitation from "./pages/firstTime/AcceptInvitation";
import InviteAccepted from "./pages/firstTime/InviteAccepted";
function App() {
  const tokenPresent = localStorage.getItem("__wasteknot");

  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        {tokenPresent ? <Authenticated /> : <Unauthenticated />}
      </BrowserRouter>
    </Provider>
  );
}

const Unauthenticated = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/confirm-OTP" element={<ConfirmOTP />} />
      <Route path="/onboarding" element={<Onboarding />} />
      <Route path="/accept-invitation" element={<AcceptInvitation />} />
      <Route path="/invite-accepted" element={<InviteAccepted />} />
    </Routes>
  );
};

const Authenticated = () => {
  const [caseType, setCaseType] = React.useState("loading");

  const dispatch = useDispatch();

  const FirstTime = () => {
    return (
      <Routes>
        <Route path="/" element={<Step1 />} />
        <Route path="/step2" element={<Step2 />} />
        <Route path="/step3" element={<Step3 />} />
        <Route path="/step4" element={<Step4 />} />
        <Route path="/step5" element={<Step5 />} />
      </Routes>
    );
  };

  const AccessDenied = () => {
    return (
      <Routes>
        <Route path="/network-error" element={<AccessDenied />} />
        <Route path="/accept-invitation" element={<AcceptInvitation />} />
        <Route path="/" element={<AccessDenied />} />
      </Routes>
    );
  };

  const Standard = () => {
    const { organization } = useSelector((state) => state.organization);

    return (
      <Frame>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route
            path="/pickups"
            element={
              organization.type === "industry" ? (
                <PickupsMain />
              ) : (
                <PickupsReycler />
              )
            }
          />

          <Route
            path="/raise-pickups"
            element={
              organization.type === "industry" ? (
                <PickupsMain />
              ) : (
                <PickupsReycler />
              )
            }
          />
          <Route path="/create-lot" element={<CreateLot />} />
          <Route path="/lot-history" element={<LotHistory />} />
          <Route path="/pickup/:id" element={<SinglePickup />} />
          <Route path="/timeline/:id" element={<PickupTimeLine />} />
          <Route path="/pickups-history" element={<PickupsHistory />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/payment-history" element={<PaymentsHistory />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/order/:id" element={<SingleOrder />} />
          <Route path="/network" element={<Network />} />
          <Route path="/network/:id" element={<SingleNetwork />} />
          <Route path="/plans-billings" element={<PlansBillings />} />
          <Route path="/scrap-inventory" element={<ScrapInventory />} />
          <Route path="/new-view-scrap" element={<ViewSingleScrap />} />
          <Route path="esg" element={<ESG />} />
          <Route path="/environmental" element={<Environmental />} />
          <Route path="/social" element={<Social />} />
          <Route path="/impact" element={<Impact />} />
          <Route path="/plans" element={<PlansSelect />} />
          <Route
            path="/billing-history"
            element={<OverallBillingHistoryTable />}
          />
          <Route path="/review-insights" element={<ReviewInsights />} />
          <Route
            path="/review-history"
            element={<OverallReviewHistoryTable />}
          />
          <Route path="/new-add-scrap" element={<NewAddScrap />} />
          <Route path="/new-view-scrap" element={<ViewScrapPage />} />
          <Route path="/new-edit-scrap" element={<EditScrapPage />} />
        </Routes>
      </Frame>
    );
  };

  useEffect(() => {
    dispatch(getAccount());
    dispatch(
      getOrganization((organization) => {
        if (
          organization.type !== "industry" &&
          organization.type !== "recycler"
        ) {
          notifyError(
            "Access Denied, You are not allowed to access this dashboard. Use the mobile app instead."
          );

          setTimeout(() => {
            localStorage.removeItem("__wasteknot");
            window.location.reload();
          }, 2000);
          return;
        }

        if (organization.setupComplete) setCaseType("standard");
        else setCaseType("first_time");
      })
    );
  }, [dispatch]);

  return (
    <>
      {((caseType) => {
        switch (caseType) {
          case "standard":
            return <Standard />;

          case "first_time":
            return <FirstTime />;

          case "loading":
            return <LoadingPage />;

          case "somethingWentWrong":
            return <AccessDenied />;

          default:
            return <Standard />;
        }
      })(caseType)}
    </>
  );
};

export default App;
