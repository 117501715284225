import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNetwork,
  getNetworkInvitations,
} from "../../redux/action/organization";
import AddPersonalNetwork from "./components/AddPersonalNetwork";
import AddExternalNetwork from "./components/AddExternalNetwork";
import { ClipLoader } from "react-spinners";
import { DashboardCard } from "../../modules";
import Ranking from "./components/Ranking";
import { useMediaQuery } from "react-responsive";
import Modal from "react-responsive-modal";
import NetworkTable from "./components/NetworkTable";
import { getNetworkAnalytics } from "../../redux/action/analytics";

const MOSTACTIVEUSERS = [
  {
    id: 1,
    brand: "ABC Recycling Solutions",
    transactionCount: 30,
  },
  {
    id: 2,
    brand: "Green Innovations Co",
    transactionCount: 24,
  },
  {
    id: 3,
    brand: "Eco Traders Ltd",
    transactionCount: 24,
  },
];

const CONTRIBUTORS = [
  {
    id: 1,
    brand: "Eco Warriors Association",
    tons: 30,
  },
  {
    id: 2,
    brand: "Sustainable Ventures Inc",
    tons: 24,
  },
  {
    id: 3,
    brand: "Green Earth Enterprises",
    tons: 22,
  },
];

function Network() {
  const { network, networkLoading, loadingAccounts, invitations } = useSelector(
    (state) => state.organization
  );
  const { networkAnalytics } = useSelector((state) => state.analytics);
  const dispatch = useDispatch();

  const isTablet = window.innerWidth <= 1224;
  const isMobile = useMediaQuery({ query: "(max-width: 674px)" });

  const [openPN, setOpenPN] = useState(false);
  const onOpenPNModal = () => setOpenPN(true);
  const onClosePNModal = () => setOpenPN(false);

  const [openCXN, setOpenCXN] = useState(false);
  const onOpenCXNModal = () => setOpenCXN(true);
  const onCloseCXNModal = () => setOpenCXN(false);

  useEffect(() => {
    dispatch(getNetwork());
    dispatch(getNetworkInvitations());
    dispatch(getNetworkAnalytics());
  }, [dispatch]);

  return (
    <div className="p-1">
      <div className="flex space-between">
        <p className="bold mb-1">My Network</p>
        <ClipLoader
          color="#6f2da8"
          size={20}
          loading={networkLoading || loadingAccounts}
        />
      </div>

      <div
        style={{ gap: "1.5rem" }}
        className={isMobile ? "" : "flex flex-wrap"}
      >
        <div style={{ gap: "1.5rem" }} className="flex column flex-1">
          <DashboardCard
            title={"Total Network"}
            color={"green lighten-5"}
            icon={"hub"}
            // percentageDec={10}
            cardValue={network.length}
          />
          <DashboardCard
            title={"Invitations"}
            color={"orange lighten-5"}
            icon={"mail"}
            cardValue={invitations.length}
          />
        </div>
        <div
          style={{ gap: "1.5rem" }}
          className={
            isMobile ? "flex column flex-1 mt-2" : "flex column flex-1"
          }
        >
          <DashboardCard
            title={"Traders/Aggregators"}
            color={"light-blue lighten-5"}
            icon={"storefront"}
            cardValue={
              network.filter((item) => item.role === "trader/aggregator").length
            }
          />
          <DashboardCard
            title={"Active Network"}
            color={"pink lighten-5"}
            icon={"toggle_on"}
            cardValue={network.filter((item) => !item.suspended).length}
          />
        </div>
        <div
          style={{ gap: "1.5rem" }}
          className={
            isMobile ? "flex column flex-1 mt-2" : "flex column flex-1"
          }
        >
          <DashboardCard
            title={"Recycler"}
            color={"deep-purple lighten-5"}
            icon={"recycling"}
            cardValue={
              network.filter((item) => item.type === "recycler").length
            }
            // percentageDec={10}
          />
          <DashboardCard
            title={"Suspended Network"}
            color={"indigo lighten-5"}
            icon={"toggle_on"}
            cardValue={network.filter((item) => item.suspended).length}
          />
        </div>

        <Ranking
          DATA={networkAnalytics.topProcessorsQuantityWise}
          heading={"Top Processors (Quantity Wise)"}
        />
        <Ranking
          DATA={networkAnalytics.topProcessorsRevenueWise}
          heading={"Top Processors (Revenue Wise)"}
        />
      </div>

      <div
        className={
          isMobile
            ? "flex mt-1 mb-1 space-between flex-wrap gap-2"
            : "flex mt-1 mb-1 space-between"
        }
      >
        <img
          onClick={onOpenPNModal}
          src="../images/addapersonalnetwork.svg"
          style={{ width: !isTablet ? "49.4%" : "100%", cursor: "pointer" }}
          className="addapersonalnetwork"
          alt="Add a personal network"
        />
        <img
          onClick={onOpenCXNModal}
          src="../images/addfromcerclexnetwork.svg"
          style={{ width: !isTablet ? "49.4%" : "100%", cursor: "pointer" }}
          className="addfromcerclexnetwork"
          alt="Add from Cercle X Network"
        />
      </div>

      <Modal
        center
        styles={{
          modal: {
            borderRadius: "10px",
            padding: 0,
            width: isMobile ? "92%" : "100%",
            position: isMobile ? "absolute" : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openPN}
        onClose={onClosePNModal}
        closeIcon={<></>}
      >
        <>
          <div className="cercle-purple p-1 white-text flex align-center space-between">
            <h4>Add A Personal Network</h4>
            <div
              onClick={onClosePNModal}
              className="flex align-center justify-center"
              style={{
                width: 40,
                height: 40,
                background: "rgba(255, 255, 255, 0.50)",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              <span class="material-symbols-outlined">close</span>
            </div>
          </div>
          <div
            className="flex align-center justify-center gap-1"
            style={{ background: "#FFFDBE", paddingBlock: "0.2rem" }}
          >
            <span class="material-icons cercle-purple-text">info</span>
            <label className="cercle-purple-text">
              An <span style={{ fontWeight: "bold" }}>Invitation</span> will be
              sent through <span style={{ fontWeight: "bold" }}>Email</span> and{" "}
              <span style={{ fontWeight: "bold" }}>SMS</span> to the provided
              email and phone number.
            </label>
          </div>
          <AddPersonalNetwork closeModal={onClosePNModal} />
        </>
      </Modal>

      <Modal
        center
        styles={{
          modal: {
            borderRadius: "10px",
            padding: 0,
            width: isMobile ? "92%" : "100%",
            position: isMobile ? "absolute" : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openCXN}
        onClose={onCloseCXNModal}
        closeIcon={<></>}
      >
        <>
          <div className="cercle-purple p-1 white-text flex align-center space-between">
            <div>
              <h4>Add from Cercle X Network</h4>
              <p style={{ fontFamily: "circular-light" }}>
                Onboard Cercle X's network of verified, authorized waste
                processors.
              </p>
            </div>
            <div
              onClick={onCloseCXNModal}
              className="flex align-center justify-center"
              style={{
                width: 40,
                height: 40,
                background: "rgba(255, 255, 255, 0.50)",
                borderRadius: "6px",
                cursor: "pointer",
              }}
            >
              <span class="material-symbols-outlined">close</span>
            </div>
          </div>
          <div
            className="flex align-center justify-center gap-1"
            style={{ background: "#FFFDBE", paddingBlock: "0.2rem" }}
          >
            <span class="material-icons cercle-purple-text">info</span>
            <label className="cercle-purple-text">
              An <span style={{ fontWeight: "bold" }}>Invitation</span> will be
              sent through <span style={{ fontWeight: "bold" }}>Email</span> and{" "}
              <span style={{ fontWeight: "bold" }}>SMS</span> to the provided
              email and phone number.
            </label>
          </div>
          <AddExternalNetwork />
        </>
      </Modal>

      <NetworkTable />
    </div>
  );
}

export default Network;
