import React, { useEffect, useState } from "react";
import { ComponentHeader } from "../modules";
import { IWMURL } from "../redux/config/urlConfig";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPickup } from "../redux/action/pickups";
import PickupTimeLine from "./PickupTimeLine";
import { useMediaQuery } from "react-responsive";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import { ProgressBar } from "react-materialize";
import PickupTimeLineRecyclers from "./PickupTimeLineRecyclers";

const SinglePickup = () => {
  const { pickup, loading } = useSelector((state) => state.pickups);
  const { id } = useParams();
  const [showDetails, setShowDetails] = useState(true);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:740px)" });

  useEffect(() => {
    dispatch(getPickup(id));
  }, [dispatch, id]);

  if (loading) return <ProgressBar />;

  return (
    <div className="p-1">
      <div>
      <span className="flex space-between align-center " > 
        <ComponentHeader title="Pickup Details" />
        <button className="btn"  >Upload Form</button>
        </span>

        <div style={{ gap: "1.5rem" }} className="flex flex-wrap mt-1">
          <div style={{}} className="flex column flex-1">
            <div
              className="box flex align-center gap-1 white space-between scrap-card"
              style={{}}
            >
              <div className="flex align-center">
                <img
                  src={`${IWMURL}/scraps/read/image/${pickup.scrap?._id}`}
                  style={{ width: "50px", height: "50px" }}
                  alt="scrap"
                />

                <div className="ml-1">
                  <p
                    className="bold small-text"
                    style={{ textTransform: "uppercase" }}
                  >
                    Scrap Name : {pickup.scrap?.name} - {pickup.scrap?.variant}
                  </p>
                  <p className="bold small-text">
                    {" "}
                    Price: Rs.{pickup.price}/Kg
                  </p>
                </div>
              </div>
            </div>

            <div className="box white">
              <div className="flex gap-1 align-center mb-1">
                <span class="material-icons cercle-purple-text">fmd_good</span>
                <h6>Location Information</h6>
              </div>
              <p
                className="chart-header grey-text"
                style={{ textTransform: "uppercase", marginBottom: "5px" }}
              >
                Pickup Location - {pickup?.pickupLocation?.nickname}
              </p>

              <div className="flex gap-1 align-center mb-1">
                <div className="">
                  <p>{pickup?.pickupLocation?.address}</p>
                </div>
              </div>

              {pickup?.dropLocation?.nickname ? <p
                className="chart-header grey-text"
                style={{ textTransform: "uppercase", marginBottom: "5px" }}
              >
                Drop Location - {pickup?.dropLocation?.nickname}
              </p> : <p
                className="chart-header grey-text"
                style={{ textTransform: "uppercase", marginBottom: "5px" }}
              >
                Drop Location
              </p>}

              <div className="flex gap-1 align-center">
                <div className="">
                  <p>{pickup?.dropLocation?.address}</p>
                </div>
              </div>
            </div>

            {pickup.assigned && (
              <>
                <div className="box white mt-1">
                  {pickup?.drivers?.map((driver, index) => {
                    return (
                      <div key={index}>
                        <div className="flex align-center space-between flex-wrap">
                          <div className="flex">
                            <img
                              src={`${IWMURL}/account/read/photo/${driver._id}`}
                              alt="driver"
                              style={{
                                width: "50px",
                                height: "50px",
                              }}
                              className="circle"
                            />

                            <div className="ml-1">
                              <p>{driver.name}</p>
                              <p>{driver.phoneNumber} </p>
                            </div>
                          </div>

                          <div
                            className={"flex gap-1" + isMobile ? " mt-1 " : ""}
                          >
                            <a
                              href={`${IWMURL}/organization/read/driver/doc/${driver._id}?type=aadhar`}
                              className="btn-outline-small mr-1"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Aadhar Card
                            </a>
                            <a
                              href={`${IWMURL}/organization/read/driver/doc/${driver._id}?type=drivingLicense`}
                              className="btn-outline-small"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Driving License
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            {pickup.assigned && (
              <>
                <div className="box white mt-1">
                  {pickup?.drivers?.map((driver, index) => {
                    return (
                      <div key={index}>
                        <div className="flex align-end space-between flex-wrap">
                          <div className="flex" key={index}>
                            <img
                              src={`${IWMURL}/vehicles/read/image/${pickup?.vehicles[index]?._id}`}
                              alt="driver"
                              style={{
                                width: "50px",
                                height: "50px",
                              }}
                              className="circle"
                            />

                            <div className="ml-1">
                              <p>{pickup?.vehicles[index]?.vehicleNumber}</p>
                              <p>{pickup?.vehicles[index]?.capacity} Tonnes</p>
                            </div>
                          </div>

                          <div
                            className={"flex gap-1" + isMobile ? " mt-1 " : ""}
                          >
                            <a
                              href={`${IWMURL}/vehicles/read/registrationCertificate/${pickup?.vehicles[index]?._id}`}
                              className="btn-outline-small mr-1"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Registration
                            </a>
                            <a
                              href={`${IWMURL}/vehicles/read/pollutionCertificate/${pickup?.vehicles[index]?._id}`}
                              className="btn-outline-small"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Pollution
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>

          <div style={{ gap: "1.5rem" }} className="flex column flex-1 full-height">
            <SinglePickupMap pickup={pickup} />
          </div>
        </div>
      </div>

      {/* {pickup.completed && (
        <>
          <p
            className="chart-header mt-1"
            style={{ textTransform: "uppercase", marginBottom: "5px" }}
          >
            Sustainability Metrics
          </p>
          <div className=" box flex flex-wrap  p-1  gap-1 mt-1 white">
            <div
              className={
                isMobile
                  ? "full-width flex  p-1 "
                  : "mh-2 p-1 flex gap-1   p-1 "
              }
            >
              <img src="../images/carbon.png" alt="" />
              <div className="semi-bold ">
                <p className="bold-title">{pickup.co2_avoided}</p>
                <p className="gray">Carbon Avoided</p>
              </div>
            </div>
            <div
              className={
                isMobile ? "flex  p-1 full-width " : "mh-2 p-1 flex gap-1  "
              }
            >
              <img src="../images/carbon-footprint.png" alt="" />
              <div className="semi-bold">
                <p className="bold-title">{pickup?.carbon_footPrint}</p>
                <p className="gray">Carbon Footprint</p>
              </div>
            </div>

            <div
              className={
                isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1  "
              }
            >
              <img src="../images/energy-saving.png" alt="energy-saving" />
              <div className="semi-bold">
                <p className="bold-title">{pickup.energy_saved}</p>
                <p className="gray">Energy Savings (KWH)</p>
              </div>
            </div>

            <div
              className={
                isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex gap-1  "
              }
            >
              <img src="../images/landfills.png" alt="landfills" />
              <div className="semi-bold">
                <p className="bold-title">{pickup.landfills_saved}</p>
                <p className="gray">Landfills Saved</p>
              </div>
            </div>

            <div
              className={isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex  "}
            >
              <img src="../images/trees-saving.png" alt="trees-saving" />
              <div className="semi-bold ml-1">
                <p className="bold-title">{pickup.trees_saved}</p>
                <p className="gray">Trees Saved</p>
              </div>
            </div>

            <div
              className={
                isMobile ? "full-width flex  p-1 " : "mh-2 p-1 flex ml-1  "
              }
            >
              <img src="../images/water-saved.png" alt="water-saved" />
              <div className="semi-bold">
                <p className="bold-title">{pickup.water_saved}</p>
                <p className="gray">Water Saved (kL)</p>
              </div>
            </div>
          </div>
        </>
      )} */}

      <div className="mt-2">
        <h6 className="">Timeline</h6>
        {pickup.path === "trader/aggregator-to-recycler" ? (
          <PickupTimeLineRecyclers
            pickup={pickup}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        ) : (
          <PickupTimeLine
            pickup={pickup}
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        )}
      </div>
    </div>
  );
};

const SinglePickupMap = GoogleApiWrapper({
  apiKey: "AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo",
})(({ pickup, google }) => {
  const mapRef = React.useRef(null);
  useEffect(() => {
    // Ensure that mapRef and pickup locations are available
    if (mapRef.current && pickup.pickupLocation && pickup.dropLocation) {
      // Create LatLngBounds object to include both pickup and drop locations
      const bounds = new window.google.maps.LatLngBounds();

      // Extend bounds to include pickup and drop locations
      bounds.extend(
        new window.google.maps.LatLng(
          pickup.dropLocation.latitude + 0.2,
          pickup.dropLocation.longitude + 0.2
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          pickup.dropLocation.latitude,
          pickup.dropLocation.longitude
        )
      );

      // Fit the map to the calculated bounds
      mapRef.current.map.fitBounds(bounds);
    }
  }, [pickup.pickupLocation, pickup.dropLocation]);

  return (
    <div style={{  }} className="single-pickup-map">
      <Map
        google={google}
        initialCenter={{
          lat: pickup.pickupLocation?.latitude,
          lng: pickup.pickupLocation?.longitude,
        }}
        center={{
          lat: pickup.pickupLocation?.latitude,
          lng: pickup.pickupLocation?.longitude,
        }}
        containerStyle={{
          position: "relative",
          width: "auto",
          height: "483px",
          // marginTop: "10px",
          borderRadius: '10px',
        }}
        zoom={14}
        ref={mapRef}
        style={{ borderRadius: '10px' }}
      >
        <Marker
          title={"Pickup Location"}
          position={{
            lat: pickup.dropLocation?.latitude + 0.2,
            lng: pickup.dropLocation?.longitude + 0.2,
          }}
        />
        <Marker
          title={"Drop Location"}
          position={{
            lat: pickup.dropLocation?.latitude,
            lng: pickup.dropLocation?.longitude,
          }}
        />

        <Polyline
          path={[
            {
              lat: pickup.dropLocation?.latitude + 0.2,
              lng: pickup.dropLocation?.longitude + 0.2,
            },
            {
              lat: pickup.dropLocation?.latitude,
              lng: pickup.dropLocation?.longitude,
            },
          ]}
          options={{
            strokeColor: "#FF0000", // Color of the polyline
            strokeOpacity: 0.8, // Opacity of the line
            strokeWeight: 2, // Thickness of the line
          }}
        />
      </Map>
    </div>
  );
});

export default SinglePickup;
