import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useMediaQuery } from 'react-responsive'
import EditEmissionQA from './EditEmission/EditEmissionQA'
import EditEnvironment from './EditEmission/EditEnvironment'

export default function EnergyQA() {
  const [showEditImpact, setShowEditImpact] = useState(false)

  const handleSubmit = () => {
    setShowEditImpact(true)
  }
  return (
    <>
     {!showEditImpact&&( 
     <>
     <div
        className="full-width flex justify-center align-center gap-1"
        style={{ background: '#FFFDBE', padding: '5px' }}
      >
        <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
          info
        </span>
        <p className="purple-cercle-text small-text">
          Data entries require supporting documentation for verification.
        </p>
      </div>
      <div className="questionery-2">
        <div className="flex column gap-1 ml-1 p-1">
          <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
            <p>
              What is the total amount of energy consumed by the company during
              the reporting period
            </p>
            <div style={{ position: 'relative' }}>
              <span>
                <input
                  className="new-input"
                  placeholder="Enter the total amount of energy consumed"
                  style={{ width: '40%' }}
                />
              </span>
              <span
                class="material-symbols-outlined"
                style={{
                  position: 'absolute',
                  left: 410,
                  top: 3,
                  color: '#6F2DA8',
                  background: '#ECE4F4',
                  borderRadius: '0 6px 6px 0',
                  padding: '14px',
                }}
              >
                <p style={{ fontSize: '14px' }}>MWh</p>
              </span>
            </div>
          </div>

          <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
            <p>
              How does the current year's energy consumption compare to the
              previous year
            </p>
            <div style={{ position: 'relative' }}>
              <span>
                <input
                  className="new-input"
                  placeholder="Enter the total amount of energy consumed  previous year"
                  style={{ width: '52%' }}
                />
              </span>
              <span
                class="material-symbols-outlined"
                style={{
                  position: 'absolute',
                  left: 550,
                  top: 3,
                  color: '#6F2DA8',
                  background: '#ECE4F4',
                  borderRadius: '0 6px 6px 0',
                  padding: '14px',
                }}
              >
                <p style={{ fontSize: '14px' }}>MWh</p>
              </span>
            </div>
          </div>

          <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
            <p>Please select the energy source used by the company</p>
            <div style={{ position: 'relative' }}>
              <select
                className="browser-default scrap-select-dropdown"
                style={{
                  background: '#FAFAFA',
                  border: 'none',
                  width: '30%',
                }}
              >
                <option>Select the sources</option>
                <option>Electricity</option>
                <option>Natural Gas</option>
                <option>Solar</option>
                <option>Wind</option>
                <option>Biomass</option>
                <option>Geothermal</option>
                <option>Other</option>
              </select>
              <span
                class="material-symbols-outlined"
                style={{
                  position: 'absolute',
                  left: 320,
                  top: 10,
                  color: '#6F2DA8',
                }}
              >
                arrow_drop_down
              </span>
            </div>
          </div>

          <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
            <p>
              What is the total amount of energy consumed from the Electricity
              during the reporting period?
            </p>
            <div style={{ position: 'relative' }}>
              <span>
                <input
                  className="new-input"
                  placeholder="Enter the total amount of energy consumed"
                  style={{ width: '40%' }}
                />
              </span>
              <span
                class="material-symbols-outlined"
                style={{
                  position: 'absolute',
                  left: 410,
                  top: 3,
                  color: '#6F2DA8',
                  background: '#ECE4F4',
                  borderRadius: '0 6px 6px 0',
                  padding: '14px',
                }}
              >
                <p style={{ fontSize: '14px' }}>MWh</p>
              </span>
            </div>
          </div>

          <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
            <p>
              What is the total amount of energy consumed from the Natural Gas
              during the reporting period?
            </p>
            <div style={{ position: 'relative' }}>
              <span>
                <input
                  className="new-input"
                  placeholder="Enter the total amount of energy consumed"
                  style={{ width: '40%' }}
                />
              </span>
              <span
                class="material-symbols-outlined"
                style={{
                  position: 'absolute',
                  left: 410,
                  top: 3,
                  color: '#6F2DA8',
                  background: '#ECE4F4',
                  borderRadius: '0 6px 6px 0',
                  padding: '14px',
                }}
              >
                <p style={{ fontSize: '14px' }}>MWh</p>
              </span>
            </div>
          </div>

          <div className="flex justify-end p-1">
            <button className="btn"  onClick={handleSubmit}>
              Submit
            </button>
          </div>

        
        </div>
      </div>
      </>
      )}
          {showEditImpact && (
            <EditEnvironment />
          )}
    </>
  )
}
