export const organizationReducer = (
  state = {
    vehicles: [],
    loadingVehicles: false,

    drivers: [],
    loadingDrivers: false,

    locations: [],
    loadingLocations: false,

    accounts: [],
    loadingAccounts: false,

    groups: [],
    group: {},
    loadingGroups: false,

    invitations: [],
    loadingInvitations: false,

    notifications: [],
    loadingNotifications: false,
    seenNotifications: true,

    organization: {},

    network: [],
    networkLoading: false,

    closestNetwork: [],
  },
  action
) => {
  switch (action.type) {
    //******  Reading Organization  ****//

    case "GETTING_NETWORK":
      return { ...state, networkLoading: true };

    case "GOT_NETWORK":
      return { ...state, network: action.payload, networkLoading: false };

    case "CANNOT_GET_NETWORK":
      return { ...state, networkLoading: false };

    case "GETTING_CLOSEST_NETWORK":
      return { ...state, networkLoading: true };

    case "GOT_CLOSEST_NETWORK":
      return {
        ...state,
        closestNetwork: action.payload,
        networkLoading: false,
      };

    case "CANNOT_GET_CLOSEST_NETWORK":
      return { ...state, networkLoading: false };

    case "GETTING_ORGANIZATION":
      return { ...state, loading: true };

    case "GOT_ORGANIZATION":
      return { ...state, organization: action.payload, loading: false };

    case "CANNOT_GET_ORGANIZATION":
      return { ...state, loading: false };

    case "GETTING_VEHICLES":
      return { ...state, loadingVehicles: true };

    case "GOT_VEHICLES":
      return { ...state, vehicles: action.payload, loadingVehicles: false };

    case "CANNOT_GET_VEHICLES":
      return { ...state, loadingVehicles: false };

    case "GETTING_LOCATIONS":
      return { ...state, loadingLocations: true };

    case "GOT_LOCATIONS":
      return { ...state, locations: action.payload, loadingLocations: false };

    case "CANNOT_GET_LOCATIONS":
      return { ...state, loadingLocations: false };

    case "GETTING_DRIVERS":
      return { ...state, loadingDrivers: true };

    case "GOT_DRIVERS":
      return { ...state, drivers: action.payload, loadingDrivers: false };

    case "CANNOT_GET_DRIVERS":
      return { ...state, loadingDrivers: false };

    case "GETTING_ACCOUNTS":
      return { ...state, loadingAccounts: true };

    case "GOT_ACCOUNTS":
      return { ...state, accounts: action.payload, loading: false };

    case "CANNOT_GET_ACCOUNTS":
      return { ...state };

    case "GETTING_GROUPS":
      return { ...state, loadingGroups: true };

    case "GOT_GROUPS":
      return { ...state, groups: action.payload, loadingGroups: false };

    case "CANNOT_GET_GROUPS":
      return { ...state };

    case "GETTING_INVITATIONS":
      return { ...state, loadingInvitations: true };

    case "GOT_INVITATIONS":
      return { ...state, invitations: action.payload };

    case "CANNOT_GET_INVITATIONS":
      return { ...state };

    case "ADDING_LOCATION":
      return { ...state, loadingLocations: true };

    case "ADDED_LOCATION":
      return {
        ...state,
        loadingLocations: false,
      };
    case "CANNOT_ADD_LOCATION":
      return { ...state, loadingLocations: false };

    case "INVITING_ACCOUNT":
      return { ...state, loadingAccounts: true };

    case "INVITED_ACCOUNT":
      return { ...state, loadingAccounts: false };

    case "CANNOT_INVITE_ACCOUNT":
      return { ...state, loadingAccounts: false };

    case "CANCELING_INVITATION":
      return { ...state, loadingInvitations: true };

    case "CANCELED_INVITATION":
      return { ...state, loadingInvitations: false };

    case "CANNOT_CANCEL_INVITATION":
      return { ...state, loadingInvitations: false };

    case "GETTING_INVITATION":
      return { ...state, loadingInvitations: true };

    case "GOT_INVITATION":
      return { ...state, loadingInvitations: false };

    case "CANNOT_GET_INVITATION":
      return { ...state, loadingInvitations: false };

    

    case "UPDATING_ORGANIZATION":
      return { ...state, loading: true };

    case "UPDATED_ORGANIZATION":
      return { ...state, loading: false };

    case "CANNOT_UPDATE_ORGANIZATION":
      return { ...state, loading: false };

    case "UPDATING_NETWORK":
      return { ...state, networkLoading: true };

    case "UPDATED_NETWORK":
      return { ...state, networkLoading: false };

    case "CANNOT_UPDATE_NETWORK":
      return { ...state, networkLoading: false };

    //****** End Of Reading ****//

    default:
      return state;
  }
};
