import React from "react";

const Ranking = ({ heading, DATA }) => {
  return (
    <div className="ranking-card">
      <div className="ranking-card-header text-center">
        <h6>{heading}</h6>
      </div>
      {DATA?.map((item, index) => (
        <div
          style={{
            paddingInline: "2rem",
            paddingBlock: "1rem",
            borderBottom: "1px solid #EBEBEB",
          }}
          className="flex align-center"
        >
          <div
            className="circle flex align-center justify-center ranking-tag"
            style={{
              background:
                index === 0
                  ? "#FFF9F3"
                  : index === 1
                  ? "#F4F7FD"
                  : index === 2
                  ? "#EBEDFF"
                  : null,
              color:
                index === 0
                  ? "#FFAB00"
                  : index === 1
                  ? "#A7B1C7"
                  : index === 2
                  ? "#6D77CB"
                  : null,
            }}
          >
            #{index + 1}
          </div>
          <div className="">
            <p>{item.organization?.name}</p>
            <p style={{ color: "#7C8DB5" }}>
              {item.value} {item.unit}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Ranking;
