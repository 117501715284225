import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculateTotal } from "../../../helpers/helper";
import { getPickupsHistory } from "../../../redux/action/pickups";
import { ClipLoader } from "react-spinners";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "react-tooltip";

function DashboardPickupHistoryTable() {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(max-width: 824px)" });
  const { history, loading } = useSelector((state) => state.pickups);
  const dispatch = useDispatch();
  const percentage = 50;

  useEffect(() => {
    dispatch(getPickupsHistory());
  }, [dispatch]);

  return (
    <div className="box  full-width white ">
      <div className="flex ph-1  align-center space-between ">
        <b className="bold">Overall Pickups History</b>
        {loading ? (
          <ClipLoader color={"#6f2da8"} loading={loading} size={20} />
        ) : (
          <Link to="/pickups-history" className="cercle-purple-text">
            View All
          </Link>
        )}
      </div>

      <table
        className={
          isTablet
            ? "striped responsive-table "
            : "striped responsive-table dashboardtablev2"
        }
      >
        <thead>
          <tr className="table-header-row">
            <th>Pickup Date</th>
            <th>Processor</th>
            <th className="">Scrap</th>
            <th>Price</th>
            <th>GST</th>
            <th>Total</th>
            <th className="">Quantity</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {history.slice(0,4).map((item, index) => {
            return (
              <tr
                key={index}
                onClick={() => navigate("/pickup/" + item._id)}
                className="tableHover"
              >
                <td>{new Date(item.date).toDateString()}</td>
                <td>{item.processor?.name}</td>
                <td>
                  {item.scrap?.name}-{item.scrap?.variant}
                </td>
                <td>Rs. {item.price}/kg</td>
                <td>5%</td>
                <td>Rs.{calculateTotal(item.price, item.quantity)}</td>
                {item.completed ? (
                  <td> {item.quantity?.toFixed(2)} MT</td>
                ) : (
                  <td> YET TO BE CONFIRMED</td>
                )}

                <td>
                  <div
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "20px",
                      height: "1.5rem",
                      position: "relative",
                      textAlign: isTablet ? "center" : "none",
                    }}
                    data-tooltip-id="status"
                    data-tooltip-content={
                      item.status === "requested"
                        ? "Pickup Raised"
                        : item.status === "accepted"
                        ? "Pickup Accepted"
                        : item.status === "assigned"
                        ? "Pending"
                        : null
                    }
                  >
                    <div
                      className="white-text full-height"
                      style={{
                        background:
                          item.status === "requested"
                            ? "#FFD400"
                            : item.status === "accepted"
                            ? "#FF881B"
                            : item.status === "assigned"
                            ? "#3871DF"
                            : item.status === "completed"
                            ? "#27AE60"
                            : null,
                        width:
                          item.status === "requested"
                            ? 25 + "%"
                            : item.status === "accepted"
                            ? 50 + "%"
                            : item.status === "assigned"
                            ? 75 + "%"
                            : item.status === "completed"
                            ? 100 + "%"
                            : 0 + "%",
                        borderRadius: "20px",
                        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                      }}
                    />
                    <span
                      className="white-text"
                      style={{
                        position: "absolute",
                        top: 2,
                        bottom: 0,
                        left: 0,
                        right: 0,
                      }}
                    >
                      {item.status === "completed" ? "Completed" : ""}
                    </span>
                    <Tooltip id="status" />
                  </div>
                </td>
                <td>
                  <span class="material-symbols-outlined grey-text text-darken-1">
                    visibility
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {(history?.length === 0 || !history) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            fire_truck
          </span>
          <p className="grey-text mt-1 ">
            Currently you don’t have any pickups.
          </p>
        </div>
      )}
    </div>
  );
}

export default DashboardPickupHistoryTable;
