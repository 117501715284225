import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Dropdown,
  Modal,
  Pagination,
  Modal as RMModal,
} from "react-materialize";
import {
  assignLocationToNetwork,
  getNetworkInvitations,
  rescindInvitation,
} from "../../../redux/action/organization";
import { notify } from "../../../helpers/helper";
import { ClipLoader } from "react-spinners";
import AddScrapToNetwork from "./AddScrapToNetwork";
import { useNavigate } from "react-router-dom";

const NetworkTable = () => {
  const {
    network,
    organization: org,
    networkLoading,
    invitations,
    loadingAccounts,
  } = useSelector((state) => state.organization);
  const isMobile = useMediaQuery({ query: "(max-width: 674px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const { account } = useSelector((state) => state.account);
  const [networkToShow, setNetworkToShow] = React.useState([]);
  const [location, setLocation] = React.useState("");
  const dispatch = useDispatch();
  const { scraps } = useSelector((state) => state.scraps);
  const navigate = useNavigate();
  const [activePage, setActivePage] = React.useState(1);
  const [page, setPage] = React.useState([]);
  const numberToShow = 7;

  useEffect(() => {
    setNetworkToShow(
      [
        network.map((item) => ({ ...item, displayType: "network" })),
        invitations.map((item) => ({ ...item, displayType: "invitation" })),
      ].flat()
    );
  }, [network, invitations]);

  useEffect(() => {
    const start = (activePage - 1) * numberToShow;
    const end = start + numberToShow;
    setPage(networkToShow.slice(start, end));
  }, [activePage, networkToShow]);

  const InvitationRow = ({ index, item }) => {
    console.log(item);
    return (
      <tr key={index}>
        <td className="capitalize">{item.organizationName}</td>
        <td className="capitalize">{item.role}</td>
        <td className="capitalize">N/A</td>
        <td className="lowecase">{item.email ? item.email : "N/A"}</td>
        <td>{item.phoneNumber ? "+" + item.phoneNumber : "N/A"}</td>
        <td>
          <div className="flex gap-1 justify-center">
            {item.locations.map((location, i) => (
              <p className="">
                {org.locations.find((loc) => loc._id === location)?.nickname ||
                  "N/A"}
              </p>
            ))}
          </div>
        </td>

        <td className="capitalize">
          <div className="flex" style={{ gap: "5px" }}>
            {!item.scraps?.length ? (
              <p>No Scrap Associated</p>
            ) : (
              item.scraps?.map((scrap, i) => (
                <div
                  style={{ backgroundColor: "#F7EEFE" }}
                  className="capsule-container cercle-purple-text"
                >
                  <p>{scraps.find((s) => s._id === scrap)?.name}</p>
                </div>
              ))
            )}
          </div>
        </td>

        <td className="">
          <RMModal
            trigger={
              <div
                style={{
                  width: isTablet ? "fit-content" : "80%",
                  textAlign: isTablet ? "center" : "none",
                }}
                className="mr-1 cancel-invite-btn pointer"
              >
                <span
                  style={{
                    color: "orangered",
                    textTransform: "none",
                  }}
                >
                  Cancel Invite
                </span>
              </div>
            }
            actions={[]}
            id={"cancel-invitation-modal" + index}
          >
            <p className="bold">Revoke Invitation</p>
            <p>
              Are you sure you want to cancel this invitation? This action
              cannot be undone.
            </p>

            <div className="flex mt-1 gap-1">
              <button
                className="btn "
                onClick={() => {
                  dispatch(
                    rescindInvitation(item._id, () => {
                      let elem = document.getElementById(
                        "cancel-invitation-modal" + index
                      );
                      let instance = window.M.Modal.getInstance(elem);
                      instance.close();
                      notify("Invitation Cancelled");
                      dispatch(getNetworkInvitations());
                    })
                  );
                }}
                disabled={loadingAccounts}
              >
                {loadingAccounts ? (
                  <ClipLoader color="#fff" size={20} />
                ) : (
                  "Yes, Cancel"
                )}
              </button>

              <Button className="btn-outline" modal="close">
                Cancel
              </Button>
            </div>
          </RMModal>
        </td>
      </tr>
    );
  };

  const NetworkRow = ({ item, index }) => {
    const { organization, role } = item;

    return (
      <tr key={index}>
        <td className="capitalize">{organization.name}</td>
        <td className="capitalize">{role}</td>
        <td className="capitalize">{organization.owner?.name}</td>
        <td className="lowercase">{organization.owner.email}</td>

        <td>+{organization.owner.phoneNumber}</td>
        <td>
          <div className="flex gap-1 justify-center">
            {item.locations.map((location, i) => (
              <p className="">
                {org.locations.find((loc) => loc._id === location)?.nickname ||
                  "N/A"}
              </p>
            ))}
          </div>
        </td>

        <td className="capitalize">
          <div className="flex ">
            {!item.scraps.length ? (
              <p>No Scrap Associated</p>
            ) : (
              item.scraps?.slice(0, 2).map((scrap, i) => (
                <div
                  style={{
                    backgroundColor: "#F7EEFE",
                  }}
                  className="capsule-container cercle-purple-text"
                >
                  <p>
                    {scraps.find((s) => s._id === scrap)?.name}-
                    {scraps.find((s) => s._id === scrap)?.variant}
                  </p>
                </div>
              ))
            )}

            {item.scraps.length > 2 && (
              <Modal
                trigger={
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="purple-text pointer"
                  >
                    +{item.scraps.length - 2} more...
                  </span>
                }
                header="Scraps Associated"
              >
                {item.scraps.map((scrap, i) => {
                  return (
                    <div
                      style={{
                        backgroundColor: "#F7EEFE",
                      }}
                      className="capsule-container cercle-purple-text"
                      key={i}
                    >
                      <p>
                        {scraps.find((s) => s._id === scrap)?.name}-
                        {scraps.find((s) => s._id === scrap)?.variant}
                      </p>
                    </div>
                  );
                })}
              </Modal>
            )}
          </div>
        </td>

        <td>
          <Dropdown
            trigger={
              <div>
                <i class="material-symbols-outlined">more_vert</i>
              </div>
            }
          >
            <span
              onClick={() => {
                navigate("/network/" + organization._id);
              }}
            >
              View Details
            </span>
            {account.permissions.includes("update-network") && (
              <span className="capitalize">
                <AddScrapToNetwork
                  associatedScraps={item.scraps}
                  network={organization._id}
                />
              </span>
            )}
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <div className="full-width box white">
      <div className="flex  space-between align-center">
        <div className="flex space-between gap-1 align-center ">
          <h5>Network list</h5>
          <ClipLoader
            color="#6f2da8"
            size={20}
            loading={networkLoading || loadingAccounts}
          />
        </div>
        <div
          className="input-style flex align-center"
          style={{
            width: isMobile ? "100%" : "40%",
            padding: "0 10px",
            borderRadius: "10px",
            height: "40px",
          }}
        >
          <span className="material-symbols-outlined grey-text mr-1">
            search
          </span>
          <input
            className="browser-default "
            type="search"
            placeholder="Search for network"
            onChange={(e) => {
              setNetworkToShow(
                networkToShow.filter(
                  (item) =>
                    item.organization?.name
                      ?.toLowerCase()
                      .includes(e.target.value.toLowerCase()) ||
                    item.organizationName
                      ?.toLowerCase()
                      .includes(e.target.value.toLowerCase())
                )
              );
            }}
          />
        </div>
      </div>

      {page.length > 0 ? (
        <table
          className={
            isMobile
              ? "striped  payment-history  responsive-table"
              : "striped  payment-history  responsive-table mynetwork"
          }
        >
          <thead>
            <tr>
              <th className="primary">Name</th>
              <th className="primary">Role</th>
              <th className="primary">Owner</th>
              <th className="primary">Email</th>
              <th className="primary">Phone Number</th>
              <th className="primary">Assigned Location</th>
              <th className="primary">Associated Scraps</th>
              <th className="primary">Action</th>
            </tr>
          </thead>

          <tbody>
            {page.map((item, i) => {
              return item.displayType === "network" ? (
                <NetworkRow item={item} index={i} />
              ) : (
                <InvitationRow index={i} item={item} />
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex column align-center full-width white mv-2 p-2">
          <span
            class="material-symbols-outlined grey-text"
            style={{ fontSize: "3rem" }}
          >
            group
          </span>
          <p className="grey-text mt-1 ">
            You have no network yet. Click on add network button to add
          </p>
        </div>
      )}

      <div className="flex justify-center mt-1">
        {networkToShow.length > numberToShow && (
          <Pagination
            activePage={activePage}
            items={
              networkToShow.length > numberToShow
                ? Math.ceil(networkToShow.length / numberToShow)
                : 1
            }
            maxButtons={
              networkToShow.length > numberToShow
                ? Math.ceil(networkToShow.length / numberToShow)
                : 1
            }
            onSelect={(page) => setActivePage(page)}
          />
        )}
      </div>
    </div>
  );
};

export default NetworkTable;
