import React, { useState } from "react";
import GaugeComponent from "react-gauge-component";
import { useMediaQuery } from "react-responsive";

const GaugeChart = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1440px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <div
      style={{ borderRadius: "12px", width: isMobile ? "100%" : "30%" }}
      className=""
    >
      <div
        style={{
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          position: "relative",
        }}
        className="black p-1"
      >
        {openInfo ? <div
          className="white"
          style={{
            borderRadius: "12px",
            position: "absolute",
            top: "4rem",
            right: "1.9rem",
            left: "1.9rem",
            zIndex: "999",
          }}
        >
          {isMobile ? <h4
            className="mt-1"
            style={{ lineHeight: "2rem", paddingInline: "2rem" }}
          >
            How can I verify a brand's
            <br />
            Net Zero Waste to Landfill claim?
          </h4> : <h3
            className="mt-2"
            style={{ lineHeight: "2.5rem", paddingInline: "2rem" }}
          >
            How can I verify a brand's
            <br />
            Net Zero Waste to Landfill claim?
          </h3>}
          <hr color="#EBEBEB" />
          <div
            style={{ paddingInline: "2rem", paddingBottom: isMobile ? '0.5rem' : "2rem" }}
            className=""
          >
            <h6 style={{ lineHeight: "1.8rem" }}>
              When a brand says "<span className="cercle-purple-text">Net Zero Waste to Landfill</span>," they're committing
              to diverting almost all (often exceeding <span className="cercle-purple-text">99%</span>) <span className="cercle-purple-text">of their waste away
              from landfills</span>. This means recycling, composting, reusing, or
              converting waste into energy, minimizing the environmental impact
              from landfill gas and resource depletion.
            </h6>
          </div>
        </div> : null}
        <div className="white-text flex align-center space-between">
          <h5>Net Zero waste to landfill</h5>
          <span onClick={() => setOpenInfo(!openInfo)} class="material-icons pointer">info</span>
        </div>
        <GaugeComponent
          type="semicircle"
          arc={{
            colorArray: ["#FF718B", "#FCB5C3", "#FFEB3A", "#7FE47E"],
            padding: 0.05,
            subArcs: [
              { limit: 50 },
              { limit: 65 },
              { limit: 80 },
              { limit: 100 },
            ],
            cornerRadius: 14.2,
            width: 0.12,
          }}
          style={{}}
          pointer={{ type: "blob", animationDelay: 0 }}
          value={93}
          labels={{
            valueLabel: {
              style: {
                fontSize: "45px",
                fontWeight: "bold",
              },
            },
          }}
        />
        <p
          style={{
            position: "absolute",
            bottom: isTablet ? "2rem" : "3rem",
            left: 0,
            right: 0,
          }}
          className="white-text center"
        >
          Diversion from landfill
        </p>
      </div>
      <div
        style={{ fontWeight: "bold", paddingBlock: "0.75rem" }}
        className="white center"
      >
        🥳 Congrats now you’re{" "}
        <span className="cercle-purple-text">Net Zero waste to landfill</span>
      </div>
      <div
        style={{
          paddingBlock: "0.75rem",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
        }}
        className="white-text cercle-purple center"
      >
        Your Score is in the{" "}
        <span className="yellow-text text-lighten-2">Top 2%</span> of
        <br />
        Coimbatore Bulk waste generators
      </div>
    </div>
  );
};

export default GaugeChart;
