import React, { useEffect, useState } from "react";
import SingleAggregatorWithTracks from "./SingleAggregatorWithTracks";
import { DashboardCard } from "../../../modules";
import { isTabletOrMobile, notify } from "../../../helpers/helper";
import Modal from "react-responsive-modal";
import AddTrack from "./AddTrack";
import {
  getCompletedPickups,
  getInventory,
  inventorizePickup,
} from "../../../redux/action/pickups";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader, SquareLoader } from "react-spinners";

function MaterialInward() {
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedPickup, setSelectedPickup] = useState({});
  const [fields, setFields] = useState({
    form: "",
    scrapSource: "",
    contaminationLevel: "",
  });
  const [showMaterialIntakeModal, setShowMaterialIntakeModal] = useState(false);
  const { completedPickups, inventorizing, loading } = useSelector(
    (state) => state.pickups
  );
  const [documents, setDocuments] = useState([
    {
      name: "qualityTestingReport",
      file: "",
    },
  ]);
  const [scraps, setScraps] = useState([]);
  const [scrap, setScrap] = useState({
    value: "",
    name: "all available scraps",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompletedPickups());
  }, [dispatch]);

  useEffect(() => {
    setData(completedPickups);
  }, [completedPickups]);

  useEffect(() => {
    let scraps = [{ name: "all available scraps", value: "" }];

    completedPickups.forEach(({ generator, completedPickups }) => {
      completedPickups.forEach((item) => {
        if (scraps.some((scrap) => scrap.value === item.scrap._id)) return;
        scraps.push({
          name: item.scrap.name + "-" + item.scrap.variant,
          value: item.scrap._id,
        });
      });
      return scraps;
    });

    setScraps(scraps);
  }, [completedPickups]);

  useEffect(() => {
    if (scrap.value === "") return setData(completedPickups);

    const newData = completedPickups.filter(
      ({ generator, completedPickups }) => {
        return completedPickups.some((item) => item.scrap._id === scrap.value);
      }
    );
    setData(newData);
  }, [completedPickups, scrap]);

  useEffect(() => {
    if (keyword === "") return setData(completedPickups);

    const newData = completedPickups.filter(
      ({ generator, completedPickups }) => {
        return generator.name.toLowerCase().includes(keyword.toLowerCase());
      }
    );
    setData(newData);
  }, [completedPickups, keyword]);

  const submit = () => {
    if (!fields.form)
      return notify("Field Required", "Form of Material is required");
    if (!fields.scrapSource)
      return notify("Field Required", "Material Origin is required");
    if (!fields.contaminationLevel)
      return notify("Field Required", "Contamination Level is required");

    if (documents.some((doc) => !doc.file))
      return notify("Field Required", "All documents are required");

    const formData = new FormData();
    formData.append("form", fields.form);
    formData.append("scrapSource", fields.scrapSource);
    formData.append("contaminationLevel", fields.contaminationLevel);
    documents.forEach((doc) => {
      formData.append(doc.name, doc.file);
    });

    dispatch(
      inventorizePickup(selectedPickup._id, formData, () => {
        dispatch(getCompletedPickups());
        dispatch(getInventory());
        setShowMaterialIntakeModal(false);
        setFields({
          form: "",
          scrapSource: "",
          contaminationLevel: "",
        });

        setDocuments([
          {
            name: "qualityTestingReport",
            file: "",
          },
        ]);

        notify(
          "Success",
          "Material has been successfully added to its respective Virtual Storage Unit (VSU)"
        );


      })
    );
  };

  return (
    <div>
      <span className="row full-width flex space-between"></span>
      <section
        style={{ gap: "1.5rem" }}
        className={isTabletOrMobile ? "" : "flex flex-wrap mb-2"}
      >
        <div style={{ gap: "1.5rem" }} className="flex column flex-1">
          <DashboardCard
            title={"Material Volume Available"}
            color={"purple lighten-5"}
            icon={"hub"}
            amount={"2 MT "}
          />
          <DashboardCard
            title={"Volume To Be Received"}
            color={"purple lighten-5"}
            icon={"fire_truck"}
            amount={"N/A"}
          />
        </div>

        <div style={{ gap: "1.5rem" }} className="flex column flex-1">
          <DashboardCard
            title={"Material Sources"}
            color={"purple lighten-5"}
            icon={"people"}
            amount={
              completedPickups.length === 1
                ? "1 Aggregator"
                : completedPickups.length + " Aggregators"
            }
          />
          <DashboardCard
            title={"Materal Types Available"}
            color={"purple lighten-5"}
            icon={"list"}
            amount={scraps.length - 1 === 1 ? "1 Type" : scraps.length - 1 + " Types"}
          />
        </div>
        <div
          style={{ width: 800, height: 260 }}
          className="grey lighten-2"
        ></div>
      </section>
      <div className="flex space-between align-center mb-1 white lighten-2 p-1">
        <p className="bold">Initial Sorting Zone </p>

        <div>
          <input
            id="search"
            type="text"
            className="browser-default disable-focus"
            style={{
              borderRadius: "5px",
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
            }}
            placeholder="Search Aggregator or Trader"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            value={keyword}
          />
        </div>

        <div>
          <select
            className="icons browser-default "
            style={{
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
            onChange={(e) => {
              setScrap({
                value: e.target.value,
                name: e.target.selectedOptions[0].text,
              });
            }}
          >
            {scraps.map((scrap, i) => (
              <option value={scrap.value} key={i}>
                {scrap.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex-wrap align-center align-center gap-1 flex ">
        {loading ? (
          <div
            style={{ width: "100%", height: "100%" }}
            className="flex align-center justify-center space-between"
          >
            <SquareLoader
              color={"#6f2da817"}
              loading={true}
              css={{ textAlign: "center" }}
              size={300}
            />
            <SquareLoader
              color={"#6f2da817"}
              loading={true}
              css={{ textAlign: "center" }}
              size={300}
            />

            <SquareLoader
              color={"#6f2da817"}
              loading={true}
              css={{ textAlign: "center" }}
              size={300}
            />
          </div>
        ) : data.length === 0 ? (
          <div className=" full-width flex column align-center justify-center p-2 ">
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "100px", color: "#ccc" }}
            >
              shelves
            </span>
            <p className="grey-text">No New Materials Received So Far</p>
          </div>
        ) : (
          data.map(({ generator, completedPickups }, index) => {
            if (completedPickups?.length === 0) return null;

            return completedPickups.map((item) => {
              return (
                <SingleAggregatorWithTracks
                  item={item}
                  organization={generator}
                  data={item.pickups}
                  colors={item.colors}
                  key={index}
                  virtualBinsCode={item.virtualBinsCode}
                  setSelectedPickup={setSelectedPickup}
                  setShowMaterialIntakeModal={setShowMaterialIntakeModal}
                />
              );
            });
          })
        )}
      </div>

      <Modal
        onClose={() => setShowMaterialIntakeModal(false)}
        open={showMaterialIntakeModal}
      >
        <div className="col  white box s5" style={{ padding: "10px" }}>
          <span className=" flex full-width space-between align-center mb-1">
            <p className="bold">Add New Material Intake To Inventory</p>
            <Modal
              id="Modal-10"
              open={false}
              trigger={
                <button className="btn gap-1 cercle-btn">
                  <span class="material-symbols-outlined">new_label</span>
                  <b>Input Truck</b>
                </button>
              }
            >
              <AddTrack />
            </Modal>
          </span>

          <>
            {" "}
            <div className="flex align-center space-between  mb-1">
              <div>
                <b className="bold">#{selectedPickup.organization?.name}</b>

                <span className="flex gap-1 ">
                  <p>
                    {selectedPickup.scrap?.name}-{selectedPickup.scrap?.variant}
                  </p>

                  {/* <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "700",
                      backgroundColor: "green",
                      padding: "0 5px",
                      borderRadius: "2px",
                      color: "#fff",
                    }}
                    className="flex align-center justify-center gap-1 "
                  >
                    <span className=" small-text material-symbols-outlined">
                      code
                    </span>
                    <b>{selectedPickup.virtualBinsCode}</b>
                  </span> */}
                </span>
              </div>
              <div className=" flex align-center  gap-2 ">
                <div>
                  <div className="flex align-center ">
                    <span class="material-symbols-outlined  cercle-purple-text ">
                      calendar_month
                    </span>
                    <b className="cercle-purple-text">Pickup Date</b>
                  </div>
                  <p>{new Date(selectedPickup.createdAt).toDateString()}</p>
                </div>

                <div>
                  <div className="flex align-center   ">
                    <span class="material-symbols-outlined  cercle-purple-text ">
                      calendar_month
                    </span>
                    <b className="cercle-purple-text">Received Date</b>
                  </div>
                  <p>{new Date(selectedPickup.completedOn).toDateString()}</p>
                </div>
              </div>
            </div>
            <hr color="#efefef" />
            <div className="flex align-center space-between mt-2">
              <div>
                <span className="flex cercle-purple-text align-center gap-1   ">
                  <p className="bold title  ">Quantity:</p>
                </span>

                <div>
                  <b>{selectedPickup.quantity} MT</b>
                </div>

                <span className="flex cercle-purple-text align-center gap-1 mt-1 ">
                  <b className="bold title  ">Select Form Of Material:</b>
                </span>

                <div>
                  <select
                    className="browser-default"
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                    onChange={(e) => {
                      setFields({ ...fields, form: e.target.value });
                    }}
                    value={fields.form}
                  >
                    <option value="">Select Form Of Material</option>
                    <option value="bales">Bales</option>
                    <option value="loose">Loose</option>
                  </select>
                </div>

                <span className="flex cercle-purple-text align-center gap-1 mt-1 ">
                  <b className="bold title  ">Material Origin</b>
                </span>

                <div key={15}>
                  <select
                    className="browser-default"
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                    onChange={(e) => {
                      setFields({ ...fields, scrapSource: e.target.value });
                    }}
                    value={fields.scrapSource}
                  >
                    <option value="">Select Material Origin</option>
                    <option value="post-consumer">Post Consumer</option>
                    <option value="post-industrial">Post Industrial</option>
                  </select>
                </div>

                <span className="flex cercle-purple-text align-center gap-1 mt-1 ">
                  <b className="bold title  ">Contamination Level :</b>
                </span>

                <div className=" mb-1 ">
                  <input
                    className="browser-default search-input "
                    type="text"
                    placeholder="Enter Contamination Level "
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        contaminationLevel: e.target.value,
                      });
                    }}
                    value={fields.contaminationLevel}
                  />
                </div>
              </div>

              <div className="flex column ">
                {/* <b> {selectedPickup._id}</b> */}
                <img
                  src="../images/track-qr.png"
                  style={{ width: "150px", height: "150px" }}
                  className="mt-1"
                  alt="track-qr"
                  onClick={() =>
                    window.open(
                      "https://trace.thinktrash.co/product-timeline/653b59c3d1853ba55ec9cd37"
                    )
                  }
                />
              </div>
            </div>
            <hr color="#efefef" />
            <section className="mt-2">
              <p className="bold  ">Attachments and Documents:</p>

              <div className="flex column align-end">
                {documents.map((doc, i) => (
                  <div
                    className="flex border gap-1 full-width align-center"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "0 2px",
                    }}
                  >
                    <select
                      className="icons browser-default "
                      value={doc.name}
                      onChange={(e) => {
                        const newDocuments = [...documents];
                        newDocuments[i].name = e.target.value;
                        setDocuments(newDocuments);
                      }}
                    >
                      <option value="">Select Document</option>
                      <option value="qualityTestingReport">
                        Quality Testing Report
                      </option>
                    </select>

                    <input
                      type="file"
                      className="browser-default"
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => {
                        const newDocuments = [...documents];
                        newDocuments[i].file = e.target.files[0];
                        setDocuments(newDocuments);
                      }}
                    />

                    {documents.length > 1 && (
                      <span
                        class="material-symbols-outlined grey-text pointer"
                        onClick={() => {
                          const newDocuments = [...documents];
                          newDocuments.splice(i, 1);
                          setDocuments(newDocuments);
                        }}
                      >
                        cancel
                      </span>
                    )}
                  </div>
                ))}

                <button
                  className="btn-small mt-1"
                  onClick={() => {
                    setDocuments([
                      ...documents,
                      {
                        name: "",
                        file: "",
                      },
                    ]);
                  }}
                >
                  Add More Files
                </button>
              </div>
            </section>
            <hr className="mt-1" />
            <div className="flex justify-end mt-2 gap-1">
              <button
                className="btn green waves-effect waves-light"
                onClick={submit}
              >
                {inventorizing ? (
                  <ClipLoader color={"#fff"} size={20} />
                ) : (
                  "Add to Inventory"
                )}
              </button>
              <button
                className="btn white cercle-purple-text"
                onClick={() => setShowMaterialIntakeModal(false)}
              >
                Cancel
              </button>
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
}

export default MaterialInward;
