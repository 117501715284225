import React from "react";
import { useState } from "react";
import { Button, Modal } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { addScrapToNetwork } from "../../../redux/action/organization";
import { notify } from "../../../helpers/helper";

const AddScrapToNetwork = ({ associatedScraps, network }) => {
  const [scrap, setScrap] = useState("");
  const { organization } = useSelector((state) => state.organization);
  const dispatch = useDispatch();

  return (
    <Modal
      trigger={<p className="pointer">Associate Scrap</p>}
      id="assign-location-modal"
      actions={<></>}
      style={{ borderRadius: '10px' }}
    >
      <p className="bold">Add Scrap</p>

      <div className="mt-1">
        <label className="black-text">
          Select a scrap to be associated with this network
        </label>
        <div className="row  ">
          <select
            className="input-style full-width browser-default "
            name="location"
            value={scrap}
            onChange={(e) => setScrap(e.target.value)}
          >
            <option value={""}>--select--</option>
            {organization?.scraps?.map((scrap, i) => {
              if (associatedScraps?.includes(scrap._id)) return null;
              return (
                <option value={scrap._id} key={i}>
                  {scrap.name}-{scrap.variant}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="flex gap-1">
        <button
          className="btn"
          disabled={!scrap}
          onClick={() => {
            dispatch(
              addScrapToNetwork({ scrap: scrap, network }, () => {
                notify("Scrap added to network successfully");
              })
            );
          }}
        >
          Add Scrap
        </button>

        <Button modal="close" className="btn-outline  first-time-but ">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AddScrapToNetwork;
