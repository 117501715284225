import React, { useState } from "react";
import { useEffect } from "react";
import ApexCharts from "react-apexcharts";
import { Tooltip } from "react-tooltip";
import { IWMURL } from "../../../redux/config/urlConfig";

function SingleAggregatorWithTracks({
  item,
  data,
  setSelectedPickup,
  setShowMaterialIntakeModal,
  organization,
}) {
  const handleLotData = (index) => {
    if (index >= 0 && index < series[0].data.length) {
      const valueToMove = series[0].data[index];
      setSelectedPickup({ ...data[index], organization });

      // setSelectedPickup({
      //   traderName: item.name,
      //   quantity: valueToMove.x,
      //   contaminationLevel: 0,
      //   formOfInput: "",
      //   wasteType: item.scrap?.name,
      //   virtualBinsCode: item.virtualBinsCode,
      //   id: valueToMove.id,
      //   pickupDate: valueToMove.pickupDate,
      //   receivedDate: valueToMove.receivedDate,
      //   index: index,
      // });

      setShowMaterialIntakeModal(true);
    }
  };
  const series = [
    {
      data,
    },
  ];

  return (
    <div className="white  box" style={{ width: "32%" }}>
      <div
        className="flex    align-center space-between "
        data-tooltip-id={organization?.name}
      >
        <span>
          <p style={{ fontWeight: "900" }}>#{organization?.name}</p>
          <span className="flex align-center  ">
            <img
              src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
              className=""
              alt="dashboard-scrap"
              style={{ width: "25px", height: "25px" }}
            />
            <p className="small-text ">
              {item.scrap?.name + "-" + item.scrap?.variant}
            </p>
          </span>
        </span>
        {/* <span
          style={{
            fontSize: "11px",
            fontWeight: "700",
            backgroundColor: item.colors[0],
            padding: "0 5px",
            borderRadius: "2px",
            color: "#fff",
          }}
          className="flex align-center justify-center gap-1 "
        >
          <span className=" small-text material-symbols-outlined">code</span>
          <b> {item.virtualBinsCode}</b>
        </span> */}

        <Tooltip id={organization.name} style={{ backgroundColor: "#111" }}>
          <div>
            <h5>{organization.name}</h5>
            <span className="flex gap-1 align-center ">
              <p>Total Quantity:</p>{" "}
              <p>{data.reduce((acc, item) => acc + item.y, 0)}</p>
            </span>
          </div>
        </Tooltip>
      </div>

      <ApexCharts
        key={data.length}
        options={{
          chart: {
            toolbar: { show: false },
            type: "treemap",
            width: 200,
            height: 200,
            events: {
              click: (
                event,
                chartContext,
                { seriesIndex, dataPointIndex, config }
              ) => {
                handleLotData(dataPointIndex);
              },
            },
          },
          title: {
            text: "",
            align: "left",
            enabled: false,
          },

          colors: item.colors,
        }}
        series={series}
        type="treemap"
      />
    </div>
  );
}

export default SingleAggregatorWithTracks;
