// import { direction } from "html2canvas/dist/types/css/property-descriptors/direction";
import React from "react";
import { useMediaQuery } from "react-responsive";

function DashboardCard({
  title,
  amount,
  color,
  icon,
  bottomContent,
  percentageInc,
  percentageDec,
  cardValue,
  direction,
  style,
}) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const renderDirection = (percentageInc, percentageDec) => {
    if (direction) {
      return direction;
    } else if (percentageInc) {
      return "north_east";
    } else if (percentageDec) {
      return "south_west";
    } else {
      return "east";
    }
  };

  return (
    <div
      style={style ? style : {}}
      className={`box p-1  ${color ? color : "white"}`}
    >
      <div className="flex align-center space-between full-width">
        <div
          className={isMobile ? "small-text" : ""}
          style={{ fontWeight: "bold" }}
        >
          {!isMobile ? <h3 style={{}}>{amount}</h3> : <h6 style={{}}>30</h6>}

          {title}
        </div>
        <div
          className={`flex align-center justify-center circle-icon ` + color}
        >
          <span
            class={`material-symbols-outlined ${
              color?.split(" ")[0]
                ? color?.split(" ")[0] + "-text"
                : "black-text"
            }`}
          >
            {icon ? icon : "local_shipping"}
          </span>
        </div>
      </div>
      {
        <div
          className={
            isMobile
              ? "flex mt-1 align-center space-between small-text"
              : "flex mt-1 align-center space-between"
          }
        >
          {(cardValue === 0 || cardValue) && (
            <div className="">
              <span
                style={{
                  color: percentageDec ? "red" : "#34C759",
                  marginRight: "6px",
                }}
                class="material-symbols-outlined small-text"
              >
                {renderDirection(percentageInc, percentageDec)}
              </span>
              <span style={{ color: "#555", fontSize: "2rem" }}>
                {cardValue ? cardValue : 0}
              </span>
            </div>
          )}
          {percentageInc && (
            <span style={{ color: percentageInc > 0 ? "#34C759" : "tomato" }}>
              {" "}
              {percentageInc ? percentageInc : 0}% This Month
            </span>
          )}

          {percentageDec && (
            <span style={{ color: "#7C8DB5" }}>
              {" "}
              -{percentageDec ? percentageDec : 0}% This Month
            </span>
          )}
        </div>
      }
      {bottomContent && (
        <div
          className={
            isMobile
              ? "flex mt-1 align-center space-between small-text bottom-arrow"
              : "flex mt-1 align-center gap-1"
          }
        >
          <span style={{ color: "#7C8DB5" }}>{bottomContent}</span>
        </div>
      )}
    </div>
  );
}

export default DashboardCard;
