import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function ImpactCard({img , title,desc,year}) {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' });

  return (
    <div
      className="full-width white mt-1"
      style={{ width:  isMobile ? '100%':'50%', borderRadius: '20px',border:'1px solid #D9D9D9' }}
    >
      <div>
        <img
          src={img}
          style={{ width: '100%', height: '100px' }}
        />
        <div className="ml-1">
          <h4 style={{color:'#151D48'}} className="flex">
            <span class="material-symbols-outlined">currency_rupee</span>{title}
          </h4>
          <p style={{color:'#425166'}}>{desc}</p>
          <div className="flex align-center mb-1 gap-1">
            <span className="material-symbols-outlined green-text" style={{fontSize:'16px'}}>
              north_east
            </span>
            <p className="small-text" style={{ color: '#4079ED' }}>
              {year}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
