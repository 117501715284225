import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { toast } from "react-toastify";

const AddLocation = ({
  addLocation,
  loading,
  values,
  setValues,
  google,
  mapWidth,
}) => {
  const [address, setAddress] = React.useState("");
  const [mapCenter, setMapCenter] = React.useState({
    longitude: 0,
    latitude: 0,
  });

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;

      setMapCenter({
        longitude: lng,
        latitude: lat,
      });

      try {
        const { data } = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo`
        );

        const address = data.results[0].formatted_address;
        setAddress(data.results[0].formatted_address);
        console.log(address, "values.address");

        setValues({
          ...values,
          location: {
            nickname: values.location.nickname,
            longitude: lng,
            latitude: lat,
            address: address,
          },
        });
      } catch (error) {
        console.error("Error getting address", error);
      }
    });
  };

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setMapCenter({
          longitude: latLng.lng,
          latitude: latLng.lat,
        });
        // console.log(latLng);

        setValues({
          ...values,
          location: {
            nickname: values.location.nickname,
            longitude: latLng.lng,
            latitude: latLng.lat,
            address,
          },
        });
      })
      .catch((error) => console.error("Error", error));
  };

  async function moveMarker(e, m) {
    try {
      setMapCenter({
        latitude: m.position.lat(),
        longitude: m.position.lng(),
      });

      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${m.position.lat()},${m.position.lng()}&key=AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo`
      );
      setAddress(data.results[0].formatted_address);
      setValues({
        ...values,
        location: {
          longitude: m.position.lng(),
          latitude: m.position.lat(),
          address: data.results[0].formatted_address,
          nickname: values.location.nickname,
        },
      });
    } catch (err) {
      toast.error("Error getting address", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  }

  return (
    <div className="">
      <h6>Add New Location</h6>
      <div className="add-location-map mt-1">
        <Map
          google={google}
          zoom={mapCenter.latitude ? 15 : 1}
          center={{
            lat: mapCenter.latitude,
            lng: mapCenter.longitude,
          }}
          containerStyle={{
            boxShadow:
              "4px 4px 8px rgba(0,0,0,0.2),-4px -4px 8px rgba(255,255,255,1)",
            width: mapWidth,
          }}
        >
          {mapCenter.latitude && mapCenter.longitude && (
            <Marker
              title={"Your location"}
              position={{
                lat: mapCenter.latitude,
                lng: mapCenter.longitude,
              }}
              draggable={true}
              onDragend={moveMarker}
            />
          )}
        </Map>
      </div>
      <div className="add-location-container">
        <div className="map-content">
          <div className="mt-1 mb-2">
            <label
              style={{ fontWeight: "bold" }}
              className="black-text"
              htmlFor=""
            >
              Location Name
            </label>
            <input
              type="text"
              id="id"
              onChange={(e) => {
                setValues({
                  ...values,
                  location: {
                    ...values.location,
                    nickname: e.target.value,
                  },
                });
              }}
              className="browser-default input-style"
              placeholder="Enter a location name for the address"
              value={values.location.nickname}
            />
          </div>

          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <label style={{ fontWeight: "bold" }} className="black-text">
                  Search Location
                </label>
                <input
                  {...getInputProps({
                    placeholder: "  Search Places ...",
                    className: "browser-default input-style select-item",
                  })}
                  style={{ padding: "5px" }}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? {
                          backgroundColor: "#fafafa",
                          cursor: "pointer",
                          padding: 5,
                        }
                      : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer",
                          padding: 5,
                        };
                    return (
                      <div
                        key={i}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <div
            onClick={() => {
              getLocation();
            }}
            className="flex align-center mt-2 pointer"
          >
            <span class="material-symbols-outlined cercle-purple-text">
              my_location
            </span>
            <div style={{ marginLeft: "0.5rem" }}>
              <b className="cercle-purple-text">Current Location</b>
              <p className="small-text grey-text">
                Enable your current location for better services
              </p>
            </div>
          </div>

          <button
            className="btn-small mt-2"
            onClick={addLocation}
            disabled={
              loading || !values.location.nickname || !values.location.address
            }
          >
            {loading ? "Adding Location..." : "Add Location"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyDsVU8VuCB3p7REGp24SbXZnkNXHaIKCfo",
})(AddLocation);
