import { toast } from "react-toastify";
import { IWM } from "../config/urlConfig";
import M from "materialize-css";
import { notify } from "../../helpers/helper";
import { hideLoading, showLoading } from "./loading";

export const getAvailablePickups = () => (dispatch) => {
  dispatch({ type: "LOADING_PICKUPS" });
  IWM.get("/pickups/read/available-pickups")
    .then((res) => {
      dispatch({
        type: "GOT_AVAILABLE_PICKUPS",
        payload: res.data.filter((pickup) => pickup.accepted === false),
      });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_AVAILABLE_PICKUPS" });
    });
};

export const raisePickup = (data, contractId, reset) => (dispatch) => {
  dispatch({ type: "LOADING_PICKUPS" });
  IWM.post("/pickups/create/contract/" + contractId, data)
    .then((res) => {
      reset();
      dispatch({ type: "STOP_LOADING_PICKUPS" });
      notify("Pickup has been raised successfully!");
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_AVAILABLE_PICKUPS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const createRecycledOutput = (data, cb) => (dispatch) => {
  dispatch({ type: "CREATING_RECYCLED_BATCH" });
  IWM.post("/recycledbatch/create", data)
    .then((res) => {
      if (cb) cb();
      dispatch({ type: "CREATED_RECYCLED_BATCH" });
      notify("Success!", "Recycled output  has been created successfully.");
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_CREATE_RECYCLED_BATCH" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const syncToBlockchain = (id, cb) => (dispatch) => {
  dispatch({ type: "CREATING_RECYCLED_BATCH" });
  IWM.put("/recycledbatch/update/sync-to-blockchain/" + id, {})
    .then((res) => {
      if (cb) cb();
      dispatch({ type: "CREATED_RECYCLED_BATCH" });
      notify("Batch has been synced to blockchain successfully!");
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_CREATE_RECYCLED_BATCH" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const raiseThirdPartyPickup =
  (data, contractId, reset) => (dispatch) => {
    dispatch({ type: "LOADING_PICKUPS" });
    IWM.post("/pickups/create/third-party-contract/" + contractId, data)
      .then((res) => {
        reset();
        dispatch({ type: "STOP_LOADING_PICKUPS" });
        notify("Pickup has been raised successfully!", "cercle-purple");
      })
      .catch((error) => {
        dispatch({ type: "CANNOT_GET_AVAILABLE_PICKUPS" });
        toast.error(error.response ? error.response?.data : error.message);
      });
  };

export const raiseDirectPickup = (data, orgId, reset) => (dispatch) => {
  dispatch({ type: "LOADING_PICKUPS" });
  IWM.post("/pickups/create/direct/" + orgId, data)
    .then((res) => {
      reset();
      dispatch({ type: "STOP_LOADING_PICKUPS" });
      notify(
        "Pickup requested successfully!",
        "The pickup request has been sent to the organization. You will be notified when the organization accepts the pickup request."
      );
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_AVAILABLE_PICKUPS" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const acceptPickup = (pickupId, data, cb) => (dispatch) => {
  dispatch({ type: "ACCEPTING_PICKUP" });
  IWM.post("/pickups/create/accept/" + pickupId, data)
    .then((res) => {
      dispatch({ type: "ACCEPTED_PICKUP" });
      cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_ACCEPT_PICKUP" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};
export const inventorizePickup = (pickupId, data, cb) => (dispatch) => {
  dispatch({ type: "INVENTORIZING_PICKUP" });
  IWM.put("/pickups/update/inventorize/" + pickupId, data)
    .then((res) => {
      dispatch({ type: "INVENTORIZED_PICKUP" });

      if (cb) cb();
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_INVENTORIZE_PICKUP" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getInventory = () => (dispatch) => {
  dispatch({ type: "GETTING_INVENTORY" });
  IWM.get("/pickups/read/inventory/")
    .then((res) => {
      dispatch({ type: "GOT_INVENTORY", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_INVENTORY" });
    });
};

export const getCompletedPickups = () => (dispatch) => {
  dispatch({ type: "GETTING_COMPLETED_PICKUPS" });
  IWM.get("/pickups/read/completed-pickups/")
    .then((res) => {
      dispatch({ type: "GOT_COMPLETED_PICKUPS", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_COMPLETED_PICKUPS" });
    });
};

export const getRecycledBatches = () => (dispatch) => {
  dispatch({ type: "GETTING_RECYCLED_BATCHES" });
  IWM.get("/recycledbatch/read")
    .then((res) => {
      dispatch({ type: "GOT_RECYCLED_BATCHES", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_RECYCLED_BATCHES" });
    });
};

export const getQuantityOverTime = (contractId, data, org) => (dispatch) => {
  dispatch({ type: "GETTING_QUANTITY_OVER_TIME" });

  IWM.get(
    `/pickup/read/quantity-over-time/${contractId}?start=${data.start}&end=${data.end}` +
      org
      ? `&organization=${org}`
      : ""
  )
    .then((res) => {
      dispatch({ type: "GOT_QUANTITY_OVER_TIME", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_QUANTITY_OVER_TIME" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const getQuantityOverTimeAllPickups = (data) => (dispatch) => {
  dispatch({ type: "GETTING_QUANTITY_OVER_TIME" });
  IWM.get(`/pickup/read/quantity-over-time?start=${data.start}&end=${data.end}`)
    .then((res) => {
      dispatch({ type: "GOT_QUANTITY_OVER_TIME", payload: res.data });
    })
    .catch((error) => {
      dispatch({ type: "CANNOT_GET_QUANTITY_OVER_TIME" });
      toast.error(error.response ? error.response?.data : error.message);
    });
};

export const ignorePickup = (id) => (dispatch) => {
  dispatch({ type: "LOADING_PICKUPS" });
  IWM.post("/pickups/create/ignore/" + id)
    .then(() => {
      dispatch({ type: "STOP_LOADING_PICKUPS" });
      dispatch(getAvailablePickups());
      notify("Pickup has been ignored successfully!");
    })
    .catch((ex) => {
      dispatch({ type: "STOP_LOADING_PICKUPS" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const getPickupsHistory = () => (dispatch) => {
  dispatch({ type: "LOADING_PICKUPS" });
  IWM.get("/pickups/read/history?limit=50")
    .then((res) => {
      dispatch({ type: "GOT_PICKUP_HISTORY", payload: res.data });
    })
    .catch((ex) => {
      dispatch({ type: "STOP_LOADING_PICKUPS" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const getPickup = (id) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: "LOADING_PICKUPS" });
  IWM.get("/pickups/read/" + id)
    .then((res) => {
      dispatch(hideLoading());
      dispatch({ type: "GOT_PICKUP", payload: res.data });
    })
    .catch((ex) => {
      dispatch(hideLoading());
      dispatch({ type: "STOP_LOADING_PICKUPS" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const getHistoryWithANetwork = (id) => (dispatch) => {
  dispatch({ type: "GETTING_PICKUP_HISTORY_WITH_A_NETWORK" });
  IWM.get("/pickups/read/organization/history/" + id)
    .then((res) => {
      dispatch({
        type: "GOT_PICKUP_HISTORY_WITH_A_NETWORK",
        payload: res.data,
      });
    })
    .catch((ex) => {
      console.log(ex.response?.data);
      dispatch({ type: "CANNOT_GET_PICKUP_HISTORY_WITH_A_NETWORK" });
    });
};

export const createOrder = (data, cb) => (dispatch) => {
  dispatch({ type: "CREATING_ORDER" });
  IWM.post("/order/create", data)
    .then((res) => {
      dispatch({ type: "CREATED_ORDER" });
      cb();
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_CREATE_ORDER" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const getOrders = () => (dispatch) => {
  dispatch({ type: "GETTING_ORDERS" });
  IWM.get("/order/read")
    .then((res) => {
      console.log(res.data);
      dispatch({ type: "GOT_ORDERS", payload: res.data });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_ORDERS" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};

export const getSingleOrder = (id) => (dispatch) => {
  dispatch({ type: "GETTING_SINGLE_ORDER" });
  IWM.get("/order/read/" + id)
    .then((res) => {
      dispatch({ type: "GOT_SINGLE_ORDER", payload: res.data });
    })
    .catch((ex) => {
      dispatch({ type: "CANNOT_GET_SINGLE_ORDER" });
      toast.error(ex.response ? ex.response.data : ex.message);
    });
};
