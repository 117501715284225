import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const DATA = [
  {
    id: 1,
    number: "18,000",
    name: "Total sales this month",
    trend: "+8%",
  },
  {
    id: 2,
    number: "6",
    name: "Total Quantity Sold",
    trend: "+5%",
  },
  {
    id: 3,
    number: "20",
    name: "Total No of Bills",
    trend: "-1.2%",
  },
  {
    id: 4,
    number: "8",
    name: "Number Of Buyers",
    trend: "0%",
  },
];

export const TodaysSales = ({ page }) => {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [data, setData] = React.useState([]);
  const { overview } = useSelector((state) => state.analytics);

  useEffect(() => {
    if (!overview.monthlySalesSummary) return;
    const d = Object.entries(overview.monthlySalesSummary).map(
      ([key, value]) => ({
        name: key,
        number: value,
      })
    );
    setData(d);
  }, [overview.monthlySalesSummary]);

  return (
    <div
      style={{
        width: isMobile ? "100%" : page === "PlansBillings" ? "50%" : "75%",
      }}
      className={isMobile ? "box white full-width" : "box white"}
    >
      <div className="flex align-center space-between mb-3">
        <h4>Sales Summary</h4>
        {/* 
        <div
          style={{ border: "1px solid #C3D3E2" }}
          className="flex align-center small-text box2 pointer"
        >
          <span class="material-symbols-outlined small-text">ios_share</span>
          <p style={{}}>Export</p>
        </div> */}
      </div>

      <div
        style={{ width: isMobile ? "100%" : null }}
        className={isMobile ? "flex column gap-2" : "flex row gap-2"}
      >
        {data.map((item, index) => (
          <div
            style={{
              background:
                index === 0
                  ? "#FFE2E5"
                  : index === 1
                  ? "#FFF4DE"
                  : index === 2
                  ? "#DCFCE7"
                  : index === 3
                  ? "#F3E8FF"
                  : null,
              paddingBlock: "1rem",
              width: isLaptop && page === "PlansBillings" ? "48.68%" : "100%",
            }}
            className={isMobile ? "box" : isLaptop ? "box" : "box  s3"}
          >
            <div
              style={{
                width: 40,
                height: 40,
                background:
                  index === 0
                    ? "#FA5A7D"
                    : index === 1
                    ? "#FF947A"
                    : index === 2
                    ? "#3CD856"
                    : index === 3
                    ? "#BF83FF"
                    : null,
              }}
              className="circle flex align-center justify-center"
            >
              {index === 0 ? (
                <span class="material-icons white-text">assessment</span>
              ) : index === 1 ? (
                <span class="material-icons white-text">description</span>
              ) : index === 2 ? (
                <span class="material-icons white-text">sell</span>
              ) : index === 3 ? (
                <span class="material-icons white-text">person_add</span>
              ) : null}
            </div>
            {item.name === "Total Sales" ? (
              <h2>{item.number}</h2>
            ) : (
              <h2>{item.number}</h2>
            )}
            <p style={{ fontSize: isMobile ? "1.2rem" : "1.1rem" }}>
              {item.name.split("_").join(" ")}
            </p>
            {/* <div className={"flex align-center gap-2"}>
              <span
                style={{ color: "#34C759", marginRight: "2px" }}
                class="material-icons small-text"
              >
                north_east
              </span>
              <p style={{ color: "#4079ED" }}>{item.trend} from last month</p>
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};
