import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

const BestWasteGeneratorCard = ({
  hotelName,
  wasteWeight,
  contaminationScore,
  segregationScore,
  rating,
  cardImage,
  movementOnClick,
  id
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 674px)" });

  return (
    <div
      style={{
        borderRadius: "10px",
        position: "relative",
        backgroundColor: "#262027",
      }}
      className=""
    >
      <div>
        <img
          src={cardImage}
          style={{
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            width: isMobile ? '100%' : "322px",
            height: "167.4px",
          }}
        />
        <div
          style={{
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            position: "absolute",
            top: 0,
            right: 0,
            paddingBlock: "0.5rem",
            paddingInline: "1rem",
          }}
          className="flex cercle-purple align-center gap-1 white-text"
        >
          <p>Best waste generator</p>
          <span
            style={{
              background: "linear-gradient(180deg, #FFE500 0%, #FFF 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
            class="material-icons"
          >
            star
          </span>
        </div>
        <div
          className="flex align-center space-between full-width"
          style={{ position: "absolute", top: isMobile ? "43.5%" : "42.5%" }}
        >
          <img
            src={id === 1 ? "../images/bwg1st.png" : id === 2 ? "../images/bwg2nd.png" : id === 3 ? "../images/bwg3rd.png" : null}
            className="ml-1"
            style={{ width: 40, height: 40 }}
          />
          <div
            className="circle white flex align-center justify-center mr-1 pointer"
            style={{ width: 30, height: 30 }}
            onClick={movementOnClick}
          >
            {id !== 3 ? <span class="material-symbols-outlined">chevron_right</span> :
            <span class="material-symbols-outlined">chevron_left</span>}
          </div>
        </div>
      </div>
      <div style={{ lineHeight: "1.75rem" }} className="white-text p-1">
        <h5>{hotelName}</h5>
        <p>
          Total Waste Collected:{" "}
          <span className="yellow-text text-lighten-5">{wasteWeight}</span>{" "}
        </p>
        <p>
          Contamination Score:{" "}
          <span className="yellow-text text-lighten-5">
            {contaminationScore}
          </span>
        </p>
        <p>
          Segregation Score:{" "}
          <span className="yellow-text text-lighten-5">{segregationScore}</span>
        </p>
        <p>
          Average Rating:{" "}
          <span className="yellow-text text-lighten-5">{rating}</span>
        </p>
      </div>
    </div>
  );
};

export default BestWasteGeneratorCard;
