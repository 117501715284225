import React from 'react'

export default function EnvironmentalCard({ name,image, icon, title, desc }) {
  return (
    <div style={{position:"relative"}}>
    {/* <span className="material-icons green-text" style={{position:'absolute',bottom:'205px',zIndex:1,left:'355px'}}>verified</span> */}
       <div
      className="full-width white mt-1 flex column pointer"
      style={{ borderRadius: '10px', position: 'relative' }}
    >
      
      <img src={image} style={{ width: '100%', height: '75px' }} />
      <div
        style={{
          width: '42px',
          height: '42px',
          background: '#fff',
          borderRadius: '12px',
          padding: '9px',
          position: 'absolute',
          top: 55,
          left: 20,
        }}
      >
        <img src={icon} style={{ width: '24px', height: '24px' }} />
      </div>
      <div
        className="p-1"
        style={{ border: '1px solid #D9D9D9', borderRadius: '0 0 10px 10px' }}
      >
        <h4>{title}</h4>
        <div className="flex space-between align-center">
          <p>{desc}</p>
          <button className="btn-small">Add</button>
        </div>
      </div>
    </div>
    </div>
 
  )
}
