import React, { useState } from "react";
import { Button, Modal } from "react-materialize";
import { notify, notifyError } from "../../../helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getClosestCercleXNetwork,
  getNetworkInvitations,
  inviteNetwork,
} from "../../../redux/action/organization";
import { ClipLoader } from "react-spinners";
// import Modal from "react-responsive-modal";
import { useMediaQuery } from "react-responsive";

const AddExternalNetwork = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 674px)" });
  const [location, setLocation] = useState("");
  const [scrap, setScrap] = useState("");
  const [selected, setSelected] = useState([]);
  const { organization, networkLoading, closestNetwork, invitations } =
    useSelector((state) => state.organization);
  const dispatch = useDispatch();

  const [openAddToNetwork, setOpenAddToNetwork] = useState(false);
  const onOpenAddToNetworkModal = () => setOpenAddToNetwork(true);
  const onCloseAddToNetworkModal = () => setOpenAddToNetwork(false);

  function getClosest(e) {
    e.preventDefault();
    const loc = organization.locations.find((loc) => loc._id === location);

    if (!loc)
      return toast.error("Please select a location", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });

    const scrapDetailed = organization.scraps.find((sp) => sp._id === scrap);

    if (!scrapDetailed)
      return toast.error("Please select a scrap", {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      });

    const { latitude, longitude } = loc;

    dispatch(
      getClosestCercleXNetwork({
        latitude,
        longitude,
        dealsWith: scrapDetailed.type,
      })
    );
  }

  return (
    <div className="">
      <div className="mb-2 flex align-center column"></div>

      <div
        style={{ paddingInline: "3rem" }}
        className="flex full-width gap-2 justify-center "
      >
        <div className="full-width flex column align-center">
          <div
            className="flex align-center"
            style={{
              width: "100%",
              padding: "0 10px",
              borderRadius: "6px",
              height: "40px",
              background: "#FAFAFA",
            }}
          >
            <span className="material-symbols-outlined grey-text mr-1">
              location_on
            </span>
            <select
              className="browser-default "
              onChange={(e) => setLocation(e.target.value)}
              value={location}
              style={{ background: "#FAFAFA" }}
            >
              <option value={""}>Select your location</option>
              {organization.locations?.map((location) => {
                return (
                  <option value={location._id}>
                    {location.nickname}-{location.address}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="full-width flex column align-center ">
          <div
            className="flex align-center"
            style={{
              width: "100%",
              padding: "0 10px",
              borderRadius: "6px",
              height: "40px",
              background: "#FAFAFA",
            }}
          >
            <span className="material-symbols-outlined grey-text mr-1">
              category
            </span>
            <select
              className="browser-default "
              onChange={(e) => setScrap(e.target.value)}
              value={scrap}
              style={{ background: "#FAFAFA" }}
            >
              <option value={""}>Select the scrap</option>
              {organization.scraps?.map((scrap) => {
                return (
                  <option value={scrap._id}>
                    {scrap.name}-{scrap.variant}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="flex align-end">
          <button className="btn-small" onClick={getClosest}>
            {/* <span className="material-symbols-outlined grey-text ">search</span> */}
            Search
          </button>
        </div>
      </div>

      <div
        className="full-width mt-2"
        style={{
          background: "#FFFFFF",
          boxShadow: "0px 4px 2px 0px rgba(0, 0, 0, 0.06)",
          height: "3px",
        }}
      />

      <div className="mt-1 full-width  flex-wrap flex p-2">
        {networkLoading ? (
          <div className="flex justify-center full-width">
            <ClipLoader size={24} color="#6f2da8" />
          </div>
        ) : closestNetwork.length === 0 ? (
          <p className="grey-text text-center full-width">No network found</p>
        ) : (
          closestNetwork.map((network, i) => {
            return (
              <>
              <Modal
                className="add-network-modal"
                key={i}
                actions={[]}
                trigger={
                  <div
                    className={
                      selected.includes(network._id)
                        ? "scrap-selected2"
                        : "scrap-select2"
                    }
                    // style={{ height: "220px" }}
                    role="button"
                    onClick={() => {
                      if (selected.includes(network._id))
                        setSelected(selected.filter((s) => s !== network._id));
                      else setSelected([...selected, network._id]);
    
                      // onOpenAddToNetworkModal()
                    }}
                  >
                    <div
                      style={{ padding: "10px", fontSize: "10px" }}
                      className="flex align-center justify-center white-text gap-1 cercle-purple"
                    >
                      Add Network
                      <span style={{ fontSize: "12px" }} class="material-icons">
                        add
                      </span>
                    </div>
                    <img
                      src={`images/factory.png`}
                      alt="scrap"
                      style={{ objectFit: "contain" }}
                    />
                    <div
                      className="flex column align-center full-height"
                      style={{ padding: "0 10px", background: "#F9FAFB" }}
                    >
                      <p className="cercle-purple-text full-width truncate text-center">
                        {network.organization}
                      </p>
                      <p className="small-text full-width truncate text-center">
                        {network.name}
                      </p>
                      <p className="small-text full-width truncate text-center">
                        {network.location?.city}
                      </p>
                    </div>
                  </div>
                }
              >
                <div className="cercle-purple p-1 white-text flex align-center space-between">
                  <h4>Add A Personal Network</h4>
                  <Button modal="close" className="" style={{ width: 40, height: 40, background: 'rgba(255, 255, 255, 0.50)', borderRadius: '6px', cursor: 'pointer' }}>
                    <span class="material-symbols-outlined flex align-center justify-center">close</span>
                  </Button>
                </div>
                <div className="flex align-center justify-center gap-1" style={{ background: '#FFFDBE', paddingBlock: '0.2rem' }}>
                <span class="material-icons cercle-purple-text">info</span>
                <label className="cercle-purple-text">An <span style={{ fontWeight: 'bold' }}>Invitation</span> will be sent through <span style={{ fontWeight: 'bold' }}>Email</span> and <span style={{ fontWeight: 'bold' }}>SMS</span> to the provided email and phone number.</label>
                </div>

                <div className="mt-1">
                  <div className="mb-1">
                    <label htmlFor="">Organization</label>
                    <p>{network?.organization}</p>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="">POC</label>
                    <p className="capitalize">{network?.name}</p>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="">Location</label>
                    <p>{network?.location?.address}</p>
                  </div>
                </div>

                <div className="flex gap-2 justify-end">
                  <Button
                    modal="close"
                    className="btn-outline-small  mt-1 first-time-but "
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-small  mt-1  "
                    modal="close"
                    onClick={() => {
                      let invitation = invitations.find(
                        (i) => i.organizationName === network.organization
                      );

                      if (invitation)
                        return notifyError("Network Added already!");

                      dispatch(
                        inviteNetwork(
                          {
                            organizationName: network.organization,
                            phoneNumber: network.phoneNumber,
                            role: "trader/aggregator",
                            scraps: [scrap],
                            location: location,
                          },
                          () => {
                            let element =
                                document.getElementById("invitation-modal"),
                              instance = window.M.Modal.getInstance(element);
                            instance.close();
                            dispatch(getNetworkInvitations());
                            notify("Invitation sent successfully");
                          }
                        )
                      );
                    }}
                  >
                    Add Network
                  </Button>
                </div>
              </Modal>
              {/* <Modal
                center
                styles={{
                  modal: {
                    borderRadius: '10px',
                    padding: 0,
                    width: isMobile ? '92%' : '100%',
                    position: isMobile ? 'absolute' : null,
                    left: isMobile ? 0 : null
                  }
                }} 
                open={openAddToNetwork} onClose={onCloseAddToNetworkModal}
                closeIcon={<></>}
              >
              <>
                <div className="cercle-purple p-1 white-text flex align-center space-between">
                  <h4>Add To Your Network</h4>
                  <div onClick={onCloseAddToNetworkModal} className="flex align-center justify-center" style={{ width: 40, height: 40, background: 'rgba(255, 255, 255, 0.50)', borderRadius: '6px', cursor: 'pointer' }}>
                    <span class="material-symbols-outlined">close</span>
                  </div>
                </div>

                <div className="mt-1">
                  <div className="mb-1">
                    <label htmlFor="">Organization</label>
                    <p>{network?.organization}</p>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="">POC</label>
                    <p className="capitalize">{network?.name}</p>
                  </div>

                  <div className="mb-1">
                    <label htmlFor="">Location</label>
                    <p>{network?.location?.address}</p>
                  </div>
                </div>

                <div
                  className=" flex  gap-1 align-center mb-1 rounded"
                  style={{ padding: "5px" }}
                >
                  <span
                    className="material-symbols-outlined grey-text cercle-purple-text"
                  >
                    info
                  </span>
                  <p className="cercle-purple-text small-text">
                    An Invitation will be sent through Email and SMS to the
                    selected organization
                  </p>
                </div>

                <div className="flex gap-2 justify-end red">
                  <Button
                    modal="close"
                    className="btn-outline  mt-1 first-time-but "
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn  mt-1  "
                    modal="close"
                    onClick={() => {
                      let invitation = invitations.find(
                        (i) => i.organizationName === network.organization
                      );

                      if (invitation)
                        return notifyError("Network Added already!");

                      dispatch(
                        inviteNetwork(
                          {
                            organizationName: network.organization,
                            phoneNumber: network.phoneNumber,
                            role: "trader/aggregator",
                            scraps: [scrap],
                            location: location,
                          },
                          () => {
                            let element =
                                document.getElementById("invitation-modal"),
                              instance = window.M.Modal.getInstance(element);
                            instance.close();
                            dispatch(getNetworkInvitations());
                            notify("Invitation sent successfully");
                          }
                        )
                      );
                    }}
                  >
                    Add Network
                  </Button>
                </div>
              </>
              </Modal> */}
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default AddExternalNetwork;
