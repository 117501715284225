import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-materialize";
import { useNavigate } from "react-router-dom";
import { onNotificationClick } from "../helpers/helper";
import { seenNotification } from "../redux/action/organization";
import {
  getNotifications,
  handleNotificationPress,
} from "../redux/action/notifications";

function Notification() {
  const { notifications } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  console.log(notifications, 'notifications');

  const navigate = useNavigate();

  if (notifications.length === 0 || !notifications) {
    return (
      <div className="flex align-center justify-center  mt-5  column  ">
        <span
          className="material-symbols-outlined grey-text"
          style={{ fontSize: "5rem" }}
        >
          notifications
        </span>
        <p className="grey-text  mt-2 ph-2  ">No Notices Right Now.</p>
      </div>
    );
  }
  return (
    <div className="flex column" 
    // style={{ maxHeight: "80vh" }}
    >
      <h4 style={{  }}>Notification</h4>
        <div className="">
          <Tabs>
            <Tab title={<span>All <span className="white-text count-notify-view small-text">{notifications.length}</span></span>} idx="all">
              <div className="" style={{ overflowY: 'auto', height: '70vh' }}>
              {notifications.map((notf, i) => {
                return (
                  <div
                    className={` flex  border notification-item ${
                      notf.read ? "" : " notifcation-unseen-background"
                    }`}
                    key={i}
                    onClick={() => {
                      handleNotificationPress(notf, navigate, dispatch);
                    }}
                  >
                    <div style={{ flex: 2 }}>
                      <div
                        className="purple circle flex align-center justify-center purple lighten-5 "
                        style={{ width: "43px", height: "43px" }}
                      >
                        <span className="material-symbols-outlined cercle-purple-text icon-bg">
                          local_shipping
                        </span>
                      </div>
                    </div>
                    &nbsp;
                    <div style={{ flex: 8 }}>
                      <p className="semibold-notification">{notf.title}</p>
                      <p className="notification-description">{notf.body}</p>
                    </div>
                    <div
                      style={{ flex: 1, alignItems: "flex-start" }}
                      className="flex justify-end"
                    >
                      {!notf.read && <div className="unread-point"></div>}
                    </div>
                  </div>
                );
              })}
              </div>
            </Tab>
            <Tab title="Accepted" idx="accepted">
              <div className="" style={{ overflowY: 'auto', height: '70vh' }}>
              {notifications.map((notf, i) => {
                return notf.type === 'driver-navigation-started' ? (
                  <div
                    className={` flex  border notification-item ${
                      notf.read ? "" : " notifcation-unseen-background"
                    }`}
                    key={i}
                    onClick={() => {
                      handleNotificationPress(notf, navigate, dispatch);
                    }}
                  >
                    <div style={{ flex: 2 }}>
                      <div
                        className="purple circle flex align-center justify-center purple lighten-5 "
                        style={{ width: "43px", height: "43px" }}
                      >
                        <span className="material-symbols-outlined cercle-purple-text icon-bg">
                          local_shipping
                        </span>
                      </div>
                    </div>
                    &nbsp;
                    <div style={{ flex: 8 }}>
                      <p className="semibold-notification">{notf.title}</p>
                      <p className="notification-description">{notf.body}</p>
                    </div>
                    <div
                      style={{ flex: 1, alignItems: "flex-start" }}
                      className="flex justify-end"
                    >
                      {!notf.read && <div className="unread-point"></div>}
                    </div>
                  </div>
                ) : ( null );
              })}
                  </div>
            </Tab>
            <Tab title="Completed" idx="completed">
              {notifications.map((notf, i) => {
                return notf.type === 'pickup-completed' ? (
                  <div
                    className={` flex  border notification-item ${
                      notf.read ? "" : " notifcation-unseen-background"
                    }`}
                    key={i}
                    onClick={() => {
                      handleNotificationPress(notf, navigate, dispatch);
                    }}
                  >
                    <div style={{ flex: 2 }}>
                      <div
                        className="purple circle flex align-center justify-center purple lighten-5 "
                        style={{ width: "43px", height: "43px" }}
                      >
                        <span className="material-symbols-outlined cercle-purple-text icon-bg">
                          local_shipping
                        </span>
                      </div>
                    </div>
                    &nbsp;
                    <div style={{ flex: 8 }}>
                      <p className="semibold-notification">{notf.title}</p>
                      <p className="notification-description">{notf.body}</p>
                    </div>
                    <div
                      style={{ flex: 1, alignItems: "flex-start" }}
                      className="flex justify-end"
                    >
                      {!notf.read && <div className="unread-point"></div>}
                    </div>
                  </div>
                ) : ( null );
              })}
            </Tab>
          </Tabs>
        </div>
    </div>
  );
}

export default Notification;