import React from "react";
import { useMediaQuery } from "react-responsive";

function Footer() {
  const isMobile = useMediaQuery({ query: "(max-width: 724px)" });

  return (
    <footer className="footer">
      <p className={isMobile ? "small-text" : "normall-size"} >
        All Rights Reserved © {new Date().getFullYear()}
        <a href="https://cerclex.com/" className="purple-text">
          &nbsp; Infinite Cercle Private Limited
        </a>
      </p>
    </footer>
  );
}

export default Footer;
