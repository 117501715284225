import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-materialize";
import { getNetwork } from "../../redux/action/organization";
import { getAllScraps } from "../../redux/action/scraps";
import { getContracts } from "../../redux/action/contracts";
import RaisePickups from "./components/RaisePickups";
import ContractsList from "./components/ContractsList";
import { IWMURL } from "../../redux/config/urlConfig";
import ChangePrice from "./components/ChangePrice";

function Pickups({ closeModal }) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { network: myNetwork } = useSelector((state) => state.organization);
  const { contracts } = useSelector((state) => state.contracts);
  const [scrapContracts, setScrapContracts] = useState([]);
  const [network, setNetwork] = useState({});
  const [pickupLocation, setPickupLocation] = useState("");
  const dispatch = useDispatch();
  const [scrap, setScrap] = useState("");
  const [scrapContract, setScrapContract] = useState("");
  const [showScrapFound, setShowScrapFound] = useState(false);

  useEffect(() => {
    dispatch(getNetwork());
    dispatch(getAllScraps());
    dispatch(getContracts());
  }, [dispatch]);

  useEffect(() => {
    setNetwork(myNetwork[0]);
  }, [myNetwork]);

  useEffect(() => {
    const existingContract = contracts.find(
      (contract) =>
        contract.scrap._id === scrap &&
        contract.processor._id === network?.organization?._id
    );
    setScrapContract(existingContract);
  }, [contracts, network, scrap]);

  useEffect(() => {
    setScrapContracts(
      contracts.filter(
        (contract) => contract?.processor?._id === network?.organization?._id
      )
    );
  }, [contracts, network]);

  return (
    <section className="">
      <div>
        <h6 className="bold">Request Pickup for Scrap</h6>
        <p>
          Send out a pickup request to authorized waste processors in your
          network
        </p>
      </div>
      {scrapContract && (
        <div className="orange lighten-5 p-1 mt-1">
          <div className="">
            <p className="small-text orange-text flex ">
              <span className="material-icons">info</span>A valid contract with
              an agreed price for the scrap selected has been found
            </p>
          </div>
          <div
            style={{ justifyContent: "space-around" }}
            className="flex align-center  flex-wrap gap-1 "
          >
            <div
              className="flex circle"
              style={{ backgroundColor: "#F4E7FF", padding: "0.3rem" }}
            >
              <img
                src={`${IWMURL}/scraps/read/image/${scrapContract.scrap._id}`}
                className=""
                alt="dashboard-scrap"
                style={{ width: "30px", height: "30px" }}
              />
            </div>

            <div style={{}} className="">
              <p
                className="cercle-purple-text"
                style={{ textTransform: "uppercase" }}
              >
                {scrapContract?.scrap?.name}-{scrapContract?.scrap?.variant}
              </p>
              <p className="bold">{scrapContract.price + "/kg"}</p>
            </div>

            <div>
              <label className="small-text cercle-purple-text">
                Started ON
              </label>
              <p className="small-text">
                {new Date(scrapContract.start).toDateString()}
              </p>
            </div>

            <div>
              <label className="small-text cercle-purple-text">Ending On</label>
              <p className="small-text">
                {new Date(scrapContract.end).toDateString()}
              </p>
            </div>

            {!scrapContract.accepted ? (
              <p className="orange-text">Pending Approval</p>
            ) : (
              <div className="flex column justify-center">
                <label className="cercle-purple-text small-text">
                  Price Can Be Changed On
                </label>

                <p className=" capitalize">
                  {" "}
                  {new Date(scrapContract.priceEndsOn).toDateString()}
                </p>
              </div>
            )}

            <div>
              {scrapContract.priceType === "variable" &&
              scrapContract.accepted &&
              new Date(scrapContract.priceEndsOn) < new Date() ? (
                <Modal
                  trigger={
                    <Button className="btn-outline-small">Change Price</Button>
                  }
                  id="change-price-modal"
                  actions={[]}
                >
                  <ChangePrice
                    selectedContract={scrapContract}
                    setWaste={() => {}}
                  />
                </Modal>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <div className="">
        <div className="hide-on-med-and-up flex justify-end">
          <Modal
            bottomSheet={true}
            trigger={
              <Button className="btn-small cercle-purple">
                View Matched Network
              </Button>
            }
          >
            <ContractsList
              setNetwork={setNetwork}
              selectedNetwork={network}
              scrap={scrap}
              setPickupLocation={setPickupLocation}
              pickupLocation={pickupLocation}
            />
          </Modal>
        </div>

        <RaisePickups
          selectedNetwork={network}
          scrapContracts={scrapContracts}
          scrap={scrap}
          setScrap={setScrap}
          setPickupLocation={setPickupLocation}
          pickupLocation={pickupLocation}
          scrapContract={scrapContract}
          setNetworkContractsList={setNetwork}
          networkContractsList={network}
          scrapContractsList={scrap}
          setPickupLocationContractsList={setPickupLocation}
          pickupLocationContractsList={pickupLocation}
          setNetwork={setNetwork}
          network={network}
          closeModal={closeModal}
        />
      </div>
    </section>
  );
}

export default Pickups;
