import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ClipLoader } from "react-spinners";
import {
  ComposedChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Monday", totalwaste: 2000 },
  { name: "Tuesday", totalwaste: 1300 },
  { name: "Wednesday", totalwaste: 3000 },
  { name: "Thursday", totalwaste: 2780 },
  { name: "Friday", totalwaste: 1890 },
  { name: "Saturday", totalwaste: 2290 },
  { name: "Sunday", totalwaste: 3890 },
];

const WeeklyReports = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { overview, overviewLoading } = useSelector((state) => state.analytics);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (!overview.quantityForEachWeekDay) return;
    setData(overview.quantityForEachWeekDay);
  }, [overview.quantityForEachWeekDay]);

  return (
    <div
      style={{ width: isTablet ? "100%" : "49.30%" }}
      className={isTablet ? "white box mt-2" : "white box"}
    >
      <div className="flex space-between">
        <b className="chart-header bold">Weekly Report</b>

        <ClipLoader color={"#6f2da8"} loading={overviewLoading} size={10} />
      </div>
      <div className="mt-1" />

      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          style={{
            background:
              "linear-gradient(90deg, rgba(94, 195, 255, 0.04) -0.98%, rgba(253, 93, 239, 0.04) 100%)",
          }}
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <Tooltip />
          <defs>
            <linearGradient
              id="gradient"
              x1="-2.65305"
              y1="2.10156"
              x2="603.449"
              y2="2.10156"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5BC4FF" />
              <stop offset="1" stop-color="#FF5BEF" />
            </linearGradient>
          </defs>
          <Line
            type="monotone"
            dataKey="quantity"
            stroke="url(#gradient)"
            strokeWidth={3}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WeeklyReports;
