import React, { useState } from 'react'
import EditEnvironmentalCard from '../../children/Environmental/components/EditEnvironmentalCard'
import { useMediaQuery } from 'react-responsive'
import Modal from 'react-responsive-modal'
import EditShareholdQA from './EditShareholdQA'
import EditWhistleQA from './EditWhistleQA'
import EditLawsuitsQA from './EditLawsuitsQA'
import EditBreachesCaseQA from './EditBreachesCaseQA'
import EditEthicsQA from './EditEthicsQA'
import EditBoardMeetingQA from './EditBoardMeetingQA'
import EditCEOQA from './EditCEOQA'

export default function EditGoverance() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openEditEngryModal, setOpenEditEngryModal] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)

  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const onOpenEditModal = () => {
    setOpenEditModal(true)
  }
  const onCloseEditModal = () => setOpenEditModal(false)

  const onOpenEditEngryModal = () => {
    setOpenEditEngryModal(true)
  }

  const handleCardClick = (name) => {
    setQu1Visible(name === 'sharehold')
    setQu2Visible(name === 'agedis')
    setQu3Visible(name === 'lawsuits')
    onOpenEditModal()
    onOpenEditEngryModal()
    setSelectedComponent(name)
  }
  return (
    <>
      <div className="p-1">
        <div className="row-1 flex gap-2">
          <div onClick={() => handleCardClick('sharehold')}>
            <EditEnvironmentalCard
              name="sharehold"
              image="../images/agedis.png"
              icon="../images/ic-sharehold.png"
              title="Shareholder Engagement"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
          <div onClick={() => handleCardClick('agedis')}>
            <EditEnvironmentalCard
              name="agedis"
              image="../images/agedis.png"
              icon="../images/ic-sharehold.png"
              title="Whistle blower"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
          <div onClick={() => handleCardClick('lawsuits')}>
            <EditEnvironmentalCard
              name="lawsuits"
              image="../images/lawsuits1.png"
              icon="../images/lawsuits.png"
              title="lawsuits"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
        </div>
        <div className="row-1 flex gap-2">
          <div onClick={() => handleCardClick('breaches')}>
            <EditEnvironmentalCard
              name="breaches"
              image="../images/breaches1.png"
              icon="../images/breaches.png"
              title="Breaches Cases"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
          <div onClick={() => handleCardClick('ethics')}>
            <EditEnvironmentalCard
              name="ethics"
              image="../images/ethics1.png"
              icon="../images/ethics.png"
              title="Ethics Violations"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
          <div onClick={() => handleCardClick('boardmeeting')}>
            <EditEnvironmentalCard
              name="boardmeeting"
              image="../images/boardmeeting.png"
              icon="../images/group.png"
              title="Board Meetings"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
        </div>
        sharehold
        <div
          style={{ width: '33%' }}
          onClick={() => handleCardClick('ceopaygapy')}
        >
          <EditEnvironmentalCard
            name="ceopaygapy"
            image="../images/ceopaygapy.png"
            icon="../images/group.png"
            title="CEO Pay Gap"
            desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
          />
        </div>
      </div>
      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openEditModal}
        onClose={onCloseEditModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          <div className="flex align-center gap-1">
          {!selectedComponent &&<h4>Edit Goverance Data</h4>}
            {selectedComponent === 'spending' && <h4>Edit sharehold</h4>}
            {selectedComponent === 'agedis' && <h4>Edit Whistle blower</h4>}
            {selectedComponent === 'lawsuits' && <h4>Edit lawsuits</h4>}
            {selectedComponent === 'breaches' && <h4>Edit Breaches Cases</h4>}
            {selectedComponent === 'ethics' && <h4>Edit Ethics Violations</h4>}
            {selectedComponent === 'boardmeeting' && <h4>Edit Board Meetings</h4>}
            {selectedComponent === 'ceopaygapy' && <h4>Edit CEO Pay Gap</h4>}
          </div>

          <div
            onClick={onCloseEditModal}
            className="flex align-center justify-center"
            style={{
              width: 40,
              height: 40,
              background: 'rgba(255, 255, 255, 0.50)',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
          >
            <span class="material-symbols-outlined">close</span>
          </div>
        </div>

        {selectedComponent === 'sharehold' && (
          <EditShareholdQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'agedis' && (
          <EditWhistleQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'lawsuits' && (
          <EditLawsuitsQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'breaches' && (
          <EditBreachesCaseQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'ethics' && (
          <EditEthicsQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'boardmeeting' && (
          <EditBoardMeetingQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'ceopaygapy' && (
          <EditCEOQA onCloseEditModal={onCloseEditModal} />
        )}
      </Modal>
    </>
  )
}
