import React from 'react';

const StartTour = ({ closeModal }) => {
  return (
    <div className='center'>
        <img src='../images/WasteknotDashboard.png' width={'100%'} height={435} />
        <div style={{ paddingInline: '5rem' }} className='mt-2 mb-2'>
            <h4 className='cercle-purple-text'>Welcome to Wasteknot!</h4>
            <h2>Let’s take a quick product tour</h2>
            <b>we’ll cover the basics, all the way from menus to rise your first pickup request. This is all a demo, so don’t be afraid to click around</b>
        </div>
        <div onClick={closeModal} className='btn mb-2'>
        Start the tour
        </div>
    </div>
  );
};

export default StartTour;