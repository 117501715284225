import React from 'react'

export default function EditEmpDistributionQA({onCloseEditModal}) {
  return (
    <div className="qu-2">
      <div
        className="full-width flex justify-center align-center gap-1"
        style={{ background: '#FFFDBE', padding: '5px' }}
      >
        <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
          info
        </span>
        <p className="purple-cercle-text small-text">
          Data entries require supporting documentation for verification.
        </p>
      </div>
      <div className="p-1">
        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>Select the states where the company has employees</p>
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'relative' }}>
              <select
                className="browser-default scrap-select-dropdown"
                style={{
                  background: '#FAFAFA',
                  border: 'none',
                  width: '30%',
                }}
              >
                <option>Select the age</option>
                <option>Plastic Waste</option>
              </select>
              <span
                class="material-symbols-outlined"
                style={{
                  position: 'absolute',
                  left: 320,
                  top: 10,
                  color: '#6F2DA8',
                }}
              >
                arrow_drop_down
              </span>
            </div>
          </div>
        </div>
        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>Specify the number of employees.</p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                style={{ width: '30%' }}
                placeholder="Enter the Number of total injuries"
              />
            </span>
          </div>
        </div>
        <div className='flex justify-end'>
          <button className='btn' onClick={onCloseEditModal}>Save</button>
        </div>
      </div>
    </div>
  )
}
