import React from "react";
import { useNavigate } from "react-router-dom";

const PlansCard = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="plan-container ">
        <div className="p-1">
          <h4 className="cercle-purple-text">Current Plan</h4>
          <p>Expires on - 12th Dec,2023</p>
          <h3>
            ₹ 43,000<span className="small-text">/month</span>
          </h3>

          <div className="status-tag green-text flex align-center gap-1">
            <span class="material-icons small-text">fiber_manual_record</span>
            <p>Active</p>
          </div>
        </div>

        <div
          className=" flex column align-center gap-1 cercle-purple-text p-1 justify-center"
          style={{ background: "#FFFDBE" }}
        >
          <h1>
            16
            <span class="material-symbols-outlined ml-1">acute</span>
          </h1>
          <p> Days remaining until plan expires</p>
        </div>
        <div
          style={{
            background: "linear-gradient(91deg, #6F2DA8 0%, #BA68C8 100%)",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
          className="p-1 flex justify-end"
        >
          <div
            style={{
              paddingBlock: "0.5rem",
              paddingInline: "0.7rem",
              borderRadius: "6px",
            }}
            className="flex align-center justify-center gap-1 cercle-purple-text white small-text pointer "
            role="button"
            onClick={() => navigate("/plans")}
          >
            <b>RENEW YOUR PLAN</b>
            <span class="material-symbols-outlined">arrow_outward</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlansCard;
