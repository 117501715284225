import React, { useState } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Renewable Energy', value: 80 },
  { name: 'Non-Renewable Energy', value: 40 },
]
const COLORS = ['#27AE60', '#FF9500']

export default function EneryConsumptionChart() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const isLaptop = useMediaQuery({ query: '(max-width:1600px)' })

  const [clickedCell, setClickedCell] = useState(null)

  const handleCellClick = (data) => {
    setClickedCell(data)
  }
  return (
    <div
      className={isLaptop ? 'mt-1 white p-1' : 'white p-1 '}
      style={{
        width: isMobile ? '100%' : '30%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <p className="bold">Energy Consumption</p>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Tooltip formatter={(value, name) => [value, name]} />

            <Pie
              data={data}
              cx="50%"
              cy="40%"
              innerRadius={50}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
              labelLine={false}
              onClick={handleCellClick}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                  style={{ outline: 'none' }}
                />
              ))}

              {/* {clickedCell && (
                <Label
                  key={`center-label`}
                  value={`${clickedCell.name}: ${clickedCell.value}`}
                  position="center"
                  fontSize={isMobile ? 12 : 10}
                />
              )} */}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div
        className={isLaptop ? '' : 'flex space-between'}
        style={{
          width: '94%',
          position: 'absolute',
          bottom: '30px',
        }}
      >
        <div>
          <span
            style={{ color: '#27AE60' }}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#27AE60',
              }}
            ></span>
            <p className="grey-text">Renewable Energy</p>
          </span>
        </div>
        <div>
          <span
            style={{ color: '#FF9500' }}
            className="flex align-center gap-1"
          >
            <span
              style={{
                borderRadius: '50%',
                height: '10px',
                width: '10px',
                background: '#FF9500',
              }}
            ></span>
            <p className="grey-text">Non - Renewable Energy</p>
          </span>
        </div>
      </div>
    </div>
  )
}
