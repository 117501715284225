import { toast } from "react-toastify";
import React from "react";
import { useLocation } from "react-router-dom";
import M from "materialize-css";

import { DefaultToast } from "../components/CustomToast";

export const handleError = (error) => {
  toast.error(error.response ? error.response?.data : error.message);
};

export const editAppTitle = (newTitle) => {
  document.title = `${newTitle}`;
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const isTabletOrMobile = window.innerWidth < 768;

export function notify(msg, descrip) {
  if (typeof msg === "string") {
    toast(
      <DefaultToast
        notification={{
          body: descrip,
          title: msg,
        }}
      />,
      {
        position: toast.POSITION.BOTTOM_LEFT,
        hideProgressBar: true,
        autoClose: 3000,
      }
    );
    return;
  }

  toast(
    <DefaultToast
      notification={{
        body: msg.body,
        title: msg.title,
      }}
    />,
    {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      closeButton: false,
      progress: undefined,
    }
  );
}

export function notifyError(msg) {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_LEFT,
    hideProgressBar: true,
    autoClose: 3000,
  });
}

export function calculateTotal(scrapPrice, quantityInTonnes) {
  scrapPrice = parseFloat(scrapPrice);
  let tonnesToKg = parseFloat(quantityInTonnes) * 1000;

  if (!quantityInTonnes) return 0;
  return (tonnesToKg * scrapPrice).toLocaleString();
}

export function playSound() {}

export function onNotificationClick(data, navigate) {
  const pickups = [
    "pickup_assigned",
    "pickup_completed",
    "pickup_requested",
    "driver_arrived",
    "pickup_ignored",
  ];

  if (pickups.includes(data.notificationType))
    return navigate("/timeline/" + data.pickup);

  return navigate("/pickups");
}

export const checkForProcessor = (groupId, contract) => {
  if (contract?.processorGroup === groupId) return true;
};

export const checkForGenerator = (groupId, contract) => {
  if (contract?.generatorGroup === groupId) return true;
};

export function decimalToPercentage(decimal) {
  return (decimal * 100).toString() + "%";
}

export function calculateInvoiceTotal(scrapPrice, quantityInTonnes, TCS) {
  scrapPrice = parseFloat(scrapPrice);
  let tonnesToKg = parseFloat(quantityInTonnes) * 1000;

  if (!quantityInTonnes) return 0;
  let amount = tonnesToKg * scrapPrice;

  TCS = amount * TCS;

  return (amount + TCS).toLocaleString();
}

export function showStatus(pickup) {
  if (pickup.ignored) return <span style={{ color: "#FF00EE" }}>Ignored</span>;
  if (pickup.completed)
    return <span style={{ color: "#FF00EE" }}>Ignored</span>;
  return <span style={{ color: "#FF00EE" }}>Pending</span>;
}

const currentDate = new Date();
export const isToday = (givenDate) => {
  return (
    currentDate.getFullYear() === givenDate.getFullYear() &&
    currentDate.getMonth() === givenDate.getMonth() &&
    currentDate.getDate() === givenDate.getDate()
  );
};

export const showAmount = (amount) => {
  return amount?.toLocaleString();
};

export const showAmountWithCurrency = (amount, curr) => {
  return curr
    ? curr + " " + amount?.toLocaleString()
    : "₹" + amount?.toLocaleString();
};

export const generateColor = () => {
  // Generate a random dark color
  const r = Math.floor(Math.random() * 100);
  const g = Math.floor(Math.random() * 100);
  const b = Math.floor(Math.random() * 100);

  // Convert the RGB values to a hexadecimal string
  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
};

export const twoDecimals = (num) => {
  return num.toFixed(2);
};
