import React from 'react'
import ImpactCard from './ImpactCard'
import { useMediaQuery } from 'react-responsive'

export default function ImpactCardList() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' });

  return (
    <div className={isMobile ? 'mt-1 box white':"box white full-width"}>
      <div>
        <h4 style={{color:'#151D48'}}>Impact</h4>
        <p className="grey-text" style={{fontSize:'16px'}}>For this Year</p>
      </div>
      <div className={isMobile ? 'flex column':"ml-1 flex gap-2 "}>
        <ImpactCard
          img="../images/economic.png"
          title="8 lakh"
          desc="Economic Growth"
          year="+8% vs Pervious year"
        />
        <ImpactCard
          img="../images/education.png"
          title="10 lakh"
          desc="Quality education"
          year="+8% vs Pervious year"
        />
        <ImpactCard
          img="../images/zerohunger.png"
          title="1 lakh"
          desc="Zero hunger"
          year="+8% vs Pervious year"
        />
        <ImpactCard
          img="../images/cleanwater.png"
          title="18,000"
          desc="Clean water"
          year="+8% vs Pervious year"
        />
      </div>
    </div>
  )
}
