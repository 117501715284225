import React from 'react'
import { useNavigate } from 'react-router-dom'
import ScrapDetailBox from './ScrapInventory/components/ScrapDetailBox'

function ScrapInventory() {
  const navigate = useNavigate()
  return (
    <div className="p-1">
      <div className="flex align-center space-between">
        <div className="flex align-center gap-1">
          <span class="material-symbols-outlined">keyboard_backspace</span>
          <b className="bold">Scrap Inventory list</b>
        </div>
        <div>
          <button
            className="btn-small"
            onClick={() => {
              navigate('/new-add-scrap')
            }}
          >
            Create new scrap
          </button>
        </div>
      </div>
      <div className="flex gap-2">
        <ScrapDetailBox
          image="../images/hdpe.png"
          scrap="HDPE"
          id="1234AB"
          date="3/02/24"
          location="WAREHOUSE 2"
          storage="90 DAYS"
        />
        <ScrapDetailBox
          image="../images/ewaste.png"
          scrap="E-WASTE"
          id="1234AB"
          date="3/02/24"
          location="WAREHOUSE 2"
          storage="90 DAYS"
        />
        <ScrapDetailBox
          image="../images/cardboard.png"
          scrap="CARD BOARD"
          id="1234AB"
          date="3/02/24"
          location="WAREHOUSE 2"
          storage="90 DAYS"
        />
        <ScrapDetailBox
          image="../images/tin.png"
          scrap="TIN TYPE CANS WASTE"
          id="1234AB"
          date="3/02/24"
          location="WAREHOUSE 2"
          storage="90 DAYS"
        />
        <ScrapDetailBox
          image="../images/hdpe.png"
          scrap="HDPE"
          id="1234AB"
          date="3/02/24"
          location="WAREHOUSE 2"
          storage="90 DAYS"
        />
      </div>
    </div>
  )
}

export default ScrapInventory