import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { DashboardCard } from "../../modules";
import { ComponentHeader } from "../../modules";
import { getAnalyticsOverview } from "../../redux/action/analytics.js";
import { getNotifications } from "../../redux/action/notifications.js";
import DashboardPickupHistoryTable from "./components/DashboardPickupsHistory";
import MainChart from "./components/MainChart";
import NextMilestone from "./components/NextMilestone";
import DownloadApp from "./components/DownlonadApp";
import WeeklyReports from "./components/WeeklyReports.js";
import BarGraph from "./components/BarGraph.js";
import { TodaysSales } from "./components/TodaysSales.js";
import LocationPickupRequestChart from "./components/LocationPickupRequestChart.js";
import QuantityScrapGeneratedChart from "./components/QuantityScrapGeneratedChart.js";
import DashboardBox from "./components/DashboardBox.js";
import SankeyChart from "./components/SankeyChart.js";
import GaugeChart from "./components/GaugeChart.js";
import Joyride from "react-joyride";
import Modal from "react-responsive-modal";
import StartTour from "../../components/StartTour.js";
import { getLocations, getNetwork } from "../../redux/action/organization.js";
import RevenueByLocationChart from "./components/RevenueByLocation.js";

const data = [
  { name: "Food A", value: 400 },
  { name: "Food B", value: 300 },
  { name: "Food C", value: 300 },
  { name: "Food D", value: 200 },
];
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const data2 = [
  { name: "MLP A", value: 400 },
  { name: "MLP B", value: 300 },
  { name: "MLP C", value: 300 },
  { name: "MLP D", value: 200 },
  { name: "MLP E", value: 500 },
];
const COLORS2 = ["#FB802E", "#EA4335", "#FBCB1B", "#27AE60", "#AE8FF7"];

function Dashboard() {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { overview } = useSelector((state) => state.analytics);
  const { account } = useSelector((state) => state.account);
  const { organization, network } = useSelector((state) => state.organization);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalyticsOverview());
    dispatch(getNotifications());
    dispatch(getNetwork());
  }, [dispatch]);

  // console.log(localStorage, 'tour');

  useEffect(() => {
    const tourShownBefore = localStorage.getItem("tourShownBefore");
    if (!tourShownBefore) {
      onOpenPNModal();
      localStorage.setItem("tourShownBefore", "true");
    }
  }, []);

  const [openPN, setOpenPN] = useState(false);
  const onOpenPNModal = () => setOpenPN(true);
  const onClosePNModal = () => setOpenPN(false);

  const { networkAnalytics } = useSelector((state) => state.analytics);

  const handleTour = () => {
    onClosePNModal();
  };

  return (
    <div className="flex ">
      <div className="dashboard-content ">
        <ComponentHeader title="Overview" />

        <div className={isMobile ? "flex gap-2 flex-wrap" : "flex gap-2"}>
          <SankeyChart />
          <GaugeChart />
        </div>

        <div style={{ gap: "1.5rem" }} className="flex flex-wrap mt-2">
          <div style={{ gap: "1.5rem" }} className="flex column flex-1">
            <DashboardCard
              title="Total Pickups Completed"
              amount={
                overview.totalPickupsThisMonth
                  ? overview.totalPickupsThisMonth + " Pickups"
                  : 0 + " Pickups"
              }
              desc="This Month"
              img="../images/calendar.png"
              percentageInc={overview.percentagePickupsChangeThisMonth}
              color="light-blue lighten-5"
            />
            {organization.type === "recycler" ? (
              <DashboardCard
                title="QUANTITY RECYCLED"
                amount={
                  overview.totalQuantityRecycled
                    ? overview.totalQuantityRecycled + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="Overall"
                img="../images/calendar.png"
                color="purple lighten-5"
              />
            ) : (
              <DashboardCard
                title="My Network"
                amount={
                  network.length
                    ? network.length > 1
                      ? network.length + " Members"
                      : network.length + " Member"
                    : 0 + " Members"
                }
                desc="Overall"
                img="../images/calendar.png"
                color="purple lighten-5"
              />
            )}
          </div>
          <div style={{ gap: "1.5rem" }} className="flex column flex-1">
            <DashboardCard
              title="Total Quantity channelized"
              amount={
                overview.totalQuantityChannelized
                  ? overview.totalQuantityChannelized + " Tonnes"
                  : 0 + " Tonnes"
              }
              desc="overall"
              img="../images/calendar.png"
              color="green lighten-5"
              percentageInc={overview.percentageQuantityChangeThisMonth}
            />
            {organization.type === "recycler" ? (
              <DashboardCard
                title="QUANTITY RECYCLED"
                amount={
                  overview.totalQuantityRecycled
                    ? overview.totalQuantityRecycled + " Tonnes"
                    : 0 + " Tonnes"
                }
                desc="Overall"
                img="../images/calendar.png"
              />
            ) : (
              <DashboardCard
                title="Pending Pickups"
                amount={
                  overview.pendingPickups
                    ? overview.pendingPickups + " Pickups"
                    : 0 + " Pickups"
                }
                desc="This Month"
                img="../images/calendar.png"
                color="orange lighten-5"
              />
            )}
          </div>

          <MainChart />
        </div>

        <div className="mt-2">
          <NextMilestone />
        </div>

        <div
          className={
            isMobile ? "mt-2 flex gap-2 column" : "mt-2 flex space-between"
          }
        >
          <LocationPickupRequestChart />
          <QuantityScrapGeneratedChart COLORS={COLORS} scrapType={"Food"} />

          <RevenueByLocationChart
            data={data2}
            COLORS={COLORS2}
            weight={"1 Ton"}
          />
        </div>

        {/* <div className={isMobile ? "column mt-2" : "mt-2"}>
          <PaymentHistoryTable />
        </div> */}

        <div className={isMobile ? "" : "flex mt-2 space-between"}>
          <WeeklyReports />

          <BarGraph />
        </div>

        <div className="mt-2">
          <DashboardPickupHistoryTable />
        </div>

        <div
          style={{ gap: "1.5rem" }}
          className={isMobile ? "" : "flex flex-wrap mt-2"}
        >
          <TodaysSales />

          <div
            style={{ gap: "1.5rem" }}
            className={
              isMobile ? "flex column flex-1 mt-1" : "flex column flex-1"
            }
          >
            <DashboardBox
              title="Cerclex Credits"
              amount={overview?.cercle_credits}
              desc="T&C's Apply"
              img="../images/cerclex.png"
            />

            <DashboardBox
              title="EPR Credits"
              desc="T&C's Apply"
              amount="N/A"
              img="../images/co2Credits.svg"
            />
          </div>
        </div>

        <div className="mt-2">
          <DownloadApp />
        </div>
      </div>

      <Modal
        center
        styles={{
          modal: {
            borderRadius: "10px",
            padding: 0,
            // width: isMobile ? '92%' : '100%',
            // position: isMobile ? 'absolute' : null,
            // left: isMobile ? 0 : null
          },
        }}
        open={openPN}
        onClose={onClosePNModal}
        // closeIcon={<></>}
      >
        <StartTour
          closeModal={() => {
            handleTour();
          }}
        />
      </Modal>

      {/* {!isTablet && (
        <aside
          className="dashboard-sidebar"
          style={{ overflowY: "auto", position: "fixed", right: "1%" }}
        >
          <Notification />
        </aside>
      )} */}
    </div>
  );
}

export default Dashboard;
