import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const LocationPickupRequestChart = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 824px)" });
  const { organization } = useSelector((state) => state.organization);
  const [selected, setSelected] = React.useState(null);
  const { overview } = useSelector((state) => state.analytics);
  const { locations } = organization;
  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (!overview.locationsAnalytics) return;
    if (!locations) return;

    const data = overview.locationsAnalytics.map((locData) => ({
      name: locations.find((loc) => loc._id === locData.location)?.nickname,
      count: locData.count,
    }));
    setData(data);
    setSelected(0);
  }, [locations, overview.locationsAnalytics]);

  return (
    <div className="box white" style={{ width: isTablet ? "100%" : "32.6%" }}>
      <h5>% Pickup Requested By Location</h5>
      <hr color="#E5E5EF" style={{ marginTop: "1rem" }} />
      <div style={{}} className="">
        <div style={{ height: 200 }} className="">
          <ResponsiveContainer className={""} width="100%" height="100%">
            <PieChart height={400}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="count"
              >
                {data.map((entry, index) => (
                  <Cell
                    style={{ outline: "none" }}
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => setSelected(index)}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex space-between">
          <div>
            <p className="small-text grey-text text-lighten-1">Location</p>
            <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>
              {data[selected]?.name || "No location selected"}
            </p>
          </div>
          <div>
            <p className="small-text grey-text text-lighten-1">
              Pickup Request
            </p>
            <p style={{ textAlign: "end", fontWeight: "bold" }}>
              {data[selected]?.count || 0}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPickupRequestChart;
