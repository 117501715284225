import React from "react";
import { ComponentHeader } from "../../../modules";
import { useMediaQuery } from "react-responsive";

const history = [
  {
    id: 1,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
  {
    id: 2,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
  {
    id: 3,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
  {
    id: 4,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
  {
    id: 5,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
  {
    id: 6,
    invoice: "Invoice #011 - Nov 2023",
    billingDate: "Nov 27,2023",
    deliveryChallan: "E-way bill #011 - Nov 2023",
  },
];

const OverallBillingHistoryTable = () => {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <section className="p-1">
      <ComponentHeader title="Billing History" />

      <div className="box white mt-1">
        <table
          className={
            isMobile
              ? "striped responsive-table "
              : "striped responsive-table billingTable"
          }
        >
          <thead>
            <tr className="table-header-row">
              <th>#</th>
              <th>Invoice</th>
              <th>Billing Date</th>
              <th>Delivery Challan</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {history.map((item, index) => {
              return (
                <tr key={index} className="tableHover">
                  <td>{item.id}</td>
                  <td>
                    <img
                      src="../images/pdficon.png"
                      className="mr-1"
                      style={{
                        width: "15px",
                        height: "18px",
                        verticalAlign: "sub",
                      }}
                    />
                    {item.invoice}
                  </td>
                  <td>{item.billingDate}</td>
                  <td>{item.deliveryChallan}</td>
                  <td>
                    <span class="material-symbols-outlined grey-text text-darken-1">
                      visibility
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {(history?.length === 0 || !history) && (
          <div className="flex column align-center full-width  mv-2 p-2">
            <span
              class="material-symbols-outlined grey-text"
              style={{ fontSize: "5rem" }}
            >
              fire_truck
            </span>
            <p className="grey-text mt-1 ">
              Currently you don’t have any pickups.
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default OverallBillingHistoryTable;
