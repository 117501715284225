import React, { useState } from "react";
import { Dropdown, Modal } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { CompanyLogo } from "../modules";
import { UserProfileImage, Notification } from "../modules";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";
import { Modal as RRModal } from "react-responsive-modal";
import SearchBar from "../components/SearchBar";

const SEARCHDATA = [
  {
    id: 1,
    pageTitle: "Dashboard",
    link: "/",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        dashboard
      </span>
    ),
    keywords: [
      "Overview",
      "Owvrviev",
      "Oevrview",
      "Ourview",
      "Your Current Milestone",
      "Payments & Invoices",
      "Weekly Reports",
      "Monthly Waste",
      "Your Sustainability Credits Earned",
      "Overall Pickups History",
      "Today’s Sales",
      "Pickup request by location",
      "Scrap generated by quantity",
      "Revenue generated by quantity",
    ],
  },
  {
    id: 2,
    pageTitle: "Pickup Request",
    link: "/pickups",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        local_shipping
      </span>
    ),
    keywords: ["Pickups", "Overall Pickups History"],
  },
  {
    id: 3,
    pageTitle: "My Network",
    link: "/network",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        diversity_3
      </span>
    ),
    keywords: ["Most Active Users", "Top Contributors", "Network list"],
  },
  {
    id: 4,
    pageTitle: "Management",
    link: "/organization",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        groups
      </span>
    ),
    keywords: [
      "User Accounts Management",
      "Account",
      "Locations Management",
      "Locations",
      "Scraps Management",
      "Scraps",
      "Active Users",
    ],
  },
  {
    id: 5,
    pageTitle: "Plans & Billings",
    link: "/plans-billings",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        receipt_long
      </span>
    ),
    keywords: [
      "Current Plan",
      "Today’s Sales",
      "Available Pickups",
      "Emergency Pickups",
      "Billing History",
    ],
  },
  {
    id: 6,
    pageTitle: "Review Insights",
    link: "/review-insights",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        reviews
      </span>
    ),
    keywords: ["Best waste generator", "Rating Overview", "Review History"],
  },
  {
    id: 7,
    pageTitle: "Analytics",
    link: "/reports",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        troubleshoot
      </span>
    ),
    keywords: [
      "Overall Reports & Analytics",
      "Vital Sustainability Metrics",
      "Pickups History",
    ],
  },
  {
    id: 8,
    pageTitle: "Settings",
    link: "/setting",
    description: "",
    icon: (
      <span className="material-symbols-outlined cercle-purple-text">
        settings
      </span>
    ),
    keywords: [
      "Account Information",
      "Change Password",
      "Change Mobile Number",
      "Notification Settings",
    ],
  },
];

function Header({ setOpenAside, openAside, userInfo }) {
  const dispatch = useDispatch();
  const [logout, setLogout] = useState(false);
  const logoutUser = () => {
    dispatch(logoutAction());
  };
  const { organization } = useSelector((state) => state.organization);
  const { profile } = useSelector((state) => state.notifications);

  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <header className="flex align-center space-between  full-width  header">
      <div className="flex align-center  ">
        <div
          className={openAside ? "open-menu-icon pointer" : "menu-icon pointer"}
          onClick={() => setOpenAside(!openAside)}
        >
          {openAside ? (
            <span className="material-symbols-outlined menu-icon icon hover">
              chevron_left
            </span>
          ) : (
            <span className="material-symbols-outlined menu-icon icon hover ">
              chevron_right
            </span>
          )}
        </div>
        <div className="company-profile flex mh-1  align-center ">
          <CompanyLogo
            userInfo={userInfo}
            style={{ width: "45px", height: "45px", objectFit: "cover" }}
          />
          <div className="flex column ml-1 justify-start align-start ">
            <b className="capitalize  truncate">{organization?.name}</b>
            <p style={{ color: "#666" }}>{organization.type}</p>
          </div>
        </div>
      </div>

      <div className="flex align-center" style={{ width: "30%" }}>
        {!isTablet ? (
          <SearchBar data={SEARCHDATA} placeholder={"Search"} />
        ) : null}

        <div
          style={{ background: "#FAFAFA", padding: 10 }}
          role="button"
          className="notification-icon circle flex align-center justify-center ml-1 mr-1"
          onClick={onOpenModal}
        >
          {profile.new && (
            <div className="new-notification-indicator">
              {profile.count > 99 ? "99+" : profile.count}
            </div>
          )}
          <span className="material-symbols-outlined primary icon mh-1 cercle-purple-text">
            notifications
          </span>
        </div>

        <RRModal
          styles={{
            modal: {
              borderRadius: "20px",
              right: isMobile ? 0 : "4rem",
              top: isMobile ? 0 : "4rem",
              bottom: isMobile ? 0 : "4rem",
              left: isMobile ? 0 : null,
              position: "absolute",
              width: isMobile ? "92%" : "800px",
              overflowY: "hidden",
            },
          }}
          open={open}
          onClose={onCloseModal}
        >
          <Notification />
        </RRModal>

        {!isMobile && (
          <Dropdown
            id="Dropdown_7"
            options={{
              alignment: "right",
            }}
            trigger={
              <div className="user-profile">
                <UserProfileImage
                  style={{ width: "45px", height: "45px", objectFit: "cover" }}
                  userInfo={userInfo}
                />
                {!isTablet ? (
                  <div>
                    {/* <p className="ml-1 capitalize">{account?.name}</p> */}
                    {/* <p
                      className=" ml-1 capitalize grey-text"
                      style={{ fontSize: 13, color: "#666" }}
                    >
                      {
                        organization.members?.find(
                          (member) => member.account?._id === account._id
                        )?.role
                      }
                    </p> */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
            className="user-profile-drop-down "
          >
            <Link to="/setting">
              <div className="flex ml-1 gap-1 ">
                <span className="material-symbols-outlined cercle-purple-text">
                  manage_accounts
                </span>
                <p className="semi-bold cercle-purple-text ">Account Setting</p>
              </div>
            </Link>
            <span>
              <div
                className="flex ml-1 gap-1 "
                href="#modal1"
                node="button"
                onClick={() => setLogout(!logout)}
              >
                <span className="material-symbols-outlined cercle-purple-text">
                  logout
                </span>
                <p className="semi-bold cercle-purple-text ">Logout </p>
              </div>
              <Modal
                id="modal1"
                open={logout}
                options={{
                  onCloseEnd: () => setLogout(false),
                }}
                actions={<></>}
              >
                <b className="normal-size">Confirm Logout </b>
                <p className="semi-bold">Are you sure you want to logout?</p>
                <div className="flex  justify-end gap-2  ">
                  <button className="green btn-small " onClick={logoutUser}>
                    Yes
                  </button>

                  <button
                    className="red btn-small"
                    onClick={() => setLogout(false)}
                  >
                    No
                  </button>
                </div>
              </Modal>
            </span>
          </Dropdown>
        )}
      </div>
    </header>
  );
}

export default Header;
