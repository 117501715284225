import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-materialize";
import {
  getOrganization,
  updateOrganization,
} from "../../../redux/action/organization";
import { notify } from "../../../helpers/helper";

function EditOrganization() {
  const dispatch = useDispatch();
  const { organization } = useSelector((state) => state.organization);
  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    if (!organization) return;
    setOrganizationName(organization?.name);
  }, [organization]);

  function onSavechange(e) {
    e.preventDefault();
    dispatch(
      updateOrganization({ name: organizationName }, () => {
        notify("Updated successfully","Organization name has been updated");
        dispatch(getOrganization());
        const elem = document.getElementById("edit-organization");
        const instance = window.M.Modal.getInstance(elem);
        instance.close();
      })
    );
  }

  return (
    <Modal
      id="edit-organization"
      trigger={
        <b
          node="button"
          className="purple-text pointer"
        >
          Edit
        </b>
      }
      actions={[]}
      header="Edit Organization Details"
    >
      <form onSubmit={onSavechange}>
        <div className="row align-center  ">
          <label>Organization Name </label>
          <div>
            <input
              type="text"
              className="browser-default input-style "
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
              placeholder={"Enter Organization Name"}
            />
          </div>
        </div>

        <div className="mt-1 flex justify-end ">
          <button
            className="btn"
            disabled={organizationName === organization?.name}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default EditOrganization;
