import React from 'react'

export default function EditGoalsQA({onCloseEditModal}) {
  return (
    <div>
    <div
      className="full-width flex justify-center align-center gap-1"
      style={{ background: '#FFFDBE', padding: '5px' }}
    >
      <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
        info
      </span>
      <p className="purple-cercle-text small-text">
        Data entries require supporting documentation for verification.
      </p>
    </div>
    <div className="p-1">
      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          What was the budgeted amount allocated for achieving the CSR goals
          during the reporting period
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              placeholder="Enter the allocated budgeted for the CSR goals"
              style={{ width: '35%' }}
            />
          </span>
          <span
            class="material-symbols-outlined"
            style={{
              position: 'absolute',
              left: 380,
              top: 1,
              color: '#6F2DA8',
              background: '#ECE4F4',
              borderRadius: '0 6px 6px 0',
              padding: '9px',
            }}
          >
            <p>
              <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
            </p>
          </span>
        </div>
      </div>

      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          How much was actually spent on achieving the CSR goals during the
          reporting period
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              placeholder="Enter the amount spent on the CSR goals"
              style={{ width: '35%' }}
            />
          </span>
          <span
            class="material-symbols-outlined"
            style={{
              position: 'absolute',
              left: 380,
              top: 1,
              color: '#6F2DA8',
              background: '#ECE4F4',
              borderRadius: '0 6px 6px 0',
              padding: '9px',
            }}
          >
            <p>
              <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
            </p>
          </span>
        </div>
      </div>

      <div className="mt-1" style={{ fontSize: '18px' }}>
        <p>
          What portion of the budgeted amount has been dispersed for achieving
          the CSR goals
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              placeholder="Enter the dispersed amount on the CSR goals"
              style={{ width: '35%' }}
            />
          </span>
          <span
            class="material-symbols-outlined"
            style={{
              position: 'absolute',
              left: 380,
              top: 1,
              color: '#6F2DA8',
              background: '#ECE4F4',
              borderRadius: '0 6px 6px 0',
              padding: '9px',
            }}
          >
            <p>
              <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
            </p>
          </span>
        </div>
      </div>
      <div className="flex justify-end p-1">
        <button className="btn" onClick={onCloseEditModal}>Save</button>
      </div>
    </div>
  </div>
  )
}
