import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ComponentHeader, DashboardCard } from "../../modules";
import BestWasteGeneratorCard from "./components/BestWasteGeneratorCard";
import RatingOverviewGraph from "./components/RatingOverviewGraph";
import ReviewHistoryTable from "./components/ReviewHistoryTable";

const BESTWASTEGENERATOR = [
  {
    id: 1,
    hotelName: "Taj vivanta",
    wasteWeight: "7000Kg",
    contaminationScore: 4.8,
    segregationScore: 4.8,
    rating: 4.3,
    img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/469577687.jpg?k=72c0fd6d06e99c7380a120478b489047954ee3aa720bedebc6287329337ba079&o=&hp=1",
  },
  {
    id: 2,
    hotelName: "Sri Krishna Sweets",
    wasteWeight: "6000Kg",
    contaminationScore: 4.5,
    segregationScore: 4.3,
    rating: 4.2,
    img: "https://m.media-amazon.com/images/I/71HjyVyxhSL.jpg",
  },
  {
    id: 3,
    hotelName: "Radisson blu Coimbatore",
    wasteWeight: "8000Kg",
    contaminationScore: 4.1,
    segregationScore: 4.1,
    rating: 4.1,
    img: "https://imgcld.yatra.com/ytimages/image/upload/t_seo_Hotel_w_930_h_550_c_fill_g_auto_q_40_f_jpg/v1509076833/Hotel/Coimbatore/00079314/EXTERIOR_-_NIGHT_VIEW_CYTqb0.jpg",
  },
];

const REVIEWHISTORYDATA = [
  {
    id: 1,
    pickupDate: "MON OCT 30 2023",
    rating: "4",
    contaminationScore: "4",
    segregationScore: "2",
    remark: "Waste not properly segregated",
  },
  {
    id: 2,
    pickupDate: "MON OCT 30 2023",
    rating: "3",
    contaminationScore: "2",
    segregationScore: "4",
    remark: "Contamination is high",
  },
  {
    id: 3,
    pickupDate: "MON OCT 30 2023",
    rating: "3",
    contaminationScore: "4",
    segregationScore: "2",
    remark: "Waste not properly segregated",
  },
  {
    id: 4,
    pickupDate: "MON OCT 30 2023",
    rating: "3",
    contaminationScore: "2",
    segregationScore: "4",
    remark: "Contamination is high",
  },
  {
    id: 5,
    pickupDate: "MON OCT 30 2023",
    rating: "4",
    contaminationScore: "4",
    segregationScore: "2",
    remark: "Waste not properly segregated",
  },
];

const ReviewInsights = () => {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [currentDataIndex, setCurrentDataIndex] = useState(0);
  const currentData = BESTWASTEGENERATOR[currentDataIndex];

  const switchToNextData = () => {
    setCurrentDataIndex((currentDataIndex + 1) % BESTWASTEGENERATOR.length);
  };

  const [steps, setSteps] = useState([]);
  const [tourKey, setTourKey] = useState(0);

  useEffect(() => {
    setSteps([
      {
        target: ".bestWasteGenerator",
        content: (
          <div className="left-align">
            <h6 className="cercle-purple-text">Best waste generator</h6>
            <p>
              You can see the top three best waste generator in your region
              these ratings are given by the
            </p>
          </div>
        ),
      },
    ]);

    // Increment the key to force a re-render
    setTourKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <div className="p-1">
      <div className="mb-1">
        <ComponentHeader title="Review Insights" />
      </div>

      <div
        style={{ gap: "1.5rem" }}
        className={isMobile ? "" : "flex flex-wrap"}
      >
        <div style={{ gap: "1.5rem" }} className="flex column flex-1">
          <DashboardCard
            title={"Waste Rating"}
            icon={"social_leaderboard"}
            color={"pink lighten-5"}
            cardValue={0}
          />
          <DashboardCard
            title={"Segregation Score"}
            icon={"family_star"}
            color={"light-blue lighten-5"}
            cardValue={0}
          />
        </div>
        <div
          style={{ gap: "1.5rem" }}
          className={
            isMobile ? "flex column flex-1 mt-2" : "flex column flex-1"
          }
        >
          <DashboardCard
            title={"Contamination Score"}
            icon={"sentiment_very_dissatisfied"}
            color={"red lighten-5"}
            cardValue={0}
          />
          <DashboardCard
            title={"Number of Reviews"}
            icon={"reviews"}
            color={"purple lighten-5"}
            cardValue={0}
          />
        </div>

        <div className={isMobile ? "mt-1" : ""}>
          <BestWasteGeneratorCard
            hotelName={currentData.hotelName}
            wasteWeight={currentData.wasteWeight}
            contaminationScore={currentData.contaminationScore}
            segregationScore={currentData.segregationScore}
            rating={currentData.rating}
            cardImage={currentData.img}
            movementOnClick={switchToNextData}
            id={currentData.id}
          />
        </div>

        <div
          className={isMobile ? "mt-1" : ""}
          style={{ width: isMobile ? "100%" : isLaptop ? "100%" : "40%" }}
        >
          <RatingOverviewGraph />
        </div>
      </div>

      <div className="mt-2">
        <ReviewHistoryTable history={[]} />
      </div>
    </div>
  );
};

export default ReviewInsights;
