import React from 'react'

export default function EditEmpSatisfactionQA({onCloseEditModal}) {
  return (
    <div>
        <div className="qu-3">
      <div className="qu-3">
        <div
          className="full-width flex justify-center align-center gap-1"
          style={{ background: '#FFFDBE', padding: '5px' }}
        >
          <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
            info
          </span>
          <p className="purple-cercle-text small-text">
            Data entries require supporting documentation for verification.
          </p>
        </div>
        <div className="p-1">
          <div className="mt-1" style={{ fontSize: '18px' }}>
            <p>
              The overall employees job satisfaction rate during the reporting
              period
            </p>
            <div style={{ position: 'relative' }}>
              <span>
                <input
                  className="new-input"
                  style={{ width: '30%' }}
                  placeholder="Enter the employees reporting period"
                />
              </span>
            </div>
          </div>
          <div className="mt-1" style={{ fontSize: '18px' }}>
            <p>
              The overall employees job satisfaction rate during the pervious
              year
            </p>
            <div style={{ position: 'relative' }}>
              <span>
                <input
                  className="new-input"
                  style={{ width: '30%' }}
                  placeholder="Enter the employees job satisfaction rate"
                />
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-end p-1">
          <button className="btn" onClick={onCloseEditModal}>Save</button>
        </div>
      </div>
    </div>

    </div>
  )
}
