import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-materialize";
import { useMediaQuery } from "react-responsive";
// import { Modal } from "react-materialize";
import {
  addLocation,
  deleteLocation,
  getInvitations,
} from "../../../redux/action/organization";
import AddLocation from "../../firstTime/components/AddLocation";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";
import { Modal as RRModal } from "react-materialize";
import { ClipLoader } from "react-spinners";

function Locations() {
  const { organization, loading, loadingLocations } = useSelector(
    (state) => state.organization
  );
  const { account } = useSelector((state) => state.account);
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvitations());
  }, [dispatch]);

  const [values, setValues] = React.useState({
    location: {
      lng: "",
      lat: "",
      address: "",
      nickname: "",
    },
  });
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter based on search query
  const filteredLocations = organization.locations?.filter(
    (item) =>
      item.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.address.toLowerCase().includes(searchQuery.toLowerCase())
    // Add other fields as needed
  );

  function addNewLocation() {
    dispatch(
      addLocation(values.location, () => {
        toast.success("Location Added successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setValues({
          location: {
            nickname: "",
            address: "",
            lat: "",
            lng: "",
          },
        });

        setOpenLocationModal(false);
      })
    );
  }

  return (
    <div>
      <div className="flex space-between align-center mt-1">
        <div>
          <h6 className="bold-title">Locations</h6>
        </div>

        <div
          style={{ justifyContent: "flex-end" }}
          className="flex full-width align-center"
        >
          <div
            className="input-style flex align-center small-text"
            style={{
              margin: 0,
              color: "#BFC3CE",
              background: "#FAFAFA",
              width: "20%",
              marginRight: "10px",
            }}
          >
            <span class="material-symbols-outlined mr-1">search</span>
            <input
              style={{ margin: 0, caretColor: "#000000" }}
              placeholder="Search"
              className="hide-focus-underline"
              type="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <button
            className="btn-small"
            disabled={!account.permissions?.includes("add-location")}
            style={{}}
            onClick={() => setOpenLocationModal(true)}
          >
            Add New Location
          </button>

          <Modal
            open={openLocationModal}
            onClose={() => setOpenLocationModal(false)}
            center
            classNames={{
              overlay: "customOverlay",
              modal: "customModal",
            }}
            styles={{
              modal: {
                borderRadius: "10px",
                position: isMobile ? "absolute" : null,
                left: isMobile ? 0 : null,
                right: isMobile ? 0 : null,
              },
            }}
          >
            <AddLocation
              addLocation={addNewLocation}
              values={values}
              setValues={setValues}
              loading={loading}
              mapWidth={isMobile ? "93%" : "100%"}
            />
          </Modal>
        </div>
      </div>

      <div className="box white mt-1">
        <div
          className={
            isMobile
              ? "p-1 flex flex-wrap gap-2"
              : "p-2 flex full-width gap-2 flex-wrap"
          }
        >
          {filteredLocations.length > 0
            ? filteredLocations.map((item, index) => (
                <div
                  className="p-1"
                  style={{
                    border: "1px solid #DADAE7",
                    borderRadius: "6px",
                    width: isMobile ? "100%" : isLaptop ? "23.96%" : "24.32%",
                  }}
                >
                  <div className="flex space-between mb-1">
                    <div className="flex align-center gap-2">
                      <div
                        className="circle flex align-center justify-center"
                        style={{ width: 46, height: 46, background: "#F2E3FF" }}
                      >
                        <span class="material-symbols-outlined cercle-purple-text">
                          business
                        </span>
                      </div>
                      <b>{item.nickname}</b>
                    </div>
                    <div className="flex align-center gap-2">
                      {account.permissions?.includes("remove-location") && (
                        <RRModal
                          header="Delete Location"
                          actions={[]}
                          trigger={
                            <span
                              onClick={() => {}}
                              class="material-symbols-outlined red-text pointer"
                            >
                              delete
                            </span>
                          }
                          id="delete-location"
                        >
                          <p>
                            Are you sure you want to delete this location? You
                            will have to re assign all the network associated
                            with this location to another location.
                          </p>

                          <div className="flex gap-1 mt-1">
                            <button
                              className="btn-small"
                              onClick={() => {
                                dispatch(
                                  deleteLocation(item._id, () => {
                                    toast.success(
                                      "Location deleted successfully",
                                      {
                                        position: toast.POSITION.BOTTOM_LEFT,
                                        hideProgressBar: true,
                                        autoClose: 3000,
                                      }
                                    );

                                    const instance = window.M.Modal.getInstance(
                                      document.getElementById("delete-location")
                                    );
                                    instance.close();
                                  })
                                );
                              }}
                            >
                              {loadingLocations ? (
                                <ClipLoader color="#fff" size={20} />
                              ) : (
                                "Yes, Delete"
                              )}
                            </button>
                            <Button
                              className="btn-small white purple-text"
                              modal="close"
                            >
                              Cancel
                            </Button>
                          </div>
                        </RRModal>
                      )}
                      <span
                        onClick={() => setOpenLocationModal(true)}
                        class="material-symbols-outlined cercle-purple-text pointer"
                      >
                        edit
                      </span>
                    </div>
                  </div>
                  <div className="flex align-center gap-1 grey-text text-darken-1">
                    <span class="material-icons cercle-purple-text">
                      fmd_good
                    </span>
                    {item.address}
                  </div>
                </div>
              ))
            : organization.locations
                ?.filter(
                  (item) =>
                    !item.nickname
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    !item.address
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  // Add other fields as needed
                )
                .slice(0, 1)
                .map((item, index) => (
                  <div className="flex align-center justify-center column full-width">
                    <img
                      className="mb-1"
                      style={{ width: "75px", height: "75px" }}
                      src="../images/no-data.svg"
                      alt="No Data Found"
                    />
                    <label>No Matching Data Found</label>
                  </div>
                ))}
        </div>
      </div>
    </div>
  );
}

export default Locations;
