import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Label,
} from "recharts";

const QuantityScrapGeneratedChart = ({ COLORS, scrapType, weight, type }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 824px)" });

  const [selected, setSelected] = React.useState(null);
  const { overview } = useSelector((state) => state.analytics);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (!overview.scrapsAnalytics) return;
    setData(overview.scrapsAnalytics);
    setSelected(0);
  }, [overview.scrapsAnalytics]);

  return (
    <div className="box white" style={{ width: isTablet ? "100%" : "32.6%" }}>
      <h5>% Scrap Quantity Generated</h5>
      <hr color="#E5E5EF" style={{ marginTop: "1rem" }} />
      <div className="">
        <div style={{ height: 200 }} className="">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={data || []}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="quantity"
                label={true}
              >
                {data.map((entry, index) => (
                  <Cell
                    style={{ outline: "none" }}
                    key={entry.scrap}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => setSelected(index)}
                  />
                ))}
                <Label
                  value={"Total Quantity"}
                  className="small-text"
                  position="center"
                  fill="#737373"
                  dy={-10}
                />
                <Label
                  className="bold"
                  value={`${data.reduce(
                    (acc, curr) => acc + curr.quantity,
                    0
                  )} Tons`}
                  position="center"
                  fill="#0A0A0A"
                  fontSize={14}
                  dy={10}
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="flex space-between">
          <div>
            <p className="small-text grey-text text-lighten-1">Scrap</p>
            <p style={{ fontWeight: "bold" }}>
              {data[selected]?.scrap || "No scrap selected"}
            </p>
          </div>
          <div>
            <p className="small-text grey-text text-lighten-1">
              Scrap Quantity
            </p>
            <p style={{ textAlign: "end", fontWeight: "bold" }}>{`${
              data[selected]?.quantity || 0
            } Tons`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantityScrapGeneratedChart;
