import React from 'react'

export default function EditWaterWmissionQA({onCloseEditModal}) {
  return (
    <div>
        <div
        className="full-width flex justify-center align-center gap-1"
        style={{ background: '#FFFDBE', padding: '5px' }}
      >
        <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
          info
        </span>
        <p className="purple-cercle-text small-text">
          Data entries require supporting documentation for verification.
        </p>
      </div>

      <div className="flex column gap-1 ml-1 p-1">
        <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
          <p>
            What is the total amount of water emissions produced by the company
            during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the total amount of water emissions"
                style={{ width: '40%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 410,
                top: 3,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '14px',
              }}
            >
              <p style={{ fontSize: '14px' }}>cu m</p>
            </span>
          </div>
        </div>

        <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
          <p>
            What is the total amount of water emissions produced by the company
            during the pervious year
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the total amount of water emissions"
                style={{ width: '52%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 550,
                top: 3,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '14px',
              }}
            >
              <p style={{ fontSize: '14px' }}>cu m</p>
            </span>
          </div>
        </div>
        <div className="flex justify-end p-1">
          <button className="btn" onClick={onCloseEditModal}>Save</button>
        </div>
      </div>
    </div>
  )
}
