import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Header, Aside } from "../components";
import { useNavigate, useLocation } from "react-router-dom";
import { socket } from "../redux/config/socket";
import { notify } from "../helpers/helper";
import { getAccount, getPlans } from "../redux/action/auth";
import { getOrganization } from "../redux/action/organization";
import {
  DriverAssignedToast,
  PriceChangeToast,
  PriceCounterToast,
} from "../components/CustomToast";
import { toast } from "react-toastify";
import {
  getNotifications,
  getNotificationsProfile,
} from "../redux/action/notifications";
import { getContracts } from "../redux/action/contracts";
import { getAllScraps } from "../redux/action/scraps";

function Frame({ children, userInfo }) {
  const [openAside, setOpenAside] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [steps, setSteps] = useState([]);
  const [tourKey, setTourKey] = useState(0);

  useEffect(() => {
    setSteps([
      {
        target: ".risePickupRequest",
        content: (
          <div className="left-align">
            <h6 className="cercle-purple-text">Rise Pickup Request</h6>
            <p>Click the + button to rise the pickup request</p>
          </div>
        ),
      },
    ]);

    // Increment the key to force a re-render
    setTourKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("__wasteknot")) return navigate("/");
    dispatch(getAccount());
    dispatch(getOrganization());
    dispatch(getNotificationsProfile());
    dispatch(getAllScraps());
    dispatch(getPlans());
  }, [dispatch, navigate]);

  useEffect(() => {
    console.log(socket);
    socket.on("notification", (msg) => {
      notify({
        title: msg.title,
        body: msg.body,
      });
      dispatch(getNotificationsProfile());
      dispatch(getNotifications());
    });
  }, [dispatch]);

  useEffect(() => {
    socket.on("pickup-assigned", (msg) => {
      toast(<DriverAssignedToast notification={msg} />, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "custom-toast",
      });
      dispatch(getNotificationsProfile());
      dispatch(getNotifications());
    });
  }, [dispatch]);

  useEffect(() => {
    socket.on("price-change-requested", (msg) => {
      toast(<PriceChangeToast notification={msg} />, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(getContracts());
    });

    socket.on("price-change-counter", (msg) => {
      toast(<PriceCounterToast notification={msg} />, {
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(getContracts());
    });

    socket.on("price-change-accepted", (msg) => {
      notify({
        title: msg.title,
        body: msg.body,
      });
      dispatch(getContracts());
    });
  }, [dispatch]);

  return (
    <div className="full-frame ">
      <Header
        setOpenAside={setOpenAside}
        openAside={openAside}
        userInfo={userInfo}
      />
      <div className="overlay">
        <Aside openAside={openAside} setOpenAside={setOpenAside} />
        <section className={openAside ? "opne-sasid" : ""}>{children}</section>
      </div>
    </div>
  );
}

export default Frame;
