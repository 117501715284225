import React, { useEffect, useState } from "react";
import { Dropdown } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
// import { XAxis, Tooltip, AreaChart, Area, ResponsiveContainer } from "recharts";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { ClipLoader } from "react-spinners";
import { getMonthlyQuantityOverTime } from "../../../redux/action/analytics";
import { useMediaQuery } from "react-responsive";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div style={{}} className="custom-tooltip white box p-1 center">
        <p
          style={{
            color: "#7C8DB5",
            fontFamily: "circular-light",
            lineHeight: "2px",
          }}
          className=""
        >
          {label}
        </p>
        <h4 className="">{`${payload[0]?.payload.quantity} Tonnes`}</h4>
        <p
          style={{
            color: "#7C8DB5",
            fontFamily: "circular-light",
            lineHeight: "10px",
          }}
          className=""
        >{`${payload[0]?.payload.count} Pickups`}</p>
      </div>
    );
  }

  return null;
};

function MainChart() {
  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const { monthlyQuantityOverTime, monthlyQuantityOverTimeLoading } =
    useSelector((state) => state.analytics);
  const [number, setNumber] = useState(3);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMonthlyQuantityOverTime(number));
  }, [dispatch, number]);

  return (
    <div
      style={{ width: isLaptop ? "62%" : "" }}
      className={
        isLaptop
          ? "p-1 full-height box white"
          : "quantity-channelized-chart p-1 full-height box white"
      }
    >
      <div className="flex space-between align-center">
        <p className="bold">Channelized Waste</p>

        <div className="flex align-center gap-1">
          <div className="flex align-center gap-1">
            <span class="material-icons small-text cercle-purple-text">
              fiber_manual_record
            </span>
            <p>Channelized Waste</p>
          </div>
          <div className="flex align-center gap-1 mr-1">
            <span
              style={{ color: "#FF9500" }}
              class="material-icons small-text"
            >
              fiber_manual_record
            </span>
            <p>Number of Pickup Requests</p>
          </div>

          {monthlyQuantityOverTimeLoading ? (
            <ClipLoader color="#6f2da8" size={20} />
          ) : (
            <Dropdown
              id="monthsDropdown"
              trigger={
                <div className="flex align-center box2">
                  <p
                    className="cercle-purple-text"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Last {number} Months
                  </p>
                  <span className="material-symbols-outlined cercle-purple-text">
                    arrow_drop_down
                  </span>
                </div>
              }
            >
              <span onClick={() => setNumber(3)}>3 Months</span>
              <span onClick={() => setNumber(6)}>6 Months</span>
              <span onClick={() => setNumber(12)}>1 Year</span>
              <span onClick={() => setNumber(24)}>2 Years</span>
            </Dropdown>
          )}
        </div>
      </div>

      <ResponsiveContainer width="100%" height={isLaptop ? 300 : 250}>
        <ComposedChart data={monthlyQuantityOverTime}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend /> */}
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="14"
              y1="181"
              x2="14"
              y2="6.57961e-08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEB8FF" />
              <stop offset="1" stop-color="#E6EDFF" stop-opacity="0" />
            </linearGradient>
          </defs>
          <Bar
            radius={10}
            dataKey="quantity"
            fill="url(#linear-gradient)"
            barSize={32.5}
          />
          <Line
            type="monotone"
            dataKey="quantity"
            stroke="#FF9500"
            dot={false}
          />
          <Line type="monotone" dataKey="count" stroke="#6f2da8" dot={false} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MainChart;
