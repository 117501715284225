import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

const BillingHistoryTable = ({ history }) => {
  const navigate = useNavigate();
  const isTablet = useMediaQuery({ query: "(max-width: 824px)" });

  return (
    <div className="box  full-width white ">
      <div className="flex ph-1  align-center space-between ">
        <b className="bold">Billing History</b>
        {/* {loading ? (
          <ClipLoader color={"#6f2da8"} loading={loading} size={20} />
        ) : ( */}
        <Link to="/billing-history" className="cercle-purple-text">
          View All
        </Link>
        {/* )} */}
      </div>

      <table
        className={
          isTablet
            ? "striped responsive-table "
            : "striped responsive-table billingTable"
        }
      >
        <thead>
          <tr className="table-header-row">
            <th>#</th>
            <th>Invoice</th>
            <th>Billing Date</th>
            <th>Delivery Challan</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {history.map((item, index) => {
            return (
              <tr key={index} className="tableHover">
                <td>{item.id}</td>
                <td>
                  <img
                    src="../images/pdficon.png"
                    className="mr-1"
                    style={{
                      width: "15px",
                      height: "18px",
                      verticalAlign: "sub",
                    }}
                    alt="pdf"
                  />
                  {item.invoice}
                </td>
                <td>{item.completedOn}</td>
                <td>{item.deliveryChallan}</td>
                <td>
                  <span
                    onClick={() =>
                      window.open(
                        "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
                        "_blank"
                      )
                    }
                    class="material-symbols-outlined grey-text text-darken-1"
                  >
                    visibility
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {(history?.length === 0 || !history) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            class="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            find_in_page
          </span>
          <p className="grey-text mt-1 ">
            No billing history available at the moment
          </p>
        </div>
      )}
    </div>
  );
};

export default BillingHistoryTable;
