import React from 'react';

export const AboutOrganisation = ({ nextPage }) => {
  return (
    <div style={{ }} className=''>
        <h1>Tells us about your Organisation</h1>
        <p className='grey-text'>You can always change them later</p>
        <div className='mt-3 flex full-width align-center gap-2'>
            <div className='full-width'>
                <h5>Registered Name<span className='red-text'>*</span></h5>
                <div className='input-field-style'>
                    <input 
                        className=' hide-focus-underline'
                        placeholder='Enter your organization name'
                        style={{ caretColor: '#000000', margin: 0 }}
                    />
                </div>
            </div>
            <div className='full-width'>
                <h5>City<span className='red-text'>*</span></h5>
                <select className='input-field-style'>
                    <option className=''>Select</option>
                </select>
            </div>
        </div>
        <div className='mt-1 flex full-width gap-2'>
            <div className='full-width'>
                <h5>Industry<span className='red-text'>*</span></h5>
                <select className='input-field-style'>
                    <option className=''>Select</option>
                </select>
            </div>
            <div className='full-width'>
                <h5>No of location<span className='red-text'>*</span></h5>
                <select className='input-field-style'>
                    <option className=''>Select</option>
                </select>
            </div>
        </div>
        <div className='mt-1 flex full-width gap-2'>
            <div className='full-width'>
                <h5>Size<span className='red-text'>*</span></h5>
                <select className='input-field-style'>
                    <option className=''>Select</option>
                </select>
            </div>
        </div>
        <div>
            <div onClick={nextPage} className='btn mt-2 full-width'>Next</div>
        </div>
    </div>
  );
};

export const AboutWaste = ({ nextPage, previousPage }) => {
  return (
    <div style={{ }} className=''>
        <h1>Tells us about your Waste</h1>
        <p>We’ll streamline your waste management process accordingly</p>
        <div className='mt-1 flex full-width gap-2'>
            <div className='full-width'>
                <h5>What are the waste you generate</h5>
                <select className='input-field-style'>
                    <option className=''>Select</option>
                </select>
            </div>
        </div>
        <div className='full-width mt-2'>
            <h5>Waste generated in the peak day (per day)</h5>
            <div className='input-field-style'>
                <input 
                    className=' hide-focus-underline'
                    placeholder='Enter the waste quantity'
                    style={{ caretColor: '#000000', margin: 0 }}
                />
            </div>
        </div>
        <div className='full-width mt-2'>
            <h5>Amount of waste generated (per day)</h5>
            <div className='input-field-style'>
                <input 
                    className=' hide-focus-underline'
                    placeholder='Enter the waste quantity'
                    style={{ caretColor: '#000000', margin: 0 }}
                />
            </div>
        </div>
        <div className='flex align-center gap-2'>
            <div onClick={previousPage} className='btn-outline mt-2 full-width'>Back</div>
            <div onClick={nextPage} className='btn mt-2 full-width'>Next</div>
            <div style={{ textDecorationLine: 'underline', fontWeight: 'bold' }} className='mt-2 mr-1 ml-1 cercle-purple-text'>Skip</div>
        </div>
    </div>
  );
};

export const PreAudit = ({ nextPage, previousPage }) => {
  return (
    <div style={{ }} className=''>
        <h1>Schedule a free Request Pre audit</h1>
        {/* <p>We’ll streamline your waste management process accordingly</p> */}
        <div className='mt-3 flex full-width align-center gap-2'>
            <div className='full-width'>
                <h5>First Name<span className='red-text'>*</span></h5>
                <div className='input-field-style'>
                    <input 
                        className=' hide-focus-underline'
                        placeholder='Enter your organization name'
                        style={{ caretColor: '#000000', margin: 0 }}
                    />
                </div>
            </div>
            <div className='full-width'>
                <h5>Last Name<span className='red-text'>*</span></h5>
                <div className='input-field-style'>
                    <input 
                        className=' hide-focus-underline'
                        placeholder='Enter your organization name'
                        style={{ caretColor: '#000000', margin: 0 }}
                    />
                </div>
            </div>
        </div>
        <div className='full-width mt-2'>
            <h5>Email<span className='red-text'>*</span></h5>
            <div className='input-field-style'>
                <input 
                    className=' hide-focus-underline'
                    placeholder='Enter your organization name'
                    style={{ caretColor: '#000000', margin: 0 }}
                />
            </div>
        </div>
        <div className='mt-1 flex full-width gap-2'>
            <div className='full-width'>
                <h5>Primary challenge which to solve with CercleX<span className='red-text'>*</span></h5>
                <select className='input-field-style'>
                    <option className=''>Select</option>
                </select>
            </div>
        </div>
        <div className='flex align-center gap-2'>
            <div onClick={previousPage} className='btn-outline mt-2 full-width'>Back</div>
            <div onClick={nextPage} className='btn mt-2 full-width'>Get free demo</div>
        </div>
    </div>
  );
};
