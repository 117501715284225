import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadingReducer } from "./reducer/loading";
import { authReducer, changeMobileNumberReducer } from "./reducer/auth";
import { organizationReducer } from "./reducer/organization";
import { notificationsReducer } from "./reducer/notifications";
import { contractsReducer } from "./reducer/contracts";
import { pickupsReducer } from "./reducer/pickupsReducer";
import { generalReducer } from "./reducer/generalReducer";
import groupReducer from "./reducer/group";
import { scrapsReducer } from "./reducer/scrapsReducer";
import { analyticsReducer } from "./reducer/analytics";

const reducer = combineReducers({
  loading: loadingReducer,
  account: authReducer,
  changeMobileNumber: changeMobileNumberReducer,
  general: generalReducer,
  organization: organizationReducer,
  notifications: notificationsReducer,
  contracts: contractsReducer,
  pickups: pickupsReducer,
  groups: groupReducer,
  scraps: scrapsReducer,
  analytics: analyticsReducer,
});

const initialstate = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialstate,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
