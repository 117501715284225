import React from 'react'
import { Button } from 'react-materialize'

export default function EditSpendingQA({onCloseEditModal}) {
  return (
    <>
     <div
          className="full-width flex justify-center align-center gap-1"
          style={{ background: '#FFFDBE', padding: '5px' }}
        >
          <span class="material-symbols-outlined" style={{ fontSize: '14px' }}>
            info
          </span>
          <p className="purple-cercle-text small-text">
            Data entries require supporting documentation for verification.
          </p>
        </div>
    
    <div className="p-1">
      
      <>
      
        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            What is the total amount spent on CSR initiatives by the company
            during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the total amount spent on CSR"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            economic growth during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on economic growth"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            quality education during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on quality education"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            zero hunger during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on zero hunger "
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            clean water during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on clean water"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            What is the total amount spent on CSR initiatives by the company
            during the reporting period
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the total amount spent on CSR"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            clean water during the pervious year
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on clean water"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            zero hunger during the pervious year
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on clean water"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
        </div>

        <div className="mt-1" style={{ fontSize: '18px' }}>
          <p>
            How much was allocated specifically for CSR initiatives related to
            quality education during the pervious year
          </p>
          <div style={{ position: 'relative' }}>
            <span>
              <input
                className="new-input"
                placeholder="Enter the amount spent on clean water"
                style={{ width: '35%' }}
              />
            </span>
            <span
              class="material-symbols-outlined"
              style={{
                position: 'absolute',
                left: 380,
                top: 1,
                color: '#6F2DA8',
                background: '#ECE4F4',
                borderRadius: '0 6px 6px 0',
                padding: '9px',
              }}
            >
              <p>
                <span class="material-symbols-outlined" style={{fontSize:'14px'}}>currency_rupee</span>
              </p>
            </span>
          </div>
          <div className="flex justify-end p-1">
            <button className="btn" onClick={onCloseEditModal}>
              Save
            </button>
          </div>
        </div>
      </>
    </div>
    </>
  )
}
