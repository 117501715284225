import React, { useState } from 'react'
import EnvironmentalCard from '../../../Environmental/components/EnvironmentalCard'
import { useMediaQuery } from 'react-responsive'
import EditEnvironmentalCard from '../../../Environmental/components/EditEnvironmentalCard'
import EditSpendingQA from './EditSpendingQA'
import Modal from 'react-responsive-modal'
import EditGoalsQA from './EditGoalsQA'
import EditSaplingsQA from './EditSaplingsQA'
import EditCamplingQA from './EditCamplingQA'

export default function EditImpact({ openED, onCloseEDModal }) {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openEditEngryModal, setOpenEditEngryModal] = useState(false)
  const [openEditWaterModal, setOpenEditWaterModal] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)

  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const onOpenEditModal = () => {
    setOpenEditModal(true)
  }
  const onCloseEditModal = () => setOpenEditModal(false)

  const onOpenEditEngryModal = () => {
    setOpenEditEngryModal(true)
  }
  const onCloseEditEngryModal = () => setOpenEditEngryModal(false)

  const onOpenEditWaterModal = () => {
    setOpenEditWaterModal(true)
  }
  const onCloseEditWaterModal = () => setOpenEditWaterModal(false)

  const handleCardClick = (name) => {
    setQu1Visible(name === 'edit-emission')
    setQu2Visible(name === 'edit-energy')
    setQu3Visible(name === 'edit-water-emission')
    onOpenEditModal()
    onOpenEditEngryModal()
    setSelectedComponent(name)
  }

  return (
    <>
      {!qu1Visible && !qu2Visible && !qu3Visible && (
        <div className="flex column gap-1 p-1 card-section">
          <div className="flex gap-2">
            <div
              className="full-width"
              onClick={() => handleCardClick('spending')}
            >
              <EditEnvironmentalCard
                name="spending"
                image="../images/csrspend.png"
                icon="../images/ic-csrspend.png"
                title="CSR Spending"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
            <div
              className="full-width"
              onClick={() => handleCardClick('goals')}
            >
              <EditEnvironmentalCard
                name="goals"
                image="../images/csrgoal.png"
                icon="../images/goal.png"
                title="CSR Goals"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
            <div
              className="full-width"
              onClick={() => handleCardClick('sapling')}
            >
              <EditEnvironmentalCard
                name="sapling"
                image="../images/plantsapling.png"
                icon="../images/plant.png"
                title="Planting saplings"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
          </div>
          <div
            style={{ width: '33%' }}
            onClick={() => handleCardClick('campaings')}
          >
            <EditEnvironmentalCard
              name="campaings"
              image="../images/awareness.png"
              icon="../images/injuries.png"
              title="Awareness Campaigns"
              desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
            />
          </div>
        </div>
      )}

      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openEditModal}
        onClose={onCloseEditModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          <div className="flex align-center gap-1">
            <h4>Edit Impact Data</h4>
          </div>

          <div
            onClick={onCloseEditModal}
            className="flex align-center justify-center"
            style={{
              width: 40,
              height: 40,
              background: 'rgba(255, 255, 255, 0.50)',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
          >
            <span class="material-symbols-outlined">close</span>
          </div>
        </div>
        {selectedComponent === 'spending' && (
          <EditSpendingQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'goals' && (
          <EditGoalsQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'sapling' && (
          <EditSaplingsQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'campaings' && (
          <EditCamplingQA onCloseEditModal={onCloseEditModal} />
        )}
      </Modal>
    </>
  )
}
