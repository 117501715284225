import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import M from "materialize-css";
import ReactDatePicker from "react-datepicker";
import { ClipLoader } from "react-spinners";
import { notify } from "../../../helpers/helper";
import { addContract, getContracts } from "../../../redux/action/contracts";

const AddScrapContract = ({ processor }) => {
  const { loading } = useSelector((state) => state.contracts);
  const { organization } = useSelector((state) => state.organization);

  const [email, setEmail] = useState("");
  const [values, setValues] = useState({
    start: "",
    end: "",
    processor: "",
    scrap: "",
    estimatedQuantity: 0,
    price: "",
    priceType: "",
    variesEvery: 0,
    priceIsSetBy: "",
  });

  const { scraps } = useSelector((state) => state.scraps);

  const dispatch = useDispatch();

  function submit(e) {
    e.preventDefault();
    dispatch(
      addContract({ ...values, processor }, () => {
        notify("Contract sent to your network for approval");
        dispatch(getContracts());
        let modal = document.getElementById("add-new-scrap");
        modal = M.Modal.getInstance(modal);
        modal.close();
      })
    );
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setValues({ ...values, start, end });
  };

  return (
    <div>
      <b className="bold">Add A Scrap Contract</b>
      <div className="flex p-1 lighten-3 grey rounded flex align-center gap-1">
        <span
          className="material-symbols-outlined grey-text
        black-text"
        >
          info
        </span>
        <p className="small-text grey-text text-darken-2 ">
          This tool enables you to input details for a scrap such as its price
          and duration of validity. These details will automatically appear in
          your pickup requests, saving you time and effort as long as your
          network is in agreement.
        </p>
      </div>

      <div className="mt-1">
        <label>Select Scrap</label>
        <div className="row  ">
          <select
            className="input-style full-width browser-default "
            onChange={(e) => setValues({ ...values, scrap: e.target.value })}
            value={values.scrap}
          >
            <option value={""}>--select--</option>
            {organization.scraps.map((scrap, i) => {
              return (
                <option value={scrap?._id}>
                  {scrap.name}-{scrap.variant}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="mt-1">
        <label className="">Choose Date Range For Price</label>
        <div className="flex align-center gap-1 custom-datepicker">
          <ReactDatePicker
            selected={values.start}
            onChange={onChange}
            startDate={values.start}
            endDate={values.end}
            selectsRange
            minDate={new Date()}
            className="browser-default input-style"
            placeholderText="Select Start And End Date"
          />
          <span className="material-symbols-outlined">calendar_month</span>
        </div>
      </div>

      <div className="mt-1">
        <label className="">Enter Starting Price Of Scrap (Per Kg)</label>
        <div class="row">
          <input
            className="browser-default input-style "
            type="number"
            placeholder="Eg. 10"
            onChange={(e) => setValues({ ...values, price: e.target.value })}
            value={values.price}
          />
        </div>
      </div>

      <div className="mt-1">
        <label>Select Price type</label>
        <div className="row  ">
          <select
            className="input-style full-width browser-default "
            onChange={(e) =>
              setValues({ ...values, priceType: e.target.value })
            }
            value={values.priceType}
          >
            <option value={""}>--select--</option>
            <option value={"fixed"}>
              Fixed - Can't be changed until contract expires
            </option>
            <option value={"variable"}>variable - Can be changed</option>
          </select>
        </div>
      </div>

      {values.priceType === "variable" && (
        <div className="mt-1">
          <label className="">
            Price Change Duration (Price can be change within X days interval)
          </label>
          <div class="row">
            <input
              className="browser-default input-style "
              type="number"
              placeholder="Eg. 7 days"
              onChange={(e) =>
                setValues({ ...values, variesEvery: e.target.value })
              }
              value={values.variesEvery}
            />

            <div className="flex align-center gap-1" style={{ marginTop: 5 }}>
              <span
                className="material-symbols-outlined grey-text"
                style={{ fontSize: 20 }}
              >
                info
              </span>
              <p className="small-text grey-text">
                Enter 0 if you want price to be changed anytime
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="mt-1">
        <label>Price change can be initiated by</label>
        <div className="row  ">
          <select
            className="input-style full-width browser-default "
            onChange={(e) =>
              setValues({ ...values, priceIsSetBy: e.target.value })
            }
            value={values.priceIsSetBy}
          >
            <option value={""}>--select--</option>
            <option value={"generator"}>Only You</option>
            <option value={"processor"}>Only This Trader</option>
            <option value={"both"}>
              All - Everyone can initiate a price change
            </option>
          </select>
        </div>
      </div>

      <div className="purple lighten-5 flex p-1 gap-1 align-center mb-1 rounded">
        <span
          className="material-symbols-outlined grey-text
        cercle-purple-text"
        >
          info
        </span>
        <p className="cercle-purple-text small-text">
          Network will have to accept this contract agreement for it to be
          effective. A request will be sent to your network for approval.
        </p>
      </div>

      <div className="flex justify-end gap-1 ">
        <button
          className="btn-outline purple-text"
          onClick={() => {
            let modal = document.getElementById("add-new-scrap");
            modal = M.Modal.getInstance(modal);
            modal.close();
          }}
        >
          Cancel
        </button>
        <button
          className="btn "
          onClick={submit}
          disabled={
            loading ||
            !values.start ||
            !values.end ||
            !values.scrap ||
            !values.price ||
            !values.priceType ||
            !values.priceIsSetBy
          }
        >
          {loading ? (
            <ClipLoader color={"white"} size={20} />
          ) : (
            "Add Scrap Contract"
          )}
        </button>
      </div>
    </div>
  );
};

export default AddScrapContract;
