import React from 'react'

export default function EditShareholdQA({onCloseEditModal}) {
  return (
    <div className="p-2 questionery-1">
      <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
        <p>How many board meetings were held during the reporting period</p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of Board Meetings"
            />
          </span>
        </div>
      </div>
      <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
        <p>
          What was the overall attendance rate for board meetings during the
          reporting period
        </p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Meeting Attendance Rate"
            />
          </span>
        </div>
      </div>
      <div className="qa-2 mt-1" style={{ fontSize: '18px' }}>
        <p>How many board members were there in the previous year</p>
        <div style={{ position: 'relative' }}>
          <span>
            <input
              className="new-input"
              style={{ width: '30%' }}
              placeholder="Enter the Number of Board Meetings"
            />
          </span>
        </div>
      </div>
      <div className="flex justify-end p-1">
        <button className="btn" onClick={onCloseEditModal}>Save</button>
      </div>
    </div>
  )
}
