import React, { PureComponent, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import { Dropdown } from 'react-materialize'

const data = [
  {
    name: 'Jan',
    waste: 4000,
    water: 2400,
    Co2: 1000,
  },
  {
    name: 'Feb',
    waste: 3000,
    water: 1398,
    Co2: 2010,
  },
  {
    name: 'Mar',
    waste: 2000,
    water: 9800,
    Co2: 6590,
  },
  {
    name: 'Apr',
    waste: 2780,
    water: 3908,
    Co2: 4800,
  },
  {
    name: 'May',
    waste: 1890,
    water: 4800,
    Co2: 5181,
  },
  {
    name: 'Jun',
    waste: 2390,
    water: 3800,
    Co2: 2500,
  },
  {
    name: 'July',
    waste: 3490,
    water: 4300,
    Co2: 2100,
  },
]

export default function EmissionCharts() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })
  const isLaptop = useMediaQuery({ query: '(max-width:1600px)' })

  const [number, setNumber] = useState(3)
  return (
    <div
      className="white p-1"
      style={{
        width: isLaptop ? '100%': '60%',
        height: '350px',
        borderRadius: '10px',
        paddingBottom: '30px',
      }}
    >
      <div className="flex space-between p-1">
        <p className="bold">Emissions Insights</p>
        <Dropdown
          id="monthsDropdown"
          trigger={
            <div
              className="flex align-center"
              style={{
                padding: '10px 6px',
                background: '#FAFAFA',
                borderRadius: '8px',
              }}
            >
              <p
                style={{
                  fontSize: '14px',

                  color: '#263238',
                }}
              >
                Last {number} Months
              </p>
              <span className="material-symbols-outlined cercle-purple-text">
                expand_more
              </span>
            </div>
          }
        >
          <span onClick={() => setNumber(3)}>3 Months</span>
          <span onClick={() => setNumber(6)}>6 Months</span>
          <span onClick={() => setNumber(12)}>1 Year</span>
          <span onClick={() => setNumber(24)}>2 Years</span>
        </Dropdown>
      </div>
      <div className="flex space-between p-1 gap-2" style={{ height: '300px' }}>
        <ResponsiveContainer
          width="100%"
          height="100%"
          style={{ paddingBottom: '40px' }}
        >
          <LineChart width={500} height={260} data={data}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />

            <Line
              type="monotone"
              dataKey="water"
              stroke="#F64E60"
              dot={false}
              strokeWidth={2.5}
            />
            <Line
              type="monotone"
              dataKey="waste"
              stroke="#8950FC"
              dot={false}
              strokeWidth={2.5}
            />
            <Line
              type="monotone"
              dataKey="Co2"
              stroke="#3CD856"
              dot={false}
              strokeWidth={2.5}
            />
          </LineChart>
        </ResponsiveContainer>
        <div className='flex column justify-end' style={{width:'16%',height:'23vh'}}>
        <div>
          <h5 style={{textAlign:'right'}}>3000/MT</h5>
          <span
            style={{ color: '#3CD856'}}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '20%',
                height: '15px',
                width: '16px',
                background: '#3CD856',
              }}
            ></span>
            <p className="grey-text small-text flex" style={{textAlign:'right'}}>Co2 Emissions</p>
          </span>
        </div>

        <div>
          <h5 style={{textAlign:'right'}}>300/MT</h5>
          <span
            style={{ color: '#8950FC' }}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '20%',
                height: '15px',
                width: '16px',
                background: '#8950FC',
              }}
            ></span>
            <p className="grey-text small-text flex">Waste generated</p>
          </span>
        </div>

        <div>
          <h5 style={{textAlign:'right'}}>2 Pickups </h5>
          <span
            style={{ color: '#EF4444' }}
            className="flex align-center gap-1"
          >
            <div></div>
            <span
              style={{
                borderRadius: '20%',
                height: '15px',
                width: '16px',
                background: '#EF4444',
              }}
            ></span>
            <p className="grey-text small-text flex">Water Emissions</p>
          </span>
        </div>
        </div>
      </div>
    </div>
  )
}
