import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { editAppTitle } from "../helpers/helper";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/action/auth";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { useMediaQuery } from "react-responsive";

function Aside({ openAside, setOpenAside }) {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1440px)" });
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { account } = useSelector((state) => state.account);
  const { organization } = useSelector((state) => state.organization);
  const logoutUser = () => {
    dispatch(logoutAction());
  };

  const renderListClasses = (path) => {
    if (typeof path === "object")
      return path.includes(location.pathname) ? "active-aside-items-list" : "";

    return location.pathname === path ? "active-aside-items-list" : "";
  };

  const navigateTo = (path, title) => {
    navigate(path);
    setOpenAside(false);
    editAppTitle(title);
  };

  return (
    <aside className={openAside ? "aside-list-open black" : "aside-list black"}>
      <ul className={openAside ? "aside-items-list-open" : "aside-items-list"}>
        <li
          className={renderListClasses("/")}
          onClick={() => navigateTo("/", "Dashboard")}
          data-tooltip-id="dashboard-tooltip"
          data-tooltip-content="Dashboard"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon target-dashboard">
            dashboard
          </span>
          {!openAside ? <Tooltip id="dashboard-tooltip" /> : null}
          {openAside && <p className="semi-bold">Dashboard</p>}
        </li>

        <li
          className={renderListClasses("/pickups")}
          onClick={() => navigateTo("/pickups", "Pickups")}
          data-tooltip-id="pickup-tooltip"
          data-tooltip-content="Pickup Request"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon target-pickups">
            local_shipping
          </span>
          {!openAside ? <Tooltip id="pickup-tooltip" /> : null}
          {openAside && <p className="semi-bold">Pickup Request</p>}
        </li>

        {organization?.type === "recycler" && (
          <li
            className={renderListClasses(["/inventory", "/create-lot"])}
            onClick={() => navigateTo("/inventory", "Inventory")}
            data-tooltip-id="inventory-tooltip"
            data-tooltip-content="Inventory"
            data-tooltip-place="right"
          >
            <span className="material-symbols-outlined icon ">inventory</span>
            {!openAside ? <Tooltip id="inventory-tooltip" /> : null}
            {openAside && <p className="semi-bold">Inventory</p>}
          </li>
        )}

        <li
          className={renderListClasses("/network")}
          onClick={() => navigateTo("/network", "My Network")}
          data-tooltip-id="network-tooltip"
          data-tooltip-content="My Network"
          data-tooltip-place="right"
        >
          <span class="material-symbols-outlined icon">diversity_3</span>
          <Tooltip id="network-tooltip" />
          {openAside && <p className="semi-bold">My Network</p>}
        </li>

        <li
          className={renderListClasses("/organization")}
          onClick={() => navigateTo("/organization", "Management")}
          data-tooltip-id="organization-tooltip"
          data-tooltip-content="Management"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon ">groups</span>
          <Tooltip id="organization-tooltip" />
          {openAside && <p className="semi-bold">Management</p>}
        </li>

        {/* <li
          className={renderListClasses("/esg")}
          onClick={() => navigateTo("/esg", "ESG")}
          data-tooltip-id="organization-tooltip"
          data-tooltip-content="ESG"
          data-tooltip-place="right"
        >
          <span class="material-symbols-outlined icon">psychiatry</span>
          <Tooltip id="organization-tooltip" />
          {openAside && <p className="semi-bold">ESG</p>}
        </li> */}

        <li
          className={renderListClasses("/plans-billings")}
          onClick={() => navigateTo("/plans-billings", "Plans")}
          data-tooltip-id="plans-tooltip"
          data-tooltip-content="Plans & Billings"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon">receipt_long</span>
          <Tooltip id="plans-tooltip" />
          {openAside && <p className="semi-bold">Plans & Billings</p>}
        </li>

        {/* <li
          className={renderListClasses("/reports")}
          onClick={() => navigateTo("/reports", "Reports")}
          data-tooltip-id="plans-tooltip"
          data-tooltip-content="Reports & Analytics"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon">emergency</span>
          <Tooltip id="plans-tooltip" />
          {openAside && <p className="semi-bold">Risk Assessment</p>}
        </li> */}

        <li
          className={
            location.pathname === "/review-insights"
              ? "active-aside-items-list"
              : ""
          }
          onClick={() => {
            navigate("/review-insights");
            setOpenAside(false);
            editAppTitle("Insights");
          }}
          data-tooltip-id="insights-tooltip"
          data-tooltip-content="Review Insights"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon">reviews</span>
          <Tooltip id="insights-tooltip" />
          {openAside && <p className="semi-bold">Review Insights</p>}
        </li>

        <li
          className={
            location.pathname === "/reports" ? "active-aside-items-list" : ""
          }
          onClick={() => {
            navigate("/reports");
            setOpenAside(false);
            editAppTitle("Reports");
          }}
          data-tooltip-id="reports-tooltip"
          data-tooltip-content="Analytics"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined target-reports icon">
            troubleshoot
          </span>
          <Tooltip id="reports-tooltip" />
          {openAside && <p className="semi-bold">Analytics</p>}
        </li>

        <li
          className={
            location.pathname === "/setting" ? "active-aside-items-list" : ""
          }
          onClick={() => {
            navigate("/setting");
            setOpenAside(false);
            editAppTitle("Settings");
          }}
          data-tooltip-id="setting-tooltip"
          data-tooltip-content="Settings"
          data-tooltip-place="right"
        >
          <span className="material-symbols-outlined icon ">settings</span>
          <Tooltip id="setting-tooltip" />
          {openAside && <p className="semi-bold">Settings</p>}
        </li>
      </ul>

      <div>
        {openAside ? (
          <div className="flex align-center">
            <img
              style={{ width: 30, height: 30 }}
              className="circle"
              src="/images/wasteknot_logo.png"
              alt="logo"
            />
          </div>
        ) : (
          <img src="/images/wasteknot_logo.png" alt="logo" />
        )}
      </div>
    </aside>
  );
}

export default Aside;
