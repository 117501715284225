import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";

const InviteAccepted = () => {
  const token = new URLSearchParams(window.location.search).get("token");
  useEffect(() => {
    if (token) {
      localStorage.setItem("__wasteknot", token);
      window.location.href = "/";
    }
  }, [token]);
  return (
    <div className="flex justify-center align-center full-frame">
      <div className="flex column align-center gap-1">
        <h1 className="bold">Invitation Accepted</h1>
        <p className="text-center">Redirecting...</p>
        <ClipLoader color="#6f2da8" size={20} />
      </div>
    </div>
  );
};

export default InviteAccepted;
