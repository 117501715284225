import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "react-materialize";
import { useMediaQuery } from "react-responsive";
import InviteAccount from "./InviteAccount";
import { Modal } from "react-materialize";
import RModal from "react-responsive-modal";
import {
  getInvitations,
  rescindAccountInvitation,
} from "../../../redux/action/organization";
import { notify } from "../../../helpers/helper";

function Users() {
  const { organization, invitations, loadingAccounts } = useSelector(
    (state) => state.organization
  );
  const { account } = useSelector((state) => state.account);
  const { members } = organization;
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter members based on search query
  const filteredMembers = members.filter(
    (item) =>
      item.account?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.account?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.role?.toLowerCase().includes(searchQuery.toLowerCase())
    // Add other fields as needed
  );

  // Filter invitations based on search query
  const filteredInvitations = invitations.filter(
    (item) =>
      item.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.role?.toLowerCase().includes(searchQuery.toLowerCase())
    // Add other fields as needed
  );

  useEffect(() => {
    dispatch(getInvitations());
  }, [dispatch]);

  return (
    <div>
      <div className="flex space-between align-center mt-1">
        <div className={isTablet ? "" : "full-width"}>
        <h6 className="bold-title mr-1">Account</h6>

        <RModal
          open={open}
          onClose={() => setOpen(false)}
          id="invitation-modal-v2"
          // actions={[]}
          center
          styles={{
            modal: {
              borderRadius: '10px',
              padding: '2rem',
              width: isTablet ? '92%' : '100%',
              position: isTablet ? 'absolute' : null,
              left: isTablet ? 0 : null,
              bottom: isTablet ? 0 : null,
              top: isTablet ? 0 : null
            }
          }}
        >
          <InviteAccount />
        </RModal>
        </div>

        <div style={{ justifyContent: 'flex-end' }} className="flex full-width align-center">
          <div
            className="input-style flex align-center small-text"
            style={{ margin: 0, color: "#BFC3CE", background: "#FAFAFA", width: isTablet ? '100%' : '40%', marginRight: '10px' }}
          >
            <span class="material-symbols-outlined mr-1">search</span>
            <input
              style={{ margin: 0, caretColor: 'black' }}
              placeholder="Search"
              className="hide-focus-underline"
              type="search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <button
            className="btn-small full-width"
            disabled={!account?.permissions?.includes("add-member")}
            onClick={() => setOpen(true)}
            style={{ margin: 0, width: isTablet ? '79%' : 'fit-content' }}
          >
            Add New User
          </button>
        </div>
      </div>

      <div className={isTablet ? 'full-width box mt-1' : "full-width box white mt-1 accounttable"}>
        <table
          className={"striped  payment-history  full-width responsive-table"}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>PHONE NUMBER </th>
              <th>ROLE</th>
              <th>STATUS</th>
              <th>ACTION</th>
            </tr>
          </thead>

          {filteredMembers.length > 0 ? 
          <tbody>
            {filteredMembers.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="capitalize">{item.account.name}</td>

                  <td className="lowercase">{item.account?.email}</td>
                  <td>+{item.account?.phoneNumber}</td>
                  <td className="capitalize">{item.role}</td>
                  <td className="green-text">
                    {item.enabled ? "Active" : "Deactivated"}
                  </td>
                  <td>
                    <Dropdown
                      trigger={
                        <div>
                          <i class="material-symbols-outlined">more_vert</i>
                        </div>
                      }
                    >
                      <span>
                        {item.enabled
                          ? "Deactivate Account"
                          : "Activate Account"}
                      </span>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody> : 
          <tbody>
            {members.filter((item) =>
              !item.account?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
              !item.account?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
              !item.role?.toLowerCase().includes(searchQuery.toLowerCase()) ).slice(0, 1).map((item, index) => (
              <tr>
                <td colSpan={7}>
                  <div className="flex align-center justify-center column">
                    <img className="mb-1" style={{ width: '75px', height: '75px' }} src="../images/no-data.svg"/>
                    <label>No Matching Data Found</label>
                  </div>
                </td>
              </tr>
                  ))}
          </tbody>
          }

          <tbody>
            {invitations.filter(
              (item) =>
                item.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.role?.toLowerCase().includes(searchQuery.toLowerCase())
              // Add other fields as needed
            ).map((item, index) => {
              return (
                <tr key={index}>
                  <td>{members.length + index + 1}.</td>
                  <td className="capitalize">N/A</td>

                  <td className="lowercase">{item.email}</td>
                  <td>N/A</td>
                  <td className="capitalize">{item.role}</td>
                  <td className={item.enabled ? "green-text" : "red-text"}>
                    {item.enabled ? "Active" : "INACTIVE"}
                  </td>
                  <td>
                    <Modal
                      header="Cancel Invitation"
                      id="cancel-invitation"
                      trigger={
                        <div className="flex align-center justify-center">
                          <div
                            style={{
                              width: isTablet ? 'fit-content' : "50%",
                              // textAlign: isTablet ? "center" : 'none'
                            }}
                            className="cancel-invite-btn pointer"
                          >
                              <span style={{ color: "orangered", textTransform: 'none' }}>Cancel Invite</span>
                          </div>
                        </div>
                      }
                      actions={[]}
                    >
                      <p>
                        Are you sure you want to cancel this invitation to{" "}
                        {item.email}?
                      </p>

                      <div className="flex gap-1 mt-1">
                        <Button
                          modal="close"
                          className="btn-small "
                          onClick={() => {
                            dispatch(
                              rescindAccountInvitation(item._id, () => {
                                dispatch(getInvitations());
                                notify("Invitation Cancelled");
                                const elem =
                                  document.getElementById("cancel-invitation");
                                const instance =
                                  window.M.Modal.getInstance(elem);
                                instance.close();
                              })
                            );
                          }}
                          disabled={
                            !account?.permissions?.includes("add-member") ||
                            loadingAccounts
                          }
                        >
                          {" "}
                          Cancel Invite
                        </Button>

                        <Button
                          className="btn-small white cercle-purple-text"
                          modal="close"
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {(members?.length === 0 || !members) && (
          <div className="flex column align-center full-width white  mv-2 p-2">
            <span
              class="material-symbols-outlined grey-text"
              style={{ fontSize: "3rem" }}
            >
              group
            </span>
            <p className="grey-text mt-1 ">
              Currently you don&apos;t have any user.
            </p>

            <p className="grey-text">
              You can invite other colleagues to use this dashboard
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Users;