import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import lotsHistory from "./lotsHistory.json";
import ApexCharts from "react-apexcharts";
import {
  Collapsible,
  CollapsibleItem,
  RadioGroup,
  Switch,
} from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import BlockchainLot from "./BlockchainLot";
import { getRecycledBatches } from "../../../redux/action/pickups";
import QRCode from "react-qr-code";
import {
  convertRGBtoHex,
  generateColor,
  twoDecimals,
} from "../../../helpers/helper";
import PercentageChart from "../components/children/PercentageChart";
import { ClipLoader } from "react-spinners";
import { IWMURL } from "../../../redux/config/urlConfig";

function LotHistory() {
  const { recycledBatches, loadingRecycledBatches } = useSelector(
    (state) => state.pickups
  );
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRecycledBatches());
  }, [dispatch]);

  useEffect(() => {
    let newBatches = recycledBatches.filter((batch) => {
      return batch.batchNumber.toLowerCase().includes(keyword.toLowerCase());
    });

    newBatches = newBatches.map((batch) => {
      const metrics = {
        co2_avoided: 0,
        energy_saved: 0,
        water_saved: 0,
        landfills_saved: 0,
        trees_saved: 0,
        carbon_footPrint: 0,
      };

      let chartData = [
        {
          data: batch.pickups.map((item) => {
            return {
              x: `${item.quantity} MT`,
              y: item.quantity,
            };
          }),
        },
      ];

      let percentageChartData = [];

      batch.pickups.forEach((item, i) => {
        const index = percentageChartData.findIndex(
          (cd) => cd.organization === item.generator?._id
        );

        metrics.co2_avoided += item.co2_avoided;
        metrics.energy_saved += item.energy_saved;
        metrics.water_saved += item.water_saved;
        metrics.landfills_saved += item.landfills_saved;
        metrics.trees_saved += item.trees_saved;
        metrics.carbon_footPrint += item.carbon_footPrint;

        if (index === -1) {
          percentageChartData.push({
            organization: item.generator?._id,
            name: item.generator?.name,
            value: item.quantity,
            color: batch.colors[0],
          });
          return;
        }
        percentageChartData[index].value += item.quantity;
      });

      return {
        ...batch,
        chartData,
        percentageChartData,
        metrics,
      };
    });
    setData(newBatches);
  }, [keyword, recycledBatches]);

  return (
    <div className="p-1">
      <div className="flex space-between align-center">
        <div className="flex gap-1">
          <b className="bold">Recycled Output History</b>

          <ClipLoader
            color={"#6f2da8"}
            loading={loadingRecycledBatches}
            size={20}
          />
        </div>

        <span className="flex align-center gap-1">
          <input
            id="search"
            type="text"
            className="browser-default disable-focus"
            style={{
              borderRadius: "5px",
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
            }}
            placeholder="Search By Batch Code"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            value={keyword}
          />
          <Link to="/create-lot">
            <button className="btn cercle-btn ">Create New Lot</button>
          </Link>
        </span>
      </div>
      <div className="flex mt-1 gap-2 flex-wrap ">
        {/* <BlockchainLot /> */}
        {data.map((item, key) => {
          return (
            <section className="white box " style={{ width: "30vw" }}>
              <article className="flex align-start space-between ">
                <div className="flex align-center gap-1">
                  <div>
                    <QRCode
                      id={`canvas-${item.batchnumber}`}
                      style={{ width: 70, height: 70 }}
                      value={
                        "https://trace.thinktrash.co/product-timeline/" +
                        item.batchnumber
                      }
                    />
                    <p style={{ fontSize: 10 }}>SCAN TO TRACE</p>
                  </div>

                  <div>
                    <p className="icon">
                      {item.scrap?.name}-{item.scrap.variant}
                    </p>
                    <b className="bold cercle-purple-text ">
                      {item.outputMass} MT
                    </b>
                    <p className="small-text grey-text ">
                      {new Date(item.createdAt).toDateString()}
                    </p>
                  </div>
                </div>

                <div className="flex column ">
                  <b
                    className="bold small-text white-text"
                    style={{
                      backgroundColor: item.colors[0],
                      padding: "2px 10px",
                      borderRadius: "2px",
                      marginBottom: "2px",
                    }}
                  >
                    {item.batchNumber}
                  </b>
                  <div className="flex ">
                    {[1, 2, 3, 4, 5].map((item, key) => {
                      return (
                        <span
                          className="material-symbols-outlined cercle-purple-text"
                          style={{ fontSize: "14px" }}
                        >
                          star
                        </span>
                      );
                    })}
                  </div>
                </div>
              </article>

              <Collapsible>
                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width p-1 ">
                      <span className="flex align-center gap-1 ">
                        <b>Lot Quantity Analytics</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                >
                  <div>
                    <ApexCharts
                      options={{
                        chart: { type: "treemap", toolbar: { show: false } },
                        title: { text: "", align: "left", enabled: false },
                        colors: item.colors,
                      }}
                      series={item.chartData}
                      type="treemap"
                      height={100}
                      width={"100%"}
                    />

                    <PercentageChart data={item.percentageChartData} />
                  </div>
                </CollapsibleItem>

                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width p-1">
                      <span className="flex align-center gap-1 ">
                        <b>Mass Balance Analytics</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                >
                  <div className="flex column">
                    <div className=" ">
                      <b className="">Input Material:</b>
                      <p className="normal-text">
                        {item.scrap?.name}-{item.scrap?.variant}
                      </p>
                    </div>

                    <hr color="#efefef" className="mt-1 mb-1" />

                    <div className=" ">
                      <b className="">Input Mass:</b>
                      <p className="normal-text">{item.inputMass} MT</p>
                    </div>

                    <hr color="#efefef" className="mt-1 mb-1" />

                    <div className=" ">
                      <b className="">Output Mass:</b>
                      <p className="normal-text">{item.outputMass} MT</p>
                    </div>

                    {item.lossDetails.length ? (
                      <div>
                        <hr color="#efefef" className="mt-1 mb-1" />
                        <b>Mass Loss Details</b>
                        <table className="responsive-table">
                          <thead>
                            <tr>
                              <td>Loss Description</td>
                              <td>Details</td>
                            </tr>
                          </thead>

                          <tbody>
                            {item.lossDetails.map((item, key) => {
                              return (
                                <tr>
                                  <td>{item.name}</td>
                                  <td>{item.mass} Kg</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                  </div>
                </CollapsibleItem>

                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width p-1">
                      <span className="flex align-center gap-1 ">
                        <b>Attachments and Documents</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                >
                  <div>
                    {item.documents.length ? (
                      <div>
                        <b>Documents</b>
                        <div className="flex flex-wrap gap-1">
                          {item.documents.map((item, key) => {
                            return (
                              <div
                                className="flex align-center gap-1 pointer"
                                style={{
                                  backgroundColor: "#f3f3f3",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                                onClick={() =>
                                  window.open(
                                    `${IWMURL}/recycledbatch/read/document/${item._id}`
                                  )
                                }
                              >
                                <span className="material-symbols-outlined">
                                  insert_drive_file
                                </span>
                                <p className="small-text">{item.name}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div className="flex align-center column">
                        <span className="material-symbols-outlined">
                          insert_drive_file
                        </span>
                        <p className="text-center">
                          No Documents Uploaded For This Batch
                        </p>
                      </div>
                    )}
                  </div>
                </CollapsibleItem>

                <CollapsibleItem
                  header={
                    <div className="flex align-center space-between full-width p-1">
                      <span className="flex align-center gap-1 ">
                        <b>Sustainability Credits Earned</b>
                      </span>

                      <span className="material-symbols-outlined">
                        expand_more
                      </span>
                    </div>
                  }
                  node="div"
                >
                  <div className="flex flex-wrap gap-1 ">
                    <span
                      className="flex  column justify-center align-center p-1 box white "
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/co2.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px",
                        }}
                        alt="co2"
                      />
                      <b>CO2</b>
                      <>{twoDecimals(item.metrics?.co2_avoided)} </>
                    </span>

                    <span
                      className="flex column justify-center align-center p-1 box white "
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/save-water.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px",
                        }}
                        alt="water"
                      />
                      <b>water saved</b>
                      <> {twoDecimals(item.metrics?.water_saved)} </>
                    </span>

                    <span
                      className="flex column justify-center align-center p-1 box white "
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/carbon-footprint.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px",
                        }}
                        alt="carbon"
                      />
                      <b>Carbon FootPrint</b>
                      <p>{twoDecimals(item.metrics?.carbon_footPrint)}</p>
                    </span>

                    <span
                      className="flex column justify-center align-center p-1 box white "
                      style={{ width: "48%" }}
                    >
                      <img
                        src="../images/energy-saving.png"
                        style={{
                          width: "70px",
                          height: "70px",
                          borderRadius: "5px",
                        }}
                        alt="energy"
                      />
                      <b>Energy Saving</b>
                      <>{twoDecimals(item.metrics?.energy_saved)} </>
                    </span>
                  </div>
                </CollapsibleItem>
              </Collapsible>
            </section>
          );
        })}
      </div>
    </div>
  );
}

export default LotHistory;

// <div className="flex mt-1 gap-2 ">
//         {lotsHistory.map((item, key) => {
//           return (
//             <div className="white box " style={{ width: "30vw" }}>
//               <div className="flex align-start space-between ">
//                 <span>
//                   <b>{item.wasteType}</b>
//                   <p className="small-text grey-text ">{item.date}</p>
//                 </span>
//                 <b
//                   className="bold small-text"
//                   style={{
//                     backgroundColor: item.colors[0],
//                     padding: "2px 10px",
//                     borderRadius: "2px"
//                   }}
//                 >
//                   {item.lotCode}
//                 </b>
//               </div>
//               <ApexCharts
//                 options={stackedBarOptions}
//                 series={item.quantityAnalysis}
//                 type="bar"
//                 height={100}
//               />
//               <ApexCharts
//                 options={chartOptions}
//                 series={item.lotData}
//                 type="treemap"
//                 height={"65%"}
//                 width={"100%"}
//               />

//               <div className="mt-1">
//                 <span className=" normal-text flex">
//                   <b>Total Quantity:&nbsp;</b>
//                   <p className="grey-text">{item.totalQuantity}(MT).</p>
//                 </span>
//                 <span className=" normal-text flex">
//                   <b>Status:&nbsp;</b>
//                   <p className="grey-text">{item.status}.</p>
//                 </span>
//               </div>
//               {item.status !== "verified" && (
//                 <>
//                   <hr />
//                   <span className="flex column mt-1 ">
//                     <b>Upload test report</b>

//                     <input type="file" />
//                   </span>
//                 </>
//               )}

//               {!item.testReport && (
//                 <>
//                   <hr />
//                   <span className="flex column mt-1 ">
//                     <b>Verify test report</b>

//                     <span className="flex gap-2 align-center">
//                       <button
//                         className="btn full-width cercle-btn "
//                         style={{
//                           backgroundColor: "#3f51b5"
//                         }}
//                       >
//                         Yes
//                       </button>
//                       <button
//                         className="btn full-width cercle-btn "
//                         style={{
//                           backgroundColor: "tomato"
//                         }}
//                       >
//                         No
//                       </button>
//                     </span>
//                   </span>
//                 </>
//               )}
//             </div>
//           );
//         })}
//       </div>
