import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader, SquareLoader } from "react-spinners";
import VSU from "../components/children/VSUR";
import isEmpty from "lodash/isEmpty";
import {
  createRecycledOutput,
  getInventory,
  getRecycledBatches,
} from "../../../redux/action/pickups";
import PercentageChart from "../components/children/PercentageChart";
import { generateColor, notify } from "../../../helpers/helper";

function CreateLot() {
  const [lotUniqueKey] = useState(Math.random());
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const { inventory, loading, creatingRecycledBatch } = useSelector(
    (state) => state.pickups
  );

  const [scraps, setScraps] = useState([]);
  const [scrap, setScrap] = useState({
    value: "",
    name: "all available scraps",
  });
  const navigate = useNavigate();
  const [outputData, setOutputData] = useState([{ data: [] }]);
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [losses, setLosses] = useState([
    {
      name: "",
      quantty: "",
    },
  ]);

  const [documents, setDocuments] = useState([
    {
      name: "",
      file: "",
    },
  ]);
  const [selectedPickups, setSelectedPickups] = useState([]);

  useEffect(() => {
    if (inventory.length === 0) dispatch(getInventory());
  }, [dispatch, inventory]);

  useEffect(() => {
    let scraps = [{ name: "all available scraps", value: "" }];
    inventory.forEach(({ receivedFrom, items }) => {
      items.forEach((item) => {
        if (scraps.some((scrap) => scrap.value === item.scrap._id)) return;
        scraps.push({
          name: item.scrap.name + "-" + item.scrap.variant,
          value: item.scrap._id,
        });
      });
      return scraps;
    });

    setScraps(scraps);
  }, [inventory]);

  useEffect(() => {
    //handle filter by scrap
    let inventoryData = [...inventory];
    if (scrap.value === "") return setData(inventoryData);
    const newData = inventoryData.filter(({ generator, items }) => {
      return items.some((item) => item.scrap._id === scrap.value);
    });
    setData(newData);
  }, [inventory, scrap]);

  useEffect(() => {
    //handle search by keyword
    let inventoryData = [...inventory];
    if (keyword === "") return setData(inventoryData);
    const newData = inventoryData.filter(({ receivedFrom, items }) => {
      return receivedFrom.name.toLowerCase().includes(keyword.toLowerCase());
    });

    setData(newData);
  }, [inventory, keyword]);

  const [quantityAnalysis, setQuantityAnalysis] = useState([]);

  const totalLosses = losses.reduce(
    (acc, curr) => acc + Number(curr.quantty / 1000),
    0
  );
  const inputMass = quantityAnalysis.reduce((acc, item) => acc + item.value, 0);
  const difference = parseFloat((inputMass - totalLosses).toFixed(3));

  const submit = () => {
    if (selectedPickups.length === 0)
      return notify(
        "Alert",
        "Please select at least one material to recycle!",
        "error"
      );

    const formData = new FormData();
    formData.append("pickups", JSON.stringify(selectedPickups));
    formData.append("quantity", difference ? difference : 0);
    formData.append(
      "losses",
      JSON.stringify(
        losses
          .filter((loss) => loss.name !== "" && loss.quantty !== "")
          .map((loss) => {
            return {
              name: loss.name,
              mass: loss.quantty,
            };
          })
      )
    );

    documents
      .filter((doc) => doc.name !== "" && doc.file !== "")
      .forEach((doc) => {
        formData.append(doc.name, doc.file);
      });

    formData.append("inputMass", inputMass ? inputMass : 0);
    formData.append("outputMass", difference ? difference : 0);
    formData.append("totalLoss", totalLosses ? totalLosses : 0);

    dispatch(
      createRecycledOutput(formData, (res) => {
        dispatch(getRecycledBatches());
        dispatch(getInventory());
        navigate("/inventory#tab_recycled");
      })
    );
  };

  return (
    <div className="p-1">
      <div className="flex space-between align-center mb-1 white lighten-2 p-1">
        <p className="bold">Create Recycling Output</p>

        <div>
          <input
            id="search"
            type="text"
            className="browser-default disable-focus"
            style={{
              borderRadius: "5px",
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
            }}
            placeholder="Search Aggregator or Trader"
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            value={keyword}
          />
        </div>

        <div>
          <select
            className="icons browser-default "
            style={{
              padding: "10px",
              minWidth: "20vw",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
            onChange={(e) => {
              setScrap({
                value: e.target.value,
                name: e.target.selectedOptions[0].text,
              });
            }}
          >
            {scraps.map((scrap, i) => (
              <option value={scrap.value} key={i}>
                {scrap.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="row gap-2 flex ">
        <div className="col  s7">
          <div className="flex-wrap align-center align-center gap-1 flex ">
            {loading ? (
              <div
                style={{ width: "100%", height: "100%" }}
                className="flex align-center justify-center space-between"
              >
                <SquareLoader
                  color={"#6f2da817"}
                  loading={true}
                  css={{ textAlign: "center" }}
                  size={300}
                />
                <SquareLoader
                  color={"#6f2da817"}
                  loading={true}
                  css={{ textAlign: "center" }}
                  size={300}
                />

                <SquareLoader
                  color={"#6f2da817"}
                  loading={true}
                  css={{ textAlign: "center" }}
                  size={300}
                />
              </div>
            ) : data.length === 0 ? (
              <div className=" full-width flex column align-center justify-center p-2 ">
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "100px", color: "#ccc" }}
                >
                  shelves
                </span>
                <p className="grey-text">No New Materials Received So Far</p>
              </div>
            ) : (
              data.map(({ receivedFrom, items }, index) => {
                if (items?.length === 0) return null;

                return items.map((item) => {
                  return (
                    <VSU
                      item={item}
                      organization={receivedFrom}
                      pickups={item.pickups}
                      colors={item.colors}
                      key={index}
                      outputData={outputData}
                      setOutputData={setOutputData}
                      setShow={setShow}
                      setData={setData}
                      data={data}
                      setQuantityAnalysis={setQuantityAnalysis}
                      selectedPickups={selectedPickups}
                      setSelectedPickups={setSelectedPickups}
                    />
                  );
                });
              })
            )}
          </div>
        </div>
        <div
          className="col s5 white box output-container"
          style={{ height: "70vh", overflowY: "auto" }}
        >
          <div className="p-1">
            <div className=" flex align-center space-between">
              <p className="bold">New Recycling Output Details</p>
            </div>
            <PercentageChart data={quantityAnalysis} />

            <fieldset style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
              <legend className="bold">Input Materials</legend>
              {show && (
                <ApexCharts
                  key={lotUniqueKey}
                  options={{
                    chart: {
                      type: "treemap",
                      toolbar: { show: false },
                      events: {
                        click: (
                          event,
                          chartContext,
                          { seriesIndex, dataPointIndex, config }
                        ) => {
                          (() => {})();
                        },
                      },
                    },
                    title: { text: "", align: "left", enabled: false },
                    colors: ["#222"],
                  }}
                  series={outputData}
                  type="treemap"
                  height={150}
                  width={"100%"}
                />
              )}
            </fieldset>

            <fieldset
              className="mt-1"
              style={{ border: "1px solid #ccc", borderRadius: "5px" }}
            >
              <legend className="bold">Associated Document Upload</legend>

              {documents.map((document, i) => (
                <div
                  className="flex border gap-1 full-width align-center"
                  style={{
                    border: "1px solid #ccc",
                    // borderRadius: "5px",
                  }}
                >
                  <input
                    type="text"
                    className="browser-default"
                    style={{
                      padding: "7px 2px",
                      // borderRadius: "5px",
                      borderLeft: "none",
                      borderTop: "none",
                      borderBottom: "none",
                      borderRight: "1px solid #ccc",
                      width: "45%",
                    }}
                    placeholder="Document Name Eg. Invoice"
                    onChange={(e) => {
                      const newDocuments = [...documents];
                      newDocuments[i].name = e.target.value;
                      setDocuments(newDocuments);
                    }}
                    min="0"
                  />

                  <input
                    type="file"
                    className="browser-default"
                    style={{
                      padding: "7px 2px",
                      borderRadius: "5px",
                      border: "none",
                      width: "45%",
                    }}
                    placeholder="Upload Document"
                    onChange={(e) => {
                      const newDocuments = [...documents];
                      newDocuments[i].file = e.target.files[0];
                      setDocuments(newDocuments);
                    }}
                    min="0"
                  />

                  {documents.length > 1 && (
                    <span
                      class="material-symbols-outlined grey-text pointer"
                      onClick={() => {
                        const newDocuments = [...documents];
                        newDocuments.splice(i, 1);
                        setDocuments(newDocuments);
                      }}
                    >
                      cancel
                    </span>
                  )}
                </div>
              ))}

              <div className="flex justify-end">
                <button
                  className="btn-small mt-1"
                  onClick={() => {
                    setDocuments([
                      ...documents,
                      {
                        name: "",
                        file: "",
                      },
                    ]);
                  }}
                >
                  Add Document
                </button>
              </div>
            </fieldset>

            <fieldset
              className="mt-1 mb-1"
              style={{ border: "1px solid #ccc", borderRadius: "5px" }}
            >
              <legend className="bold">Mass Balance Calculation</legend>

              <div className="flex gap-1 align-center space-between mt-1">
                <p className="grey-text">Input Mass:</p>
                <p className="" style={{ fontSize: 16 }}>
                  {inputMass} MT
                </p>
              </div>

              <div className="mt-1">
                <p className=",">Mass Losses:</p>
                <div className="flex column align-end ">
                  {losses.map((loss, i) => (
                    <div
                      className="flex border gap-1 full-width align-center"
                      style={{
                        border: "1px solid #ccc",
                        // borderRadius: "5px",
                      }}
                    >
                      <input
                        type="text"
                        className="browser-default"
                        style={{
                          padding: "7px 2px",
                          // borderRadius: "5px",
                          borderLeft: "none",
                          borderTop: "none",
                          borderBottom: "none",
                          borderRight: "1px solid #ccc",
                          width: "45%",
                        }}
                        placeholder="Loss Name Eg. Transit Loss"
                        onChange={(e) => {
                          const newLosses = [...losses];
                          newLosses[i].name = e.target.value;
                          setLosses(newLosses);
                        }}
                        min="0"
                      />

                      <input
                        type="number"
                        className="browser-default"
                        style={{
                          padding: "7px 2px",
                          borderRadius: "5px",
                          border: "none",
                          width: "45%",
                        }}
                        placeholder="Quantity in Kg's"
                        onChange={(e) => {
                          const newLosses = [...losses];
                          newLosses[i].quantty = e.target.value;
                          setLosses(newLosses);
                        }}
                        min="0"
                      />

                      {losses.length > 1 && (
                        <span
                          class="material-symbols-outlined grey-text pointer"
                          onClick={() => {
                            const newDocuments = [...losses];
                            newDocuments.splice(i, 1);
                            setLosses(newDocuments);
                          }}
                        >
                          cancel
                        </span>
                      )}
                    </div>
                  ))}

                  <button
                    className="btn-small mt-1"
                    onClick={() => {
                      setLosses([
                        ...losses,
                        {
                          name: "",
                          file: "",
                        },
                      ]);
                    }}
                  >
                    Add Loss
                  </button>
                </div>

                <div className="mb-1">
                  <div className="flex full-width space-between mt-1">
                    <p className="grey-text">Total Loss</p>
                    <p style={{ fontSize: 16 }}>
                      {isNaN(totalLosses) ? 0 : totalLosses} MT
                    </p>
                  </div>

                  <div className="flex full-width space-between mt-1">
                    <p className="grey-text">Difference</p>
                    <p>{isNaN(difference) ? 0 : difference} MT</p>
                  </div>
                </div>

                <hr />

                <div className="flex full-width space-between mt-1 mb-1 align-center">
                  <p className="grey-text">Final Recyclable Quantity</p>
                  <p style={{ fontSize: 20, fontWeight: "bolder" }}>
                    {isNaN(difference) ? 0 : difference} MT
                  </p>
                </div>
              </div>
            </fieldset>

            <div className="  ">
              <button className="btn green " onClick={submit}>
                {creatingRecycledBatch ? (
                  <ClipLoader
                    color={"#fff"}
                    loading={true}
                    css={{ textAlign: "center" }}
                    size={20}
                  />
                ) : (
                  "Create Recycled Batch"
                )}
              </button>
              <button
                className="btn white cercle-purple-text "
                onClick={() => setShow(show ? false : true)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateLot;
