import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { IWMURL } from "../../../redux/config/urlConfig";
import { useEffect } from "react";
import { getAllScraps } from "../../../redux/action/scraps";
import { addScraps } from "../../../redux/action/organization";
import { useMediaQuery } from "react-responsive";

const AddMoreScraps = ({ setOpen, account }) => {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { scraps } = useSelector((state) => state.scraps);
  // console.log(scraps, 'scraps');
  const { loading, organization } = useSelector((state) => state.organization);
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  // console.log(selected, 'selectedScrap');

  function submit(e) {
    e.preventDefault();
    dispatch(
      addScraps({ scraps: selected }, () => {
        setOpen && setOpen(false);
      })
    );
  }

  const filtered = scraps.filter(
    (scrap) =>
      scrap.name.toLowerCase().includes(search.toLowerCase()) ||
      scrap.variant.toLowerCase().includes(search.toLowerCase())
  );

  useEffect(() => {
    if (organization.scraps)
      setSelected([...organization.scraps.map((s) => s._id)]);
  }, [organization.scraps]);

  useEffect(() => {
    dispatch(getAllScraps());
  }, [dispatch]);

  return (
    <div className={isTablet ? "flex flex-wrap" : "flex "} mt-2 style={{}}>
      <form
        className="flex column align-center space-between full-width"
        onSubmit={submit}
      >
        <div className="full-height full-width">
          <div className="flex align-center">
            <div
              className="input-style flex align-center"
              style={{
                width: "40%",
                padding: "0 10px",
                borderRadius: "15px",
                height: "40px",
                border: "1px solid #ccc",
              }}
            >
              <span className="material-symbols-outlined grey-text mr-1">
                search
              </span>
              <input
                className="browser-default "
                type="text"
                placeholder="Search for scraps..."
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
            <button
              className="btn-small ml-1 green"
              type="submit"
              disabled={
                loading || selected.length === organization.scraps?.length
              }
            >
              {loading ? <ClipLoader size={20} color="#fff" /> : "Next"}
            </button>
          </div>

          <div
            // style={{ height: "85vh", overflowY: "auto" }}
            className="mt-1 full-width flex flex-wrap"
          >
            {scraps.length === 0 ? (
              <p className="grey-text text-center full-width">
                No scraps found
              </p>
            ) : (
              // filtered.map((scrap, i) => {
              //   console.log(scrap, 'scrap');
              //   return (
              //     <div
              //       key={i}
              //       className={
              //         selected.includes(scrap._id)
              //           ? "scrap-selected"
              //           : "scrap-select"
              //       }
              //       role="button"
              //       onClick={() => {
              //         if (selected.includes(scrap._id))
              //           setSelected(selected.filter((s) => s !== scrap._id));
              //         else setSelected([...selected, scrap._id]);
              //       }}
              //       style={{ position: 'relative' }}
              //     >
              //       <img
              //         src={`${IWMURL}/scraps/read/image/${scrap._id}`}
              //         alt="scrap"
              //         style={{ height: '106.47' }}
              //       />
              //       <div className="align-center center">
              //         <p className="cercle-purple-text">{scrap.name}</p>
              //         <p className="small-text">{scrap.variant}</p>
              //       </div>
              //       {selected.includes(scrap._id) ? <div className="cercle-purple circle flex align-center justify-center check-icon">
              //         <span class="material-symbols-outlined white-text small-text">done</span>
              //       </div> :
              //       // <div className="check-icon circle" style={{ border: '2px solid #6F2DA8' }}/>
              //       null}
              //     </div>
              //   );
              // })

              <div
                className={
                  isTablet
                    ? "full-width box white mt-1 scrapstable-isMobile"
                    : "full-width box white scrapstable"
                }
                style={{ height: "85vh", overflowY: "auto" }}
              >
                <div
                  className={
                    isTablet
                      ? "flex flex-wrap space-between"
                      : "flex flex-wrap "
                  }
                  style={{ gap: isTablet ? null : "0.67rem" }}
                >
                  {filtered.map((item, index) => (
                    <div
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        border: selected.includes(item._id) ? "1px solid #6F2DA8" : "1px solid #D0D5DD",
                        position: "relative",
                      }}
                      className="flex column align-center center mt-1 ml-1 mr-1 mb-1"
                      key={index}
                      onClick={() => {
                        if (selected.includes(item._id))
                          setSelected(selected.filter((s) => s !== item._id));
                        else setSelected([...selected, item._id]);
                      }}
                    >
                      <img src={`${IWMURL}/scraps/read/image/${item._id}`} />
                      <div className="">
                        <p
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                          className="cercle-purple-text"
                        >
                          {item?.name}
                        </p>
                        <label className="grey-text text-darken-1 scrapstable-variant">
                          {item.variant}
                        </label>
                      </div>
                      {selected.includes(item._id) ? (
                        <span className="cercle-purple circle flex align-center justify-center check-icon">
                          <span class="material-symbols-outlined white-text small-text">
                            done
                          </span>
                        </span>
                      ) : // <div className="check-icon circle" style={{ border: '2px solid #6F2DA8' }}/>
                      null}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>

      <SideBar
        setSelected={setSelected}
        selected={selected}
        loading={loading}
        hideOnSmallDevices={true}
        account={account}
      />
    </div>
  );
};

const SideBar = ({ selected, setSelected, loading, hideOnSmallDevices, account }) => {
  const { scraps } = useSelector((state) => state.scraps);
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  // console.log(selected, 'selected selected');
  return (
    <div
      className={`ml-2`}
      style={{ width: isTablet ? "" : "30%", height: "60vh" }}
    >
      <div className="flex align-center gap-1 ">
        <p>Selected Scraps </p>
        <ClipLoader size={20} color="#6f2da8" loading={loading} />
      </div>

      <div
        className={
          isTablet
            ? "first-time-aside-list-container flex flex-wrap"
            : "first-time-aside-list-container full-width"
        }
      >
        {!selected.length ? (
          <div
            className="flex justify-center column align-center  p-2 "
            style={{ height: "70%" }}
          >
            <i
              className="material-symbols-outlined grey-text"
              style={{ fontSize: 40 }}
            >
              info
            </i>
            <p className="grey-text small-text text-center">
              No Scraps Selected
            </p>
          </div>
        ) : (
          selected.map((scrap, index) => (
            <div
              className={"grey lighten-4 full-width p-1 flex column align-end"}
              style={{ borderBottom: "5px solid #fff" }}
              key={index}
            >
              <div className="full-width  space-between">
                <div className="flex ">
                  <img
                    src={`${IWMURL}/scraps/read/image/${scrap}`}
                    alt="scrap"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />

                  <div>
                    <p>{scraps.find((s) => s._id === scrap)?.name}</p>
                    <p className="small-text">
                      {scraps.find((s) => s._id === scrap)?.variant}
                    </p>
                  </div>
                </div>

                {account?.permissions?.includes("remove-scraps") ? 
                (<button
                  className="btn-small white purple-text mt-1"
                  onClick={() => {
                    if (selected.includes(scrap))
                      setSelected(selected.filter((s) => s !== scrap));
                    else setSelected([...selected, scrap]);
                  }}
                >
                  Remove
                </button>)
                 : (null)}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AddMoreScraps;
