import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts'
import { useMediaQuery } from 'react-responsive'

const data = [
  { name: 'Natural gas', value: 800 },
  { name: 'solar', value: 500 },
  { name: 'wind', value: 300 },
  { name: 'Biomass', value: 600 },
]

const COLORS = ['#00C49F', '#0088FE', '#FFBB28', '#FF8042']

const CandidatesChart = () => {
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div
      className="white p-1"
      style={{
        width: '33%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <h5 style={{ textAlign: 'center' }}>% Total Succession Candidates by Level </h5>

      <div className="flex">
        <div
          className="flex column gap-2"
          style={{
            position: 'absolute',
            bottom: '80px',
            right: '40px',
            textAlign: 'right',
          }}
        >
          <div>
            <p
              style={{
                color: 'grey',
                fontSize: isLaptop ? '16px' : '18px',
                display: isLaptop ? 'none' : '',
              }}
            >
              Number of Members
            </p>
            <h5 className={isLaptop ? 'small-text' : ''}>10</h5>
          </div>
          <div>
            <p
              style={{
                color: 'grey',
                fontSize: isLaptop ? '16px' : '18px',
                display: isLaptop ? 'none' : '',
              }}
            >
             Succession Readiness Level
            </p>
            <h5 className={isLaptop ? 'small-text' : ''}>70%</h5>
          </div>
        </div>
        <div style={{ width: '100%', display: 'flex' }}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Tooltip formatter={(value, name) => [value, name]} />
              <Pie
                data={data}
                cx="30%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    style={{ outline: 'none' }}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default CandidatesChart
