import React, { useState } from "react";
import { ComponentHeader } from "../../../modules";
import { useMediaQuery } from "react-responsive";
import Modal from "react-responsive-modal";
import { useLocation } from 'react-router-dom';
import ReviewDetails from "./ReviewDetails";

const REVIEWHISTORYDATA = [
  {
    id: 1,
    pickupDate: "MON OCT 30 2023",
    rating: "4",
    contaminationScore: "4",    
    segregationScore: "2",
    remark: 'Waste not properly segregated'
  },
  {
    id: 2,
    pickupDate: "MON OCT 30 2023",
    rating: "3",
    contaminationScore: "2",    
    segregationScore: "4",
    remark: 'Contamination is high'
  },
  {
    id: 3,
    pickupDate: "MON OCT 30 2023",
    rating: "3",
    contaminationScore: "4",    
    segregationScore: "2",
    remark: 'Waste not properly segregated'
  },
  {
    id: 4,
    pickupDate: "MON OCT 30 2023",
    rating: "3",
    contaminationScore: "2",    
    segregationScore: "4",
    remark: 'Contamination is high'
  },
  {
    id: 5,
    pickupDate: "MON OCT 30 2023",
    rating: "4",
    contaminationScore: "4",    
    segregationScore: "2",
    remark: 'Waste not properly segregated'
  },
];

const OverallReviewHistoryTable = () => {
  const location = useLocation();

  const isLaptop = useMediaQuery({ query: "(max-width: 1440px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [openPRD, setOpenPRD] = useState(false);
  const onOpenPRDModal = () => setOpenPRD(true);
  const onClosePRDModal = () => setOpenPRD(false);

  const images = new Array(3).fill(null).map((_, index) => (
    <img
      key={index} // Important for performance
      src={'https://whatdesigncando.s3.eu-central-1.amazonaws.com/app/uploads/20210120101404/iStock-927987734-1440x959.jpg'}
      style={{ width: "90px", height: "100px", borderRadius: "6px" }}
      className="mr-1"
    />
  ));

  const [ details, setDetails ] = useState('');

  const viewOnClick = (value) => {
    setDetails(value)
    onOpenPRDModal()
  };

  return (
    <section className="p-1">
      <ComponentHeader title="Review History" />

      <div className="box white mt-1">
        <table
          className={
            isMobile
              ? "striped responsive-table "
              : "striped responsive-table dashboardtable"
          }
        >
          <thead>
            <tr className="table-header-row">
              <th>Pickup Date</th>
              <th>Rating</th>
              <th>Contamination Score</th>
              <th>Segregation Score</th>
              <th>Remark</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {REVIEWHISTORYDATA.map((item, index) => {
              return (
                <tr key={index} className="tableHover">
                  <td>{item.pickupDate}</td>
                  <td>{item.rating}</td>
                  <td>{item.contaminationScore}</td>
                  <td>{item.segregationScore}</td>
                  <td style={{ textTransform: "none" }}>{item.remark}</td>
                  <td
                    onClick={() => {
                      viewOnClick(item);
                    }}
                  >
                    <span class="material-symbols-outlined grey-text text-darken-1">
                      visibility
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {(REVIEWHISTORYDATA?.length === 0 || !REVIEWHISTORYDATA) && (
          <div className="flex column align-center full-width  mv-2 p-2">
            <span
              class="material-symbols-outlined grey-text"
              style={{ fontSize: "5rem" }}
            >
              fire_truck
            </span>
            <p className="grey-text mt-1 ">
              Currently you don’t have any pickups.
            </p>
          </div>
        )}

        <Modal
          center
          open={openPRD}
          onClose={onClosePRDModal}
          closeIcon={<></>}
          styles={{
            modal: {
              borderRadius: "10px",
              padding: 0,
              width: isMobile ? "92%" : "",
              position: isMobile ? "absolute" : null,
              left: isMobile ? 0 : null,
            },
          }}
        >
          <ReviewDetails details={details} images={images} onClosePRDModal={onClosePRDModal}/>
        </Modal>
      </div>
    </section>
  );
};

export default OverallReviewHistoryTable;
