import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { calculateTotal } from "../helpers/helper";
import { ClipLoader } from "react-spinners";

function PickupHistoryTable() {
  const navigate = useNavigate();
  const { networkHistory, networkHistoryLoading } = useSelector(
    (state) => state.pickups
  );

  return (
    <div className="box  full-width white ">
      <div className="flex ph-1  align-center space-between ">
        <b className="bold">Pickups History</b>
        <div className="flex">
          {networkHistoryLoading && <ClipLoader color="#6f2da8" size={20} />}
          <Link to="/pickups-history" className="cercle-purple-text ml-1">
            View All
          </Link>
        </div>
      </div>

      <table className="striped  full-width responsive-table dashboardtable">
        <thead>
          <tr>
            <th>Pickup Date </th>
            <th>Processor</th>
            <th>Price</th>
            <th>GST</th>
            <th>Total</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {networkHistory.slice(-4).map((item, index) => {
            return (
              <tr key={index} className="pointer" onClick={() => navigate("/pickup/" + item._id)}>
                <td>{new Date(item.date).toDateString()}</td>
                <td>{item.contradictingOrg.name}</td>
                <td>Rs. {item.price}/kg</td>
                <td>N/A</td>
                <td>Rs.{calculateTotal(item.price, item.quantity)}</td>

                <td>
                  <div>
                    <span
                      className={`${(() => {
                        let colors = {
                          pending: "orange-text",
                          completed: "green-text",
                          assigned: "blue-text",
                          rejected: "red-text",
                          cancelled: "red-text",
                        };
                        return colors[item.status];
                      })()}`}
                    >
                      {item.status}
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {(networkHistory?.length === 0 || !networkHistory) && (
        <div className="flex column align-center full-width  mv-2 p-2">
          <span
            className="material-symbols-outlined grey-text"
            style={{ fontSize: "5rem" }}
          >
            fire_truck
          </span>
          <p className="grey-text mt-1 ">
            Currently you don’t have any pickups.
          </p>
        </div>
      )}
    </div>
  );
}

export default PickupHistoryTable;
