import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IWMURL } from "../../../redux/config/urlConfig";
import { removeScraps } from "../../../redux/action/organization";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import AddMoreScraps from "./AddMoreScraps";
import Modal from "react-responsive-modal";
import { useState } from "react";
import { Link } from "react-router-dom";

const Scraps = () => {
  const { organization } = useSelector((state) => state.organization);
  const { account } = useSelector((state) => state.account);
  console.log(account?.permissions, "account?.permissions");
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  console.log(account.permissions);

  const filteredScraps = organization.scraps?.filter((item) =>
    `${item?.name}-${item.variant}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        classNames={{
          modal: "",
        }}
        styles={{
          modal: {
            borderRadius: "10px",
            width: isTablet ? "92%" : "100%",
            position: isTablet ? "absolute" : null,
            left: isTablet ? 0 : null,
          },
        }}
      >
        <AddMoreScraps account={account} />
      </Modal>
      <div className="flex mt-1 space-between align-center">
        <h5>Scraps</h5>

        <div
          style={{ justifyContent: "flex-end" }}
          className="flex full-width align-center gap-1"
        >
          <div
            className="input-style flex align-center small-text"
            style={{
              margin: 0,
              color: "#BFC3CE",
              background: "#FAFAFA",
              width: "20%",
              marginRight: "10px",
            }}
          >
            <span class="material-symbols-outlined mr-1">search</span>
            <input
              style={{ margin: 0, caretColor: "black" }}
              placeholder="Search"
              className="hide-focus-underline"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Link
            className="btn-small mt-1"
            to="/new-add-scrap"
            disabled={!account.permissions.includes("add-scraps")}
            style={{ margin: 0, top: "19.4rem", right: "1.5rem" }}
          >
            Add More Scraps
          </Link>
        </div>
      </div>

      <div
        className={
          isTablet
            ? "full-width box white mt-1 scrapstable-isMobile"
            : "full-width box white mt-1 scrapstable"
        }
      >
        {filteredScraps.length > 0 ? (
          <div
            className={
              isTablet ? "flex flex-wrap space-between" : "flex flex-wrap p-2"
            }
            style={{ gap: isTablet ? null : "0.67rem" }}
          >
            {filteredScraps.map((item, index) => (
              <div
                style={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  border: "1px solid #6F2DA8",
                  position: "relative",
                }}
                className="flex column align-center center mt-1 ml-1 mr-1 mb-1"
                key={index}
              >
                <img
                  src={`${IWMURL}/scraps/read/image/${item._id}`}
                  alt="Scrap"
                />
                <div className="">
                  <p
                    style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    className="cercle-purple-text"
                  >
                    {item?.name}
                  </p>
                  <label className="grey-text text-darken-1 scrapstable-variant">
                    {item.variant}
                  </label>
                </div>
                {account.permissions.includes("remove-scraps") ? (
                  <span
                    onClick={() => {
                      dispatch(
                        removeScraps(item._id, () => {
                          toast.success("Scrap deleted successfully", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            hideProgressBar: true,
                            autoClose: 3000,
                          });
                        })
                      );
                    }}
                    class="material-symbols-outlined red-text small-text check-icon pointer"
                  >
                    delete
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          organization.scraps
            ?.filter(
              (item) =>
                !`${item?.name}-${item.variant}`
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
            .slice(0, 1)
            .map((item, index) => (
              <div className="flex align-center justify-center column full-width p-1">
                <img
                  className="mb-1"
                  style={{ width: "75px", height: "75px" }}
                  src="../images/no-data.svg"
                  alt="No Data"
                />
                <label>No Matching Data Found</label>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default Scraps;
