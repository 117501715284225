import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useMediaQuery } from 'react-responsive'

import EditEnvironmentalCard from '../../../Environmental/components/EditEnvironmentalCard'
import EditEmpAdditionQA from './EditEmpAdditionQA'
import EditAgeDistributionQA from './EditAgeDistributionQA'
import EditEmpSatisfactionQA from './EditEmpSatisfactionQA'
import EditInjuriesQA from './EditInjuriesQA'
import EditSpendingQA from '../../../Impact/components/EditImpact/EditSpendingQA'
import EditGoalsQA from '../../../Impact/components/EditImpact/EditGoalsQA'
import EditSaplingsQA from '../../../Impact/components/EditImpact/EditSaplingsQA'
import EditCamplingQA from '../../../Impact/components/EditImpact/EditCamplingQA'
import EditEmpDistributionQA from './EditEmpDistributionQA'

export default function EditSocialCard() {
  const [openEditModal, setOpenEditModal] = useState(false)
  const [selectedComponent, setSelectedComponent] = useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })

  const [qu1Visible, setQu1Visible] = useState(false)
  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const [qu4Visible, setQu4Visible] = useState(false)
  const [qu5Visible, setQu5Visible] = useState(false)
  const handleCardClick = (name) => {
    setQu1Visible(name === 'emp-addi')
    setQu2Visible(name === 'emp-dis')
    setQu3Visible(name === 'emp-satis')
    setQu4Visible(name === 'injury-no')
    setQu5Visible(name === 'age-dis')
    onOpenEditModal()

    setSelectedComponent(name)
  }
  const onOpenEditModal = () => {
    setOpenEditModal(true)
  }
  const onCloseEditModal = () => setOpenEditModal(false)

  return (
    <>
      {!qu1Visible && !qu2Visible && !qu3Visible && !qu4Visible && !qu5Visible && (
        <div className="flex column gap-2 p-1 card-section">
          <div className="flex gap-2">
            <div
              className="full-width"
              onClick={() => handleCardClick('spending')}
            >
              <EditEnvironmentalCard
                name="spending"
                image="../images/empaddi.png"
                icon="../images/emp.png"
                title="Employee Addition"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
            <div
              className="full-width"
              onClick={() => handleCardClick('goals')}
            >
              <EditEnvironmentalCard
                name="goals"
                image="../images/empdis.png"
                icon="../images/ic-empdis.png"
                title="Employee Distribution"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>

            <div
              className="full-width"
              onClick={() => handleCardClick('sapling')}
            >
              <EditEnvironmentalCard
                name="sapling "
                image="../images/empsatis.png"
                icon="../images/ic-empstatis.png"
                title="Employee Satisfaction"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
          </div>
          <div className="flex gap-2">
            <div
              style={{ width: '33%' }}
              onClick={() => handleCardClick('campaings')}
            >
              <EditEnvironmentalCard
                name="campaings"
                image="../images/awareness.png"
                icon="../images/injuries.png"
                title="Awareness Campaigns"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
            <div
              style={{ width: '33%' }}
              onClick={() => handleCardClick('campaings-1')}
            >
              <EditEnvironmentalCard
                name="campaings-1"
                image="../images/agedis.png"
                icon="../images/emp.png"
                title="Age Distribution"
                desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
              />
            </div>
          </div>
        </div>
      )}

      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openEditModal}
        onClose={onCloseEditModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          <div className="flex align-center gap-1">
          {!selectedComponent && <h4>Edit Social Data</h4>}
            {selectedComponent === 'spending' && <h4>Edit Employee Addition</h4>}
            {selectedComponent === 'goals' && <h4>Edit Employee Distribution</h4>}
            {selectedComponent === 'sapling' && <h4>Edit Employee Satisfaction</h4>}
            {selectedComponent === 'campaings' && <h4>Edit Awareness Campaigns</h4>}
            {selectedComponent === 'campaings-1' && <h4>Edit Age Distribution</h4>}
          </div>

          <div
            onClick={onCloseEditModal}
            className="flex align-center justify-center"
            style={{
              width: 40,
              height: 40,
              background: 'rgba(255, 255, 255, 0.50)',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
          >
            <span class="material-symbols-outlined">close</span>
          </div>
        </div>
        {selectedComponent === 'spending' && (
          // <EditSpendingQA onCloseEditModal={onCloseEditModal} />
          <EditEmpAdditionQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'goals' && (
          // <EditGoalsQA onCloseEditModal={onCloseEditModal} />
          <EditEmpDistributionQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'sapling' && (
          // <EditSaplingsQA onCloseEditModal={onCloseEditModal} />
          <EditEmpSatisfactionQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'campaings' && (
          // <EditCamplingQA onCloseEditModal={onCloseEditModal} />
          <EditCamplingQA onCloseEditModal={onCloseEditModal} />
        )}
        {selectedComponent === 'campaings-1' && (
          // <EditCamplingQA onCloseEditModal={onCloseEditModal} />
          <EditAgeDistributionQA onCloseEditModal={onCloseEditModal} />
        )}
      </Modal>
    </>
  )
}
