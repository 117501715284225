import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ClipLoader } from "react-spinners";
import {
  ComposedChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div style={{ borderRadius: "9px" }} className="custom-tooltip black p-1">
        <p className="white-text">{`${payload[0].value} Tons`}</p>
      </div>
    );
  }

  return null;
};

const data = [
  { name: "Jan", totalwaste: 20 },
  { name: "Feb", totalwaste: 13 },
  { name: "Mar", totalwaste: 30 },
  { name: "Apr", totalwaste: 27 },
  { name: "May", totalwaste: 82 },
  { name: "Jun", totalwaste: 22 },
  { name: "Jul", totalwaste: 38 },
  { name: "Aug", totalwaste: 27 },
  { name: "Sep", totalwaste: 30 },
  { name: "Oct", totalwaste: 17 },
  { name: "Nov", totalwaste: 90 },
  { name: "Dec", totalwaste: 80 },
];

const BarGraph = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const { overview, overviewLoading } = useSelector((state) => state.analytics);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    if (!overview.quantityForEachMonth) return;
    setData(overview.quantityForEachMonth);
  }, [overview.quantityForEachMonth]);

  return (
    <div
      style={{ width: isTablet ? "100%" : "49.30%" }}
      className={isTablet ? "white box mt-2" : "white box"}
    >
      <div className="flex space-between">
        <div>
          <b className="chart-header bold">Overview</b>
          <p className="grey-text">Monthly Waste Quantity</p>
        </div>

        <ClipLoader color={"#6f2da8"} loading={overviewLoading} size={10} />
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <XAxis dataKey="month" />
          <Tooltip
            content={<CustomTooltip />}
            itemStyle={{ color: "#6F2DA8" }}
            cursor={false}
          />
          {/* <Legend /> */}
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="14"
              y1="181"
              x2="14"
              y2="6.57961e-08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DEB8FF" />
              <stop offset="1" stop-color="#E6EDFF" stop-opacity="0" />
            </linearGradient>
          </defs>
          <Bar
            className="colored-bar"
            radius={isTablet ? 5 : 8}
            dataKey="quantity"
            fill="#ECE4F4"
            barSize={isTablet ? 20 : 32.5}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarGraph;
