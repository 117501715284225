import React from 'react'
import GaugeComponent from 'react-gauge-component'
import { useMediaQuery } from 'react-responsive'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'

const data = [
  {
    // name: 'Page A',
    Email: 60,
    Surveys: 40,
    Calls: 20,
  },
]

const percentage = 80

const ShareHolderChart = () => {
  const isLaptop = useMediaQuery({ query: '(max-width: 1440px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const colorForPercentage = (percent) => {
    return percent <= percentage ? '#6F2DA8' : '#ECE4F4'
  }

  return (
    <div
      className=" white p-1 flex space-between gap-2 "
      style={{
        width: isMobile ? '100%' : '35%',
        height: '350px',
        position: 'relative',
        borderRadius: '12px',
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        margin: 'auto',
      }}
    >
      <div style={{position:'relative'}}>
      <div>
        <div className="flex space-between">
          <p className="bold ml-1">Shareholder Engagement</p>
        </div>

        <div
          style={{
            width: isLaptop ? '100%' : '80%',
            display: 'flex',
            justifyContent: '',
            position: 'absolute',
            bottom: 80,
            left: 20,
          }}
        >
          <GaugeComponent
            type="semicircle"
            arc={{
              colorArray: [colorForPercentage(percentage), '#ECE4F4'],
              padding: 0,
              subArcs: [{ limit: percentage }],
            }}
            pointer={{ type: 'blob', animationDelay: 0 }}
            value={percentage}
          />
        </div>
      </div>
      <div className='full-width' style={{position:'absolute',top:230,left:80,color:'#263238'}}>
      <h4>Meeting Attendance</h4>
      </div>
   </div>
      <div style={{ width: '40%', height: '320px', marginBottom: '20px' }}>
        <p className="bold" style={{ textAlign: 'center' }}>
          Proposal
        </p>
        <ResponsiveContainer width="70%" height="80%">
          <BarChart
            width={10}
            height={10}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <YAxis
              tick={{ fontSize: 10 }}
              tickFormatter={(value) => `${value}%`}
            />

            <Tooltip />

            <Bar dataKey="Email" stackId="a" fill="#E23A45" />
            <Bar dataKey="Surveys" stackId="a" fill="#FF9F00" />
            <Bar dataKey="Calls" stackId="a" fill="#407BFF" />
          </BarChart>
          <div className="flex gap-1">
            <span
              style={{ color: '#407BFF' }}
              className="flex align-center gap-1"
            >
              <span
                style={{
                  borderRadius: '50%',
                  height: '10px',
                  width: '10px',
                  background: '#407BFF',
                }}
              ></span>
              <p>Email</p>
            </span>

            <span
              style={{ color: '#FF9F00' }}
              className="flex align-center gap-1"
            >
              <span
                style={{
                  borderRadius: '50%',
                  height: '10px',
                  width: '10px',
                  background: '#FF9F00',
                }}
              ></span>
              <p>Surveys</p>
            </span>
            <span
              style={{ color: '#E23A45' }}
              className="flex align-center gap-1"
            >
              <span
                style={{
                  borderRadius: '50%',
                  height: '10px',
                  width: '10px',
                  background: '#E23A45',
                }}
              ></span>
              <p>Calls</p>
            </span>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ShareHolderChart
