import React, { useState } from 'react'
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { useMediaQuery } from 'react-responsive'
import { Dropdown } from 'react-materialize'

const data = [
  { name: 'Jan', Women: 4000, Men: 2400 },
  { name: 'Feb', Women: 3000, Men: 1398 },
  { name: 'Mar', Women: 9000, Men: 9800 },
  { name: 'Apr', Women: 2780, Men: 3908 },
  { name: 'May', Women: 1890, Men: 4800 },
]

function EmployeeAdditionChart() {
  const [number, setNumber] = useState(3)
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  return (
    <div
      className="box white full-width"
      style={{ borderRadius: '12px', width: isMobile ? '100%':'40%', height: '35%' }}
    >
      <div
        className={
          isMobile
            ? 'flex column gap-1'
            : 'flex space-between align-center mb-1'
        }
      >
        <h5>Employee Addition</h5>
        <div className="flex gap-2 align-center">
          <div className="flex align-center gap-1">
            <div className="flex align-center gap-1">
              <span
                style={{
                  width: '10px',
                  height: '5px',
                  borderRadius: '50%',
                  background: '#6F2DA8',
                  padding: '5px',
                }}
              ></span>
              <p>Men</p>
            </div>
            <div className="flex align-center gap-1">
              <span
                style={{
                  width: '10px',
                  height: '5px',
                  borderRadius: '50%',
                  background: '#FF9500',
                  padding: '5px',
                }}
              ></span>
              <p>Women</p>
            </div>
          </div>
          <Dropdown
            id="monthsDropdown"
            trigger={
              <div className="flex align-center">
                <p
                  className="cercle-purple-text"
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Last {number} Months
                </p>
                <span className="material-symbols-outlined cercle-purple-text">
                  arrow_drop_down
                </span>
              </div>
            }
          >
            <span onClick={() => setNumber(3)}>3 Months</span>
            <span onClick={() => setNumber(6)}>6 Months</span>
            <span onClick={() => setNumber(12)}>1 Year</span>
            <span onClick={() => setNumber(24)}>2 Years</span>
          </Dropdown>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={230}>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar
            radius={10}
            dataKey="Men"
            fill="url(#colorUv)"
            barSize={32.5}
          />
          <Line
            type="monotone"
            dataKey="Women"
            stroke="#FF9500"
            strokeWidth={2}
            dot={false}
          />
          <Line
            type="monotone"
            dataKey="amt"
            stroke="#6F2DA8"
            strokeWidth={2}
            dot={false}
          />
          <defs>
            <linearGradient
              id="colorUv"
              x1="14"
              y1="181"
              x2="14"
              y2="6.57961e-08"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DEB8FF" />
              <stop offset="1" stopColor="#E6EDFF" stopOpacity="0" />
            </linearGradient>
          </defs>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default EmployeeAdditionChart
