import React, { useEffect } from "react";
import { IWMURL } from "../../../redux/config/urlConfig";
import { useNavigate } from "react-router-dom";

function OrderBox({ order, setOrder, selectedOrder }) {
  const navigate = useNavigate();

  const totalQuantity = order.pickups.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  );

  return (
    <div
      className={`order-box ${
        selectedOrder._id === order._id
          ? "order-box-selected green lighten-5"
          : ""
      }`}
      onClick={() => {
        setOrder(order);
        document.getElementById(order._id).scrollIntoView();
      }}
      id={order._id}
    >
      <div className="">
        <div className="flex align-center gap-1 space-between">
          <div className="flex align-start  full-width space-between">
            <div className="flex align-center gap-1">
              {" "}
              <img
                src={`${IWMURL}/scraps/read/image/${order.scrap._id}`}
                className="scrap-image mr-1"
                alt="scrap"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <p className="">
                  {order.scrap.name}-{order.scrap.variant}
                </p>
                <p>{order.quantity} Tons</p>
                <p className="small-text grey-text">
                  {new Date(order.createdAt).toDateString()}
                </p>
              </div>
            </div>

            <div>
              <p
                className={`bold ${
                  selectedOrder._id === order._id ? "green-text" : ""
                }`}
              >
                #{order.orderNumber}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="flex gap-1 ">
            <p>Quantity:</p>
            <p style={{ marginTop: 0 }}>{order.quantity} Tonnes</p>
          </div>
          <div className="flex gap-1  ">
            <p>Price:</p>
            <p style={{ marginTop: 0 }}>Rs. {order.price}/Kg</p>
          </div>
          <div className="flex gap-1  ">
            <p>Status: </p>
            <span className="green-text">{order.status}</span>
          </div>
          <div className="flex gap-1  ">
            <p>Pending Quantity:</p>
            <p style={{ marginTop: 0 }}>
              {order.quantity - totalQuantity} Tonnes
            </p>
          </div>

          <div className="pg-container">
            <div
              style={{
                width: `${(totalQuantity / order.quantity) * 100}%`,
                backgroundColor:
                  selectedOrder._id === order._id ? "green" : "#6f2da8",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderBox;
