import React, { useState } from 'react'
import { DashboardCard } from '../../modules'
import ESGHeader from './component/ESGHeader'
import BoardMeetingAnalytic from './component/BoardMeetingAnalytic'
import ShareHolderChart from './component/ShareHolderChart'
import CEOChart from './component/CEOChart'
import CandidatesChart from './component/CandidatesChart'
import BoardOfDirector from './component/BoardOfDirector'
import { useMediaQuery } from 'react-responsive'
import Modal from 'react-responsive-modal'
import EnvironmentalCard from './children/Environmental/components/EnvironmentalCard'
import ShareholdQA from './component/ShareholdQA'
import WhistleQA from './component/WhistleQA'
import LawsuitsQA from './component/LawsuitsQA'
import BreachesCaseQA from './component/BreachesCaseQA'
import EthicsQA from './component/EthicsQA'
import BoardMeetingQA from './component/BoardMeetingQA'
import CEOQA from './component/CEOQA'

export default function ESG() {
  const isMobile = useMediaQuery({ query: '(max-width: 674px)' })
  const [openED, setOpenED] = useState(false)
  const [card, setCard] = useState(false)
  const [qu1Visible, setQu1Visible] = useState(false)
  const [qu2Visible, setQu2Visible] = useState(false)
  const [qu3Visible, setQu3Visible] = useState(false)
  const [qu4Visible, setQu4Visible] = useState(false)
  const [qu5Visible, setQu5Visible] = useState(false)
  const [qu6Visible, setQu6Visible] = useState(false)
  const [qu7Visible, setQu7Visible] = useState(false)
  const [modalTitle, setModalTitle] = useState('Add Social Data')
  const toggleQuestionery = () => {
    setQu1Visible(false)
    setQu2Visible(false)
    setQu3Visible(false)
    setQu4Visible(false)
    setQu5Visible(false)
    setQu6Visible(false)
    setQu7Visible(false)

    setCard(false)
  }
  const onOpenEDModal = () => {
    setOpenED(true)
  }
  const onCloseEDModal = () => setOpenED(false)

  const handleCardClick = (name) => {
    setQu1Visible(name === 'sharehold')
    setQu2Visible(name === 'agedis')
    setQu3Visible(name === 'lawsuits')
    setQu4Visible(name === 'breaches')
    setQu5Visible(name === 'ethics')
    setQu6Visible(name === 'boardmeeting')
    setQu7Visible(name === 'ceopaygapy')

    onOpenEDModal()

    switch (name) {
      case 'sharehold':
        setModalTitle('Shareholder Engagement')
        break
      case 'agedis':
        setModalTitle('Whistle blower')
        break
      case 'lawsuits':
        setModalTitle('lawsuits')
        break
      case 'breaches':
        setModalTitle('Breaches Cases')
        break
      case 'ethics':
        setModalTitle('Ethics Violations')
        break
      case 'boardmeeting':
        setModalTitle('Board Meetings')
        break
      case 'ceopaygapy':
        setModalTitle('CEO Pay Gap')
        break
      default:
        setModalTitle('Add Governance Data')
    }
  }
  return (
    <div className="p-1 full-height" style={{height:'100vh'}}>
      <ESGHeader />
      <div style={{ gap: '1.5rem' }} className={isMobile ? 'flex column':"flex mt-1"}>
        <div style={{ gap: '1.5rem' }} className="flex column flex-1">
          <DashboardCard
            title="Breaches Cases"
            amount={0}
            desc="+100% vs Pervious year"
            img="../images/breaches.png"
          />

          <DashboardCard
            title="Whistle blower"
            amount={0}
            desc="overall"
            img="../images/lawsuits.png"
          />
        </div>
        <div style={{ gap: '1.5rem' }} className="flex column flex-1">
          <DashboardCard
            title="Number of lawsuits"
            amount={0}
            desc="This Month"
            img="../images/whistle.png"
          />

          <DashboardCard
            title="Ethics Violations"
            amount={0}
            desc="overall"
            img="../images/ethics.png"
          />
        </div>
        <BoardMeetingAnalytic />
        <ShareHolderChart />
      </div>
      <div className="mt-1 flex column gap-1" style={{ position: 'relative' }}>
        <div className={isMobile ? 'flex column gap-1':"mt-1 flex gap-2"}>
          <CEOChart />
          <CandidatesChart />
          <BoardOfDirector />
        </div>
        <div
          className="ESG-btn p-1  fab-style-esg flex align-center justify-center gap-1"
          onClick={onOpenEDModal}
        >
          <span class="material-symbols-outlined" style={{ fontSize: '16px' }}>
            add
          </span>
          <p>Governance Data</p>
        </div>
      </div>
      <Modal
        center
        styles={{
          modal: {
            borderRadius: '10px',
            padding: 0,
            width: isMobile ? '92%' : '100%',
            maxWidth: '1200px',
            position: isMobile ? 'absolute' : null,
            left: isMobile ? 0 : null,
          },
        }}
        open={openED}
        onClose={onCloseEDModal}
        closeIcon={<></>}
      >
        <div className="cercle-purple p-1 white-text flex align-center space-between">
          {!card && (
            <div className="flex align-center gap-1">
              <span
                class="material-symbols-outlined"
                onClick={toggleQuestionery}
              >
                arrow_back
              </span>

              <h4>
                {' '}
                {qu1Visible
                  ? 'Shareholder Engagement'
                  : qu2Visible
                  ? 'Whistle blower'
                  : qu3Visible
                  ? 'lawsuits'
                  : qu4Visible
                  ? 'Breaches Cases'
                  : qu5Visible
                  ? 'Ethics Violations'
                  : qu6Visible
                  ? 'Board Meetings'
                  : qu7Visible
                  ? 'CEO Pay Gap'
                  : 'Add Governace Data'}
              </h4>
            </div>
          )}
          {!qu1Visible &&
            !qu2Visible &&
            !qu3Visible &&
            !qu4Visible &&
            !qu5Visible &&
            !qu6Visible &&
            !qu7Visible && (
              <div
                onClick={onCloseEDModal}
                className="flex align-center justify-center"
                style={{
                  width: 40,
                  height: 40,
                  background: 'rgba(255, 255, 255, 0.50)',
                  borderRadius: '6px',
                  cursor: 'pointer',
                }}
              >
                <span class="material-symbols-outlined">close</span>
              </div>
            )}
        </div>
        {!qu1Visible &&
          !qu2Visible &&
          !qu3Visible &&
          !qu4Visible &&
          !qu5Visible &&
          !qu6Visible &&
          !qu7Visible && (
            <>
              <div className="p-2">
                <div className="row-1 flex gap-2">
                  <div onClick={() => handleCardClick('sharehold')}>
                    <EnvironmentalCard
                      name="sharehold"
                      image="../images/agedis.png"
                      icon="../images/ic-sharehold.png"
                      title="Shareholder Engagement"
                      desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                    />
                  </div>
                  <div onClick={() => handleCardClick('agedis')}>
                    <EnvironmentalCard
                      name="agedis"
                      image="../images/agedis.png"
                      icon="../images/ic-sharehold.png"
                      title="Whistle blower"
                      desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                    />
                  </div>
                  <div onClick={() => handleCardClick('lawsuits')}>
                    <EnvironmentalCard
                      name="lawsuits"
                      image="../images/lawsuits1.png"
                      icon="../images/lawsuits.png"
                      title="lawsuits"
                      desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                    />
                  </div>
                </div>
                <div className="row-1 flex gap-2">
                  <div onClick={() => handleCardClick('breaches')}>
                    <EnvironmentalCard
                      name="breaches"
                      image="../images/breaches1.png"
                      icon="../images/breaches.png"
                      title="Breaches Cases"
                      desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                    />
                  </div>
                  <div onClick={() => handleCardClick('ethics')}>
                    <EnvironmentalCard
                      name="ethics"
                      image="../images/ethics1.png"
                      icon="../images/ethics.png"
                      title="Ethics Violations"
                      desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                    />
                  </div>
                  <div onClick={() => handleCardClick('boardmeeting')}>
                    <EnvironmentalCard
                      name="boardmeeting"
                      image="../images/boardmeeting.png"
                      icon="../images/group.png"
                      title="Board Meetings"
                      desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                    />
                  </div>
                </div>
                <div
                  style={{ width: '33%' }}
                  onClick={() => handleCardClick('ceopaygapy')}
                >
                  <EnvironmentalCard
                    name="ceopaygapy"
                    image="../images/ceopaygapy.png"
                    icon="../images/group.png"
                    title="CEO Pay Gap"
                    desc="High satisfaction boosts ESG scores through engaged workforce, reduced turnover, and positive impact."
                  />
                </div>
              </div>
            </>
          )}
        {qu1Visible && (
          <div className="qu-1">
            <div
              className="full-width flex justify-center align-center gap-1"
              style={{ background: '#FFFDBE', padding: '5px' }}
            >
              <span
                class="material-symbols-outlined"
                style={{ fontSize: '14px' }}
              >
                info
              </span>
              <p className="purple-cercle-text small-text">
                Data entries require supporting documentation for verification.
              </p>
            </div>
            <ShareholdQA />
          </div>
        )}
        {qu2Visible && (
          <div className="qu-1">
            <WhistleQA />
          </div>
        )}
        {qu3Visible && (
          <div className="qu-1">
            <LawsuitsQA />
          </div>
        )}
        {qu4Visible && (
          <div className="qu-1">
            <BreachesCaseQA />
          </div>
        )}
        {qu5Visible && (
          <div className="qu-1">
            <EthicsQA />
          </div>
        )}
        {qu6Visible && (
          <div className="qu-1">
            <BoardMeetingQA />
          </div>
        )}
        {qu7Visible && (
          <div className="qu-1">
            <CEOQA />
          </div>
        )}
      </Modal>
    </div>
  )
}