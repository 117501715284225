import React from "react";
import AddScrapContract from "../../pickups/components/AddScrapContract";
import { Checkbox, Modal } from "react-materialize";
import { Modal as RSModal } from "react-responsive-modal";
import { ClipLoader } from "react-spinners";
import ScrapContracts from "../../pickups/components/ScrapContract";
import RecentTransactionTable from "../../pickups/components/RecentTransactionTable";
import { PickupHistoryTable } from "../../../modules";
import QuantityOverTime from "../../pickups/components/QuantityOverTime";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { getHistoryWithANetwork } from "../../../redux/action/pickups";
import { getNetwork } from "../../../redux/action/organization";
import { getContracts } from "../../../redux/action/contracts";
import { useParams } from "react-router-dom";
import AnalyticCard from "../../../modules/AnalyticCard";
import { IWMService } from "../../../redux/config/config";

const SingleNetwork = ({}) => {
  const cardData = [
    {
      icon: "local_shipping",
      bgColor: "#D6EAF8",
      iconColor: "#347AE2",
    },
    { icon: "forklift", bgColor: "#F0FFF6", iconColor: "rgba(39, 174, 96, 1)" },
  ];
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1224px)" });
  const isLaptop = useMediaQuery({ query: "(max-width: 1600px)" });
  const { network: myNetwork, organization: org } = useSelector(
    (state) => state.organization
  );
  const { networkHistory } = useSelector((state) => state.pickups);
  const { contracts, loading } = useSelector((state) => state.contracts);
  const [scrapContracts, setScrapContracts] = useState([]);
  const [network, setNetwork] = useState({});
  const [block, setBlock] = useState(false);
  const [remainder, setRemainder] = useState(false);
  const onCloseBlockModal = () => setBlock(false);
  const onCloseRemainderModal = () => setRemainder(false);
  const { scraps } = useSelector((state) => state.scraps);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (isEmpty(myNetwork)) dispatch(getNetwork());
    if (isEmpty(contracts)) dispatch(getContracts());
  }, []);

  useEffect(() => {
    const network = myNetwork.find(
      (network) => network.organization?._id === id
    );
    if (network) setNetwork(network);
    setNetwork(network);
  }, [id, myNetwork]);

  useEffect(() => {
    setScrapContracts(
      contracts.filter(
        (contract) => contract?.processor?._id === network?.organization?._id
      )
    );
  }, [contracts, network]);

  useEffect(() => {
    if (isEmpty(network)) return;
    dispatch(getHistoryWithANetwork(network?.organization?._id));
  }, [dispatch, network]);

  if (isEmpty(network))
    return (
      <div className="flex align-center justify-center mt-2 mb-2">
        <p>Network not found </p>
      </div>
    );

  return (
    <div className="p-1">
      <div className="box white flex space-between align-center p-1">
        <div className="flex align-center gap-2">
          <div className="icon-box" style={{ position: "relative" }}>
            <span
              class="material-symbols-outlined purple-text"
              style={{
                position: "absolute",
                top: 22,
                left: 28,
                fontSize: "38px",
              }}
            >
              corporate_fare
            </span>
          </div>
          <div>
            <span className="flex align-center gap-2 ">
              <h4>{network?.organization?.name}</h4>
              <span
                className="green-text flex align-center gap-1"
                style={{
                  background: "rgba(225, 255, 238, 1)",
                  width: "100px",
                  height: "25px",
                  borderRadius: "10px",
                  paddingLeft: "10px",
                }}
              >
                <span
                  class="material-symbols-outlined"
                  style={{ fontSize: "18px" }}
                >
                  verified_user
                </span>
                <p style={{ marginTop: "4px" }}>Verified</p>
              </span>
            </span>

            <p className="normal-text capitalize">{network?.role}</p>
            <p className="normal-text">{network.organization?.owner?.email}</p>
          </div>
        </div>

        <div className="flex column gap-1">
          {/* <div
            className="block-btn pointer flex align-center gap-1 p-1"
            style={{ marginLeft: "22px" }}
            onClick={() => {
              viewBlock();
            }}
          >
            <span style={{ textTransform: "none" }}>Block</span>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "16px" }}
            >
              block
            </span>
          </div> */}

          {/* <div
            className="remainder-btn pointer flex align-center gap-1 white-text p-1"
            onClick={() => {
              viewRemainder();
            }}
          >
            <span style={{ color: "#fff", textTransform: "none" }}>
              Remainder
            </span>
            <span
              class="material-symbols-outlined"
              style={{ fontSize: "16px" }}
            >
              notifications_active
            </span>
          </div> */}
        </div>
      </div>
      <div className="flex gap-2">
        <div
          className="box mt-1 white"
          style={{
            width: "25%",
            border: "1px solid #EDEDEE",
            borderRadius: "10px",
          }}
        >
          <h4 className="ml-1">About</h4>
          <div className="ml-1">
            <span className="flex gap-1 mt-1">
              <p className="grey-text">CONTACT PERSON</p>
              <p className="normal-text capitalize">
                {network.organization.owner?.name}
              </p>
            </span>
            <span className="flex gap-1 mt-1">
              <p className="grey-text">PHONE NUMBER</p>
              <p className="normal-text">
                {network.organization.owner?.phoneNumber}
              </p>
            </span>
            <span className="flex gap-1 mt-1">
              <p className="grey-text">ASSIGNED LOCATION</p>
              <p className="normal-text">
                {
                  org.locations.find((location) =>
                    network.locations.includes(location._id)
                  )?.nickname
                }
              </p>
            </span>
            <span className="flex column gap-1 mt-1">
              <p className="grey-text">ASSOCIATED SCRAPS</p>
              <div className="flex" style={{ gap: "5px" }}>
                {!network.scraps.length ? (
                  <p>No Scrap Associated</p>
                ) : (
                  network.scraps?.slice(0, 2).map((scrap, i) => (
                    <section
                      style={{
                        width: "10vw",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#F7EEFE",
                        }}
                        className="capsule-container cercle-purple-text"
                      >
                        <p>
                          {scraps.find((s) => s._id === scrap)?.name}-
                          {scraps.find((s) => s._id === scrap)?.variant}
                        </p>
                      </div>
                      {network.scraps.length > 2 && i === 1 && (
                        <Modal
                          trigger={
                            <span
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              className="purple-text pointer"
                            >
                              +{network.scraps.length - 2} more...
                            </span>
                          }
                          header="Scraps Associated"
                        ></Modal>
                      )}
                    </section>
                  ))
                )}
              </div>
            </span>
          </div>
        </div>
        <div
          className="box mt-1 white"
          style={{
            width: "25%",
            border: "1px solid #EDEDEE",
            borderRadius: "10px",
          }}
        >
          <div className="flex space-between align-center">
            <span className="flex align-center gap-2 space-between full-width">
              <h4 className="ml-1">GST Document</h4>

              {network.organization.gstVerified && (
                <span
                  className="flex align-center gap-1"
                  style={{ color: "#3484F0" }}
                >
                  <span class="material-icons" style={{ fontSize: "19px" }}>
                    check_circle
                  </span>
                  <p style={{ marginTop: "5px" }}>Valid</p>
                </span>
              )}
            </span>
          </div>

          {network.organization.gstDocument ? (
            <div>
              <div className="ml-1 mt-2">
                <p className="grey-text normal-text">GST NUMBER</p>
                <p className="normal-text">{network.organization.gst}</p>
              </div>
              <div className="ml-1 mt-2">
                <p className="grey-text normal-text">DOCUMENT</p>
                <div className="flex align-center gap-1">
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#B90E0A" }}
                  >
                    picture_as_pdf
                  </span>
                  <span>
                    <a
                      href={`${IWMService.baseURL}/organization/read/docs/${network.organization._id}?type=gstDocument`}
                      className="normal-text cercle-purple-text"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View GST Document{" "}
                    </a>
                    <p className="grey-text small-text">250 KB</p>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-1 text-center">
              <span class="material-symbols-outlined">description</span>
              <p>File not available!</p>

              <p className="small-text grey-text mt-1">
                You can send a request to upload the GST Document
              </p>
              <button className="btn-small black lighten  mt-1">
                Send Request
                <span
                  className="material-symbols-outlined ml-1"
                  style={{ position: "absolute", top: 10 }}
                >
                  send
                </span>
              </button>
            </div>
          )}
        </div>

        <div
          className="box mt-1 white"
          style={{
            width: "25%",
            border: "1px solid #EDEDEE",
            borderRadius: "10px",
          }}
        >
          <div className="flex space-between align-center">
            <span className="flex align-center gap-2 space-between full-width">
              <h4 className="ml-1">CPCB Certificate</h4>

              {network.organization.gstVerified && (
                <span
                  className="flex align-center gap-1"
                  style={{ color: "#3484F0" }}
                >
                  <span class="material-icons" style={{ fontSize: "19px" }}>
                    check_circle
                  </span>
                  <p style={{ marginTop: "5px" }}>Valid</p>
                </span>
              )}
            </span>
          </div>

          {network.organization.cpcbCertificate ? (
            <div>
              <div className="ml-1 mt-2">
                <p className="grey-text normal-text">DOCUMENT</p>
                <div className="flex align-center gap-1">
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#B90E0A" }}
                  >
                    picture_as_pdf
                  </span>
                  <span>
                    <a
                      href={`${IWMService.baseURL}/organization/read/docs/${network.organization._id}?type=cpcbCertificate`}
                      className="normal-text cercle-purple-text"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View CPCB Certificate{" "}
                    </a>
                    <p className="grey-text small-text">250 KB</p>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-1 text-center">
              <span class="material-symbols-outlined">description</span>
              <p>File not available!</p>

              <p className="small-text grey-text mt-1">
                You can send a request to upload the CPCB Certificate
              </p>
              <button className="btn-small black lighten  mt-1">
                Send Request
                <span
                  className="material-symbols-outlined ml-1"
                  style={{ position: "absolute", top: 10 }}
                >
                  send
                </span>
              </button>
            </div>
          )}
        </div>

        <div
          className="mt-1 full-width flex column gap-2"
          style={{
            width: "25%",
          }}
        >
          <AnalyticCard
            count="24"
            title="Total Pickups"
            trend="20%"
            icon={cardData[0].icon}
            bgColor={cardData[0].bgColor}
            iconColor={cardData[0].iconColor}
          />
          <div
            style={{
              background: "#fff",
              borderRadius: "10px",
              boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.10)",
              height: isLaptop ? "19vh" : "16vh",
            }}
            className={isTablet ? "box" : "flex column gap-1 full-width"}
          >
            <h3 className="ml-1">240 MT</h3>
            <div className="flex align-center" style={{ width: "60%" }}>
              <select
                className="browser-default ml-1"
                style={{
                  width: "80%",
                  appearance: "none",
                  color: "#717D96",
                  fontSize: isTablet ? "1.2rem" : "1.2rem",
                  border: "none",
                }}
              >
                <option
                  className="p-1 ml-1"
                  style={{ background: "#ECE4F4", color: "#6F2DA8" }}
                >
                  <h4>Total Channelized </h4>
                </option>
                <option style={{ background: "#ECE4F4", color: "#6F2DA8" }}>
                  Plastic
                </option>
                <option style={{ background: "#ECE4F4", color: "#6F2DA8" }}>
                  Paper
                </option>
              </select>
              <span
                className="material-symbols-outlined"
                style={{ color: "#717D96" }}
              >
                arrow_drop_down
              </span>
            </div>

            <div className="flex align-center gap- ml-1 ">
              <span className="material-icons green-text">north_east</span>
              <div className=" flex space-between align-center full-width">
                <span
                  className="flex gap-1 align-center"
                  style={{ color: "#27AE60" }}
                >
                  20
                  <p style={{ color: "rgba(0, 0, 0, 0.35)" }}>
                    vs last 30 days
                  </p>
                </span>
                <div
                  style={{
                    width: 45,
                    height: 45,
                    background: `${cardData[1].bgColor}`,
                  }}
                  className="circle flex align-center justify-center mr-1"
                >
                  <span
                    className={`material-icons`}
                    style={{ color: `${cardData[1].iconColor}` }}
                  >
                    {cardData[1].icon}
                  </span>
                </div>
              </div>
            </div>

            <RSModal
              center
              open={block}
              onClose={onCloseBlockModal}
              closeIcon={<></>}
              styles={{
                modal: {
                  borderRadius: "10px",
                  padding: 0,
                  width: isMobile ? "92%" : "35%",
                  position: isMobile ? "absolute" : null,
                  left: isMobile ? 0 : null,
                },
              }}
            >
              <div className="cercle-purple p-2 white-text flex align-center space-between">
                <h4>Block</h4>
                <div
                  onClick={onCloseBlockModal}
                  className="flex align-center justify-center"
                  style={{
                    width: 40,
                    height: 40,
                    background: "rgba(255, 255, 255, 0.50)",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                >
                  <span class="material-symbols-outlined">close</span>
                </div>
              </div>
              <div className="p-1 ml-1 mt-1">
                <h6>Reasons for Blocking</h6>
                <span className="flex align-center">
                  <Checkbox />
                  <p>Not Accepting Pickup Requests</p>
                </span>
                <span className="flex align-center">
                  <Checkbox />
                  <p>Not On Time for Pickup</p>
                </span>
                <span className="flex align-center">
                  <Checkbox />
                  <p>Not Responding</p>
                </span>
                <span className="flex align-center">
                  <Checkbox />
                  <p>Lack of Transparency</p>
                </span>
                <span className="flex align-center">
                  <Checkbox />
                  <p>Other</p>
                </span>

                <span>
                  <textarea
                    className="browser-default input-style"
                    name="description"
                    rows="3"
                    placeholder="Description"
                    style={{
                      padding: "10px",
                      width: "60%",
                      height: "10%",
                      fontSize: "18px",
                    }}
                  />
                </span>
                <div className="flex gap-2 justify-end mt-1">
                  <button className="btn-outline" onClick={onCloseBlockModal}>
                    Cancel
                  </button>
                  <button className="btn">Send</button>
                </div>
              </div>
            </RSModal>

            <RSModal
              center
              open={remainder}
              onClose={onCloseRemainderModal}
              closeIcon={<></>}
              styles={{
                modal: {
                  borderRadius: "10px",
                  padding: 0,
                  width: isMobile ? "92%" : "35%",
                  position: isMobile ? "absolute" : null,
                  left: isMobile ? 0 : null,
                },
              }}
            >
              <div className="cercle-purple p-2 white-text flex align-center space-between">
                <h4>Reminder & Action Center</h4>
                <div
                  onClick={onCloseRemainderModal}
                  className="flex align-center justify-center"
                  style={{
                    width: 40,
                    height: 40,
                    background: "rgba(255, 255, 255, 0.50)",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                >
                  <span class="material-symbols-outlined">close</span>
                </div>
              </div>
              <div className="p-1 ml-1 mt-1">
                <div>
                  <h6>Reminder Type</h6>
                  <div
                    className="flex mt-1 "
                    style={{ width: "80%", position: "relative" }}
                  >
                    <select className="browser-default new-select-dropdown">
                      <option>Select the reminder</option>
                      <option>All</option>
                      <option>WhatsApp</option>
                      <option>SMS</option>
                      <option>Email</option>
                    </select>
                    <span
                      class="material-symbols-outlined"
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        color: "#6F2DA8",
                      }}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>

                <div>
                  <h6>Delivery Method</h6>
                  <div
                    className="flex mt-1"
                    style={{ width: "80%", position: "relative" }}
                  >
                    <select className="browser-default new-select-dropdown">
                      <option>Select the method</option>
                      <option>All</option>
                      <option>WhatsApp</option>
                      <option>SMS</option>
                      <option>Email</option>
                    </select>
                    <span
                      class="material-symbols-outlined"
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        color: "#6F2DA8",
                      }}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
                <div className="mt-1">
                  <h6>Additional Information</h6>
                  <span>
                    <textarea
                      className="browser-default input-style"
                      name="description"
                      rows="3"
                      placeholder="Description"
                      style={{
                        padding: "10px",
                        width: "60%",
                        height: "10%",
                        fontSize: "18px",
                      }}
                    />
                  </span>
                </div>
                <div className="flex gap-2 justify-end mt-1">
                  <button
                    className="btn-outline"
                    onClick={onCloseRemainderModal}
                  >
                    Cancel
                  </button>
                  <button className="btn">Send</button>
                </div>
              </div>
            </RSModal>
          </div>
        </div>
      </div>

      <div className="mt-1 p-1">
        <div className="flex space-between align-center mb-1 ">
          <p className="bold ">Contracts For Scraps</p>

          <Modal
            id="add-new-scrap"
            trigger={<button className="btn-small">Add Scrap Contract</button>}
            actions={[]}
          >
            <AddScrapContract processor={network?.organization?._id} />
          </Modal>
        </div>

        {loading ? (
          <div className="flex align-center justify-center mt-2 mb-2">
            <ClipLoader color="#6f2da8" loading={true} size={30} />
          </div>
        ) : !scrapContracts?.length ? (
          <div className="flex column align-center full-width  mv-2 p-2">
            <span
              className="material-symbols-outlined grey-text"
              style={{ fontSize: "2rem" }}
            >
              file_copy
            </span>
            <p className="grey-text mt-1 ">
              Currently you don’t have any scraps added.
            </p>
          </div>
        ) : (
          <ScrapContracts selectedNetwork={network} />
        )}
      </div>

      <div className={isTablet ? "column" : "flex gap-1  mt-1"}>
        <RecentTransactionTable data={networkHistory} />
      </div>

      <div className="mt-2">
        <PickupHistoryTable data={networkHistory} />
      </div>

      <div className="mt-2">
        <QuantityOverTime organization={network?.organization?._id} />
      </div>
    </div>
  );
};
export default SingleNetwork;
