import React, { useEffect } from "react";
import { Button, Modal } from "react-materialize";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  acceptPickup,
  getAvailablePickups,
  getOrders,
  getSingleOrder,
  ignorePickup,
} from "../../redux/action/pickups";
import { IWMURL } from "../../redux/config/urlConfig";
import { notify } from "../../helpers/helper";
import { ClipLoader } from "react-spinners";
import DashboardPickupHistoryTable from "../dashboard/components/DashboardPickupsHistory";
import CreateOrder from "./components/CreateOrder";
import OrderBox from "./components/OrderBox";
import OrderSearchBar from "./components/OrderSearchBar";
import { PickupStatus } from "./components/PickupStatus";

const PickupsReycler = () => {
  const { availablePickups, contract, accepting, loading } = useSelector(
    (state) => state.pickups
  );
  const { organization } = useSelector((state) => state.organization);
  const [location, setLocation] = React.useState("");
  const { orders, orderLoading } = useSelector((state) => state.pickups);
  const [order, setOrder] = React.useState({});
  const [ordersToDisplay, setOrdersToDisplay] = React.useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAvailablePickups());
    dispatch(getOrders());
  }, [dispatch]);

  useEffect(() => {
    if (!orders.length) return;

    setOrder({
      _id: "all",
      pickups: availablePickups,
    });

    setOrdersToDisplay(orders);
  }, [availablePickups, orders]);

  return (
    <div className="p-1">
      <div className="flex align-center gap-1 mb-1 space-between ">
        <h3 className="bold">Material Sourcing Announcement & Pickups </h3>

        <Modal
          trigger={
            <Button
              className="btn"
              tooltip="Post a material sourcing announcement to all of your networks"
            >
              Post New Announcement
            </Button>
          }
          actions={[]}
          id="create-order-modal"
        >
          <CreateOrder />
        </Modal>
      </div>

      <section className="flex gap-1">
        <div className="order-container">
          <OrderSearchBar
            setOrdersToDisplay={setOrdersToDisplay}
            ordersToDisplay={ordersToDisplay}
            orders={orders}
          />

          <div
            className={`order-all-pickups-btn ${
              order._id === "all" ? "order-box-selected green lighten-5" : ""
            }`}
            onClick={() => {
              setOrder({
                _id: "all",
                pickups: availablePickups,
              });
            }}
          >
            <p>All Available Pickup Requests</p>

            <div>
              <i className="material-symbols-outlined">double_arrow</i>
            </div>
          </div>

          {orderLoading || loading ? (
            <div className="flex justify-center full-width mt-2 mb-2">
              <ClipLoader color="#6f2da8" size={20} />
            </div>
          ) : !orders.length ? (
            <div className="flex justify-center">
              <div className="flex column align-center full-width  mv-2 p-2">
                <i
                  className="material-symbols-outlined grey-text"
                  style={{ fontSize: 80 }}
                >
                  inventory_2
                </i>
                <p className="grey-text mt-1 ">
                  You have no orders in your inventory.
                </p>
              </div>
            </div>
          ) : (
            ordersToDisplay.map((ord, index) => {
              return (
                <OrderBox
                  order={ord}
                  key={index}
                  setOrder={setOrder}
                  selectedOrder={order}
                />
              );
            })
          )}
        </div>

        <div className="order-pickups">
          {order._id === "all" ? (
            <p className="bold">All Available Material Pickup Request</p>
          ) : (
            <div className="flex space-between align-center">
              <p className="">Pickups Associated With Announcement</p>

              <p className="bold green-text">#{order.orderNumber}</p>
            </div>
          )}
          <main>
            {loading ? (
              <div className="flex justify-center full-width mt-2 mb-2">
                <ClipLoader color="#6f2da8" size={20} />
              </div>
            ) : order.pickups?.length === 0 ? (
              <div className="flex justify-center full-width mt-2 mb-2">
                <div className="flex column align-center full-width  mv-2 p-2">
                  <i
                    className="material-symbols-outlined grey-text"
                    style={{ fontSize: 80 }}
                  >
                    inventory_2
                  </i>
                  <p className="grey-text mt-1 ">
                    You have no pickups in your inventory.
                  </p>
                </div>
              </div>
            ) : (
              <table className="striped  payment-history  ">
                <thead>
                  <tr>
                    <th>Scrap</th>
                    <th>Date</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {order.pickups?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className="flex align-center gap-1 ">
                            <img
                              src={`${IWMURL}/scraps/read/image/${item.scrap._id}`}
                              className="scrap-image"
                              alt="scrap"
                            />

                            <p>
                              {item.scrap.name}-{item.scrap.variant}
                            </p>
                          </span>
                        </td>
                        <td>{new Date(item.date).toDateString()}</td>
                        <td style={{}}>
                          <div>
                            <p>{item.pickupLocation?.address}</p>
                            <PickupStatus item={item} />
                          </div>
                        </td>
                        <td></td>
                        <td>INR {item.price}/Kg</td>
                        <td>{item.quantity}</td>

                        {item.accepted ? (
                          <td>
                            <button
                              className="btn-small white cercle-purple-text"
                              onClick={() => navigate("/pickup/" + item._id)}
                            >
                              View
                            </button>
                          </td>
                        ) : (
                          <td>
                            <div
                              className={"flex full-width align-center gap-1 "}
                            >
                              <Modal
                                id={"accept-pickup-modal-" + index}
                                trigger={
                                  <button className="btn waves-effect waves-purple">
                                    Accept
                                  </button>
                                }
                                actions={[]}
                              >
                                <p className="bold ">Accept pickup</p>

                                <div className="">
                                  <label className="black-text">
                                    Select a drop location for the pickup
                                  </label>
                                  <div className="row  ">
                                    <select
                                      className="input-style full-width browser-default "
                                      name="location"
                                      value={location}
                                      onChange={(e) =>
                                        setLocation(e.target.value)
                                      }
                                    >
                                      <option value={""}>--select--</option>
                                      {organization.locations?.map(
                                        (location, i) => (
                                          <option value={location._id} key={i}>
                                            {location.nickname}-
                                            {location.address}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="flex gap-1 mt-1">
                                  <button
                                    className="btn waves-effect waves-purple"
                                    onClick={(e) => {
                                      const elem = document.getElementById(
                                        "accept-pickup-modal-" + index
                                      );
                                      const instance =
                                        window.M.Modal.getInstance(elem);
                                      e.preventDefault();
                                      if (!location) {
                                        return notify(
                                          "Please select a drop location"
                                        );
                                      }
                                      const loc = organization.locations.find(
                                        (loc) => loc._id === location
                                      );
                                      dispatch(
                                        acceptPickup(
                                          item._id,
                                          { dropLocation: loc },
                                          () => {
                                            notify(
                                              "Pickup accepted successfully!"
                                            );
                                            if (item.order) {
                                              dispatch(
                                                getSingleOrder(item.order)
                                              );
                                            }
                                            dispatch(getAvailablePickups());
                                            instance.close();
                                          }
                                        )
                                      );
                                    }}
                                  >
                                    {accepting ? (
                                      <ClipLoader color="#fff" size={20} />
                                    ) : (
                                      "Accept Pickup"
                                    )}
                                  </button>
                                  <button
                                    className="btn-outline waves-effect waves-purple "
                                    onClick={(e) => {
                                      const elem = document.getElementById(
                                        "accept-pickup-modal-" + index
                                      );
                                      const instance =
                                        window.M.Modal.getInstance(elem);

                                      e.preventDefault();
                                      instance.close();
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </Modal>

                              <Link
                                to="/"
                                className="purple-text ml-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  dispatch(ignorePickup(item._id));
                                }}
                              >
                                Ignore
                              </Link>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </main>
        </div>
      </section>
    </div>
  );
};

export default PickupsReycler;
