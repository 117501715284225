import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { ResponsiveContainer } from 'recharts'
import { Dropdown } from 'react-materialize'
import { useMediaQuery } from 'react-responsive'

const CustomBar = (props) => {
  const { x, y, width, height } = props

  const dotSize = 20

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill="#EDD8FF" rx={8} />

      <circle
        cx={x + 10}
        cy={y}
        r={dotSize / 2}
        fill="#6B3E99"
        filter="drop-shadow(0px 7px 8px rgba(74, 58, 255, 0.13))"
      />
    </g>
  )
}

const data = [
  {
    name: 'TN',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'KL',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'KA',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'MH',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'AP',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
]

function EmployeeDistributionChart() {
  const [number, setNumber] = useState(3)
  const isMobile = useMediaQuery({ query: '(max-width:740px)' })
  return (
    <div
      className="quantity-channelized-chart box white"
      style={{ borderRadius: '12px', width: isMobile ? '100%' : '40%' }}
    >
      <div className="flex space-between align-center mb-1 p-1">
        <h5>Employee distribution</h5>
      </div>

      <ResponsiveContainer width="100%" height={235}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          barSize={20}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
          />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />

          <Bar dataKey="pv" shape={<CustomBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default EmployeeDistributionChart
