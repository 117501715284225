import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

function ContractsList({ setNetwork, selectedNetwork, scrap, pickupLocation }) {
  const { network, networkLoading } = useSelector(
    (state) => state.organization
  );
  const { scraps } = useSelector((state) => state.scraps);

  const [dataToShow, setDataToShow] = useState([]);

  useEffect(() => {
    if (pickupLocation && !scrap) {
      setDataToShow(
        network.filter((network) => network?.locations.includes(pickupLocation))
      );
    }

    if (!pickupLocation && scrap) {
      setDataToShow(
        network.filter((network) => network.scraps.includes(scrap))
      );
    }

    if (pickupLocation && scrap) {
      setDataToShow(
        network.filter(
          (network) =>
            network?.locations.includes(pickupLocation) &&
            network.scraps.includes(scrap)
        )
      );
    }
  }, [network, pickupLocation, scrap]);

  useEffect(() => {
    setNetwork(dataToShow[0]);
  }, [dataToShow, setNetwork]);

  return (
    <div className="raise-pickups-sidebar grey lighten-4">
      {networkLoading ? (
        <div className="flex align-center justify-center full-height">
          <ClipLoader size={20} color={"#6f2da8"} />
          <p className="ml-1">Loading...</p>
        </div>
      ) : dataToShow?.length === 0 ? (
        <div className="flex align-center justify-center white box full-height">
          <div className="text-center">
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 50, color: "#666" }}
            >
              group
            </span>
            <p className="text-center">
              You have no waste processors that can handle this{" "}
              {scrap ? (
                <span className="cercle-purple-text">
                  {scraps.find((s) => s._id === scrap)?.name} -
                  {scraps.find((s) => s._id === scrap)?.variant}
                </span>
              ) : (
                "scrap"
              )}{" "}
              in this location
            </p>
          </div>
        </div>
      ) : (
        <>
          {dataToShow.map((network, i) => {
            const isSelected = network?._id === selectedNetwork?._id;
            return (
              <div
                className={
                  isSelected ? "selected-network2 " : "unselected-network2 "
                }
                key={i}
                onClick={() => {
                  setNetwork(network);
                }}
              >
                <div className="">
                  <div className="">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="material-symbols-outlined cercle-purple-text mr-1">
                        apartment
                      </span>

                      <p
                        className="capitalize"
                        style={{
                          color: isSelected ? "#6f2da8" : "#666",
                        }}
                      >
                        {network?.organization?.name}
                      </p>
                    </div>

                    <div className="flex align-center">
                      <span className="material-symbols-outlined cercle-purple-text mr-1">
                        handshake
                      </span>
                      <p className="capitalize">{network.role}</p>
                    </div>
                  </div>

                  {isSelected && (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        background: "#6f2da8",
                        borderRadius: "50%",
                        marginTop: ".5rem",
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default ContractsList;
